import {FC, useRef} from 'react';
import {Virtuoso} from 'react-virtuoso';

import {useEffectOnce} from 'shared/hooks';
import {TEMPORARY_TYPE} from 'shared/layouts/BaseLayout/Header/Notifications/constants';
import {useNotificationsContext} from 'shared/layouts/BaseLayout/Header/Notifications/NotificationContext';
import {bem} from 'shared/utils';

import {Notification} from './components/Notification';
import {Scroller} from './components/Scroller';
import s from './NotificationList.module.scss';

const sn = bem('notificationList', s);

type Props = {toggleNotifications: () => void};

export const NotificationList: FC<Props> = ({toggleNotifications}) => {
  const parentRef = useRef<HTMLDivElement | null>(null);
  const {hasUnread, idOfFirstRead, endReached, notificationList, setRecentlyRead} = useNotificationsContext();

  useEffectOnce(() => {
    setRecentlyRead(
      notificationList.filter((item) => !item.isRead && item.severityType !== TEMPORARY_TYPE).map((item) => item.id),
    );
  });

  return (
    <div className={s.notificationList} ref={parentRef}>
      {!!notificationList.length ? (
        <div className={s.notificationList_virtuoso}>
          <Virtuoso
            data={notificationList}
            endReached={endReached}
            components={{Scroller}}
            itemContent={(index, channel) => {
              const {id} = channel;
              return (
                <>
                  {hasUnread && index === 0 && <p className={sn('caption')}>Новые</p>}
                  {id === idOfFirstRead && <p className={sn('caption')}>Ранее</p>}
                  <Notification
                    channel={channel}
                    parent={parentRef.current}
                    toggleNotifications={toggleNotifications}
                  />
                </>
              );
            }}
          />
        </div>
      ) : (
        <p>Список уведомлений пуст</p>
      )}
    </div>
  );
};
