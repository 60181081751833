import * as Sentry from '@sentry/browser';
import {useState} from 'react';

import {FullAccessRequestDTO} from 'api/dto/FullAccessRequestDTO';
import {fullAccessRequestsApi} from 'api/services/fullAccessRequestsApi';
import {ClipLoader} from 'shared/components/ClipLoader';
import {DataTable} from 'shared/components/DataTable';
import {SearchInput} from 'shared/components/SearchInput';
import {Card, CardContent, CardHeader} from 'shared/components/shadcn-ui/Card';
import {useEffectOnce} from 'shared/hooks';
import {useDebounce} from 'shared/hooks/useDebounce';
import {cn, handleApiErrors} from 'shared/utils/helpers';

import {getColumns} from './utils/constants';

export const Requests = () => {
  const [requestList, setRequestList] = useState<FullAccessRequestDTO[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredRequestList, setFilteredRequestList] = useState<FullAccessRequestDTO[]>([]);

  const columns = getColumns();

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const data = await fullAccessRequestsApi.getRequests();
        setRequestList(data);
        setFilteredRequestList(data);
      } catch (error) {
        Sentry.captureException(error);
        handleApiErrors(error, 'Произошла ошибка при загрузке списка запросов на полный доступ.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  });

  const handleSearchInputChange = useDebounce((searchValue) => {
    if (typeof searchValue === 'string') {
      const filteredCompanies = requestList?.filter(({companyName}) =>
        companyName.toLowerCase().includes(searchValue.toLowerCase().trim()),
      );
      setFilteredRequestList(filteredCompanies);
    }
  }, 500);

  if (isLoading) return <ClipLoader />;

  return (
    <Card className="p-8">
      <CardHeader className={cn({['justify-center flex-col gap-2']: !requestList.length})}>
        <SearchInput className="self-start" placeholder="Поиск..." onChange={handleSearchInputChange} />
      </CardHeader>
      <CardContent>
        <DataTable columns={columns} data={filteredRequestList} />
      </CardContent>
    </Card>
  );
};
