import {camelizeKeys} from 'humps';

import ApiClient from 'api/client';
import {DaDataCompanySuggestion} from 'shared/models/DaDataCompanySuggestion';

export class DaDataSuggestionService {
  private basePath = '/da-data-suggestions';
  async getDataByINN(searchQuery: string) {
    return ApiClient.get<DaDataCompanySuggestion[]>(`${this.basePath}/company`, {
      params: {query: searchQuery},
    }).then((res) =>
      res.data.length
        ? (camelizeKeys(res.data[0], (key, convert) => {
            return key === 'actuality_date' ? key : convert(key);
          }) as DaDataCompanySuggestion)
        : null,
    );
  }
}

export const DaDataSuggestionApi = new DaDataSuggestionService();
