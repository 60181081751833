import * as z from 'zod';

import {REQUIRED_FIELD_MSG} from 'shared/utils/validation';

const COMPANY_NAME_MSG = 'Проверьте наименование организации';
const LEGAL_ADDRESS_MSG = 'Проверьте юридический адрес';

export const FormSchema = z.object({
  inn: z.string(),
  companyName: z
    .string()
    .nonempty({message: REQUIRED_FIELD_MSG})
    .min(3, {message: COMPANY_NAME_MSG})
    .max(180, {message: COMPANY_NAME_MSG}),

  legalAddress: z
    .string()
    .nonempty({message: REQUIRED_FIELD_MSG})
    .min(3, {message: LEGAL_ADDRESS_MSG})
    .max(180, {message: LEGAL_ADDRESS_MSG}),
});
