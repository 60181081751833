export const filterCurrentYear = <T extends Record<string, any>>(
  globalData: {[key: number]: T[]},
  currentYear: number,
): {[key: number]: T[]} => {
  const filteredGlobalData: {[key: number]: T[]} = {};

  Object.keys(globalData).forEach((key) => {
    if (Number(key) !== currentYear) {
      filteredGlobalData[Number(key)] = globalData[Number(key)];
    }
  });

  return filteredGlobalData;
};
