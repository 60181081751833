import {ChevronDown, ChevronUp, X} from 'lucide-react';
import {FC, useState, MouseEvent, useEffect} from 'react';

import {Popover, PopoverContent, PopoverTrigger} from 'shared/components/shadcn-ui/Popover';
import {bem} from 'shared/utils';
import {cn} from 'shared/utils/helpers';

import s from './MultiSelect.module.scss';

import {Badge} from '../shadcn-ui/Badge';
import {Button} from '../shadcn-ui/Button';
import {Checkbox} from '../shadcn-ui/Checkbox';
import {Command, CommandEmpty, CommandGroup, CommandHeader, CommandInput, CommandItem} from '../shadcn-ui/Command';
import {ItemType} from '../shadcn-ui/Command/ComboBox';
import {useFormField} from '../shadcn-ui/Form';
import {ScrollArea} from '../shadcn-ui/ScrollArea';

const sn = bem('multiselect', s);
type Props = {
  itemList: (ItemType & {isChecked: boolean})[];
  placeholder?: string;
  title: string;
  onValueChange: (value: ItemType[]) => void;
  value: ItemType[];
  disabled?: boolean;
};

export const MultiSelect: FC<Props> = ({itemList, title, placeholder, disabled, value, onValueChange}) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<ItemType[]>(value);

  const [inputValue, setInputValue] = useState('');
  const {error} = useFormField();

  const unSelect = (item: ItemType) => {
    setSelected((prev) => prev.filter((s) => s.value !== item.value));
  };

  const handleUnselect = (e: MouseEvent, item: ItemType) => {
    e.stopPropagation();
    unSelect(item);
  };

  useEffect(() => {
    onValueChange(selected);
  }, [selected]);

  const resetInputValue = () => setSelected([]);

  const selectables = itemList.filter((item) => !selected.includes(item));

  const getCurrentIcon = () => {
    if (open) {
      return <ChevronUp className="ml-2 absolute top-1.5 right-3 text-neutral-400" />;
    }
    return <ChevronDown className="ml-2 absolute top-1.5 right-3 text-neutral-400" />;
  };

  const onSelect = (value: string, item: ItemType & {isChecked: boolean}) => {
    const {isChecked, ...itemWithoutIsChecked} = item;
    if (!isChecked) {
      setSelected((prev) => [...prev, itemWithoutIsChecked]);
    } else {
      unSelect(itemWithoutIsChecked);
    }
  };

  return (
    <Popover open={open} onOpenChange={disabled ? undefined : setOpen}>
      <PopoverTrigger asChild>
        <div
          role="ComboBox"
          aria-expanded={open}
          className={cn(sn('trigger'), {
            [sn('placeholder')]: !selected.length,
            [sn('trigger_error')]: error,
            'hover:cursor-not-allowed': disabled,
          })}
        >
          {selected.length
            ? selected.map((item) => {
              return (
                <Badge
                  key={item.value}
                  variant="secondary"
                  className="text-neutral-700 text-base whitespace-nowrap h-7"
                >
                  {item.label}
                  <Button
                    variant="ghost"
                    className="ml-1 h-4 p-0 ring-offset-background rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
                    onClick={(e) => handleUnselect(e, item)}
                    disabled={disabled}
                  >
                    {!disabled && <X className="h-5 w-5 text-neutral-400" />}
                  </Button>
                </Badge>
              );
            })
            : placeholder}
          {getCurrentIcon()}
          <div className={sn('error')}> {error?.message}</div>
        </div>
      </PopoverTrigger>
      <PopoverContent className={sn('menu')}>
        <Command>
          <CommandHeader title={title} onResetClick={resetInputValue} />
          <CommandInput value={inputValue} onValueChange={setInputValue} placeholder="Найти" />
          <CommandEmpty>Данные отсутствуют.</CommandEmpty>
          <ScrollArea className="h-48">
            <CommandGroup>
              {selectables.map((item) => {
                return (
                  <CommandItem
                    key={item.value}
                    onSelect={(value) => onSelect(value, item)}
                    className={'cursor-pointer text-base'}
                  >
                    <Checkbox className="mr-2 h-4 w-4 opacity-100" checked={item.isChecked} />
                    {item.label}
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </ScrollArea>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
