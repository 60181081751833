import cn from 'classnames';
import {FC} from 'react';

import {routes} from 'shared/constants/routes';
import {useUserRole} from 'shared/hooks';

import s from './ContentWhenBlurred.module.scss';

import {EnableIntegrationBtn} from '../EnableIntegrationBtn';

type Props = {
  routeToEnableIntegration: keyof typeof routes;
  messageForUser: string;
};

export const ContentWhenBlurred: FC<Props> = ({routeToEnableIntegration, messageForUser}: Props) => {
  const {isCompanyAdmin, isCompanyUser} = useUserRole();

  if (isCompanyAdmin) {
    return <EnableIntegrationBtn to={routes[routeToEnableIntegration]}>Подключить интеграцию</EnableIntegrationBtn>;
  }

  if (isCompanyUser) {
    return <p className={cn(s.message, s.message_forUser)}>{messageForUser}</p>;
  }

  return null;
};
