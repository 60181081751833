import {forwardRef, HTMLAttributes} from 'react';

import {cn} from 'shared/utils/helpers';

const Card = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(({className, ...props}, ref) => (
  <div
    ref={ref}
    className={cn(
      'rounded-lg border bg-card text-card-foreground shadow-sm p-6 m:p-12 border-none bg-neutral-0',
      className,
    )}
    {...props}
  />
));
Card.displayName = 'Card';

const CardHeader = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(({className, ...props}, ref) => (
  <div ref={ref} className={cn('flex flex-row justify-between items-center flex-space-y-1.5', className)} {...props} />
));
CardHeader.displayName = 'CardHeader';

const CardTitle = forwardRef<HTMLParagraphElement, HTMLAttributes<HTMLHeadingElement>>(({className, ...props}, ref) => (
  <h3 ref={ref} className={cn('font-semibold leading-none tracking-tight text-xl', className)} {...props} />
));
CardTitle.displayName = 'CardTitle';

const CardActions = forwardRef<HTMLParagraphElement, HTMLAttributes<HTMLHeadingElement>>(
  ({className, children, ...props}, ref) => (
    <div ref={ref} className={cn('flex flex-row gap-3 justify-between items-center', className)} {...props}>
      {children}
    </div>
  ),
);
CardActions.displayName = 'CardActions';

const CardDescription = forwardRef<HTMLParagraphElement, HTMLAttributes<HTMLParagraphElement>>(
  ({className, ...props}, ref) => <p ref={ref} className={cn('text-sm text-muted-foreground', className)} {...props} />,
);
CardDescription.displayName = 'CardDescription';

const CardContent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(({className, ...props}, ref) => (
  <div ref={ref} className={cn('mt-12', className)} {...props} />
));
CardContent.displayName = 'CardContent';

const CardFooter = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(({className, ...props}, ref) => (
  <div ref={ref} className={cn('flex items-center mt-8', className)} {...props} />
));
CardFooter.displayName = 'CardFooter';

export {Card, CardHeader, CardFooter, CardTitle, CardDescription, CardContent, CardActions};
