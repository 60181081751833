import {DateRange} from 'react-day-picker';

import {prepareDateRange, safeFormatDate} from 'shared/utils/date';

import ApiClient from '../client';
import {CommonMetricsDTO, HrBrandMetricsDTO, RecruitmentMetricsDTO} from '../dto';

class DashboardsService {
  getBasePath(compId: string | number): string {
    return `companies/${compId}/dashboards`;
  }

  prepareDatePeriodParams(dateFrom: Date | undefined, dateTo: Date | undefined) {
    return {startDate: dateFrom && safeFormatDate(dateFrom), endDate: dateTo && safeFormatDate(dateTo)};
  }

  getCommonMetricsData(compId: string | number, dateRange: DateRange | undefined) {
    return ApiClient.get<CommonMetricsDTO>(`${this.getBasePath(compId)}/common-metrics`, {
      params: prepareDateRange(dateRange),
    }).then((res) => res.data);
  }

  getRecruitmentsData(compId: string | number, dateRange: DateRange | undefined) {
    return ApiClient.get<RecruitmentMetricsDTO>(`${this.getBasePath(compId)}/recruitment`, {
      params: prepareDateRange(dateRange),
    }).then((res) => res.data);
  }

  getHrBrandData(compId: string | number, dateRange: DateRange | undefined) {
    return ApiClient.get<HrBrandMetricsDTO>(`${this.getBasePath(compId)}/hr-brand`, {
      params: prepareDateRange(dateRange),
    }).then((res) => res.data);
  }

  setHiringCost(compId: string | number, newValue: string | number) {
    return ApiClient.post(`${this.getBasePath(compId)}/recruitment/hiring-cost`, {hiringCost: newValue}).then(
      (res) => res.data,
    );
  }

  setVacanciesFillingTime(compId: string | number, newValue: string | number) {
    return ApiClient.post(`${this.getBasePath(compId)}/recruitment/vacancies-filling-time`, {
      vacanciesFillingTime: newValue,
    }).then((res) => res.data);
  }

  setFlowRateKpi(compId: string | number, newValue: string | number | null) {
    return ApiClient.post(`${this.getBasePath(compId)}/common-metrics/flow-rate-kpi`, {
      flowRateKpiValue: newValue,
    }).then((res) => res.data);
  }
}

export const DashboardsApi = new DashboardsService();
