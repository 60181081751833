import {zodResolver} from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/browser';
import {createColumnHelper} from '@tanstack/react-table';
import {PaginationState} from '@tanstack/table-core/src/features/Pagination';
import {formatISO} from 'date-fns';
import {EditIcon} from 'lucide-react';
import {FC, useEffect, useMemo, useRef, useState} from 'react';
import {useFieldArray, useForm} from 'react-hook-form';
import * as z from 'zod';

import {CompaniesApi, DictionariesApi} from 'api';
import {CompanyStaffDismissalReasonDTO, CreateStaffDismissalDTO, HrBrandMetricsDTO, IntegrationTypeId} from 'api/dto';
import {Option} from 'shared/components/CreatableSelect/utils/types';
import {ChartCard} from 'shared/components/Dashboards';
import {VerticalBarChart} from 'shared/components/Dashboards/Charts/VerticalBarChart';
import {EditMetricDescription} from 'shared/components/Dashboards/EditMetricDescription';
import {CHART_CARD_TIPS, DIRTY_FORM_CONFIRM_OPTIONS, EMPTY_REASONS_FOR_FIRED} from 'shared/components/Dashboards/utils';
import {DataTable} from 'shared/components/DataTable';
import {DateCell} from 'shared/components/DateCell';
import {RowActions} from 'shared/components/RowActions/RowActions';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Form} from 'shared/components/shadcn-ui/Form';
import {ScrollArea} from 'shared/components/shadcn-ui/ScrollArea';
import {Separator} from 'shared/components/shadcn-ui/Separator';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from 'shared/components/shadcn-ui/Sheet';
import {toast} from 'shared/components/shadcn-ui/Toast/useToast';
import {HEIGHT_MEDIA_BREAKPOINT_L} from 'shared/constants';
import {
  useCurrentCompany,
  useConfirmation,
  useDependencyOnIntegration,
  useEffectOnce,
  usePrevious,
  useUserRole,
} from 'shared/hooks';
import {useDimensions} from 'shared/hooks/useDimensions';
import {WithPagination} from 'shared/models/requestPagination';
import {safeParseDate} from 'shared/utils/date';
import {handleApiErrors} from 'shared/utils/helpers';
import {REQUIRED_FIELD_MSG} from 'shared/utils/validation';

import {NumberWhoQuitCell} from './components/NumberWhoQuitCell';
import {ReasonCell} from './components/ReasonCell';
import {FormSchema} from './utils/validationSchema';

import s from '../HrBrand.module.scss';

type Props = {
  data: HrBrandMetricsDTO['charts']['dismissalReasonsFunnel'];
  className: string;
  isLoading: boolean;
  onAfterEdit: () => void;
};

const ITEMS_PER_PAGE = 15;
const DEFAULT_PAGINATION_STATE = {pageSize: ITEMS_PER_PAGE, pageIndex: 0};

type TableRowType = z.infer<typeof FormSchema>['rows'][0];

export const ReasonsForFiredChart: FC<Props> = ({data, className, isLoading, onAfterEdit}) => {
  const [isEditing, setIsEditing] = useState(false);
  const {confirm} = useConfirmation();
  const {isCompanyAdmin} = useUserRole();
  const {company, hasDemoStatus} = useCurrentCompany();
  const [activeEditingRow, setActiveEditingRow] = useState<number | null>(null);
  const [tableData, setTableData] = useState<WithPagination<CompanyStaffDismissalReasonDTO> | null>(null);
  const [removedReasonIds, setRemovedReasonIds] = useState<number[]>([]);
  const [paginationState, setPaginationState] = useState<PaginationState>(DEFAULT_PAGINATION_STATE);
  const prevPaginationState = usePrevious(paginationState);
  const [reasonDictionary, setReasonDictionary] = useState<Option[]>([]);
  const {infoBadge, integrationStatus} = useDependencyOnIntegration(IntegrationTypeId.OneC);
  const isAllowedEditing = isCompanyAdmin && integrationStatus?.isEnabled;

  const containerRef = useRef<HTMLDivElement>(null);
  const {width: containerWidth, height: screenHeight} = useDimensions(containerRef);

  const onEditDialogVisibleChange = async (isOpened: boolean) => {
    if (!isOpened) {
      if (form.formState.isDirty && !(await confirm(DIRTY_FORM_CONFIRM_OPTIONS))) return;
      form.reset(form.formState.defaultValues);
    }
    setIsEditing(isOpened);
  };

  const findReasonById = (id: string) => {
    return reasonDictionary.find((item) => item.value === id)?.label ?? '';
  };

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      rows: [],
    },
  });

  const {prepend, remove} = useFieldArray({control: form.control, name: 'rows'});
  const tableRows = form.watch('rows');

  const startEdit = () => {
    fetchTableData();
    setIsEditing(true);
  };

  const fetchReasonDictionary = async () => {
    try {
      const dictionary = await DictionariesApi.getDismissalReasons();
      setReasonDictionary(dictionary.map(({value, description}) => ({value: String(value), label: description})));
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const getPreparedTableItems = (tableData: CompanyStaffDismissalReasonDTO[]) => {
    return tableData.map((item) => ({
      ...item,
      reason: item.reasonName,
      date: safeParseDate(item.dismissalDate) ?? item.dismissalDate,
    }));
  };

  useEffectOnce(() => {
    fetchReasonDictionary();
  });

  const fetchTableData = async () => {
    if (company?.id) {
      try {
        const tableData = await CompaniesApi.getCompanyStaffDismissalReasonsList(company.id, {
          page: paginationState.pageIndex + 1,
          size: ITEMS_PER_PAGE,
        });

        const preparedTableItems = removedReasonIds.length
          ? getPreparedTableItems(tableData.items).filter((item) => !removedReasonIds.includes(item.id))
          : getPreparedTableItems(tableData.items);

        setTableData(tableData);
        form.reset({rows: preparedTableItems});
        setActiveEditingRow(null);
      } catch (error) {
        Sentry.captureException(error);
        handleApiErrors(error);
      }
    }
  };

  useEffect(() => {
    if (company?.id && prevPaginationState && prevPaginationState.pageIndex !== paginationState.pageIndex) {
      fetchTableData();
    }
  }, [paginationState]);

  const onChangeRowReason = (reasonId: string, rowIndex: number) => {
    form.setValue(`rows.${rowIndex}.reasonId`, Number(reasonId));
    form.setValue(`rows.${rowIndex}.reason`, findReasonById(reasonId));
    form.trigger();
  };

  const saveChanges = async (values: z.infer<typeof FormSchema>) => {
    if (!company?.id) return;
    setActiveEditingRow(null);

    const newValues = values.rows.map(({date, ...row}, index) => {
      if (!date) {
        form.setError(`rows.${index}.date`, {message: REQUIRED_FIELD_MSG});
      }
      return {dismissalDate: date ? formatISO(date, {representation: 'date'}) : date, ...row};
    });

    if (newValues.every((item) => item.dismissalDate)) {
      await CompaniesApi.saveCompanyStaffDismissalReasons(company.id, newValues as CreateStaffDismissalDTO[]);
      form.reset();
      setIsEditing(false);
      onAfterEdit();
      toast({
        title: 'Данные успешно сохранены.',
      });
    }
  };

  const onSubmit = async (values: z.infer<typeof FormSchema>) => {
    if (!company?.id) return;
    try {
      if (removedReasonIds.length) {
        await CompaniesApi.deleteCompanyStaffDismissalReasons(company.id, Array.from(new Set(removedReasonIds)));
        setRemovedReasonIds([]);
      }
      if (values.rows.length) {
        await saveChanges(values);
      }
      setPaginationState(DEFAULT_PAGINATION_STATE);
    } catch (error) {
      Sentry.captureException(error);
      handleApiErrors(error);
    }
  };

  const removeRow = (rowIndex: number) => {
    const reason = form.getValues().rows[rowIndex];
    remove(rowIndex);
    if (reason?.id) {
      const {id} = reason;
      setRemovedReasonIds((prev) => prev.concat([id]));
    }
  };

  const addNewRow = () => {
    prepend(
      {
        id: null,
        reasonId: null,
        reason: '',
        qty: 1,
        date: null,
      },
      {shouldFocus: true},
    );
  };

  const getColumns = useMemo(() => {
    const columnHelper = createColumnHelper<TableRowType>();
    return [
      columnHelper.accessor('date', {
        header: 'Дата',
        size: 150,
        meta: {
          activeEditingRow,
        },
        cell: DateCell,
      }),
      columnHelper.accessor('reason', {
        size: 600,
        header: 'Причина увольнения',
        meta: {
          activeEditingRow,
          onChangeRowReason: onChangeRowReason,
          reasonList: reasonDictionary,
        },
        cell: ReasonCell,
      }),
      columnHelper.accessor('qty', {
        header: 'Кол-во уволившихся, чел',
        size: 175,
        cell: ({row, column}) => (
          <NumberWhoQuitCell row={row} columnId={column.id} activeEditingRow={activeEditingRow} />
        ),
      }),
      columnHelper.display({
        id: 'actions',
        size: 125,
        meta: {
          activeEditingRow,
        },
        cell: RowActions,
      }),
    ];
  }, [tableRows, activeEditingRow]);

  const getPageCount = (): number => {
    if (tableData?.total) {
      const {total} = tableData;
      return Math.ceil(total / ITEMS_PER_PAGE);
    }
    return 0;
  };

  return (
    <>
      <ChartCard
        ref={containerRef}
        title="Причины увольнений"
        className={s.hrBrand__chartCard}
        description={CHART_CARD_TIPS.reasonsForFired}
        headerActions={
          isAllowedEditing && (
            <Button variant="ghost" size="icon" onClick={startEdit}>
              <EditIcon />
            </Button>
          )
        }
      >
        <VerticalBarChart<{itemName: string; percentage: number}>
          className={className}
          containerWidth={containerWidth}
          height={380}
          dataKey="percentage"
          labelKey="itemName"
          color="#F3A0B2"
          legendLabel="Количество уволившихся (%)"
          data={data?.length ? data : EMPTY_REASONS_FOR_FIRED}
          isLoading={isLoading}
        />
        {!isLoading && !data?.length ? infoBadge : null}
      </ChartCard>
      <Sheet open={isEditing} onOpenChange={onEditDialogVisibleChange}>
        <SheetContent className="lg:max-w-5xl">
          <ScrollArea className="h-full pr-3">
            <SheetHeader>
              <SheetTitle>Редактирование причин увольнений</SheetTitle>
              <SheetDescription>
                Для корректной работы графика необходимо заполнить таблицу ниже. Укажите причины увольнений ваших
                сотрудников и количество уволившихся по этим причинам. Система автоматически преобразит введённые данные
                в наглядный список и отсортирует значения по частотности.
              </SheetDescription>
            </SheetHeader>
            <Form {...form}>
              <section>
                <EditMetricDescription
                  title="Редактирование причин увольнений"
                  onButtonClick={addNewRow}
                  disabledAddBtn={hasDemoStatus}
                />
                <form id="editFiredReasonsForm" onSubmit={form.handleSubmit(onSubmit)}>
                  <DataTable<TableRowType>
                    columns={getColumns}
                    data={tableRows}
                    pageCount={getPageCount()}
                    manualPagination={true}
                    autoResetPageIndex={false}
                    state={{pagination: paginationState}}
                    onPaginationChange={setPaginationState}
                    isDisabledBtns={hasDemoStatus}
                    meta={{removeRow, startEditingRow: setActiveEditingRow, isDisabled: hasDemoStatus}}
                    scrollHeight={(screenHeight ?? HEIGHT_MEDIA_BREAKPOINT_L) > HEIGHT_MEDIA_BREAKPOINT_L ? 470 : 400}
                  />
                </form>
              </section>
            </Form>
            <Separator className="mt-4 mb-4" />
            <SheetFooter className="justify-start mr-3">
              <SheetClose asChild></SheetClose>
              <Button variant="primary" type="submit" form="editFiredReasonsForm" disabled={hasDemoStatus}>
                Сохранить изменения
              </Button>
            </SheetFooter>
          </ScrollArea>
        </SheetContent>
      </Sheet>
    </>
  );
};
