import * as Sentry from '@sentry/browser';

import {Button} from 'shared/components/shadcn-ui/Button';
import {useConfirmation} from 'shared/hooks';
import {handleApiErrors} from 'shared/utils/helpers';
import {useRootDispatch} from 'store';
import {authAsyncActions} from 'store/auth/actions';
import {companiesActions} from 'store/companies';

export const ExitButton = () => {
  const {confirm} = useConfirmation();
  const dispatch = useRootDispatch();

  const exit = async () => {
    if (
      await confirm({
        title: 'Выход из профиля',
        description: 'Вы действительно хотите выйти из профиля?',
        acceptButton: 'Выйти из профиля',
        cancelButton: 'Отмена',
      })
    ) {
      try {
        dispatch(authAsyncActions.signOut());
        dispatch(companiesActions.setCurrentCompanyId(''));
      } catch (error) {
        Sentry.captureException(error);
        handleApiErrors(error, 'Ошибка! Не удалось выйти из системы.');
      }
    }
  };

  return (
    <Button onClick={exit} variant="primary" className="basis-72">
      Выйти из профиля
    </Button>
  );
};
