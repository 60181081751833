import {zodResolver} from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/browser';
import {FC, HTMLAttributes, useState} from 'react';
import {FieldErrors, useForm} from 'react-hook-form';
import {useNavigate} from 'react-router';
import {useSearchParams} from 'react-router-dom';
import * as z from 'zod';

import {AuthApi} from 'api';
import {ResetPasswordDTO} from 'api/dto';
import SuccessImg from 'assets/icons/tick.svg';
import {PasswordInput} from 'shared/components/PasswordInput';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Form, FormControl, FormField, FormItem, FormMessage} from 'shared/components/shadcn-ui/Form';
import {routes} from 'shared/constants/routes';
import {bem, setValidationFormErrors} from 'shared/utils';
import {extractApiErrorMessage} from 'shared/utils/axios';
import {cn, handleApiErrors} from 'shared/utils/helpers';

import s from './NewPasswordForm.module.scss';
import {FormSchema} from './validationSchema';

const sn = bem('newPasswordForm', s);

type Props = {className?: string} & HTMLAttributes<HTMLFormElement>;

export const NewPasswordForm: FC<Props> = ({className = ''}) => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email') || '';

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const prepareDataForServer = (values: z.infer<typeof FormSchema>): ResetPasswordDTO => {
    return Object.assign(values, {token: searchParams.get('token') || ''}, {email});
  };

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    try {
      await AuthApi.resetPassword(prepareDataForServer(data));
      setIsSuccess(true);
    } catch (error) {
      Sentry.captureException(error);
      const extractedError = extractApiErrorMessage(error);
      if (typeof extractedError === 'string') {
        handleApiErrors(error);
      } else {
        setValidationFormErrors<typeof data>(extractedError, (fieldName, fieldErrors) => {
          form.setError(fieldName, {
            message: fieldErrors[0],
          });
        });
      }
    }
  };

  const returnToAuth = async () => {
    navigate(routes.login);
  };

  return (
    <div className={cn(className, s.newPasswordForm)}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className={sn('content')}>
          <h2 className={sn('title')}>{isSuccess ? 'Вы успешно установили новый пароль' : 'Установка пароля'}</h2>
          <p className={sn('subtitle')}>
            {isSuccess
              ? 'Для входа в систему вернитесь на авторизацию.'
              : `Укажите новый пароль для авторизации в профиль ${email}.`}
          </p>
          {isSuccess ? (
            <>
              <img className={sn('success')} src={SuccessImg} alt="Success image" />
              <Button type="button" variant="primary" onClick={returnToAuth}>
                Вернуться к авторизации
              </Button>
            </>
          ) : (
            <>
              <FormField
                control={form.control}
                name="password"
                render={({field}) => (
                  <FormItem className="h-[58px]">
                    <FormControl>
                      <PasswordInput placeholder="Новый пароль" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="confirmPassword"
                render={({field}) => (
                  <FormItem className="mb-28 h-[58px]">
                    <FormControl>
                      <PasswordInput placeholder="Повторите пароль" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <p className={sn('error')}>
                {
                  (
                    form.formState.errors as FieldErrors<
                    z.infer<typeof FormSchema> & {
                      repeatedPassword: string;
                    }
                    >
                  ).repeatedPassword?.message
                }
              </p>
              <Button type="submit" variant="primary" disabled={form.formState.isSubmitting}>
                {form.formState.isSubmitting ? 'Загрузка...' : 'Установить пароль'}
              </Button>
              <Button className="mt-4" variant="outline" onClick={() => navigate(routes.login)}>
                Перейти на страницу входа
              </Button>
            </>
          )}
        </form>
      </Form>
    </div>
  );
};
