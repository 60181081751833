import * as Sentry from '@sentry/browser';
import cn from 'classnames';
import {addDays, isBefore, sub} from 'date-fns';
import {DownloadIcon} from 'lucide-react';
import {FC, useEffect, useState} from 'react';
import {DateRange} from 'react-day-picker';

import {useCurrentCompany} from 'shared/hooks';
import {isBeforeOrSame, isEqualDates} from 'shared/utils/date';
import {handleApiErrors} from 'shared/utils/helpers';

import s from './DashboardTopBar.module.scss';
import {DateRangeInputForm} from './DateRangeInputForm';
import {DEMO_END_DATE, QUICK_INTERVAL_BUTTONS, QuickPeriodType, getEndDate, getStartDate} from './utils';

import {Button} from '../../shadcn-ui/Button';
import {PopoverCalendar} from '../../shadcn-ui/Calendar';
import {toast} from '../../shadcn-ui/Toast/useToast';
import {useDashboardContext} from '../DashboardContextProvider';

type Props = {
  className?: string;
};

export const DashboardTopBar: FC<Props> = ({className}: Props) => {
  const {dateRange, downloadReport, setDateRange, quickPeriod, setQuickPeriod} = useDashboardContext();
  const {company, hasDemoStatus} = useCurrentCompany();

  const [isDownloadingReport, setIsDownloadingReport] = useState(false);
  const [selectedRange, setSelectedRange] = useState<DateRange | undefined>(dateRange);

  useEffect(() => {
    if (!isEqualDates(dateRange?.from, selectedRange?.from) || !isEqualDates(dateRange?.to, selectedRange?.to)) {
      setSelectedRange(dateRange);
    }
  }, [dateRange]);

  const demoDefaultMonth = dateRange?.to && isBefore(dateRange?.to, DEMO_END_DATE) ? dateRange?.to : DEMO_END_DATE;

  const setInterval = ({duration, period}: QuickPeriodType) => {
    setQuickPeriod({duration, period});
    const today = new Date();
    const fromDate = sub(today, {[period]: duration});
    setDateRange({
      // Сделали по аналогии с Я.Метрикой: например, неделя это -6 дней от текущего дня, а не 7.
      from: addDays(fromDate, 1),
      to: today,
    });
  };

  const startDownloadReport = async () => {
    setIsDownloadingReport(true);
    try {
      await downloadReport(document.getElementById('dashboardContext'), {
        name: `${company?.companyName}__Отчет`,
        extension: 'jpg',
      });
      toast({
        title: 'Операция выполнена успешно.',
      });
    } catch (e) {
      Sentry.captureException(e);
      handleApiErrors(e, 'Произошла ошибка при выполнении операции. Повторите попытку позже.');
    } finally {
      setIsDownloadingReport(false);
    }
  };

  const handleDayClick = (day: Date) => {
    if ((selectedRange?.from && selectedRange?.to) || (!selectedRange?.from && !selectedRange?.to)) {
      setSelectedRange({from: day, to: undefined});
    } else if (selectedRange?.from && !selectedRange?.to) {
      const newSelectedRange = isBeforeOrSame(selectedRange.from, day)
        ? {from: selectedRange.from, to: day}
        : {from: day, to: selectedRange.from};
      setSelectedRange(newSelectedRange);
    }
  };

  return (
    <div className={cn(s.dashboardTopBar, className)}>
      {QUICK_INTERVAL_BUTTONS.map(({title, duration, period}, idx) => (
        <Button
          key={`${idx}|${period}|${duration}`}
          className={cn(s.dashboardTopBar__timePeriodButton, {
            [s['dashboardTopBar__timePeriodButton--active']]:
              quickPeriod?.period === period && quickPeriod.duration === duration,
          })}
          variant="outline"
          onClick={() => setInterval({duration, period})}
        >
          {title}
        </Button>
      ))}
      <PopoverCalendar
        mode="range"
        defaultMonth={hasDemoStatus ? demoDefaultMonth : dateRange?.to}
        selected={selectedRange}
        onDayClick={handleDayClick}
        toDate={getEndDate(hasDemoStatus)}
        fromDate={getStartDate(hasDemoStatus)}
        footer={<DateRangeInputForm />}
        popoverTriggerClassNames={cn('min-w-[243px]', {[s['dashboardTopBar__timePeriodButton--active']]: !quickPeriod})}
      />
      <Button
        className={s.dashboardTopBar__downloadReportBtn}
        variant="primary"
        onClick={startDownloadReport}
        disabled={isDownloadingReport}
      >
        <DownloadIcon className="mr-2" />
        {!isDownloadingReport ? 'Скачать отчёт' : 'Обработка...'}
      </Button>
    </div>
  );
};
