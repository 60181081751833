import {DialogClose} from '@radix-ui/react-dialog';
import * as Sentry from '@sentry/browser';
import {FC, useState} from 'react';

import {fullAccessRequestsApi} from 'api/services/fullAccessRequestsApi';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Dialog, DialogContent} from 'shared/components/shadcn-ui/Dialog';
import {SuccessfullySendingRequest} from 'shared/components/SuccessfullySendingRequest';
import {useConfirmation, useCurrentCompany} from 'shared/hooks';
import {isAxiosError} from 'shared/utils/axios';
import {handleApiErrors} from 'shared/utils/helpers';

type Props = {saveToStorage: (companyId: number) => void};

export const SendFullAccessRequestPopup: FC<Props> = ({saveToStorage}) => {
  const [isSending, setIsSending] = useState(false);
  const [isShownDialog, setShownDialog] = useState(false);
  const {accept} = useConfirmation();

  const {company} = useCurrentCompany();

  const send = async () => {
    try {
      setIsSending(true);
      if (company) {
        await fullAccessRequestsApi.sendRequest(company.id);
        saveToStorage(company.id);
      }
      setShownDialog(true);
    } catch (error) {
      Sentry.captureException(error);
      handleApiErrors(error, 'Не удалось отправить письмо. Попробуйте еще раз.');
      if (isAxiosError(error) && company) {
        if (error.response?.status === 400) {
          saveToStorage(company.id);
          accept();
        }
      }
    } finally {
      setIsSending(false);
    }
  };

  return (
    <Dialog open={isShownDialog} onOpenChange={setShownDialog}>
      <Button variant="primary" onClick={() => send()} disabled={isSending} className="w-[170px]">
        {isSending ? 'Отправка запроса...' : 'Отправить запрос'}
      </Button>

      <DialogContent className="max-w-[400px]" withClose={false}>
        <SuccessfullySendingRequest
          withoutCloseBtn={true}
          title="Запрос отправлен"
          subtitle="Ожидайте, наш менеджер свяжется с вами по электронной почте в течение 24 часов."
        />
        <DialogClose asChild>
          <Button className="basis-[100%] mt-10" variant="outline" onClick={accept}>
            Ок
          </Button>
        </DialogClose>
      </DialogContent>
    </Dialog>
  );
};
