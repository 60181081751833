import {Table} from '@tanstack/react-table';
import {ChevronLeft, ChevronRight} from 'lucide-react';
import {FC, useCallback} from 'react';

import {Button} from 'shared/components/shadcn-ui/Button';
import {useCurrentCompany} from 'shared/hooks';
import {usePagination} from 'shared/hooks/usePagination';
import {bem} from 'shared/utils';

import s from './PaginationPanel.module.scss';

import {Pagination} from '../Pagination';

const sn = bem('paginationPanel', s);
type Props = {
  table: Table<any>;
  isDisabledBtns?: boolean;
};

export const PaginationPanel: FC<Props> = ({table, isDisabledBtns = false}: Props) => {
  const currentPage = table.getState().pagination.pageIndex;
  const pagesCount = table.getPageCount();
  const paginationRange = usePagination({pagesCount, currentPage});
  const isFirstPage = currentPage === 0;
  const isLastPage = currentPage === pagesCount - 1;

  const onNextPage = useCallback(() => table.nextPage(), [table]);

  const onPrevPage = useCallback(() => table.previousPage(), [table]);

  const changePaginationNumber = useCallback((page: number) => table.setPageIndex(page), [table]);

  if (pagesCount === 1) return <div className={s.paginationPanel} />;
  return (
    <div className={s.paginationPanel}>
      {!isFirstPage && (
        <Button className={sn('btn')} variant={'icon'} size="icon" onClick={onPrevPage} type="button">
          <ChevronLeft className={sn('icon')} />
        </Button>
      )}

      <Pagination
        paginationRange={paginationRange}
        changePaginationNumber={changePaginationNumber}
        paginationNumber={currentPage}
        isDisabledBtns={isDisabledBtns}
      />

      {!isLastPage && pagesCount !== 0 && (
        <Button
          className={sn('btn')}
          variant={'icon'}
          size="icon"
          onClick={onNextPage}
          type="button"
          disabled={isDisabledBtns}
        >
          <ChevronRight className={sn('icon')} />
        </Button>
      )}
    </div>
  );
};
