export const DEFAULT_VALUES = {
  inn: '',
  companyName: '',
  legalAddress: '',
  firstName: '',
  lastName: '',
  email: '',
  jobTitle: '',
  phoneNumber: '',
};
