import {
  add,
  eachMonthOfInterval,
  endOfYear,
  format,
  isAfter,
  isEqual,
  parse,
  startOfDay,
  startOfMonth,
  startOfToday,
} from 'date-fns';
// eslint-disable-next-line no-duplicate-imports
import {ru} from 'date-fns/locale';
import {ChevronLeft, ChevronRight, CalendarDaysIcon} from 'lucide-react';
import {useState, FC} from 'react';

import {Popover, PopoverContent, PopoverTrigger} from 'shared/components/shadcn-ui/Popover';
import {isAfterOrSame} from 'shared/utils/date';
import {cn} from 'shared/utils/helpers';

import {buttonVariants, Button} from '../shadcn-ui/Button';

function getStartOfCurrentMonth() {
  return startOfMonth(startOfToday());
}

interface MonthPickerProps {
  currentMonth?: Date;
  onMonthChange: (newMonth: Date) => void;
  disabledMonths?: string[];
  initialYear?: number;
  displayMonthEnd?: Date | null;
}

function MonthPicker({
  currentMonth = new Date(),
  onMonthChange,
  disabledMonths = [],
  initialYear,
  displayMonthEnd = startOfMonth(new Date()),
}: MonthPickerProps) {
  const [currentYear, setCurrentYear] = useState(initialYear?.toString() ?? format(new Date(), 'yyyy', {locale: ru}));
  const firstDayCurrentYear = parse(currentYear, 'yyyy', new Date());

  const months = eachMonthOfInterval({
    start: firstDayCurrentYear,
    end: endOfYear(firstDayCurrentYear),
  });

  const currentYearInt = parseInt(currentYear, 10);
  const nextYearInt = new Date().getFullYear() + 1;

  function previousYear() {
    const firstDayNextYear = add(firstDayCurrentYear, {years: -1});
    setCurrentYear(format(firstDayNextYear, 'yyyy', {locale: ru}));
  }

  function nextYear() {
    const firstDayNextYear = add(firstDayCurrentYear, {years: 1});
    setCurrentYear(format(firstDayNextYear, 'yyyy', {locale: ru}));
  }

  return (
    <div className="p-3">
      <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
        <div className="space-y-4">
          <div className="relative flex items-center justify-center pt-1">
            <div className="text-sm font-medium" aria-live="polite" role="presentation" id="month-picker">
              {format(firstDayCurrentYear, 'yyyy', {locale: ru})}
            </div>
            <div className="flex items-center space-x-1">
              <button
                name="previous-year"
                aria-label="Go to previous year"
                className={cn(
                  buttonVariants({variant: 'outline'}),
                  'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
                  'absolute left-1',
                )}
                type="button"
                onClick={previousYear}
              >
                <ChevronLeft className="h-4 w-4" />
              </button>
              <button
                name="next-year"
                aria-label="Go to next year"
                className={cn(
                  buttonVariants({variant: 'outline'}),
                  'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
                  'absolute right-1',
                )}
                type="button"
                onClick={nextYear}
                disabled={currentYearInt >= nextYearInt}
              >
                <ChevronRight className="h-4 w-4" />
              </button>
            </div>
          </div>
          <div className="grid w-full grid-cols-3 gap-2" role="grid" aria-labelledby="month-picker">
            {months.map((month) => {
              const isAfterNextYear = isAfter(month, endOfYear(add(new Date(), {years: 1})));
              const isMaxMonthRestricted = !!displayMonthEnd && isAfterOrSame(startOfMonth(month), displayMonthEnd);
              const isInDisabledMonths =
                !isEqual(month, currentMonth) &&
                disabledMonths?.some((date) => isEqual(month, parse(date, 'dd.MM.yyyy', new Date())));

              return (
                <div
                  key={month.toString()}
                  className="relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-slate-100 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md dark:[&:has([aria-selected])]:bg-slate-800"
                  role="presentation"
                >
                  <button
                    name="day"
                    className={cn(
                      'inline-flex h-9 w-16 items-center justify-center rounded-md p-0 text-sm font-normal ring-offset-white transition-colors hover:bg-slate-100 hover:text-slate-900 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-400 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 aria-selected:opacity-100 dark:ring-offset-slate-950 dark:hover:bg-slate-800 dark:hover:text-slate-50 dark:focus-visible:ring-slate-800',
                      isEqual(month, currentMonth) &&
                        'bg-primary-300 text-slate-50 hover:bg-primary-300 hover:text-neutral-0 focus:bg-primary-300 focus:text-neutral-0',
                      !isEqual(month, currentMonth) &&
                        isEqual(month, getStartOfCurrentMonth()) &&
                        'bg-slate-100 text-slate-900 ',
                    )}
                    disabled={isAfterNextYear || isMaxMonthRestricted || isInDisabledMonths}
                    role="gridcell"
                    tabIndex={-1}
                    type="button"
                    onClick={() => onMonthChange(month)}
                  >
                    <time dateTime={format(month, 'yyyy-MM-dd', {locale: ru})}>
                      {format(month, 'MMM', {locale: ru})}
                    </time>
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

type PopoverMonthPickerProps = {
  popoverTriggerClassNames?: string;
  isLoading?: boolean;
  disabled?: boolean;
  currentMonth?: Date;
  onMonthChange: (newMonth: Date) => void;
  currentYear?: number;
  disabledMonths?: string[];
  displayMonthEnd?: Date | null;
};

const PopoverMonthPicker: FC<PopoverMonthPickerProps> = ({
  popoverTriggerClassNames,
  isLoading = false,
  disabled = false,
  currentMonth,
  onMonthChange,
  currentYear,
  disabledMonths = [],
  displayMonthEnd,
}: PopoverMonthPickerProps) => {
  const [isToggled, setIsToggled] = useState(false);
  const toggle = () => setIsToggled((prev) => !prev);

  return (
    <Popover open={isToggled} onOpenChange={toggle}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className={cn('w-[170px] pl-3 text-base font-normal', popoverTriggerClassNames)}
          disabled={isLoading || disabled}
        >
          <span>{currentMonth ? format(currentMonth, 'LLLL', {locale: ru}) : 'Выберите дату'}</span>
          <CalendarDaysIcon className="ml-auto" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start" withPortal={false}>
        <MonthPicker
          currentMonth={currentMonth}
          onMonthChange={(date) => {
            onMonthChange(date);
            toggle();
          }}
          disabledMonths={disabledMonths}
          initialYear={currentMonth?.getFullYear() ?? currentYear}
          displayMonthEnd={displayMonthEnd}
        />
      </PopoverContent>
    </Popover>
  );
};

export {PopoverMonthPicker};
