import * as Sentry from '@sentry/browser';
import {Loader2} from 'lucide-react';
import {ButtonHTMLAttributes, FC, useState} from 'react';

import {Button} from 'shared/components/shadcn-ui/Button';
import {useConfirmation} from 'shared/hooks';
import {handleApiErrors} from 'shared/utils/helpers';
import {useRootDispatch} from 'store';
import {companiesAsyncActions} from 'store/companies/actions';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  name: string;
  title: string;
  description: string;
  accept: () => void;
}

export const ActionIconButton: FC<Props> = ({
  disabled = false,
  name,
  title,
  description,
  accept,
  children,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const {confirm} = useConfirmation();
  const dispatch = useRootDispatch();

  const onAccept = async () => {
    if (
      await confirm({
        title: name,
        description,
        acceptButton: title,
        cancelButton: 'Отмена',
      })
    )
      try {
        setIsLoading(true);
        await accept();
        dispatch(companiesAsyncActions.fetchCurrentCompany());
      } catch (e) {
        Sentry.captureException(e);
        handleApiErrors(e);
      } finally {
        setIsLoading(false);
      }
  };

  return (
    <Button title={title} variant="icon" size="icon" onClick={onAccept} disabled={disabled} {...props}>
      {isLoading ? <Loader2 className="mr-2 animate-spin" /> : children}
    </Button>
  );
};
