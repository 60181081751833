import * as Sentry from '@sentry/browser';
import {RefreshCcw} from 'lucide-react';
import {PropsWithChildren, useEffect, useState} from 'react';

import {YandexIntegrationType} from 'api/dto';
import {Button} from 'shared/components/shadcn-ui/Button';
import {RefreshDescription} from 'shared/components/YandexOauth/RefreshDescription';
import {useYandexIntegrationParametersContext} from 'shared/components/YandexOauth/YandexIntegrationProvider';
import {useConfirmation} from 'shared/hooks';
import {bem} from 'shared/utils';
import {cn, handleApiErrors} from 'shared/utils/helpers';

import s from '../../../../SettingsForm.module.scss';

const sn = bem('settingsForm', s);

export const CounterError = ({children}: PropsWithChildren) => {
  const {refreshParameters, init, authenticated, reset} = useYandexIntegrationParametersContext();

  const [isInit, setIsInit] = useState(false);
  const {confirm} = useConfirmation();

  const enableOauth = async () => {
    if (
      await confirm({
        title: 'Смена аккаунта',
        description:
          'Чтобы сменить аккаунт Яндекс.Метрики, вам необходимо вернуться в Яндекс и авторизоваться под другим Яндекс ID.',
        acceptButton: 'Ок, готово',
        cancelButton: 'Отмена',
      })
    ) {
      try {
        reset();
        await init();
        setIsInit(true);
      } catch (error) {
        Sentry.captureException(error);
        handleApiErrors(error, 'Ошибка! Не войти в аккаунт Яндекса.');
      }
    }
  };

  useEffect(() => {
    if (isInit && authenticated !== null) {
      refreshParameters();
    }
  }, [authenticated]);

  return (
    <>
      <RefreshDescription description="Выберите счётчик" />
      <h4 className={sn('subtitle')}>На подключённом аккаунте не обнаружено ни одного активного счётчика</h4>
      <p>
        Добавьте счётчик к текущему аккаунту Яндекс Метрики или авторизуйтесь под другой учётной записью. Затем обновите
        список счётчиков, нажав на кнопку{' '}
        <span className={sn('refresh')}>
          <RefreshCcw />
        </span>
      </p>
      {children}
      <Button
        variant="primary"
        type="button"
        className={cn('mt-16 block ml-auto', {['relative top-[-88px] right-[120px]']: !!children})} // сдвиг кнопки, чтобы располагалась перед Далее
        onClick={enableOauth}
      >
        Подключить другой аккаунт
      </Button>
    </>
  );
};
