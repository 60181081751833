import * as React from 'react';

import {cn} from 'shared/utils/helpers';

export type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const Textarea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(({className, ...props}, ref) => {
  return (
    <textarea
      className={cn(
        `flex min-h-[60px] w-full rounded-md border border-input flex h-10 w-full rounded-md border border-neutral-300
        bg-neutral-100 px-3 py-2 text-base text-neutral-900 placeholder:text-neutral-400
        focus:outline-none active:outline-none active:bg-neutral-100 focus:border-primary-400
        read-only:focus:border-primary-100
        autofill:bg-primary-400  active:border-primary-400 read-only:active:border-primary-100 placeholder-shown:bg-neutral-0
        invalid:[&:not(:placeholder-shown)]:border-error invalid:[&:not(:placeholder-shown)]: 
        focus:invalid:border-error focus:invalid:text-error
        disabled:cursor-not-allowed disabled:opacity-50 transition duration-360 ease-out hover:ease-in`,
        className,
      )}
      ref={ref}
      {...props}
    />
  );
});
Textarea.displayName = 'Textarea';

export {Textarea};
