import {zodResolver} from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/browser';
import {ClockIcon} from 'lucide-react';
import {FC, useEffect, useState} from 'react';
import {useForm, useWatch} from 'react-hook-form';
import {NumericFormat} from 'react-number-format';
import {NumberFormatValues} from 'react-number-format/types/types';
import {z} from 'zod';

import {DashboardsApi} from 'api';
import {RecruitmentMetricsDTO} from 'api/dto';
import {MetricCard} from 'shared/components/Dashboards';
import {MINI_CARD_TIPS, DIRTY_FORM_CONFIRM_OPTIONS} from 'shared/components/Dashboards/utils';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from 'shared/components/shadcn-ui/Form';
import {Input} from 'shared/components/shadcn-ui/Input';
import {Separator} from 'shared/components/shadcn-ui/Separator';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from 'shared/components/shadcn-ui/Sheet';
import {toast} from 'shared/components/shadcn-ui/Toast/useToast';
import {useCurrentCompany, useConfirmation} from 'shared/hooks';
import {bem, setValidationFormErrors} from 'shared/utils';
import {extractApiErrorMessage} from 'shared/utils/axios';
import {pluralizeDays} from 'shared/utils/helpers';

import s from './VacanciesFillingTime.module.scss';
import {EditVacanciesFillingTimeSchema} from './validationSchema';

const sn = bem('vacanciesFillingTime', s);

type Props = {
  className: string;
  fillingTime: RecruitmentMetricsDTO['metrics']['fillingTime'];
  hiringTime: RecruitmentMetricsDTO['metrics']['hiringTime'];
  onAfterEdit: () => void;
};

type FormValues = z.infer<typeof EditVacanciesFillingTimeSchema>;

export const VacanciesFillingTimeMetric: FC<Props> = ({fillingTime, hiringTime, className, onAfterEdit}: Props) => {
  const {company, hasDemoStatus} = useCurrentCompany();
  const [isEditing, setIsEditing] = useState(false);
  const {confirm} = useConfirmation();

  const form = useForm<FormValues>({
    resolver: zodResolver(EditVacanciesFillingTimeSchema),
    defaultValues: {
      vacanciesFillingTime: fillingTime,
    },
  });

  const onEditDialogVisibleChange = async (isOpened: boolean) => {
    if (!isOpened) {
      if (form.formState.isDirty && !(await confirm(DIRTY_FORM_CONFIRM_OPTIONS))) return;
      form.reset(form.formState.defaultValues);
    }
    setIsEditing(isOpened);
  };

  const vacanciesFillingTimeValue = useWatch({
    control: form.control,
    name: 'vacanciesFillingTime',
    defaultValue: fillingTime,
  });

  // TODO: в будущем бэк сделает 1 АПИ точку для редактирования и тогда можно будет переиспользовать редактирование
  const onSubmit = async (data: FormValues) => {
    const {vacanciesFillingTime} = data;
    if (!company?.id || vacanciesFillingTime === null) return;
    try {
      await DashboardsApi.setVacanciesFillingTime(company.id, vacanciesFillingTime);
      toast({
        title: 'Значение метрики успешно изменено.',
      });
      onAfterEdit();
      cancelEdit();
    } catch (e) {
      Sentry.captureException(e);
      const extractedError = extractApiErrorMessage(e);
      if (typeof extractedError === 'string') {
        toast({
          title: extractedError,
          variant: 'destructive',
        });
      } else {
        setValidationFormErrors<typeof data>(extractedError, (fieldName, fieldErrors) => {
          form.setError(fieldName, {
            message: fieldErrors[0],
          });
        });
      }
    }
  };

  useEffect(() => {
    form.reset({vacanciesFillingTime: fillingTime});
  }, [fillingTime]);

  const startEdit = () => setIsEditing(true);
  const cancelEdit = () => {
    form.reset();
    setIsEditing(false);
  };

  const onValueChange = ({floatValue}: NumberFormatValues) => {
    form.setValue('vacanciesFillingTime', floatValue ?? 0, {shouldDirty: true, shouldValidate: true});
  };

  const resetValue = () => {
    form.reset({vacanciesFillingTime: fillingTime});
  };

  return (
    <>
      <MetricCard
        className={className}
        title="Время заполнения и найма"
        value={hiringTime}
        description={MINI_CARD_TIPS.fillingAndHiringTime}
        icon={<ClockIcon />}
        onEditIconClick={startEdit}
        formatter={(value) => `${value} / ${pluralizeDays(fillingTime ?? 0)}`}
      />
      <Sheet open={isEditing} onOpenChange={onEditDialogVisibleChange}>
        <SheetContent side="right">
          <SheetHeader>
            <SheetTitle>Редактирование времени заполнения</SheetTitle>
            <SheetDescription>
              Для корректной работы показателя укажите cредний срок сбора требований и публикации вакансий.
            </SheetDescription>
          </SheetHeader>
          <Form {...form}>
            <form id="editDashboardMetricForm" className={sn('form')} onSubmit={form.handleSubmit(onSubmit)}>
              <FormField
                control={form.control}
                name="vacanciesFillingTime"
                render={({field: {value}}) => (
                  <FormItem>
                    <FormLabel>Сбор требований и публикация</FormLabel>
                    <div className={sn('formField')}>
                      <FormControl>
                        <NumericFormat
                          defaultValue={0}
                          customInput={Input}
                          value={value}
                          suffix={` ${pluralizeDays(form.getValues().vacanciesFillingTime ?? 0, false)}`}
                          onValueChange={onValueChange}
                          disabled={hasDemoStatus}
                          className="disabled:cursor-default"
                        />
                      </FormControl>
                      <Button variant="link" type="button" onClick={resetValue} disabled={hasDemoStatus}>
                        Сбросить
                      </Button>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
          <MetricCard
            className={s.recruitment__cardE}
            title="Время заполнения"
            value={vacanciesFillingTimeValue ?? 0}
            description={MINI_CARD_TIPS.fillingTime}
            icon={<ClockIcon />}
            formatter={pluralizeDays}
          />
          <Separator className="mt-4 mb-4" />
          <SheetFooter className="justify-start">
            <Button
              variant="primary"
              type="submit"
              form="editDashboardMetricForm"
              className="disabled:cursor-default"
              disabled={hasDemoStatus || vacanciesFillingTimeValue === fillingTime}
            >
              Сохранить изменения
            </Button>
          </SheetFooter>
        </SheetContent>
      </Sheet>
    </>
  );
};
export default VacanciesFillingTimeMetric;
