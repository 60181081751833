import {Row} from '@tanstack/react-table';
import {forwardRef} from 'react';

import {EditableCell} from 'shared/components/EditableCell/EditableCell';
import {Input} from 'shared/components/shadcn-ui/Input';

type Props = {
  row: Row<any>;
  columnId: string;
  activeEditingRow: number | null;
};
export const NumberWhoQuitCell = forwardRef<HTMLInputElement, Props>(({row, columnId, activeEditingRow}, ref) => {
  return (
    <EditableCell columName={columnId} rowIndex={row.index} ref={ref}>
      <Input type="number" min={0} disabled={!!row.original.id && row.original.id !== activeEditingRow} />
    </EditableCell>
  );
});
