import {formatDistanceToNow} from 'date-fns';
import {FC, useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';

import {NotificationDTO, NotificationType} from 'api/dto/NotificationDTO';
import {Button} from 'shared/components/shadcn-ui/Button';
import {routes} from 'shared/constants/routes';
import {TEMPORARY_TYPE} from 'shared/layouts/BaseLayout/Header/Notifications/constants';
import {useNotificationsContext} from 'shared/layouts/BaseLayout/Header/Notifications/NotificationContext';
import {bem} from 'shared/utils';

import s from './Notification.module.scss';

const sn = bem('notification', s);

type Props = {
  channel: NotificationDTO;
  parent: Element | null;
  toggleNotifications: () => void;
};

export const Notification: FC<Props> = ({channel, parent, toggleNotifications}) => {
  const {relevantDate, id, severityType, isRead, message, notificationEventType} = channel;
  const ref = useRef(null);
  const {setTemporary, setRecentlyRead} = useNotificationsContext();
  const time = formatDistanceToNow(new Date(relevantDate), {addSuffix: true});
  const navigate = useNavigate();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && entry.intersectionRatio === 1) {
          if (severityType === TEMPORARY_TYPE) {
            setTemporary((prev) => [...prev, channel]);
          } else {
            setRecentlyRead((prev) => [...new Set([...prev, id])]);
          }
        }
      },
      {
        root: parent,
        rootMargin: '0px',
        threshold: 1,
      },
    );

    if (ref.current && !isRead) observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, []);
  const inviteNewUsers = () => {
    navigate(routes.companyUsersSettings);
    toggleNotifications();
  };
  return (
    <div className={s.notification} ref={ref}>
      {!isRead && <span className={sn('dot')}></span>}
      <p>
        {message}
        {notificationEventType === NotificationType.invitingNewUsers && (
          <Button className="p-0 pl-2 h-auto" variant={'link'} onClick={inviteNewUsers}>
            Добавить пользователей
          </Button>
        )}
      </p>
      <span className={sn('time')}>{time}</span>
    </div>
  );
};
