import * as Sentry from '@sentry/browser';
import {FC, useState} from 'react';
import {ControllerRenderProps, useFormContext} from 'react-hook-form';

import {HuntflowRecruitmentMapItemDTO} from 'api/dto';
import {huntflowIntegrationApi} from 'api/services/huntflowIntegrationApi';
import {ClipLoader} from 'shared/components/ClipLoader';
import {MultiSelect} from 'shared/components/MultiSelect/MultiSelect';
import {Button} from 'shared/components/shadcn-ui/Button';
import {CardActions} from 'shared/components/shadcn-ui/Card/Card';
import {ItemType} from 'shared/components/shadcn-ui/Command/ComboBox';
import {FormControl, FormField, FormItem, FormLabel} from 'shared/components/shadcn-ui/Form';
import {Separator} from 'shared/components/shadcn-ui/Separator';
import {useCurrentCompany, useEffectOnce, useFormValues} from 'shared/hooks';
import {bem} from 'shared/utils';
import {extractApiErrorMessage, isAxiosError} from 'shared/utils/axios';
import {handleApiErrors} from 'shared/utils/helpers';

import {InfoBlock} from './components/InfoBlock';
import s from './ReferralStep.module.scss';

import {FormValuesType} from '../../utils/types';

const sn = bem('referralStep', s);

type Props = {
  onError: (error: string) => void;
  initialMap: HuntflowRecruitmentMapItemDTO[];
  onChangeTab: (newTabName: string) => void;
  isFirstSetup: boolean;
};

export const ReferralStep: FC<Props> = ({onError, onChangeTab, isFirstSetup}) => {
  const {company, hasDemoStatus} = useCurrentCompany();
  const [isLoading, setIsLoading] = useState(true);
  const [sourcesList, setSourcesList] = useState<ItemType[]>([]);

  const form = useFormContext<FormValuesType>();
  const {control} = form;
  const formValues = useFormValues();
  const {accessToken: huntflowAccessToken, organizationId: huntflowOrgId} = useFormValues<FormValuesType>();

  const goBack = () => onChangeTab('mappingStep');

  const init = async () => {
    if (!company?.id) return;

    try {
      const sourcesList = hasDemoStatus
        ? []
        : await huntflowIntegrationApi.getReferralProgramSources(company.id, huntflowOrgId, huntflowAccessToken);

      setSourcesList(() => {
        return sourcesList.map(({huntflowResumeSourceId, resumeSourceName}) => ({
          label: resumeSourceName,
          value: huntflowResumeSourceId.toString(),
        }));
      });

      const checkedSources = await huntflowIntegrationApi.getReferralProgramSettings(company.id, huntflowOrgId);
      form.setValue(
        'referralProgramSources',
        checkedSources.map(({resumeSourceName, huntflowResumeSourceId}) => ({
          value: huntflowResumeSourceId.toString(),
          label: resumeSourceName,
        })),
      );
    } catch (error) {
      Sentry.captureException(error);
      const extractedError = extractApiErrorMessage(error);
      if (isAxiosError(error)) {
        if (error.response?.status === 400) {
          onError(typeof extractedError === 'string' ? extractedError : 'Проверьте авторизационные данные Хантфлоу.');
        } else {
          handleApiErrors(error, 'Не удалось получить данные Хантфлоу.');
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffectOnce(() => {
    init();
  });

  const onValueChange = (
    values: ItemType[],
    field: ControllerRenderProps<FormValuesType, 'referralProgramSources'>,
  ) => {
    form.setValue(field.name, values);
    form.clearErrors(field.name);
  };

  const getActionButtons = () => {
    if (!isFirstSetup) return null;
    return (
      <>
        <Separator className="my-3" />
        <CardActions className="flex items-end justify-end">
          <Button variant="outline" onClick={goBack} type="button">
            Назад
          </Button>
          <Button type="submit" variant="primary" disabled={hasDemoStatus || form.formState.isSubmitting}>
            {form.formState.isSubmitting ? 'Загрузка...' : 'Сохранить'}
          </Button>
        </CardActions>
      </>
    );
  };

  if (isLoading) return <ClipLoader containerHeight={450} />;
  return (
    <div className={s.referralStep}>
      <div className={sn('content')}>
        <FormField
          control={control}
          name="referralProgramSources"
          render={({field}) => {
            if (field.value.find((item) => item.label === '')) return <ClipLoader />;
            const values = field.value.map(({label, value}) => ({value: value, label, isChecked: true})) ?? [];
            return (
              <FormItem className="basis-[150px]">
                <FormLabel>Выберите источники реферальной программы</FormLabel>
                <FormControl>
                  <MultiSelect
                    itemList={sourcesList.map(({value, label}) => ({
                      value,
                      label,
                      isChecked: values.some((item) => item.value === value),
                    }))}
                    title="Данные Хантфлоу"
                    onValueChange={(selectedValues) => onValueChange(selectedValues, field)}
                    value={values}
                    placeholder="Выберите один или несколько источников"
                    disabled={hasDemoStatus || !formValues.isEnabled}
                  />
                </FormControl>
              </FormItem>
            );
          }}
        />
        {getActionButtons()}
      </div>

      <Separator orientation="vertical" className={sn('separator')} />
      <InfoBlock />
    </div>
  );
};
