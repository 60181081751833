import * as z from 'zod';

import {phoneFieldValidation} from 'shared/utils/validation';

const MIN_LENGTH_MSG = 'Минимальная длина 3 символа';
const MAX_LENGTH_MSG = 'Максимальная длина 180 символов';

export const FormSchema = z.object({
  firstName: z.string().min(3, {message: MIN_LENGTH_MSG}).max(180, {message: MAX_LENGTH_MSG}),
  lastName: z.string().min(3, {message: MIN_LENGTH_MSG}).max(180, {message: MAX_LENGTH_MSG}),
  email: z
    .string()
    .email({
      message: 'Некорректный email',
    })
    .optional(),
  statusId: z.string().refine((v) => !isNaN(Number(v)) && v?.length > 0),
  // TODO: попросить бэк разрешить очищать поле
  phoneNumber: phoneFieldValidation,
});
