import {createContext, useContext, useMemo, useState} from 'react';
import {Outlet} from 'react-router';

import {useUserRole} from 'shared/hooks';

type SettingsContextType = {
  noTitle: boolean;
  showTitle: () => void;
};

const SettingsContext = createContext<SettingsContextType>({
  noTitle: false,
  showTitle: null as unknown as () => void,
});

export const useSettingsContext = () => useContext(SettingsContext);

export const SettingsContextProvider = () => {
  const {isCompanyAdmin} = useUserRole();
  const [noTitle, setNoTitle] = useState<boolean>(!isCompanyAdmin);
  const showTitle = () => setNoTitle(false);
  const contextValue = useMemo(() => ({noTitle, showTitle}), [noTitle, showTitle]);
  return (
    <SettingsContext.Provider value={contextValue}>
      <Outlet />
    </SettingsContext.Provider>
  );
};
