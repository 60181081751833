import {isSameDay} from 'date-fns';
import * as z from 'zod';

import {REQUIRED_FIELD_MSG} from 'shared/utils/validation';

export const FormSchema = z.object({
  rows: z
    .array(
      z
        .object({
          id: z.coerce.number().nullable(),
          date: z
            .date({
              invalid_type_error: REQUIRED_FIELD_MSG,
            })
            .nullable(),
          positive: z
            .number({
              required_error: REQUIRED_FIELD_MSG,
            })
            .or(z.string().nonempty({message: REQUIRED_FIELD_MSG})),
          negative: z
            .number({
              required_error: REQUIRED_FIELD_MSG,
            })
            .or(z.string().nonempty({message: REQUIRED_FIELD_MSG})),
          neutral: z
            .number({
              required_error: REQUIRED_FIELD_MSG,
            })
            .or(z.string().nonempty({message: REQUIRED_FIELD_MSG})),
        })
        .refine((data) => data.positive !== 0 || data.negative !== 0 || data.neutral !== 0, {
          message: 'Проверьте значениe',
          path: ['positive'],
        })
        .refine((data) => data.positive !== 0 || data.negative !== 0 || data.neutral !== 0, {
          message: 'Проверьте значениe',
          path: ['neutral'],
        })
        .refine((data) => data.positive !== 0 || data.negative !== 0 || data.neutral !== 0, {
          message: 'Проверьте значениe',
          path: ['negative'],
        }),
    )
    .superRefine((rows, ctx) => {
      rows.forEach(({date, id}, index) => {
        if (!date) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Дата должна быть заполнена',
            path: [index, 'date'],
          });
        } else {
          const duplicates = rows.filter((row) => row.date && isSameDay(date, row.date) && !id);
          if (duplicates.length > 1) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Дата должна быть уникальной',
              path: [index, 'date'],
            });
          }
        }
      });
    }),
});
