import {DismissableLayer} from '@radix-ui/react-dismissable-layer';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import {forwardRef, ElementRef, ComponentPropsWithoutRef} from 'react';

import {cn} from 'shared/utils/helpers';

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;
const PopoverClose = PopoverPrimitive.Close;

const PopoverContent = forwardRef<
  ElementRef<typeof PopoverPrimitive.Content>,
  ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> & {withPortal?: boolean}
>(({className, align = 'center', sideOffset = 4, withPortal = true, ...props}, ref) => {
  const content = (
    <DismissableLayer>
      <PopoverPrimitive.Content
        ref={ref}
        align={align}
        sideOffset={sideOffset}
        className={cn(
          'z-50 w-[--radix-popover-trigger-width] rounded-md border bg-popover p-4 text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
          className,
        )}
        {...props}
      />
    </DismissableLayer>
  );

  if (withPortal) return <PopoverPrimitive.Portal>{content}</PopoverPrimitive.Portal>;
  return content;
});
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export {Popover, PopoverTrigger, PopoverContent, PopoverClose};
