import {CheckCircle2, MinusCircle} from 'lucide-react';
import {Link, useMatch} from 'react-router-dom';

import {IntegrationTypeId} from 'api/dto';
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
  navigationMenuTriggerStyle,
  ACTIVE_LINK_STYLE,
} from 'shared/components/shadcn-ui/NavigationMenu';
import {routes} from 'shared/constants/routes';
import {useIntegrationStatuses} from 'shared/hooks';
import {cn} from 'shared/utils/helpers';

export const NavTabs = () => {
  const isHuntflowIntegrationPage = useMatch({path: routes.huntflowIntegration});
  const isYandexMetricIntegrationPage = useMatch({path: routes.yandexMetricIntegration});
  const isYandexDirectIntegrationPage = useMatch({path: routes.yandexDirectIntegration});
  const isOneCIntegrationPage =
    useMatch({path: routes.oneCIntegration}) ||
    (!isHuntflowIntegrationPage && !isYandexMetricIntegrationPage && !isYandexDirectIntegrationPage);

  const isOneCEnabled = useIntegrationStatuses(IntegrationTypeId.OneC)?.isEnabled;
  const isHuntflowEnabled = useIntegrationStatuses(IntegrationTypeId.Huntflow)?.isEnabled;
  const isYandexMetricaEnabled = useIntegrationStatuses(IntegrationTypeId.YandexMetrica)?.isEnabled;
  const isYandexDirectEnabled = useIntegrationStatuses(IntegrationTypeId.YandexDirect)?.isEnabled;

  return (
    <NavigationMenu orientation="vertical" className="flex gap-x-4 w-56">
      <NavigationMenuList className="flex-col items-start h-44 gap-y-3">
        <NavigationMenuItem className="flex gap-x-2 items-center">
          <Link
            to={routes.oneCIntegration}
            className={cn(navigationMenuTriggerStyle(), 'w-36 justify-start', {
              [ACTIVE_LINK_STYLE]: isOneCIntegrationPage,
            })}
          >
            1С
          </Link>
          {isOneCEnabled ? <CheckCircle2 className="stroke-success-700" /> : <MinusCircle />}
        </NavigationMenuItem>
        <NavigationMenuItem className="flex gap-x-2 items-center ">
          <Link
            to={routes.huntflowIntegration}
            className={cn(navigationMenuTriggerStyle(), 'w-36 justify-start', {
              [ACTIVE_LINK_STYLE]: isHuntflowIntegrationPage,
            })}
          >
            Хантфлоу
          </Link>
          {isHuntflowEnabled ? <CheckCircle2 className="stroke-success-700" /> : <MinusCircle />}
        </NavigationMenuItem>
        <NavigationMenuItem className="flex gap-x-2 items-center ">
          <Link
            to={routes.yandexMetricIntegration}
            className={cn(navigationMenuTriggerStyle(), 'w-36 justify-start', {
              [ACTIVE_LINK_STYLE]: isYandexMetricIntegrationPage,
            })}
          >
            Яндекс Метрика
          </Link>
          {isYandexMetricaEnabled ? <CheckCircle2 className="stroke-success-700" /> : <MinusCircle />}
        </NavigationMenuItem>
        <NavigationMenuItem className="flex gap-x-2 items-center ">
          <Link
            to={routes.yandexDirectIntegration}
            className={cn(navigationMenuTriggerStyle(), 'w-36 justify-start', {
              [ACTIVE_LINK_STYLE]: isYandexDirectIntegrationPage,
            })}
          >
            Яндекс Директ
          </Link>
          {isYandexDirectEnabled ? <CheckCircle2 className="stroke-success-700" /> : <MinusCircle />}
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
};
