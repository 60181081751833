import {RefreshCcw} from 'lucide-react';
import {Fragment} from 'react';

import {IntegrationTypeId} from 'api/dto';
import {IntegrationInfoBlock} from 'shared/components/IntegrationInfoBlock';
import {MEDIA_BREAKPOINT_L} from 'shared/constants';
import {useCurrentCompany, useIntegrationStatuses} from 'shared/hooks';
import {bem} from 'shared/utils';
import {cn} from 'shared/utils/helpers';

import s from '../GoalsStep.module.scss';

const sn = bem('goalsStep', s);

export const InfoBlock = () => {
  const {hasDemoStatus} = useCurrentCompany();
  const hasMetricaSettings = useIntegrationStatuses(IntegrationTypeId.YandexMetrica);

  const isLargeScreen = window.innerWidth >= MEDIA_BREAKPOINT_L;

  if (hasDemoStatus)
    return (
      <IntegrationInfoBlock description={[<div key="demo" className={cn({['min-h-[300px]']: isLargeScreen})} />]} />
    );

  return (
    <IntegrationInfoBlock
      title={hasMetricaSettings ? 'Подключение и выбор цели' : 'Шаг 2. Подключение и выбор цели'}
      previewChars={108}
      description={[
        <Fragment key="description-1">
          На этом шаге вам необходимо выбрать одну или несколько целей для подключённого ранее счётчика Яндекс Метрики.
          Если система «Стафскрин» не нашла ни одной активной цели, попробуйте создать новые, используя эту{' '}
          <a
            className="underline text-link"
            href="https://yandex.ru/support/metrica/general/goal-page-views.html"
            target="_blank"
            rel="noreferrer"
          >
            инструкцию
          </a>
          . Или вернитесь на предыдущий шаг и выберете другой счётчик. В случае создания новых целей обновите страницу,
          нажав на соответствующую кнопку.
        </Fragment>,
        <Fragment key="description-2">
          В случае создания новых целей обновите страницу, нажав на кнопку{' '}
          <span className={sn('refresh')}>
            <RefreshCcw />
          </span>
        </Fragment>,

        'Если некоторые цели в списке неактивны, значит, что они были удалены из Яндекс Метрики и система «Стафскрин» больше не получает с них данные.',
        'Если некоторые счётчики в списке неактивны, значит, что они были удалены из Яндекс Метрики и система «Стафскрин» больше не может получать с них данные.',
      ]}
    />
  );
};
