import {forwardRef} from 'react';

import {cn} from 'shared/utils/helpers';

import {Button, ButtonProps} from '../shadcn-ui/Button';

export const FixedButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({onClick, className, children, ...props}, ref) => {
    return (
      <Button
        className={cn(
          'w-[64px] h-[64px] bg-primary box-border rounded-[50%] fixed z-40 bottom-[80px] right-[80px] hover:ring-warning hover:ring-2 p-3',
          className,
        )}
        variant="icon"
        onClick={onClick}
        ref={ref}
        {...props}
      >
        {children}
      </Button>
    );
  },
);
