import {PackageIcon} from 'lucide-react';
import {FC} from 'react';

import {CompaniesApi} from 'api';
import {CompanyUserDTO} from 'api/dto';
import {toast} from 'shared/components/shadcn-ui/Toast/useToast';
import {useCurrentCompany, useUserRole} from 'shared/hooks';
import {cn, getUserArchiveStatusId} from 'shared/utils/helpers';
import {useRootSelector} from 'store';
import {dictionariesSelector} from 'store/dictionaries/selectors';

import {ActionIconButton} from '../ActionIconButton';

type Props = {
  companyUser: CompanyUserDTO;
  disabled: boolean;
};

export const ArchiveUserButton: FC<Props> = ({companyUser, disabled}) => {
  const userStatuses = useRootSelector(dictionariesSelector.getDictionaries).userStatuses;
  const archiveStatusId = getUserArchiveStatusId(userStatuses ?? []);
  const {company} = useCurrentCompany();
  const {isSuperAdmin} = useUserRole();

  const archiveUser = async () => {
    if (archiveStatusId !== null && company?.id) {
      await CompaniesApi.updateCompanyUser(company.id, companyUser.userId, {
        statusId: archiveStatusId,
      });
      toast({
        title: `Пользователь ${companyUser.fio} заархивирован`,
      });
    }
  };

  return (
    <>
      <ActionIconButton
        className={cn({['mr-2']: !isSuperAdmin})}
        name="Блокирование сотрудника"
        title="Отправить в архив"
        description={`Вы действительно хотите отправить в архив ${companyUser.fio}?`}
        accept={archiveUser}
        disabled={disabled}
      >
        <PackageIcon />
      </ActionIconButton>
    </>
  );
};
