import * as amplitude from '@amplitude/analytics-browser';
import {createAsyncThunk} from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';

import {CompaniesApi} from 'api';
import {UserDTOWithAuthData} from 'api/dto';
import {JwtAuthServiceSingleton} from 'services/JwtAuthService';
import {SignInModel} from 'shared/models';
import {serializeError} from 'store/serializeErrors';

export const signIn = createAsyncThunk<
UserDTOWithAuthData,
Omit<SignInModel, 'fingerprint'>,
{
  rejectValue: unknown;
}
>('auth/signIn', async (values, {rejectWithValue}) => {
  try {
    return await JwtAuthServiceSingleton.signIn(values);
  } catch (error) {
    Sentry.captureException(error);
    return rejectWithValue(error);
  }
});

export const signOut = createAsyncThunk(
  'auth/signOut',
  async () => {
    amplitude.reset(); // TODO заменить на вызов метода сервиса аналитики
    return await JwtAuthServiceSingleton.signOut();
  },
  {serializeError},
);

export const getCompanyUserProfile = createAsyncThunk(
  'auth/getCompanyUserProfile',
  async ({companyId, userId}: {companyId: string | number; userId: string | number}) => {
    return await CompaniesApi.getCompanyUserById(companyId, userId);
  },
  {serializeError},
);

export const authAsyncActions = {
  signIn,
  signOut,
  getCompanyUserProfile,
};
