import * as Sentry from '@sentry/browser';
import {Dispatch, FC, SetStateAction, createContext, useCallback, useContext, useEffect, useState} from 'react';
import {Outlet} from 'react-router';

import {YandexApi} from 'api';
import {
  YandexDirectSettingsViewDTO,
  YandexMetricaSettingsViewDTO,
  YandexSegmentDTO,
  YandexOAuthSettingsDTO,
  IntegrationTypeId,
  YandexIntegrationType,
  IntegrationStatusId,
} from 'api/dto';
import {useCurrentCompany, useEffectOnce, useIntegrationStatuses} from 'shared/hooks';
import {ErrorData, useYandexOAuth} from 'shared/hooks/useYandexOAuth';
import {isAxiosError} from 'shared/utils/axios';
import {handleApiErrors} from 'shared/utils/helpers';
import {YandexIntegrationStep} from 'typings/yandexOauth';

type YandexContextType = {
  yandexParameters: YandexDirectSettingsViewDTO | YandexMetricaSettingsViewDTO | null;
  setYandexParameters: (params: YandexDirectSettingsViewDTO | YandexMetricaSettingsViewDTO | null) => void;
  fetchYandexParameters: () => void;
  integrationStep: YandexIntegrationStep | null;
  setIntegrationStep: (step: YandexIntegrationStep | null) => void;
  authenticated: boolean | null;
  reset: () => void;
  init: () => void;
  error: ErrorData | null;
  oAuthConfig: Omit<YandexOAuthSettingsDTO, 'type'> | null;

  refreshParameters: () => void;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  deletedSegments: YandexSegmentDTO[];
  setDeletedSegments: Dispatch<SetStateAction<YandexSegmentDTO[]>>;
};

export const YandexIntegrationContext = createContext<YandexContextType>({
  yandexParameters: null,
  setYandexParameters: () => null,
  fetchYandexParameters: async () => null,
  integrationStep: null,
  setIntegrationStep: () => null,
  authenticated: null,
  reset: () => null,
  init: () => null,
  error: null,
  oAuthConfig: null,
  refreshParameters: () => null,
  isLoading: false,
  setIsLoading: () => null,
  deletedSegments: [],
  setDeletedSegments: () => null,
});

export const YandexIntegrationProvider: FC<{type: YandexIntegrationType}> = ({type}) => {
  const [isLoading, setIsLoading] = useState(false);
  const {hasDemoStatus, company} = useCurrentCompany();
  const [yandexParameters, setYandexParameters] = useState<
  YandexDirectSettingsViewDTO | YandexMetricaSettingsViewDTO | null
  >(null);
  const integrationStatus = useIntegrationStatuses(
    type === YandexIntegrationType.direct ? IntegrationTypeId.YandexDirect : IntegrationTypeId.YandexMetrica,
  );
  const isYandexEnabled = integrationStatus?.isEnabled;
  const [integrationStep, setIntegrationStep] = useState<YandexIntegrationStep | null>(null);
  const {authenticated, reset, init, error, oAuthConfig, setOAuthConfig} = useYandexOAuth(type);
  const [deletedSegments, setDeletedSegments] = useState<YandexSegmentDTO[]>([]);

  const checkCurrentStartStep = useCallback(async () => {
    if (!integrationStatus) return;
    if (hasDemoStatus) {
      setIntegrationStep(YandexIntegrationStep.showSettings);
      await fetchYandexParameters();
      return;
    }
    // показываем, что настройки в ключены сразу после включения, пока еще идет загрузка данных (статус Info)
    if (
      isYandexEnabled &&
      integrationStatus?.statusId !== IntegrationStatusId.Ready &&
      integrationStatus?.statusId !== IntegrationStatusId.Info
    ) {
      setIntegrationStep(YandexIntegrationStep.showSettings);
      await fetchYandexParameters();
    } else {
      setIntegrationStep(isYandexEnabled ? YandexIntegrationStep.showSuccess : YandexIntegrationStep.showPlaceholder);
    }
  }, [integrationStatus, hasDemoStatus]);

  const fetchYandexParameters = useCallback(async () => {
    if (company?.id) {
      try {
        setIsLoading(true);

        const data =
          type === YandexIntegrationType.direct
            ? await YandexApi.getYandexDirectIntegrationSettingsView(company.id)
            : await YandexApi.getYandexMetricaIntegrationSettingsView(company.id);
        setYandexParameters(data);
        setOAuthConfig(data.accessToken ? {accessToken: data.accessToken} : null);
      } catch (error) {
        Sentry.captureException(error);
        if (isAxiosError(error) && error.response?.status !== 404) {
          handleApiErrors(error, 'Не удалось загрузить детали интеграции.');
        }
      } finally {
        setIsLoading(false);
      }
    }
  }, [company, type]);

  const refreshParameters = useCallback(async () => {
    if (!company?.id) return;
    try {
      if (type === YandexIntegrationType.metrica && !hasDemoStatus) {
        await YandexApi.updateDataOfYandexMetricaOnServer(company.id);
      }
      fetchYandexParameters();
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response?.status === 403) return;
        if (error.response?.status === 404 || error.response?.status === 400) {
          fetchYandexParameters();
        }
      } else {
        handleApiErrors(error);
      }
    }
  }, [company, type, hasDemoStatus, fetchYandexParameters]);

  useEffectOnce(() => {
    checkCurrentStartStep();
  });

  const contextValue = {
    yandexParameters,
    setYandexParameters,
    fetchYandexParameters,
    integrationStep,
    setIntegrationStep,
    authenticated,
    reset,
    init,
    error,
    oAuthConfig,
    refreshParameters,
    isLoading,
    setIsLoading,
    deletedSegments,
    setDeletedSegments,
  };

  return (
    <YandexIntegrationContext.Provider value={contextValue}>
      <Outlet />
    </YandexIntegrationContext.Provider>
  );
};

export const useYandexIntegrationParametersContext = () => useContext(YandexIntegrationContext);
