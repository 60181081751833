import * as Sentry from '@sentry/browser';
import {useState} from 'react';

import {UsersApi} from 'api';
import {OnboardingStatus} from 'api/dto';
import ZapIcon from 'assets/icons/zap.svg';
import {bem} from 'shared/utils';
import {handleApiErrors} from 'shared/utils/helpers';
import {useRootDispatch, useRootSelector} from 'store';
import {authActions} from 'store/auth';
import {authSelectors} from 'store/auth/selectors';

import {OnboardingTabs} from './components/OnboardingTabs';
import s from './Onboarding.module.scss';

import {FixedButton} from '../FixedButton';
import {Dialog, DialogContent} from '../shadcn-ui/Dialog';

const sn = bem('onboarding', s);

export const Onboarding = () => {
  const [isShownOnboarding, setIsShownOnboarding] = useState(false);
  const user = useRootSelector(authSelectors.getUser);
  const onboardingStatus = user?.onboardingState;
  const dispatch = useRootDispatch();

  const updateOnboardingStatus = async (userId: number, status: OnboardingStatus) => {
    try {
      await UsersApi.setOnboardingState(userId, status);
      const updatedUser = await UsersApi.getUser(userId);
      dispatch(authActions.setAuthorizedUser(updatedUser));
    } catch (error) {
      Sentry.captureException(error);
      handleApiErrors(error, 'Не удалось отправить информацию о прохождении онбординга.');
    }
  };

  const postponeOnboarding = async () => {
    if (user?.id && onboardingStatus === OnboardingStatus.notStarted) {
      await updateOnboardingStatus(user.id, OnboardingStatus.started);
    }
    setIsShownOnboarding(false);
  };

  const completeOnboarding = async () => {
    if (user?.id && onboardingStatus !== OnboardingStatus.completed) {
      await updateOnboardingStatus(user.id, OnboardingStatus.completed);
    }
    setIsShownOnboarding(false);
  };

  const onOpenChange = async (isOpen: boolean) => {
    if (!user?.id) return;
    if (!isOpen && onboardingStatus === OnboardingStatus.notStarted) {
      await postponeOnboarding();
      return;
    }
    setIsShownOnboarding(isOpen);
  };

  return (
    <Dialog open={isShownOnboarding} onOpenChange={onOpenChange}>
      <FixedButton onClick={() => setIsShownOnboarding(true)}>
        <img src={ZapIcon} alt="вернуться к онбордингу" className={sn('zap')} />
      </FixedButton>
      <DialogContent
        className="max-w-[660px] h-[754px] sm:rounded-[20px] p-0 overflow-hidden  bg-transparent"
        classNameOverlay="backdrop-filter-none inset-0 bg-[#120B58] opacity-40"
        withClose={false}
      >
        <OnboardingTabs postponeOnboarding={postponeOnboarding} completeOnboarding={completeOnboarding} />
      </DialogContent>
    </Dialog>
  );
};
