import {useNavigate} from 'react-router-dom';

import {routes} from 'shared/constants/routes';
import {useEffectOnce} from 'shared/hooks';

export const PermissionDenied = () => {
  const navigate = useNavigate();
  useEffectOnce(() => {
    setTimeout(() => {
      navigate(routes.home, {replace: true});
    }, 2500);
  });
  return <h1>У вас нет прав для просмотра этой страницы</h1>;
};
