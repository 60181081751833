import {zodResolver} from '@hookform/resolvers/zod';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate, generatePath} from 'react-router';
import * as z from 'zod';

import {CompaniesApi} from 'api';
import {ClipLoader} from 'shared/components/ClipLoader';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Card, CardHeader} from 'shared/components/shadcn-ui/Card';
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from 'shared/components/shadcn-ui/Form';
import {Input} from 'shared/components/shadcn-ui/Input';
import {Separator} from 'shared/components/shadcn-ui/Separator';
import {Textarea} from 'shared/components/shadcn-ui/Textarea/Textarea';
import {toast} from 'shared/components/shadcn-ui/Toast/useToast';
import {routes} from 'shared/constants/routes';
import {useCurrentCompany, useLoadCompany, useUserRole} from 'shared/hooks';
import {useScrollToTopOnMount} from 'shared/hooks/useScrollToTopOnMount';
import {useProvideBreadcrumbsData} from 'shared/layouts/BaseLayout/BaseLayoutContext';
import {bem} from 'shared/utils';
import {extractApiErrorMessage} from 'shared/utils/axios';
import {safeParseDate} from 'shared/utils/date';
import {cn} from 'shared/utils/helpers';
import {useRootDispatch} from 'store';
import {companiesActions} from 'store/companies';

import {ActiveCompanyBadge} from './components/ActiveCompanyBadge';
import s from './EditCompany.module.scss';
import {FormSchema} from './utils/validationSchema';

const sn = bem('companyForm', s);

export const EditCompany = () => {
  const {company, loaded} = useLoadCompany();
  const navigate = useNavigate();
  const {isCompanyAdmin} = useUserRole();
  const dispatch = useRootDispatch();
  const {hasDemoStatus, hasActiveCompanyStatus} = useCurrentCompany();
  const formattedStatusExpiresAt = safeParseDate(company?.statusExpiresAt);

  useProvideBreadcrumbsData(company);
  useScrollToTopOnMount();

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      companyName: company?.companyName,
      legalAddress: company?.legalAddress,
      inn: company?.inn,
    },
  });
  const {isSubmitting} = form.formState;

  useEffect(() => {
    if (company) {
      form.reset(company);
    }
  }, [company]);

  const submit = async ({inn, ...data}: z.infer<typeof FormSchema>) => {
    if (company?.id) {
      try {
        await CompaniesApi.updateCompany(company.id, data);
        toast({
          title: 'Данные успешно обновлены.',
        });
        dispatch(companiesActions.setCurrentCompany({...company, ...data}));
        if (!isCompanyAdmin) openCompanyPage();
      } catch (error) {
        const extractedError = extractApiErrorMessage(error);
        if (typeof extractedError === 'string') {
          toast({
            variant: 'destructive',
            title: extractedError,
          });
        } else {
          Object.entries(extractedError).forEach(([fieldName, fieldErrors]: [string, string[]]) => {
            if (Object.keys(form.getValues()).includes(fieldName)) {
              form.setError(fieldName as keyof typeof data, {
                message: fieldErrors[0],
              });
            }
          });
        }
      }
    }
  };

  const openCompanyPage = () => {
    navigate(generatePath(routes.company, {compId: company?.id}));
  };

  if (loaded) {
    return <ClipLoader />;
  }

  return (
    <Card className={cn('flex gap-2', {['gap-8']: isCompanyAdmin})}>
      {isCompanyAdmin && <CardHeader className="font-bold basis-[300px] self-start">Данные компании</CardHeader>}
      <Form {...form}>
        <form
          id="companyDataForm"
          className={cn(sn('form'), {[sn('form_admin')]: isCompanyAdmin})}
          onSubmit={form.handleSubmit(submit)}
        >
          <FormField
            control={form.control}
            name="inn"
            render={({field}) => (
              <FormItem className="h-[80px] w-full max-w-[276px]">
                <FormLabel>ИНН</FormLabel>
                <FormControl>
                  <Input placeholder="ИНН" type="number" disabled={true} readOnly={true} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Separator className="my-6" />
          <div className={cn('w-full max-w-[276px] flex-wrap  flex gap-6', {[' max-w-[600px]  gap8']: isCompanyAdmin})}>
            <FormField
              control={form.control}
              name="companyName"
              render={({field}) => (
                <FormItem className="h-[140px] w-full max-w-[276px]">
                  <FormLabel>Наименование организации</FormLabel>
                  <FormControl>
                    <Textarea className="h-[100px]" placeholder="Наименование" readOnly={hasDemoStatus} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="legalAddress"
              render={({field}) => (
                <FormItem className="h-[140px] w-full max-w-[276px]">
                  <FormLabel>Юридический адрес</FormLabel>
                  <FormControl>
                    <Textarea
                      className="h-[100px]"
                      placeholder="Юридический адрес"
                      readOnly={hasDemoStatus}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <Separator className="my-6" />
          {hasActiveCompanyStatus && formattedStatusExpiresAt && (
            <ActiveCompanyBadge className="mb-6" statusExpiresAt={formattedStatusExpiresAt} />
          )}

          <div className={cn(sn('actions'))}>
            {!isCompanyAdmin && (
              <Button type="button" variant="outline" onClick={openCompanyPage}>
                Отмена
              </Button>
            )}

            <Button
              type="submit"
              form="companyDataForm"
              variant="primary"
              disabled={isSubmitting || hasDemoStatus}
              className={cn('self-end mb-6 ml-auto  min-w-[120px]', {['mr-[124px]']: isCompanyAdmin})}
            >
              {isSubmitting ? 'Загрузка...' : isCompanyAdmin ? 'Сохранить' : 'Сохранить и закрыть'}
            </Button>
          </div>
        </form>
      </Form>
    </Card>
  );
};
