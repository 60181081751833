import * as z from 'zod';

export const FormSchema = z.object({
  rows: z.array(
    z.object({
      datePeriod: z.object({
        from: z.date().optional(),
        to: z.date().optional(),
      }),
      successfulHiresNumber: z.number().or(z.string()),
    }),
  ),
});
