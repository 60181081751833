import {SortAscIcon, SortDescIcon} from 'lucide-react';
import {FC} from 'react';

import {RadioGroup, RadioGroupItem} from 'shared/components/shadcn-ui/RadioGroup';
import {bem} from 'shared/utils';
import {cn} from 'shared/utils/helpers';

import s from './AscOrDesSortBlock.module.scss';

const sn = bem('ascOrDesSortBlock', s);

type Props = {
  handleSortingClick: (mode: 'asc' | 'desc' | 'none') => void;
  sortingDirection: 'asc' | 'desc' | 'none';
  toggleMenu?: () => void;
  hasClearBtn?: boolean;
};

export const AscOrDesSortBlock: FC<Props> = ({
  sortingDirection,
  handleSortingClick,
  toggleMenu,
  hasClearBtn = true,
}) => {
  return (
    <div className={s.ascOrDesSortBlock}>
      <RadioGroup
        className={sn('group')}
        value={sortingDirection}
        onValueChange={handleSortingClick}
        onClick={toggleMenu}
      >
        <p className={sn('label')}>Сортировка</p>
        {hasClearBtn && (
          <RadioGroupItem className={sn('clear')} indicatorType="check" value="none">
            СБРОСИТЬ
          </RadioGroupItem>
        )}
        <div className={sn('separator')} />
        <RadioGroupItem className={cn(sn('asc'), sn('item'))} indicatorType="check" value="asc">
          <SortAscIcon className={sn('icon')} />
          По возрастанию
        </RadioGroupItem>
        <RadioGroupItem className={cn(sn('desc'), sn('item'))} indicatorType="check" value="desc">
          <SortDescIcon className={sn('icon')} />
          По убыванию
        </RadioGroupItem>
      </RadioGroup>
    </div>
  );
};
