import {EditIcon, XCircle} from 'lucide-react';
import {FC} from 'react';
import {generatePath, useNavigate} from 'react-router';

import {CompaniesApi} from 'api';
import {CompanyStatus, CompanyUserDTO} from 'api/dto';
import {Button} from 'shared/components/shadcn-ui/Button';
import {toast} from 'shared/components/shadcn-ui/Toast/useToast';
import {routes} from 'shared/constants/routes';
import {useCurrentCompany, useUserRole} from 'shared/hooks';
import {getUserArchiveStatusId} from 'shared/utils/helpers';
import {useRootSelector} from 'store';
import {dictionariesSelector} from 'store/dictionaries/selectors';

import {ActionIconButton} from '../ActionIconButton';
import {ArchiveUserButton} from '../ArchiveUserButton';

type Props = {
  data: CompanyUserDTO;
  getValue: () => string;
};

export const UserActionButtons: FC<Props> = ({data, getValue}) => {
  const {userStatuses} = useRootSelector(dictionariesSelector.getDictionaries);
  const archiveStatusId = userStatuses ? getUserArchiveStatusId(userStatuses) : null;
  const isArchived = data.statusId === archiveStatusId;
  const {company} = useCurrentCompany();
  const {isSuperAdmin} = useUserRole();
  const navigate = useNavigate();
  const companyStatusName = company?.statusName;

  const editUser = (id: string | number) => {
    isSuperAdmin
      ? navigate(generatePath(routes.companyUser, {compId: String(company?.id), userId: String(id)}))
      : navigate(routes.editCompanyUser, {state: {userId: String(id)}});
  };

  const deleteUser = async (id: string | number, fio: string) => {
    if (company?.id) {
      await CompaniesApi.deleteCompanyUser(company.id, id);
      toast({
        title: `Пользователь ${fio} удален.`,
      });
    }
  };
  return (
    <div className="flex flex-row items-center">
      <Button
        variant="icon"
        size="icon"
        title="Редактировать"
        onClick={() => editUser(data.userId)}
        disabled={companyStatusName === CompanyStatus.Demo}
      >
        <EditIcon />
      </Button>

      <ArchiveUserButton companyUser={data} disabled={isArchived || companyStatusName === CompanyStatus.Demo} />

      {isSuperAdmin && (
        <ActionIconButton
          className="mr-2"
          name="Удалить сотрудника"
          title="Удалить"
          description={`Вы действительно хотите удалить ${data.fio}?`}
          accept={() => deleteUser(data.userId, data.fio)}
        >
          <XCircle />
        </ActionIconButton>
      )}
      <span>{getValue()}</span>
    </div>
  );
};
