import {UserMinusIcon, UserPlusIcon, UsersIcon} from 'lucide-react';
import {useEffect, useRef, useState} from 'react';

import {DashboardsApi} from 'api';
import {CommonMetricsDTO, IntegrationTypeId} from 'api/dto';
import {
  MetricCard,
  AbsencesDynamicsChart,
  ActualStaffingChart,
  FlowRateDynamicChart,
  HeadCountDynamicChart,
  HiresAndFiresChart,
  DashboardTopBar,
  useDashboardContext,
  ChartCard,
} from 'shared/components/Dashboards';
import {FlowRateKpiMetric} from 'shared/components/Dashboards/FlowRateKpiMetric';
import {CHART_CARD_TIPS, formatValueAsPeopleNumber, MINI_CARD_TIPS} from 'shared/components/Dashboards/utils';
import {handleErrorFromFetchingData} from 'shared/components/Dashboards/utils/helpers';
import {useCurrentCompany, useDependencyOnIntegration, useIntegrationStatuses} from 'shared/hooks';
import {useDebounce} from 'shared/hooks/useDebounce';
import {useDimensions} from 'shared/hooks/useDimensions';
import {usePrevious} from 'shared/hooks/usePrevious';
import {isEqualDates} from 'shared/utils/date';
import {cn} from 'shared/utils/helpers';
import {useRootDispatch} from 'store';
import {companiesAsyncActions} from 'store/companies/actions';

import s from './CommonMetrics.module.scss';
import {MOCK_DATA} from './utils/constants';

export const CommonMetricsDashboard = () => {
  const {company, hasActiveCompanyStatus} = useCurrentCompany();
  const {data, setData, dateRange} = useDashboardContext();
  const prevDateRange = usePrevious(dateRange);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useRootDispatch();

  const actualStaffingChartRef = useRef<HTMLDivElement>(null);
  const actualStaffingChartWidth = useDimensions(actualStaffingChartRef).width;

  const oneCIntegrationStatus = useIntegrationStatuses(IntegrationTypeId.OneC);
  const {infoBadge: chartInfoBadge} = useDependencyOnIntegration(IntegrationTypeId.OneC);

  const isBlur = hasActiveCompanyStatus && !oneCIntegrationStatus?.isEnabled;

  const dashboardData = data as CommonMetricsDTO;
  const {charts: apiChartsData} = dashboardData || {};
  const chartsData = isBlur ? MOCK_DATA.charts : apiChartsData;

  const debouncedRefetchData = useDebounce(async () => {
    company && (await fetchData(company?.id));
  }, 500);

  // запрос данных при смене компании
  useEffect(() => {
    if (company) {
      fetchData(company.id);
      dispatch(companiesAsyncActions.fetchIntegrationsStatuses());
    }
  }, [company]);

  // запрос данных при изменении периода
  useEffect(() => {
    if (
      dateRange &&
      prevDateRange &&
      (!isEqualDates(dateRange?.from, prevDateRange?.from) || !isEqualDates(dateRange?.to, prevDateRange?.to))
    ) {
      debouncedRefetchData();
    }
  }, [dateRange]);

  // TODO: DRY
  const fetchData = async (companyId: string | number): Promise<CommonMetricsDTO | undefined> => {
    try {
      setIsLoading(true);
      const dashboardData = await DashboardsApi.getCommonMetricsData(companyId, dateRange);
      setData(dashboardData);
      return dashboardData;
    } catch (e) {
      handleErrorFromFetchingData(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <DashboardTopBar />
      <div className={s.commonMetrics}>
        <MetricCard
          title="Численность (ССЧ)"
          value={dashboardData?.metrics?.averageHeadcount ?? null}
          description={MINI_CARD_TIPS.averageHeadcount}
          icon={<UsersIcon />}
          formatter={formatValueAsPeopleNumber}
        />
        <FlowRateKpiMetric onAfterEdit={debouncedRefetchData} flowRate={dashboardData?.metrics?.flowRate} />
        <MetricCard
          title="Приёмы"
          value={dashboardData?.metrics?.hired ?? null}
          description={MINI_CARD_TIPS.hired}
          icon={<UserPlusIcon />}
          formatter={formatValueAsPeopleNumber}
        />
        <MetricCard
          title="Увольнения"
          value={dashboardData?.metrics?.fired ?? null}
          description={MINI_CARD_TIPS.layoffs}
          icon={<UserMinusIcon />}
          formatter={formatValueAsPeopleNumber}
        />

        <ChartCard
          className={s.commonMetrics__chartCard}
          title="Динамика численности (ССЧ)"
          description={CHART_CARD_TIPS.populationDynamics}
        >
          <HeadCountDynamicChart
            data={chartsData?.headcountDynamics}
            isLoading={isLoading}
            className={cn({[s.overlay]: isBlur})}
          />
          {!isLoading && !apiChartsData?.headcountDynamics?.length ? chartInfoBadge : null}
        </ChartCard>

        <ChartCard
          className={s.commonMetrics__chartCard}
          title="Динамика текучести"
          description={CHART_CARD_TIPS.fluidityDynamics}
        >
          <FlowRateDynamicChart
            data={chartsData?.flowRateDynamics}
            isLoading={isLoading}
            className={cn({[s.overlay]: isBlur})}
          />
          {!isLoading && !apiChartsData?.flowRateDynamics?.length ? chartInfoBadge : null}
        </ChartCard>

        <ChartCard
          title="Фактическая укомплектованность"
          className={s.commonMetrics__chartCard}
          description={CHART_CARD_TIPS.actualStaffing}
          ref={actualStaffingChartRef}
        >
          <ActualStaffingChart
            containerWidth={actualStaffingChartWidth}
            data={chartsData?.staffingLevelInfo ?? []}
            isLoading={isLoading}
            className={cn({[s.overlay]: isBlur})}
          />
          {!isLoading && !apiChartsData?.staffingLevelInfo?.values?.length ? chartInfoBadge : null}
        </ChartCard>

        <ChartCard
          title="Приёмы и увольнения"
          description={CHART_CARD_TIPS.hiredAndLayoffs}
          className={s.commonMetrics__chartCard}
        >
          <HiresAndFiresChart
            data={chartsData?.hiredFiredInfo}
            isLoading={isLoading}
            className={cn({[s.overlay]: isBlur})}
          />
          {!isLoading && !apiChartsData?.hiredFiredInfo?.length ? chartInfoBadge : null}
        </ChartCard>

        <ChartCard title="Отсутствия" className={s.commonMetrics__chartCard} description={CHART_CARD_TIPS.absences}>
          <AbsencesDynamicsChart
            data={chartsData?.absencesDynamics ?? []}
            isLoading={isLoading}
            className={cn({[s.overlay]: isBlur})}
          />
          {!isLoading && !apiChartsData?.absencesDynamics?.length ? chartInfoBadge : null}
        </ChartCard>
      </div>
    </>
  );
};
