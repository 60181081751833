const IS_ASIDE_COLLAPSED_KEY_LS = 'isCollapsed';

export const setIsAsideCollapsedToLS = (isAsideCollapsed: boolean) => {
  localStorage.setItem(IS_ASIDE_COLLAPSED_KEY_LS, JSON.stringify(isAsideCollapsed));
};

export const removeIsAsideCollapsedToLS = () => {
  localStorage.removeItem(IS_ASIDE_COLLAPSED_KEY_LS);
};

export const getIsAsideCollapsedFromLS = (): boolean => {
  const isAsideCollapsedString = localStorage?.getItem(IS_ASIDE_COLLAPSED_KEY_LS);
  return !!isAsideCollapsedString && JSON.parse(isAsideCollapsedString) === true;
};
