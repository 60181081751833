import {FC} from 'react';
import {useFormContext} from 'react-hook-form';
import {useLocation} from 'react-router';

import {useUserRole} from 'shared/hooks';
import {cn} from 'shared/utils/helpers';

import {PhoneInput} from '../PhoneInput';
import {FormControl, FormField, FormItem, FormLabel, FormMessage} from '../shadcn-ui/Form';
import {Input} from '../shadcn-ui/Input';

type PropsType = {
  withDisabledEmail?: boolean;
  readonly?: boolean;
};
export const NewCompanyUserFields: FC<PropsType> = ({withDisabledEmail, readonly = false}) => {
  const {control} = useFormContext();
  const {state} = useLocation();
  const userId = state?.userId ?? null;
  const {hasCompanyRole, isSuperAdmin} = useUserRole();

  return (
    <>
      <FormField
        control={control}
        name="firstName"
        render={({field}) => (
          <FormItem className={cn('h-[92px]', {['basis-72']: hasCompanyRole, ['w-[276px]']: isSuperAdmin})}>
            <FormLabel>Имя</FormLabel>
            <FormControl>
              <Input placeholder="Имя" readOnly={readonly} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="lastName"
        render={({field}) => (
          <FormItem className={cn('h-[92px]', {['basis-72']: hasCompanyRole, ['w-[276px]']: isSuperAdmin})}>
            <FormLabel>Фамилия</FormLabel>
            <FormControl>
              <Input placeholder="Фамилия" readOnly={readonly} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="email"
        render={({field}) => (
          <FormItem className={cn('h-[92px]', {['basis-72']: hasCompanyRole, ['w-[276px]']: isSuperAdmin})}>
            <FormLabel>Электронный адрес</FormLabel>
            <FormControl>
              <Input
                placeholder="Электронный адрес"
                readOnly={userId !== null || withDisabledEmail || readonly}
                disabled={userId !== null || withDisabledEmail}
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="phoneNumber"
        render={({field}) => (
          <FormItem className={cn('h-[92px]', {['basis-72']: hasCompanyRole, ['w-[276px]']: isSuperAdmin})}>
            <FormLabel>
              Контактный телефон <span>(не обязательно)</span>
            </FormLabel>
            <FormControl>
              <PhoneInput readOnly={readonly} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="jobTitle"
        render={({field}) => (
          <FormItem className={cn('h-[92px]', {['basis-72']: hasCompanyRole, ['w-[276px]']: isSuperAdmin})}>
            <FormLabel>Должность</FormLabel>
            <FormControl>
              <Input placeholder="Должность" readOnly={readonly} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
};
