import {Lock} from 'lucide-react';
import {FC, useContext} from 'react';

import {OnboardingStatus} from 'api/dto';
import {FixedButton} from 'shared/components/FixedButton';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Card, CardContent, CardTitle} from 'shared/components/shadcn-ui/Card';
import {useUserRole} from 'shared/hooks';
import {useFullAccessRequest} from 'shared/hooks/useFullAccessRequest';
import {cn} from 'shared/utils/helpers';
import {useRootSelector} from 'store';
import {authSelectors} from 'store/auth/selectors';

import s from './AccessRequestCard.module.scss';

import {BaseLayoutContext} from '../../BaseLayoutContext';

type Props = {isToggled: boolean};

export const AccessRequestCard: FC<Props> = ({isToggled}) => {
  const {isCollapsedAsideMenu} = useContext(BaseLayoutContext);
  const {isCompanyAdmin} = useUserRole();
  const user = useRootSelector(authSelectors.getUser);

  const {sendRequest, isFullAccessSent} = useFullAccessRequest();

  const getContent = () => {
    if (!isCompanyAdmin) return;
    return (
      <CardContent className="mt-6">
        <Button
          onClick={sendRequest}
          variant="secondary"
          className={cn(s.accessRequestCard__btn, {['w-full']: !isToggled, ['w-[0px] px-0']: isToggled})}
        >
          Полный доступ
        </Button>
        {isCollapsedAsideMenu && user?.onboardingState === OnboardingStatus.completed && !isFullAccessSent && (
          <FixedButton onClick={sendRequest} className="bg-secondary">
            <Lock className="w-[30px] h-[30px]" />
          </FixedButton>
        )}
      </CardContent>
    );
  };

  return (
    <Card
      className={cn(
        s.accessRequestCard,
        'mt-auto mb-2 bg-transparent basis-40 p-7 leading-7 rounded-[20px] max-h-[176px]',
        {
          ['w-[276px]  border border-solid border-primary-100']: !isToggled,
          ['w-[0px] px-0']: isToggled,
        },
      )}
    >
      <CardTitle className={cn('m-0 text-[18px] text-neutral-0 font-medium font-sans', s.accessRequestCard__title)}>
        Вы используете демодоступ
      </CardTitle>
      {getContent()}
    </Card>
  );
};
