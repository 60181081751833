import {Navigate, Outlet} from 'react-router';

import {routes} from 'shared/constants/routes';
import {useCurrentCompany} from 'shared/hooks';

import {NavTabs} from './components/NavTabs';
import s from './Sources.module.scss';

export const Sources = () => {
  const {hasDemoStatus, hasActiveCompanyStatus, company} = useCurrentCompany();
  const canShowSettings = hasDemoStatus || hasActiveCompanyStatus;

  if (company && !canShowSettings) {
    return <Navigate replace to={routes.settings} />;
  }

  return (
    <div className={s.sources}>
      <NavTabs />
      <Outlet />
    </div>
  );
};
