import * as React from 'react';

import {cn} from 'shared/utils/helpers';

import {ScrollArea, ScrollBar} from '../ScrollArea';

const Table = React.forwardRef<
HTMLTableElement,
React.HTMLAttributes<HTMLTableElement> & {
  scrollHeight?: number;
  viewportRef?: React.MutableRefObject<any>;
}
>(({className, scrollHeight, viewportRef, ...props}, ref) => {
  const getContent = () => (
    <table ref={ref} className={cn('w-full caption-bottom text-sm m:text-base box-border', className)} {...props} />
  );

  if (!scrollHeight) return getContent();
  return (
    <ScrollArea
      className={'rounded-lg '}
      disableScrollbar
      style={{height: `${scrollHeight}px`}}
      viewportRef={viewportRef}
    >
      {getContent()}
    </ScrollArea>
  );
});

const TableHeader = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({className, ...props}, ref) => (
    <thead ref={ref} className={cn('sticky z-30 top-0 bg-secondary [&_tr]:border-b', className)} {...props} />
  ),
);

const TableBody = React.forwardRef<
HTMLTableSectionElement,
React.HTMLAttributes<HTMLTableSectionElement> & {withScrollBar?: boolean}
>(({className, children, withScrollBar, ...props}, ref) => {
  return (
    <tbody ref={ref} className={cn('[&_tr:last-child]:border-0', className)} {...props}>
      {children}
      {withScrollBar && <ScrollBar className="pt-[60px]" />}
    </tbody>
  );
});
TableBody.displayName = 'TableBody';

const TableFooter = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({className, ...props}, ref) => (
    <tfoot ref={ref} className={cn('bg-primary font-medium text-primary-foreground', className)} {...props} />
  ),
);
TableFooter.displayName = 'TableFooter';

const TableRow = React.forwardRef<HTMLTableRowElement, React.HTMLAttributes<HTMLTableRowElement>>(
  ({className, ...props}, ref) => (
    <tr
      ref={ref}
      className={cn('border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted h-12', className)}
      {...props}
    />
  ),
);
TableRow.displayName = 'TableRow';

const TableHead = React.forwardRef<HTMLTableCellElement, React.ThHTMLAttributes<HTMLTableCellElement>>(
  ({className, ...props}, ref) => (
    <th
      ref={ref}
      className={cn(
        'h-12 bg-neutral-100 z-50 px-3 m:px-4 text-left text-sm m:text-base align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0  opacity-100',
        className,
      )}
      {...props}
    />
  ),
);
TableHead.displayName = 'TableHead';

const TableCell = React.forwardRef<HTMLTableCellElement, React.TdHTMLAttributes<HTMLTableCellElement>>(
  ({className, ...props}, ref) => (
    <td
      ref={ref}
      className={cn(
        'px-3 m:px-4 py-3 h-12 text-base text-neutral-900 font-normal leading-6 box-border [&:has([role=checkbox])]:pr-0 ',
        className,
      )}
      {...props}
    />
  ),
);
TableCell.displayName = 'TableCell';

const TableCaption = React.forwardRef<HTMLTableCaptionElement, React.HTMLAttributes<HTMLTableCaptionElement>>(
  ({className, ...props}, ref) => (
    <caption ref={ref} className={cn('text-sm m:text-base text-muted-foreground', className)} {...props} />
  ),
);
TableCaption.displayName = 'TableCaption';

export {Table, TableHeader, TableBody, TableFooter, TableHead, TableRow, TableCell, TableCaption};
