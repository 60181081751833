import {AccountRoles, CompanyRoles} from 'api/dto';
import {useRootSelector} from 'store';
import {authSelectors} from 'store/auth/selectors';
import {getUserCompanyRole} from 'store/selectors';

export function useUserRole() {
  const userCompanyRole = useRootSelector(getUserCompanyRole);
  const userSystemRole = useRootSelector(authSelectors.getUser)?.systemRoleName;
  const loadingCompanyRoles = userSystemRole === AccountRoles.user && !userCompanyRole;

  return {
    companyRole: userCompanyRole,
    systemRole: userSystemRole,
    isSuperAdmin: userSystemRole === AccountRoles.superAdmin,
    isCompanyAdmin: userCompanyRole === CompanyRoles.admin,
    isCompanyUser: userCompanyRole === CompanyRoles.user,
    hasCompanyRole: !!userCompanyRole,
    loadingCompanyRoles,
  };
}
