import {Row} from '@tanstack/react-table';
import {FC} from 'react';
import {useFormContext} from 'react-hook-form';

import {EditableCell} from 'shared/components/EditableCell';
import {PopoverCalendar} from 'shared/components/shadcn-ui/Calendar';
import {safeFormatDate, safeParseDate} from 'shared/utils/date';
import {cn} from 'shared/utils/helpers';

import {PopoverMonthPicker} from '../MonthPicker';

type Props = {
  column: any;
  row: Row<any>;
};

export const DateCell: FC<Props> = ({row: {index, original}, column: {columnDef, id}}) => {
  const {clearErrors, setValue} = useFormContext();
  const name = `rows.${index}.${id}`;

  const {
    formState: {errors},
  } = useFormContext();

  // TODO убрать кастование
  const isError = errors?.['rows' as keyof typeof errors]?.[index as keyof typeof errors.rows]?.[id as any];
  const isDisabled = original.id && original.id !== columnDef.meta?.activeEditingRow;
  const currentYear = columnDef.meta?.currentYear;
  const disabledMonths = columnDef.meta?.disabledMonths ?? [];

  const onSelect = (date: Date | undefined) => {
    clearErrors(name);

    /* добавляем выбранный месяц в список выбранных месяцев, и удаляем из этого списка месяц,
     который мог быть выбран в monthPicker при инициализации*/
    if (columnDef.meta?.setDisabledMonths && columnDef.meta?.disabledMonths && columnDef.meta?.type === 'month') {
      columnDef.meta?.setDisabledMonths(date);
    }

    setValue(`rows.${index}.date`, date);
  };

  return (
    <EditableCell columName={id} rowIndex={index}>
      {columnDef.meta?.type === 'month' ? (
        <PopoverMonthPicker
          disabled={isDisabled}
          currentMonth={safeParseDate(original.date) ?? undefined}
          onMonthChange={onSelect}
          popoverTriggerClassNames={cn({['border-error']: isError})}
          currentYear={currentYear}
          disabledMonths={disabledMonths}
        />
      ) : (
        <PopoverCalendar
          isDisabled={isDisabled}
          disabled={isDisabled}
          mode="single"
          onDayClick={onSelect}
          autoClosing
          selected={safeParseDate(original.date) ?? undefined}
          withPortal={false}
          toDate={new Date()}
          popoverTriggerClassNames={cn({['border-error']: isError})}
        />
      )}
    </EditableCell>
  );
};
