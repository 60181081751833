import {cva, type VariantProps} from 'class-variance-authority';
import * as React from 'react';

import {cn} from 'shared/utils/helpers';

const badgeVariants = cva(
  'border-transparent text-neutral-800 inline-flex items-center rounded-full border px-2.5 py-0.5 font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default: 'bg-primary-100',
        secondary: 'bg-neutral-200 text-secondary-foreground',
        destructive: 'bg-error-100 text-destructive-foreground',
        outline: 'text-foreground',
        on: 'text-xs flex items-center flex-center px-3 pt-1.5 h-5 w-14 text-white bg-primary-600 border-primary-700 tracking-wide',
        off: 'text-xs flex items-center flex-center px-3 pt-1.5 h-5 w-14 tracking-wide',
      },
      status: {
        unactive: 'bg-warning-300',
        demo: 'bg-secondary-400',
        active: 'bg-neutral-200',
        archive: 'bg-neutral-100',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {}

function Badge({className, variant, status, ...props}: BadgeProps) {
  return <div className={cn(badgeVariants({variant, status}), className)} {...props} />;
}

export {Badge, badgeVariants};
