import {IntegrationTypeId} from 'api/dto';
import {IntegrationInfoBlock} from 'shared/components/IntegrationInfoBlock';
import {useCurrentCompany, useIntegrationStatuses} from 'shared/hooks';

export const InfoBlock = () => {
  const {hasDemoStatus} = useCurrentCompany();
  const hasHuntflowSettings = useIntegrationStatuses(IntegrationTypeId.Huntflow);

  if (hasDemoStatus) return <IntegrationInfoBlock />;

  return (
    <IntegrationInfoBlock
      title={hasHuntflowSettings ? 'Выбор организации' : 'Шаг 2. Выбор организации'}
      previewChars={132}
      description={[
        <p className="font-bold" key="paragraph1">
          На этом этапе необходимо выбрать вашу организацию. Если к Хантфлоу подключены несколько организаций,
          необходимо выбрать только одну.
        </p>,
        <>
          Если к Хантфлоу подключены несколько организаций, необходимо выбрать только одну. В дальнейшем, чтобы изменить
          выбор организации, свяжитесь с менеджером:&nbsp;
          <a className="underline text-link" href="mailto:hello@staffscreen.ru">
            hello@staffscreen.ru
          </a>
          .
        </>,
      ]}
    />
  );
};
