import * as Sentry from '@sentry/browser';
import {Plus} from 'lucide-react';
import {useState} from 'react';
import {useNavigate} from 'react-router';

import {CompanyListDTO} from 'api/dto';
import {CompaniesApi} from 'api/services/companiesApi';
import {ClipLoader} from 'shared/components/ClipLoader';
import {DataTable} from 'shared/components/DataTable';
import {SearchInput} from 'shared/components/SearchInput';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Card, CardContent, CardHeader} from 'shared/components/shadcn-ui/Card';
import {CardActions, CardDescription, CardTitle} from 'shared/components/shadcn-ui/Card/Card';
import {routes} from 'shared/constants/routes';
import {useEffectOnce} from 'shared/hooks';
import {useDebounce} from 'shared/hooks/useDebounce';
import {cn, handleApiErrors} from 'shared/utils/helpers';
import {useRootDispatch} from 'store';
import {companiesActions} from 'store/companies';

import {getColumns} from './utils/constants';

export const Companies = () => {
  const dispatch = useRootDispatch();
  const [companyList, setCompanyList] = useState<CompanyListDTO[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredCompanyList, setFilteredCompanyList] = useState<CompanyListDTO[]>([]);

  const navigate = useNavigate();
  const columns = getColumns();

  useEffectOnce(() => {
    dispatch(companiesActions.setCurrentCompanyId(''));
  });

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const data = await CompaniesApi.getAllCompanies();
        setCompanyList(data);
        setFilteredCompanyList(data);
      } catch (error) {
        Sentry.captureException(error);
        handleApiErrors(error, 'Произошла ошибка при загрузке списка компаний.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  });

  const handleSearchInputChange = useDebounce((searchValue) => {
    if (typeof searchValue === 'string') {
      const filteredCompanies = companyList?.filter(({companyName}) =>
        companyName.toLowerCase().includes(searchValue.toLowerCase().trim()),
      );
      setFilteredCompanyList(filteredCompanies);
    }
  }, 500);

  const addCompany = () => {
    navigate(routes.addNewCompany);
  };

  if (isLoading) return <ClipLoader />;

  return (
    <Card className="p-8">
      <CardHeader className={cn({['justify-center flex-col gap-2']: !companyList.length})}>
        {!!companyList.length && <SearchInput placeholder="Поиск..." onChange={handleSearchInputChange} />}
        {!companyList.length && (
          <>
            <CardTitle>Список компаний пустует</CardTitle>
            <CardDescription className="text-base max-w-sm text-center px-4">
              Создайте первую компанию, чтобы запустить машину HR-аналитики.
            </CardDescription>
          </>
        )}
        <CardActions className={cn({['my-8']: !companyList.length})}>
          <Button variant="primary" onClick={addCompany}>
            <Plus color="white" className="mr-1" />
            Создать компанию
          </Button>
        </CardActions>
      </CardHeader>
      <CardContent>
        {
          <DataTable<CompanyListDTO>
            emptySearchResult="Компании не найдены"
            columns={columns}
            data={filteredCompanyList}
            className="table-fixed"
          />
        }
      </CardContent>
    </Card>
  );
};
