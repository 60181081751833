import {DialogClose} from '@radix-ui/react-dialog';
import * as Sentry from '@sentry/browser';
import {useState} from 'react';

import {AuthApi} from 'api';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Dialog, DialogContent} from 'shared/components/shadcn-ui/Dialog';
import {SuccessfullySendingRequest} from 'shared/components/SuccessfullySendingRequest';
import {handleApiErrors} from 'shared/utils/helpers';
import {useRootSelector} from 'store';
import {authSelectors} from 'store/auth/selectors';

export const ChangePassword = () => {
  const [isSending, setSending] = useState(false);
  const [isShownDialog, setShownDialog] = useState(false);
  const user = useRootSelector(authSelectors.getUser);

  const changePassword = async () => {
    if (user?.email) {
      try {
        setSending(true);
        await AuthApi.forgotPassword({email: user?.email});
        setShownDialog(true);
      } catch (error) {
        Sentry.captureException(error);
        handleApiErrors(error, 'Не удалось отправить письмо. Попробуйте еще раз.');
      } finally {
        setSending(false);
      }
    }
  };
  return (
    <>
      <Button variant="outline" onClick={changePassword} className="basis-72" disabled={isSending}>
        {isSending ? 'Идет запрос...' : 'Сменить пароль'}
      </Button>
      <Dialog open={isShownDialog} onOpenChange={setShownDialog}>
        <DialogContent className="max-w-[400px]">
          <SuccessfullySendingRequest
            withoutCloseBtn={true}
            title="Смена пароля"
            subtitle="Отправили вам на почту письмо со ссылкой для смены пароля."
          />
          <DialogClose asChild>
            <Button className="basis-[100%] mt-10" variant="primary">
              Хорошо
            </Button>
          </DialogClose>
        </DialogContent>
      </Dialog>
    </>
  );
};
