import * as z from 'zod';

export const FormSchema = z
  .object({
    isEnabled: z.boolean(),
    accessToken: z.string().nonempty('Необходимо указать Access Token'),
    refreshToken: z.string().nonempty('Необходимо указать Refresh Token'),
    organizationId: z.number({
      required_error: 'Необходимо выбрать организацию',
    }),
    hasReferralProgram: z.boolean(),
    organizationName: z.string(),
    referralProgramSources: z.array(
      z.object({
        value: z.string(),
        label: z.string(),
      }),
    ),
    funnelStages: z
      .array(
        z.object({
          funnelItem: z.number(),
          funnelItemName: z.string(),
          huntflowRecruitmentStatusesIds: z
            .array(z.object({value: z.string(), label: z.string()}))
            .min(1, {message: 'Выберите соответствующий этап подбора из Хантфлоу'})
            .max(1, {message: 'Должен быть выбран только один этап подбора из Хантфлоу'}),
        }),
      )
      .optional(),
  })
  .refine(
    (data) => {
      return (data.hasReferralProgram && data.referralProgramSources.length) || !data.hasReferralProgram;
    },
    {
      message: 'Выберите один или несколько источников реферальной программы.',
      path: ['referralProgramSources'],
    },
  );
