import {Copy} from 'lucide-react';

import manualFile from 'assets/docs/Инструкция по настройке интеграции 1С Иностудио.pdf';
import {IntegrationInfoBlock} from 'shared/components/IntegrationInfoBlock';
import {Button} from 'shared/components/shadcn-ui/Button';
import {env} from 'shared/constants/env';
import {useCurrentCompany} from 'shared/hooks';
import {copyToClipboard} from 'shared/utils/helpers';

export const InfoBlock = () => {
  const {hasDemoStatus} = useCurrentCompany();

  const getIntegrationsApiUrl = (): string => {
    return env.INTEGRATIONS_DOMAIN || 'https://integrations.staffscreen.ru/';
  };

  const copyApi = () => {
    copyToClipboard(getIntegrationsApiUrl(), 'Адрес API для интеграции c 1C скопирован в буфер-обмен.');
  };

  if (hasDemoStatus) {
    return (
      <IntegrationInfoBlock
        title="Как настроить источник 1C?"
        description={[
          'В полной версии вы сможете настроить интеграцию между 1С и Стафскрин для получения данных о кадровых изменениях.',
          'Мы подготовили подробную инструкцию, с помощью которой вы сможете выполнить настройку интеграции для своей компании.',
        ]}
        previewChars={112}
      />
    );
  }

  return (
    <IntegrationInfoBlock
      title="Как настроить источник 1C?"
      description={[
        <>
          Вы можете настроить интеграцию между 1С и Стафскрин самостоятельно. Для этого необходимо заполнить поля слева,
          следуя{' '}
          <a className="underline text-link" href={manualFile} download>
            инструкции
          </a>
          .
        </>,
        'Мы подготовили подробную инструкцию, с помощью которой вы сможете выполнить настройку интеграции для своей компании.',
        <>
          Или эту интеграцию вам поможет настроить наша команда. Напишите на{' '}
          <a className="underline text-link" href="mailto:hello@staffscreen.ru">
            hello@staffscreen.ru
          </a>
          , чтобы менеджер связался с вами.
        </>,
        <>
          Адрес API для интеграции{' '}
          <a className="underline text-link" target="_blank" rel="noopener noreferrer" href={getIntegrationsApiUrl()}>
            {getIntegrationsApiUrl()}
          </a>
          <Button
            type="button"
            size="icon"
            variant="icon"
            className="text-neutral-400 ml-1.5 h-7 w-7 p-1 relative top-1"
            onClick={copyApi}
          >
            <Copy />
          </Button>
          .
        </>,

        <>
          В поле <span className="text-primary">IP-адрес в формате V4</span> укажите разрешённый IP-адрес для обращения
          к <span className="text-primary">API Стафскрин</span>. Если такой адрес не один, добавьте IP, нажав на
          соответствующую кнопку.
        </>,

        <>
          Доступ к API и документации возможен только с IP адресов, указанных в поле{' '}
          <span className="text-error">IP адреса</span>.
        </>,
      ]}
      previewChars={129}
    />
  );
};
