import {zodResolver} from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/browser';
import {CheckCircle2} from 'lucide-react';
import {useEffect, useRef, useState} from 'react';
import {Controller, FieldErrors, useForm, useWatch} from 'react-hook-form';

import {
  HuntflowIntegrationSettingsDTO,
  HuntflowRecruitmentMapItemDTO,
  HuntflowRecruitmentStageDTO,
  IntegrationTypeId,
} from 'api/dto';
import {huntflowIntegrationApi} from 'api/services/huntflowIntegrationApi';
import {ClipLoader} from 'shared/components/ClipLoader';
import {NoIntegrationPlaceholder} from 'shared/components/NoIntegrationPlaceholder';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Card, CardContent, CardDescription, CardHeader, CardTitle} from 'shared/components/shadcn-ui/Card';
import {Form} from 'shared/components/shadcn-ui/Form';
import {Separator} from 'shared/components/shadcn-ui/Separator';
import {Switch} from 'shared/components/shadcn-ui/Switch';
import {Tabs, TabsContent, TabsList, TabsTrigger} from 'shared/components/shadcn-ui/Tabs';
import {toast} from 'shared/components/shadcn-ui/Toast/useToast';
import {useConfirmation, useCurrentCompany, useIntegrationStatuses, useFullAccessRequest} from 'shared/hooks';
import {HuntflowOrganizationModel} from 'shared/models/huntflowModel';
import {bem, setValidationFormErrors} from 'shared/utils';
import {extractApiErrorMessage, isAxiosError} from 'shared/utils/axios';
import {handleApiErrors} from 'shared/utils/helpers';
import {useRootDispatch} from 'store';
import {companiesAsyncActions} from 'store/companies/actions';

import {MappingStep} from './components/MappingStep';
import {OrganizationStep} from './components/OrganizationStep';
import {ReferralStep} from './components/ReferralStep';
import {TokensStep} from './components/TokensStep';
import s from './HuntflowIntegration.module.scss';
import {formatMap, prepareMapBeforeSave} from './utils/helpers';
import {FormValuesType} from './utils/types';
import {FormSchema} from './utils/validationSchema';

const sn = bem('huntflowIntegrationForm', s);

const getFormDefaultValues = (isEnabledIntegration: boolean | undefined) => ({
  isEnabled: isEnabledIntegration,
  accessToken: '',
  refreshToken: '',
  organizationId: 0,
  organizationName: '',
  funnelStages: [],
  hasReferralProgram: true,
  referralProgramSources: [],
});

export const HuntflowIntegration = () => {
  const dispatch = useRootDispatch();
  const {company, hasDemoStatus} = useCurrentCompany();
  const {sendRequest: sendRequestToFullAccess} = useFullAccessRequest();
  const integrationStatus = useIntegrationStatuses(IntegrationTypeId.Huntflow);
  const isHuntflowEnabled = integrationStatus?.isEnabled;
  const [isLoader, setIsLoader] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [integrationSettings, setIntegrationSettings] = useState<HuntflowIntegrationSettingsDTO | null>(null);
  const initialized = useRef(false);
  const [recruitmentStagesMap, setRecruitmentStagesMap] = useState<HuntflowRecruitmentMapItemDTO[]>([]);

  const form = useForm<FormValuesType>({
    resolver: zodResolver(FormSchema),
    defaultValues: getFormDefaultValues(isHuntflowEnabled),
    reValidateMode: 'onChange',
  });

  const [organizationsList, setOrganizationsList] = useState<HuntflowOrganizationModel[]>([]);
  const [activeTab, setActiveTab] = useState<string>('tokensStep');
  const [allRecruitmentStages, setAllRecruitmentStages] = useState<HuntflowRecruitmentStageDTO[]>([]);

  const {confirm} = useConfirmation();
  const formValues = useWatch<FormValuesType>({control: form.control});
  const {accessToken, refreshToken, organizationId, funnelStages} = formValues;
  const {errors: formErrors} = form.formState;

  const hasReferralProgram = useWatch({
    control: form.control,
    name: 'hasReferralProgram',
    defaultValue: integrationSettings?.hasReferralProgram ?? true,
  });

  const initRecruitmentStages = async () => {
    if (!company || !organizationId || !accessToken) return;
    try {
      const stagesList = hasDemoStatus
        ? []
        : await huntflowIntegrationApi.getRecruitmentStagesList(organizationId, accessToken);

      setAllRecruitmentStages(stagesList);
      form.reset({...form.getValues(), funnelStages: formatMap(recruitmentStagesMap, stagesList)});
    } catch (error) {
      Sentry.captureException(error);
      const extractedError = extractApiErrorMessage(error);
      if (isAxiosError(error)) {
        if (error.response?.status === 400) {
          setErrorAtMappingStep(
            typeof extractedError === 'string' ? extractedError : 'Проверьте авторизационные данные Хантфлоу.',
          );
        } else {
          handleApiErrors(error, 'Не удалось получить данные Хантфлоу.');
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initRecruitmentStages();
  }, [accessToken, organizationId]);

  const fetchRecruitmentStagesMap = async (companyId: number, huntflowOrgId: number, huntflowAccessToken: string) => {
    if (formValues.funnelStages?.length) return;
    try {
      const map = await huntflowIntegrationApi.getRecruitmentStagesMap(companyId, huntflowOrgId, huntflowAccessToken);
      setRecruitmentStagesMap(map);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    fetchRecruitmentStagesList();
  }, [recruitmentStagesMap]);

  const fetchRecruitmentStagesList = async () => {
    let stagesList: HuntflowRecruitmentStageDTO[] = [];

    if (organizationId && accessToken) {
      try {
        stagesList = hasDemoStatus
          ? []
          : await huntflowIntegrationApi.getRecruitmentStagesList(organizationId, accessToken);
      } catch (err) {
        Sentry.captureException(err);
      }
    }
    form.setValue('funnelStages', formatMap(recruitmentStagesMap, stagesList));
  };

  const fetchIntegrationSettings = async (companyId: number) => {
    try {
      const res = await huntflowIntegrationApi.getHuntflowIntegrationSettingsView(companyId);
      const {isEnabled, accessToken, refreshToken, organizationId, organizationName, hasReferralProgram} = res;
      form.reset({
        isEnabled,
        accessToken,
        refreshToken,
        organizationId,
        organizationName,
        hasReferralProgram,
        referralProgramSources: [],
        funnelStages, // устанавливается из formValues
      });
      setIntegrationSettings(res);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      initialized.current = true;
      setIsLoader(false);
    }
  };

  useEffect(() => {
    if (organizationId && accessToken && company?.id)
      fetchRecruitmentStagesMap(company.id, organizationId, accessToken);
  }, [organizationId]);

  useEffect(() => {
    if (company?.id && integrationSettings === null && !initialized.current) {
      fetchIntegrationSettings(company.id);
    }
  }, [integrationSettings, company]);

  const setMappingStep = () => {
    if (!hasDemoStatus) form.trigger('organizationId');
    if (!accessToken) {
      setActiveTab('tokensStep');
    } else if (!organizationId) {
      setActiveTab('organizationStep');
    } else {
      setActiveTab('mappingStep');
    }
  };

  const onSubmit = async (data: FormValuesType) => {
    /* TODO убрать валидацию введенного токена, путем запроса списка организаций 
    Хантфлоу через прокси-сервер, после того, как бэкенд добавит ассинхронное валидирование токена*/
    if (accessToken && data.isEnabled) {
      const updatedOrganizationList = await fetchOrganizationsList(accessToken);
      if (!updatedOrganizationList?.length) return;
    }

    saveIntegrationSettings(data);
  };

  const saveIntegrationSettings = async (data: FormValuesType) => {
    if (company?.id) {
      try {
        const {funnelStages, referralProgramSources, ...model} = data;

        if (funnelStages) {
          await huntflowIntegrationApi.setHuntflowIntegrationSettings(company.id, {
            ...model,
            referralProgramSourcesIds: model.hasReferralProgram
              ? referralProgramSources.map(({value}) => Number(value))
              : [],
            map: prepareMapBeforeSave(funnelStages),
          });
        }

        form.clearErrors();
        dispatch(companiesAsyncActions.fetchIntegrationsStatuses());
        toast({
          title: 'Настройки успешно обновлены.',
        });
      } catch (error) {
        Sentry.captureException(error);
        const extractedError = extractApiErrorMessage(error);
        if (typeof extractedError === 'string') {
          handleApiErrors(error);
        } else {
          setValidationFormErrors<typeof data>(extractedError, (fieldName, fieldErrors) => {
            form.setError(fieldName, {
              message: fieldErrors[0],
            });
            if (fieldName === 'accessToken' || 'refreshToken') setActiveTab('tokensStep');
          });
        }
      }
    }
  };

  const saveAndConfirmSelectedOrganization = async () => {
    if (hasDemoStatus) {
      setMappingStep();
      return;
    }
    const {organizationName} = formValues;
    const confirmConfig = {
      title: 'Подтверждение выбранной организации',
      description: (
        <>
          <p>
            Вы выбрали организацию «{organizationName}» для интеграции с Хантфлоу. Изменить этот выбор самостоятельно не
            получится.
          </p>
          <p>Вы подтверждаете выбранную организацию?</p>
        </>
      ),
      acceptButton: 'Подтвердить и продолжить',
      cancelButton: 'Отмена',
    };
    if (await confirm(confirmConfig)) {
      setMappingStep();
    }
  };

  const setErrorAtMappingStep = (error: string) => {
    setActiveTab('tokensStep');
    form.setError('accessToken', {message: error});
  };

  const onErrorSubmit = (errors: FieldErrors<FormValuesType>) => {
    if (errors.accessToken && activeTab !== 'tokensStep') {
      setActiveTab('tokensStep');
    } else if (errors.organizationId && activeTab !== 'organizationStep') {
      setActiveTab('organizationStep');
    } else if (errors.funnelStages && activeTab !== 'mappingStep') {
      setActiveTab('mappingStep');
    } else if (errors.referralProgramSources) {
      setActiveTab('referralStep');
    }
    form.trigger();
  };

  const saveTokensStep = async () => {
    if (hasDemoStatus) {
      setActiveTab('organizationStep');
      return;
    }
    form.trigger(['accessToken', 'refreshToken']);

    if (!accessToken || !refreshToken) {
      return;
    }

    if (!formValues.organizationId) {
      const organizationList = await fetchOrganizationsList(accessToken);
      if (organizationList?.length) setActiveTab('organizationStep');
    } else {
      setActiveTab('organizationStep');
    }
  };

  const fetchOrganizationsList = async (accessToken: string): Promise<HuntflowOrganizationModel[] | undefined> => {
    try {
      const organizationsList = await huntflowIntegrationApi.getAvailableOrganizations(accessToken);
      setOrganizationsList(organizationsList);
      return organizationsList;
    } catch (error) {
      Sentry.captureException(error);
      const extractedError = extractApiErrorMessage(error);
      if (typeof extractedError === 'string') {
        handleApiErrors(error);
        form.setError('accessToken', {
          message: 'Организаций не найдено. Проверьте AccessToken',
        });
      } else {
        setValidationFormErrors<FormValuesType>(extractedError, (fieldName, fieldErrors) => {
          form.setError(fieldName, {
            message: fieldErrors[0],
          });
        });
      }
    }
  };

  const getContent = () => {
    if (isLoader) return <ClipLoader className="max-h-[320px]" />;
    if (!integrationSettings && !formValues.isEnabled) {
      return (
        <NoIntegrationPlaceholder
          disabled={hasDemoStatus}
          title="Интеграция с Хантфлоу не подключена"
          onStart={() => {
            form.setValue('isEnabled', true);
          }}
        />
      );
    }

    const isDisabledTokenStep =
      !formValues.accessToken || !formValues.refreshToken || !!formErrors.accessToken || !!formErrors.refreshToken;

    return (
      <>
        <CardDescription className="font-bold text-lg  text-neutral-900 mb-[33px]">
          Настройка интеграции
        </CardDescription>
        <Tabs onValueChange={onChangeTab} value={activeTab}>
          <TabsList className="bg-neutral-200 w-full flex">
            <TabsTrigger value="tokensStep" className="data-[state=active]:bg-neutral-0 basis-2/6">
              {integrationSettings ? 'Токены' : 'Шаг 1. Токены'}
              {formValues.accessToken && formValues.refreshToken && (
                <CheckCircle2 className="ml-2.5 text-neutral-700" />
              )}
            </TabsTrigger>
            <TabsTrigger
              value="organizationStep"
              className="data-[state=active]:bg-neutral-0 basis-2/6"
              disabled={isDisabledTokenStep}
            >
              {integrationSettings ? 'Организация' : 'Шаг 2. Организация'}
              {!!formValues.organizationId && <CheckCircle2 className="ml-2.5 text-neutral-700" />}
            </TabsTrigger>
            <TabsTrigger
              value="mappingStep"
              className="data-[state=active]:bg-neutral-0 basis-2/6"
              disabled={isDisabledTokenStep || !formValues.organizationId || !!form.formState.errors.organizationId}
            >
              {integrationSettings ? 'Этапы воронки' : 'Шаг 3. Этапы воронки'}
              {(isHuntflowEnabled ||
                integrationSettings ||
                (!!formValues.funnelStages?.length &&
                  formValues.funnelStages.every((item) => item.huntflowRecruitmentStatusesIds?.length))) && (
                <CheckCircle2 className="ml-2.5 text-neutral-700" />
              )}
            </TabsTrigger>
            {hasReferralProgram && (
              <TabsTrigger
                value="referralStep"
                className="data-[state=active]:bg-neutral-0 basis-2/6"
                disabled={
                  isDisabledTokenStep ||
                  !formValues.organizationId ||
                  !!form.formState.errors.organizationId ||
                  !!form.formState.errors.funnelStages
                }
              >
                {integrationSettings ? 'Реферальная программа' : 'Шаг 4. Реферальная программа'}
                {(isHuntflowEnabled || integrationSettings) && <CheckCircle2 className="ml-2.5 text-neutral-700" />}
              </TabsTrigger>
            )}
          </TabsList>
          <Form {...form}>
            <form id="huntflowIntegrationSettings" onSubmit={form.handleSubmit(onSubmit, onErrorSubmit)}>
              <TabsContent value="tokensStep">
                <TokensStep isFirstSetup={!integrationSettings} onChangeTab={onChangeTab} />
              </TabsContent>
              <TabsContent value="organizationStep">
                <OrganizationStep
                  isFirstSetup={!integrationSettings}
                  organizationList={organizationsList}
                  prevOrgId={integrationSettings?.organizationId ?? 0}
                  onChangeTab={onChangeTab}
                />
              </TabsContent>
              <TabsContent value="mappingStep">
                <MappingStep
                  onChangeTab={onChangeTab}
                  isFirstSetup={!integrationSettings}
                  allRecruitmentStages={allRecruitmentStages}
                  isLoading={isLoading}
                  hasReferralProgram={hasReferralProgram}
                />
              </TabsContent>
              <TabsContent value="referralStep">
                <ReferralStep
                  onError={setErrorAtMappingStep}
                  initialMap={recruitmentStagesMap}
                  onChangeTab={onChangeTab}
                  isFirstSetup={!integrationSettings}
                />
              </TabsContent>
            </form>
          </Form>
        </Tabs>
      </>
    );
  };

  const onChangeTab = (newTabName: string) => {
    if (newTabName === 'organizationStep') {
      saveTokensStep();
    } else if (newTabName === 'mappingStep') {
      !integrationSettings && activeTab === 'organizationStep'
        ? saveAndConfirmSelectedOrganization()
        : setMappingStep();
    } else {
      setActiveTab(newTabName);
    }
  };

  const toggleSwitcher = (isEnabled: boolean) => {
    if (isEnabled) {
      // проверяем валидность accessToken в предыдущих настройках
      if (integrationSettings && !integrationSettings.isEnabled && formValues.accessToken) {
        fetchOrganizationsList(formValues.accessToken);
      }
    }
    form.setValue('isEnabled', isEnabled);
  };

  return (
    <Card className="p-14 mt-0 w-full min-h-[800px]">
      <CardHeader className="justify-start items-center h-10 mb-2">
        <div className={sn('switch')}>
          <Controller
            control={form.control}
            render={({field}) => (
              <Switch
                title={hasDemoStatus ? 'Вы используете демодоступ.' : ''}
                checked={hasDemoStatus || field.value}
                onCheckedChange={hasDemoStatus ? sendRequestToFullAccess : toggleSwitcher}
              />
            )}
            name="isEnabled"
          />
        </div>
        <CardTitle className="leading-[24px]">Хантфлоу</CardTitle>
        {integrationSettings && (
          <Button
            type="submit"
            form="huntflowIntegrationSettings"
            variant="primary"
            disabled={form.formState.isSubmitting || hasDemoStatus}
            className="ml-auto"
          >
            {form.formState.isSubmitting ? 'Загрузка...' : 'Сохранить'}
          </Button>
        )}
      </CardHeader>
      <Separator />
      <CardContent className="mt-8">{getContent()}</CardContent>
    </Card>
  );
};
