import * as z from 'zod';

export const FormSchema = z
  .object({
    isEnabled: z.boolean(),
    counterStatus: z.object({
      counterId: z.number({required_error: 'Выберите счётчик.'}),
    }),
    isCareerProfile: z.boolean(),
    goals: z.array(z.number()),
    rows: z.array(
      z.object({
        id: z.coerce.number().nullable(),
        name: z.string().min(3, {message: 'Минимум 3 символа'}).max(200, {message: 'Максимум 200 символов'}),
        url: z.string().url('Укажите корректный URL.'),
        isActive: z.boolean(),
        isDeleted: z.boolean().optional(),
        counterId: z.number().nullable(),
      }),
    ),
  })
  // если id=0 (дефолтное значение, если нет счетчиков), то будет ошибка
  .refine((data) => !!data.counterStatus.counterId, {
    message: 'Укажите счётчик.',
    path: ['counterStatus.counterId'],
  })
  .refine((val) => !!val.goals?.length && val.counterStatus.counterId, {
    message: 'Выберите одну или несколько целей.',
    path: ['goals'],
  })
  .refine(
    (data) => {
      return (
        data.counterStatus.counterId &&
        (data.isCareerProfile || data.rows?.filter((segment) => segment.isActive)?.length)
      );
    },
    {
      message:
        'Выберите один или несколько сегментов. Если у вас есть карьерный сайт, вернитесь на шаг 1 и укажите это.',
      path: ['rows'],
    },
  )
  .superRefine((data, ctx) => {
    const nameMap: Map<string, number[]> = new Map();
    data.rows.forEach((row, index) => {
      const value = nameMap.get(row.name);
      nameMap.set(row.name, value ? [...value, index] : [index]);
    });

    nameMap.forEach((indexes) => {
      if (indexes.length > 1) {
        indexes.forEach((index) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Название уже занято',
            path: ['rows', index, 'name'],
          });
        });
      }
    });
  });
