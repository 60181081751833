import {Navigate} from 'react-router';

import {routes} from 'shared/constants/routes';
import {useUserRole} from 'shared/hooks';

export const Home = () => {
  const {isSuperAdmin} = useUserRole();
  const redirectPath = isSuperAdmin ? routes.companies : routes.dashboard;
  return <Navigate to={redirectPath} replace />;
};
