import {load} from '@fingerprintjs/fingerprintjs';

export class FingerprintService {
  private fingerprint: string | null = null;

  public async create(): Promise<string> {
    const fgp = await load();
    const {visitorId} = await fgp.get();
    return visitorId;
  }
  public async get(): Promise<string> {
    this.fingerprint = localStorage?.getItem('fingerprint') ?? (await this.create());
    return this.fingerprint;
  }
}
