import {Building2, LayoutDashboardIcon, SmilePlus, Settings, SettingsIcon, Mails, ContactIcon} from 'lucide-react';
import {ReactNode} from 'react';

import {AccountRoles} from 'api/dto/UserDTO';
import {routes} from 'shared/constants/routes';

export type MenuItem = {
  title?: string;
  subtitle: string;
  route: string;
  icon?: ReactNode;
  subItems?: Omit<MenuItem, 'subItems'>[]; // TODO: на будущее, переделать с использованием Accordion
};

export const getMenuItems = (role?: AccountRoles): MenuItem[] => {
  switch (role) {
    case AccountRoles.superAdmin:
      return [
        {subtitle: 'Компании', icon: <Building2 />, route: routes.companies},
        {subtitle: 'Заявки', icon: <Mails />, route: routes.requests},
        {subtitle: 'Настройки профиля', icon: <Settings />, route: routes.settings},
      ];
    case AccountRoles.user:
      return [
        {title: 'Дашборды', subtitle: 'Общие метрики', icon: <LayoutDashboardIcon />, route: routes.generalDashboard},
        {subtitle: 'Подбор персонала', route: routes.recruitmentDashboard, icon: <SmilePlus />},
        {subtitle: 'HR-бренд', icon: <ContactIcon />, route: routes.hrBrandDashboard},
        {title: 'Другое', subtitle: 'Настройки', route: routes.settings, icon: <SettingsIcon />},
      ];
    default:
      return [];
  }
};
