import {endOfDay, isAfter, sub} from 'date-fns';

import {QuickPeriodButton} from './types';

export const QUICK_INTERVAL_BUTTONS: QuickPeriodButton[] = [
  {title: 'Неделя', period: 'weeks', duration: 1},
  {title: 'Месяц', period: 'months', duration: 1},
  {title: 'Квартал', period: 'months', duration: 3},
  {title: 'Год', period: 'months', duration: 12},
];
const today = endOfDay(new Date());
export const DEMO_START_DATE = new Date('2023-01-01');
export const DEMO_END_DATE = isAfter(new Date('2024-06-30'), today) ? today : new Date('2024-06-30');

export const getStartDate = (hasDemoStatus: boolean) => {
  const threeYearsAgo = sub(today, {years: 3});
  return hasDemoStatus ? DEMO_START_DATE : threeYearsAgo;
};

export const getEndDate = (hasDemoStatus: boolean) => {
  return hasDemoStatus ? DEMO_END_DATE : today;
};
