import ApiClient from 'api/client';
import {FullAccessRequestDTO} from 'api/dto/FullAccessRequestDTO';

class FullAccessRequestsService {
  private basePath = '/full-access-requests';
  async checkFullAccessSent(companyId: string | number) {
    return ApiClient.get<boolean>(`${this.basePath}/is-sent`, {params: {companyId: companyId}}).then((res) => res.data);
  }
  async sendRequest(companyId: string | number) {
    return ApiClient.post(this.basePath, null, {
      params: {companyId: companyId},
    }).then((res) => res.data);
  }
  async acceptRequest(id: number, statusExpiresAt: string) {
    return ApiClient.post(`${this.basePath}/${id}/accept`, {
      params: {statusExpiresAt},
    }).then((res) => res.data);
  }
  async getRequests() {
    return ApiClient.get<FullAccessRequestDTO[]>(this.basePath).then((res) => res.data);
  }
}

export const fullAccessRequestsApi = new FullAccessRequestsService();
