import {RefreshCcw} from 'lucide-react';
import {FC} from 'react';

import {YandexIntegrationType} from 'api/dto';
import {Button} from 'shared/components/shadcn-ui/Button';
import {CardDescription} from 'shared/components/shadcn-ui/Card';
import {useYandexIntegrationParametersContext} from 'shared/components/YandexOauth/YandexIntegrationProvider';
import {useCurrentCompany} from 'shared/hooks';

type Props = {
  description: string;
  disabled?: boolean;
};

export const RefreshDescription: FC<Props> = ({description, disabled}) => {
  const {refreshParameters} = useYandexIntegrationParametersContext();
  const {company} = useCurrentCompany();

  const refresh = async () => {
    if (!company?.id) return;
    await refreshParameters();
  };

  return (
    <>
      <CardDescription className="font-bold text-lg text-neutral-900 mb-8 flex ">
        <span>{description}</span>
        <Button
          type="button"
          size="icon"
          variant="icon"
          className="h-10 w-14 border ml-auto text-neutral-900"
          onClick={refresh}
          disabled={disabled}
        >
          <RefreshCcw className="w-6 h-6" />
        </Button>
      </CardDescription>
    </>
  );
};
