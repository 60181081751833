import {batch} from 'react-redux';
import {useParams} from 'react-router-dom';

import {CompanyDetailsDTO} from 'api/dto';
import {useEffectOnce} from 'shared/hooks';
import {useRootDispatch, useRootSelector} from 'store';
import {companiesActions} from 'store/companies';
import {companiesAsyncActions} from 'store/companies/actions';

export function useLoadCompany(): {company: CompanyDetailsDTO | null; loaded: boolean} {
  const dispatch = useRootDispatch();
  const {compId} = useParams();
  const {currentCompany, loaded} = useRootSelector((state) => state.companies);

  useEffectOnce(() => {
    if (compId) {
      batch(() => {
        dispatch(companiesActions.setCurrentCompanyId(compId));
        dispatch(companiesAsyncActions.fetchCurrentCompany());
        dispatch(companiesAsyncActions.fetchIntegrationsStatuses());
      });
    }
  });

  return {company: currentCompany, loaded};
}
