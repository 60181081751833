import {FormFieldsApiError} from 'shared/models/apiError';

type ValidationHandler<T> = (fieldName: keyof T, error: string[]) => void;

export const setValidationFormErrors = <T>(
  extractedError: FormFieldsApiError,
  handleValidation: ValidationHandler<T>,
) => {
  Object.entries(extractedError).forEach(([fieldName, fieldErrors]: [string, string[]]) => {
    <T>handleValidation(fieldName as keyof T, fieldErrors);
  });
};
