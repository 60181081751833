import * as Sentry from '@sentry/browser';
import {clsx, type ClassValue} from 'clsx';
import {twMerge} from 'tailwind-merge';

import {DictionaryTypeDTO} from 'api/dto/DictionaryDTO';
import {toast} from 'shared/components/shadcn-ui/Toast/useToast';

import {extractApiErrorMessage} from './axios';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getRawPhoneNumber(formattedPhoneNum: string): string {
  return formattedPhoneNum.replace(/\D/g, '');
}

export function pluralize(count: number, words: string[], includeValue = true): string {
  const cases = [2, 0, 1, 1, 1, 2];
  return (
    (includeValue ? count : '') + ' ' + words[count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)]]
  );
}

export function pluralizeDays(countDays: number, includeValue = true): ReturnType<typeof pluralize> {
  return pluralize(countDays, ['день', 'дня', 'дней'], includeValue);
}

export function pluralizeYears(countYears: number, includeValue = true): ReturnType<typeof pluralize> {
  return pluralize(countYears, ['год', 'года', 'лет'], includeValue);
}

export function roundToNearest(numToRound: number, numToRoundTo = 5): number {
  return Math.round(numToRound / numToRoundTo) * numToRoundTo;
}
export const getUserArchiveStatusId = (list: DictionaryTypeDTO[]): number | null => {
  return list.find(({description}) => description.toLowerCase().includes('архив'))?.value ?? null;
};
export const copyToClipboard = async (str: string, successMessage?: string) => {
  navigator.clipboard
    .writeText(str)
    .then(() => {
      toast({
        description: `${successMessage ?? 'Скопировано в буфер-обмен.'} `,
      });
    })
    .catch((err) => {
      Sentry.captureException(err);
      toast({
        variant: 'destructive',
        description: 'Не удалось копировать в буфер-обмен. Повторите попытку.',
      });
    });
};

export const handleApiErrors = (error: unknown, unexpectedError?: string) => {
  const extractedError = extractApiErrorMessage(error, unexpectedError);
  toast({
    variant: 'destructive',
    title: typeof extractedError === 'string' ? extractedError : unexpectedError ?? 'Произошла непредвиденная ошибка.',
  });
};
