import {SerializedError} from '@reduxjs/toolkit';

import {BasicApiError, FormFieldsApiError} from 'shared/models/apiError';
import {isAxiosError} from 'shared/utils/axios';

export type ExtendedSerializedError = SerializedError & {
  formErrors: FormFieldsApiError;
  status: number;
};

export const serializeError = (error: unknown): SerializedError | ExtendedSerializedError => {
  if (isAxiosError(error) && error.response && error.response.data) {
    const {data} = error.response;
    return {
      name: 'Axios Error',
      stack: error.stack,
      code: error.code,
      status: (data as BasicApiError).status,
      message: (data as BasicApiError)?.title || error.message,
      formErrors: data instanceof Object && 'errors' in data ? (data as BasicApiError)?.errors : null,
    } as ExtendedSerializedError;
  }

  return error as SerializedError;
};
