import {Edit2Icon, Trash2Icon} from 'lucide-react';

import {Button} from 'shared/components/shadcn-ui/Button';
import {useCurrentCompany} from 'shared/hooks';

export const RowActions = ({row: {id, ...row}, column: {columnDef, ...column}, table}: any) => {
  const {hasDemoStatus} = useCurrentCompany();

  return (
    <div className="flex items-center">
      {table.options.meta?.startEditingRow && typeof table.options.meta?.startEditingRow === 'function' && (
        <Button
          variant="ghost"
          type="button"
          disabled={hasDemoStatus || columnDef.meta?.activeEditingRow === row.original.id || !row.original.id}
          onClick={() => table.options.meta?.startEditingRow(row.original.id)}
        >
          <Edit2Icon />
        </Button>
      )}

      <Button variant="ghost" type="button" disabled={hasDemoStatus} onClick={() => table.options.meta?.removeRow(id)}>
        <Trash2Icon />
      </Button>
    </div>
  );
};
