import {useState, useEffect, useCallback, RefObject} from 'react';

import {useEffectOnce} from './useEffectOnce';

interface Dimensions {
  width: number | null;
  height: number | null;
}

export function useDimensions(elementRef?: RefObject<HTMLElement>): Dimensions {
  const [dimensions, setDimensions] = useState<Dimensions>({width: null, height: null});

  const updateDimensions = useCallback(() => {
    if (elementRef?.current) {
      const {width, height} = elementRef.current.getBoundingClientRect();
      setDimensions({width, height});
    } else {
      setDimensions({width: window.innerWidth, height: window.innerHeight});
    }
  }, [elementRef]);

  useEffectOnce(() => {
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  });

  useEffect(() => {
    updateDimensions();
  }, [updateDimensions]);

  return dimensions;
}
