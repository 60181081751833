import * as ToastPrimitives from '@radix-ui/react-toast';
import {cva, type VariantProps} from 'class-variance-authority';
import {X} from 'lucide-react';
import {ComponentPropsWithoutRef, ElementRef, ReactElement, forwardRef} from 'react';

import {cn} from 'shared/utils/helpers';

import s from './Toaster.module.scss';

const ToastProvider = ToastPrimitives.Provider;

const ToastViewport = forwardRef<
ElementRef<typeof ToastPrimitives.Viewport>,
ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>(({className, ...props}, ref) => (
  <ToastPrimitives.Viewport
    ref={ref}
    className={cn(
      'fixed top-0 z-[100] flex max-h-screen w-full flex-col-reverse p-4 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col max-w-[490px]',
      className,
    )}
    {...props}
  />
));
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;

const toastVariants = cva(
  'group pointer-events-auto relative flex w-full items-start bg-background space-x-4 overflow-hidden rounded-lg border p-4 pr-8 shadow-lg text-neutral-900 transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full',
  {
    variants: {
      variant: {
        default: 'border border-neutral-200',
        destructive: 'destructive group border-error-200  ',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

const Toast = forwardRef<
ElementRef<typeof ToastPrimitives.Root>,
ComponentPropsWithoutRef<typeof ToastPrimitives.Root> & VariantProps<typeof toastVariants>
>(({className, variant, ...props}, ref) => {
  return <ToastPrimitives.Root ref={ref} className={cn(toastVariants({variant}), className)} {...props} />;
});
Toast.displayName = ToastPrimitives.Root.displayName;

const ToastAction = forwardRef<
ElementRef<typeof ToastPrimitives.Action>,
ComponentPropsWithoutRef<typeof ToastPrimitives.Action>
>(({className, ...props}, ref) => (
  <ToastPrimitives.Action
    ref={ref}
    className={cn(
      'inline-flex h-8 shrink-0 items-center justify-center rounded-md border bg-transparent px-3 text-base font-medium ring-offset-background transition-colors hover:bg-success-200 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 group-[.destructive]:border-muted/40 group-[.destructive]:hover:border-destructive/30 group-[.destructive]:hover:bg-error-100 group-[.destructive]:hover:text-destructive-foreground group-[.destructive]:focus:ring-destructive',
      className,
    )}
    {...props}
  />
));
ToastAction.displayName = ToastPrimitives.Action.displayName;

const ToastClose = forwardRef<
ElementRef<typeof ToastPrimitives.Close>,
ComponentPropsWithoutRef<typeof ToastPrimitives.Close>
>(({className, ...props}, ref) => (
  <div className={s.container}>
    <div className={s.container__border}></div>
    <ToastPrimitives.Close
      ref={ref}
      className={cn(
        'w-[22px] h-[22px] absolute top-[1px] left-[1px] rounded-[50%] text-foreground/50 bg-neutral-100 hover:bg-neutral-200 flex justify-center items-center hover:text-foreground',
        className,
      )}
      toast-close=""
      {...props}
    >
      <X className="h-4 w-4 m-0" />
    </ToastPrimitives.Close>
  </div>
));
ToastClose.displayName = ToastPrimitives.Close.displayName;

const ToastTitle = forwardRef<
ElementRef<typeof ToastPrimitives.Title>,
ComponentPropsWithoutRef<typeof ToastPrimitives.Title>
>(({className, ...props}, ref) => (
  <ToastPrimitives.Title ref={ref} className={cn('text-[16px] font-normal', className)} {...props} />
));
ToastTitle.displayName = ToastPrimitives.Title.displayName;

const ToastDescription = forwardRef<
ElementRef<typeof ToastPrimitives.Description>,
ComponentPropsWithoutRef<typeof ToastPrimitives.Description>
>(({className, ...props}, ref) => (
  <ToastPrimitives.Description ref={ref} className={cn('text-neutral-700 text-base', className)} {...props} />
));
ToastDescription.displayName = ToastPrimitives.Description.displayName;

type ToastProps = ComponentPropsWithoutRef<typeof Toast>;

type ToastActionElement = ReactElement<typeof ToastAction>;

export {
  type ToastProps,
  type ToastActionElement,
  ToastProvider,
  ToastViewport,
  Toast,
  ToastTitle,
  ToastDescription,
  ToastClose,
  ToastAction,
};
