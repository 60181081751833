import {zodResolver} from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/browser';
import {XIcon} from 'lucide-react';
import {FC, HTMLAttributes, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router';
import * as z from 'zod';

import {AuthApi} from 'api';
import {ForgotPassword} from 'api/dto';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Form, FormControl, FormField, FormItem, FormMessage} from 'shared/components/shadcn-ui/Form';
import {Input} from 'shared/components/shadcn-ui/Input';
import {SuccessfullySendingRequest} from 'shared/components/SuccessfullySendingRequest';
import {routes} from 'shared/constants/routes';
import {bem, setValidationFormErrors} from 'shared/utils';
import {extractApiErrorMessage} from 'shared/utils/axios';
import {cn, handleApiErrors} from 'shared/utils/helpers';

import s from './ForgotPasswordForm.module.scss';
import {FormSchema} from './validationSchema';

const sn = bem('forgotPasswordForm', s);

type Props = {className?: string} & HTMLAttributes<HTMLFormElement>;

export const ForgotPasswordForm: FC<Props> = ({className = ''}) => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const navigate = useNavigate();
  const form = useForm<ForgotPassword>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    try {
      await AuthApi.forgotPassword(form.getValues());
      setIsSuccess(true);
    } catch (error) {
      const extractedError = extractApiErrorMessage(error);
      Sentry.captureException(error);
      if (typeof extractedError === 'string') {
        handleApiErrors(error);
      } else {
        setValidationFormErrors<typeof data>(extractedError, (fieldName, fieldErrors) => {
          form.setError(fieldName, {
            message: fieldErrors[0],
          });
        });
      }
    }
  };

  const close = () => {
    navigate(routes.login);
  };

  return (
    <div className={cn(className, s.forgotPasswordForm)}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className={sn('content')}>
          {isSuccess ? (
            <SuccessfullySendingRequest
              title="Проверьте вашу почту"
              subtitle={`Мы успешно отправили письмо со ссылкой на электронный адрес ${form.watch('email')}.`}
            />
          ) : (
            <>
              <Button className="m-0 absolute right-2.5 top-2.5 p-0" type="button" variant="ghost" onClick={close}>
                <XIcon />
              </Button>
              <h2 className={sn('title')}>Сброс пароля</h2>
              <p className={sn('subtitle')}>
                Укажите электронный адрес, на него будет отправлено письмо с ссылкой для сброса пароля.
              </p>
              <FormField
                control={form.control}
                name="email"
                render={({field}) => (
                  <FormItem className={sn('email')}>
                    <FormControl>
                      <Input placeholder="Электронный адрес" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type="submit" variant="primary" disabled={form.formState.isSubmitting}>
                {form.formState.isSubmitting ? 'Загрузка...' : 'Отправить'}
              </Button>
            </>
          )}
        </form>
      </Form>
    </div>
  );
};
