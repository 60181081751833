import cn from 'classnames';
import {Loader, AlertOctagon, FileWarning} from 'lucide-react';
import {FC} from 'react';

import {IntegrationStatusId} from 'api/dto';
import {Card, CardContent} from 'shared/components/shadcn-ui/Card';

type Props = {
  type?: IntegrationStatusId;
  message: string;
  isGeneralForSection?: boolean;
  className?: string;
};

export const ChartInfoBadge: FC<Props> = ({type, message, isGeneralForSection = true, className}) => {
  const getContent = () => {
    return (
      <CardContent
        className={cn(
          'm-0 w-[60%] relative top-[-30px] min-w-80 max-w-[900px] py-2 px-6 flex items-center gap-3 rounded-3xl shrink-0 text-neutral-600',
          {
            ['bg-error-100 border-error-200']: type === IntegrationStatusId.Error,
            ['bg-secondary-200 border-secondary-300']: type === IntegrationStatusId.Warning,
            ['bg-warning-100 border-warning-200 ']:
              type === IntegrationStatusId.Info || type === IntegrationStatusId.Undefined,
          },
          className,
        )}
      >
        {type === IntegrationStatusId.Info ||
          (type === IntegrationStatusId.Undefined && <FileWarning className="shrink-0" />)}
        {type === IntegrationStatusId.Warning && <Loader className="shrink-0" />}
        {type === IntegrationStatusId.Error && <AlertOctagon className="shrink-0" />}
        <span>{message}</span>
      </CardContent>
    );
  };
  if (isGeneralForSection)
    return (
      <Card
        className={cn(
          'absolute top-[55px] left-0 bottom-0 right-0 p-0 bg-transparent flex items-center justify-center ',
        )}
      >
        {getContent()}
      </Card>
    );
  return <> {getContent()}</>;
};
