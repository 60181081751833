import {IntegrationTypeId} from 'api/dto';
import {IntegrationInfoBlock} from 'shared/components/IntegrationInfoBlock';
import {useCurrentCompany, useIntegrationStatuses} from 'shared/hooks';

export const InfoBlock = () => {
  const {hasDemoStatus} = useCurrentCompany();
  const hasHuntflowSettings = useIntegrationStatuses(IntegrationTypeId.Huntflow);

  if (hasDemoStatus) return <IntegrationInfoBlock />;

  return (
    <IntegrationInfoBlock
      title={hasHuntflowSettings ? 'Реферальная программа' : 'Шаг 4. Реферальная программа'}
      previewChars={81}
      description={[
        'В Стафскрин реализована возможность рассчёта эффективности реферальной программы.',
        'Выберите в выпадающем списке источник реферальной программы, чтобы информация об её эффективности отобразилась на дашборде «HR-бренд», и сохраните настройки.',
      ]}
    />
  );
};
