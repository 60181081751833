import {useState} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';

import {YandexDirectSettingsViewDTO} from 'api/dto';
import {ClipLoader} from 'shared/components/ClipLoader';
import {FieldBox} from 'shared/components/FieldBox';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Separator} from 'shared/components/shadcn-ui/Separator';
import {useYandexIntegrationParametersContext} from 'shared/components/YandexOauth/YandexIntegrationProvider';
import {useEffectOnce, useShowMore} from 'shared/hooks';
import {bem} from 'shared/utils';
import {YandexIntegrationStep} from 'typings/yandexOauth';

import {InfoBlock} from './components/InfoBlock';
import s from './SuccessIntegrationInfo.module.scss';

import {FormValuesType} from '../../utils/types';

const sn = bem('successIntegrationInfo', s);

export const SuccessIntegrationInfo = () => {
  const {yandexParameters, refreshParameters, setIntegrationStep} = useYandexIntegrationParametersContext();
  const [isLoading, setIsLoading] = useState(true);
  const form = useFormContext<FormValuesType>();
  const [visibleACCount, showMoreAC] = useShowMore(8, 8);
  const yandexDirectParameters = yandexParameters as YandexDirectSettingsViewDTO;
  const activeAdsCampaigns = yandexDirectParameters?.availableAdsCampaigns?.filter((item) =>
    yandexDirectParameters?.selectedAdsCampaigns.includes(item.id),
  );

  const {selectedAllCampaigns} = yandexDirectParameters || {};

  const isSelectedAllCampaings = useWatch({
    control: form.control,
    name: 'addAllCampaigns',
    defaultValue: selectedAllCampaigns,
  });

  useEffectOnce(async () => {
    setIsLoading(true);
    await refreshParameters();
    setIsLoading(false);
  });

  const edit = () => {
    setIntegrationStep(YandexIntegrationStep.showSettings);
    form.reset({...form.getValues(), isEnabled: yandexParameters?.isEnabled});
  };

  if (isLoading || !yandexParameters) return <ClipLoader />;

  return (
    <div className={sn('content')}>
      <div className={sn('parametersBlock')}>
        <div className={sn('parameter')}>
          <h3 className={sn('title')}>{`Рекламные кампании (${form.getValues().adsCampaignsIds?.length} из ${
            form.getValues().availableAdsCampaigns?.length
          })`}</h3>
          {activeAdsCampaigns.map((item, index) => {
            if (index >= visibleACCount) return null;
            return <FieldBox className={sn('fieldBox')} key={`index__${item.id}`} value={item.name} />;
          })}
          {isSelectedAllCampaings && (
            <p>
              Выбраны все доступные рекламные кампании типа{' '}
              <span className={sn('accent_secondary')}>«Мастер кампания»</span>.
            </p>
          )}
          {!!activeAdsCampaigns.length && activeAdsCampaigns.length > visibleACCount && (
            <Button variant="link" onClick={showMoreAC}>
              Показать далее
            </Button>
          )}
        </div>
        <Separator />
        <Button type="button" variant="primary" onClick={edit} className="mt-12 self-end mr-0">
          Редактировать
        </Button>
      </div>
      <Separator orientation="vertical" className={sn('separator')} />
      <InfoBlock />
    </div>
  );
};
