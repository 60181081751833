import {zodResolver} from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/browser';
import {DeleteIcon} from 'lucide-react';
import {useEffect, useRef, useState} from 'react';
import {useFieldArray, useForm, useWatch} from 'react-hook-form';
import {z} from 'zod';

import {OneCSettingsDTO} from 'api/dto';
import {oneCIntegrationApi} from 'api/services/oneCIntegrationApi';
import {NoIntegrationPlaceholder} from 'shared/components/NoIntegrationPlaceholder';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Card, CardContent, CardHeader, CardTitle} from 'shared/components/shadcn-ui/Card';
import {CardActions} from 'shared/components/shadcn-ui/Card/Card';
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from 'shared/components/shadcn-ui/Form';
import {Input} from 'shared/components/shadcn-ui/Input';
import {Separator} from 'shared/components/shadcn-ui/Separator';
import {Switch} from 'shared/components/shadcn-ui/Switch';
import {toast} from 'shared/components/shadcn-ui/Toast/useToast';
import {useCurrentCompany, useFullAccessRequest} from 'shared/hooks';
import {bem} from 'shared/utils';
import {extractApiErrorMessage} from 'shared/utils/axios';
import {cn, copyToClipboard, handleApiErrors} from 'shared/utils/helpers';
import {useRootDispatch} from 'store';
import {companiesAsyncActions} from 'store/companies/actions';

import {InfoBlock} from './components/InfoBlock';
import s from './OneCIntegration.module.scss';
import {FormSchema} from './utils/validationSchema';

const sn = bem('oneCIntegration', s);

type FormValues = z.infer<typeof FormSchema>;

export const OneCIntegration = () => {
  const {company, hasDemoStatus} = useCurrentCompany();
  const [integrationSettings, setIntegrationSettings] = useState<OneCSettingsDTO | null>(null);
  const dispatch = useRootDispatch();
  const {sendRequest} = useFullAccessRequest();
  const initialized = useRef(false);

  const form = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      isEnabled: integrationSettings?.isEnabled ?? false,
      apiKey: integrationSettings?.apiKey || '',
      ipList: integrationSettings?.whiteIPAddresses?.map((item) => ({ip: item})) || [],
    },
  });

  const {isDirty, isSubmitting} = form.formState;

  const {
    fields,
    append: appendIpAddress,
    remove: removeApiAddress,
  } = useFieldArray({
    control: form.control,
    name: 'ipList',
  });

  const formValues = useWatch<FormValues>({control: form.control});

  useEffect(() => {
    if (company && !initialized.current) {
      fetchIntegrationSettings();
    }
  }, [company]);

  const fetchIntegrationSettings = async () => {
    try {
      if (company?.id) {
        const res = await oneCIntegrationApi.getOneCIntegrationSettings(company?.id);
        setIntegrationSettings(res);
        const {isEnabled, apiKey, whiteIPAddresses} = res;
        form.reset({
          isEnabled,
          apiKey,
          ipList: whiteIPAddresses?.map((item) => ({ip: item})) || [],
        });
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      initialized.current = true;
    }
  };

  const toggleIsEnabledOneCIntegration = async (checked: boolean) => {
    if (!integrationSettings) {
      if (checked) {
        await generateNewApiKey();
        appendIpAddress({ip: ''});
        form.trigger();
      } else {
        form.reset();
      }
    }
    form.setValue('isEnabled', checked, {shouldDirty: true});
  };

  const copyApiKey = () => {
    copyToClipboard(form.getValues().apiKey, 'API-Ключ копирован в буфер-обмен.');
  };

  const generateNewApiKey = async () => {
    if (company) {
      try {
        const newApiKey = await oneCIntegrationApi.generateOneCApiKey(company.id);
        form.setValue('apiKey', newApiKey, {shouldDirty: true});
      } catch (error) {
        Sentry.captureException(error);
        handleApiErrors(error, 'Произошла ошибка при выполнении запроса. Повторите попытку.');
      }
    }
  };

  const onSubmit = async (data: FormValues) => {
    if (company) {
      try {
        const {isEnabled, apiKey, ipList} = data;
        const res = await oneCIntegrationApi.updateOneCIntegrationSettings(company.id, {
          isEnabled,
          apiKey,
          whiteIPAddresses: ipList.map(({ip}) => ip),
        });
        setIntegrationSettings(res);
        toast({
          title: 'Данные успешно обновлены.',
        });
      } catch (error) {
        Sentry.captureException(error);
        const extractedError = extractApiErrorMessage(error);
        if (typeof extractedError === 'string') {
          handleApiErrors(error);
        } else {
          /* TODO предполагается, что бэк будет присылать ошибки в нужном формате,
            в текущий момент невозможно однозначно определить к какому полю какая ошибка соответствует, т.к.  в массиве
            extractedError.validation теряется соответствие
          */
          extractedError.validation.forEach((item, index) => {
            form.setError(`ipList.${index}.ip`, {
              message: item,
            });
          });
        }
      } finally {
        dispatch(companiesAsyncActions.fetchIntegrationsStatuses());
      }
    }
  };

  const getCardContent = () => {
    if (!integrationSettings && !formValues.isEnabled && !hasDemoStatus) {
      return (
        <NoIntegrationPlaceholder
          title="Интеграция с 1С не подключена"
          onStart={() => toggleIsEnabledOneCIntegration(true)}
        />
      );
    }

    return (
      <div className={sn('content')}>
        <div className={sn('formsBlock')}>
          <Form {...form}>
            <form id="oneCIntegrationSettings" onSubmit={form.handleSubmit(onSubmit)}>
              <FormField
                name="apiKey"
                control={form.control}
                render={({field}) => (
                  <FormItem>
                    <FormLabel>API-Ключ</FormLabel>
                    <FormControl>
                      <Input autoComplete="off" readOnly {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <CardActions className="mt-6">
                <Button
                  type="button"
                  variant="outline"
                  onClick={copyApiKey}
                  disabled={!formValues.isEnabled}
                  className="w-full"
                >
                  Скопировать API
                </Button>

                <Button
                  type="button"
                  variant="outline"
                  disabled={isSubmitting || hasDemoStatus || !formValues.isEnabled}
                  onClick={generateNewApiKey}
                  className="w-full"
                >
                  Перевыпустить API
                </Button>
              </CardActions>
              <section className="mt-12">
                {fields.map((field, index) => (
                  <div key={field.id} className="flex w-full mb-3">
                    <FormField
                      name={`ipList.${index}.ip`}
                      control={form.control}
                      render={({field}) => (
                        <FormItem className="grow-[2] mr-2 h-[92px]">
                          <FormLabel>IP-адрес в формате V4</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              readOnly={hasDemoStatus || !formValues.isEnabled}
                              placeholder="_ _ _. _ _ _ . _ _ _ . _ _ _"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <Button
                      type="button"
                      variant="outline"
                      size="icon"
                      onClick={() => removeApiAddress(index)}
                      className="self-center mt-4"
                      disabled={!formValues.isEnabled || (index === 0 && fields.length < 2) || hasDemoStatus}
                    >
                      <DeleteIcon />
                    </Button>
                  </div>
                ))}
              </section>

              <CardActions className="mt-6">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    appendIpAddress({ip: ''});
                  }}
                  disabled={hasDemoStatus || isSubmitting || !formValues.isEnabled}
                  className={cn(sn('btn'), sn('btn_add'), {['mr-auto']: !!integrationSettings})}
                >
                  Добавить IP
                </Button>
              </CardActions>
              {!integrationSettings && !hasDemoStatus && (
                <>
                  <Separator className="mt-12 mb-12" />
                  <Button
                    variant="primary"
                    type="submit"
                    form="oneCIntegrationSettings"
                    disabled={isSubmitting || hasDemoStatus || !isDirty}
                    className="block mr-0 ml-auto"
                  >
                    Сохранить
                  </Button>
                </>
              )}
            </form>
          </Form>
        </div>
        <Separator orientation="vertical" className={sn('separator')} />
        <InfoBlock />
      </div>
    );
  };

  return (
    <Card className="p-14 mt-0 w-full min-h-[320px]">
      <CardHeader className="justify-start items-center h-10 mb-2">
        <div className={sn('switch')}>
          <Switch
            title={hasDemoStatus ? 'Вы используете демодоступ.' : ''}
            checked={hasDemoStatus || formValues.isEnabled}
            onCheckedChange={hasDemoStatus ? sendRequest : toggleIsEnabledOneCIntegration}
          />
        </div>
        <CardTitle className="leading-[24px]">1C</CardTitle>
        {(integrationSettings || hasDemoStatus) && (
          <Button
            variant="primary"
            type="submit"
            form="oneCIntegrationSettings"
            disabled={isSubmitting || !isDirty}
            className="ml-auto"
          >
            Сохранить
          </Button>
        )}
      </CardHeader>
      <Separator />
      <CardContent className="mt-8"> {getCardContent()}</CardContent>
    </Card>
  );
};
