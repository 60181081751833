import {Navigate, Outlet, useMatch} from 'react-router';

import {routes} from 'shared/constants/routes';
import {useUserRole} from 'shared/hooks';

import {Menu} from './Menu';
import {Profile} from './Profile';

export const Settings = () => {
  const match = useMatch({path: routes.settings, end: true});
  const {isCompanyAdmin, loadingCompanyRoles} = useUserRole();

  if (loadingCompanyRoles) {
    return null;
  }

  if (match && !isCompanyAdmin) {
    return <Profile />;
  }

  if (!match && !isCompanyAdmin) {
    return <Navigate replace to={routes.settings} />;
  }

  if (match && isCompanyAdmin) {
    return <Navigate replace to={routes.profileSettings} />;
  }

  return (
    <>
      <Menu />
      <Outlet />
    </>
  );
};
