import * as Sentry from '@sentry/browser';
import {useEffect, useState} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';

import {YandexDirectSettingsViewDTO} from 'api/dto';
import {NoIntegrationPlaceholder} from 'shared/components/NoIntegrationPlaceholder';
import {useYandexIntegrationParametersContext} from 'shared/components/YandexOauth/YandexIntegrationProvider';
import {useConfirmation} from 'shared/hooks';
import {handleApiErrors} from 'shared/utils/helpers';

import {FormValuesType} from '../../../../utils/types';

export const ErrorsBlock = () => {
  const {refreshParameters, init, authenticated, reset} = useYandexIntegrationParametersContext();
  const [isInit, setIsInit] = useState(false);
  const {confirm} = useConfirmation();
  const form = useFormContext<FormValuesType>();
  const {yandexParameters} = useYandexIntegrationParametersContext();

  const yandexDirectParameters = yandexParameters as YandexDirectSettingsViewDTO;
  const selectedAllCampaigns = yandexDirectParameters?.selectedAllCampaigns;

  const isSelectedAllCampaings = useWatch({
    control: form.control,
    name: 'addAllCampaigns',
    defaultValue: selectedAllCampaigns,
  });

  const enableOauth = async () => {
    if (
      await confirm({
        title: 'Смена аккаунта',
        description:
          'Чтобы сменить аккаунт Яндекс.Директ, вам необходимо вернуться в Яндекс и авторизоваться под другим Яндекс ID.',
        acceptButton: 'Ок, готово',
        cancelButton: 'Отмена',
      })
    ) {
      try {
        reset();
        await init();
        setIsInit(true);
      } catch (error) {
        Sentry.captureException(error);
        handleApiErrors(error, 'Ошибка! Не войти в аккаунт Яндекса.');
      }
    }
  };

  useEffect(() => {
    if (isInit && authenticated !== null) {
      refreshParameters();
    }
  }, [authenticated]);

  useEffect(() => {
    form.clearErrors('adsCampaignsIds');
  }, [isSelectedAllCampaings]);

  return (
    <NoIntegrationPlaceholder
      title="Для выбранного аккаунта не найдено ни одной рекламной кампании"
      description="Создайте рекламную кампанию к текущему аккаунту Яндекс Директ или авторизуйтесь под другой учётной записью."
      onStart={enableOauth}
      btnTextContent="Подключить другой аккаунт"
    />
  );
};
