import {useCallback, useContext} from 'react';

import {ConfirmationContext} from 'shared/components/ConfirmationProvider';
import {ConfirmConfig} from 'shared/components/ConfirmationProvider/state';

let resolver: (v: boolean) => void;

export function useConfirmation() {
  const {dispatch} = useContext(ConfirmationContext);

  const closeConfirm = useCallback(() => {
    dispatch({type: 'CLOSE_CONFIRM'});
  }, [dispatch]);

  const accept = useCallback(() => {
    resolver(true);
    closeConfirm();
  }, [closeConfirm]);

  const cancel = useCallback(() => {
    resolver(false);
    closeConfirm();
  }, [closeConfirm]);

  const confirm = (payload: string | ConfirmConfig) => {
    dispatch({
      type: 'OPEN_CONFIRM',
      payload: typeof payload === 'string' ? {description: payload} : payload,
    });
    return new Promise((res) => {
      resolver = res;
    });
  };
  return {confirm, accept, cancel};
}
