import {FC} from 'react';

import {Button} from 'shared/components/shadcn-ui/Button';
import {CardDescription} from 'shared/components/shadcn-ui/Card';
import {bem} from 'shared/utils';

import s from './NoIntegrationPlaceholder.module.scss';

const sn = bem('noIntegrationPlaceholder', s);

type Props = {
  title: string;
  onStart?: () => void;
  disabled?: boolean;
  description?: string;
  btnTextContent?: string;
};

export const NoIntegrationPlaceholder: FC<Props> = ({
  title,
  onStart,
  disabled = false,
  description = 'Чтобы графики правильно работали, подключите интеграцию и настройте её.',
  btnTextContent = 'Подключить и настроить',
}) => {
  return (
    <>
      <CardDescription className="font-bold text-[30px] text-neutral-900 mb-2 text-center">{title}</CardDescription>
      <p className={sn('description')}>{description}</p>
      {onStart && (
        <Button variant="primary" className="block mx-auto" onClick={onStart} disabled={disabled}>
          {btnTextContent}
        </Button>
      )}
    </>
  );
};
