import {FC, HTMLAttributes} from 'react';

import {Breadcrumbs} from 'shared/layouts/BaseLayout/Header/Breadcrumbs';
import {bem} from 'shared/utils';
import {cn} from 'shared/utils/helpers';

import s from './Header.module.scss';
import {Notifications} from './Notifications';
import {NotificationsContextProvider} from './Notifications/NotificationContext';
import {UserBar} from './UserBar';

type Props = HTMLAttributes<HTMLElement>;

const sn = bem('header', s);

export const Header: FC<Props> = ({className}) => {
  return (
    <header className={cn(className, s.header)}>
      <Breadcrumbs />
      <div className={sn('userBar')}>
        <NotificationsContextProvider>
          <Notifications />
        </NotificationsContextProvider>
        <UserBar />
      </div>
    </header>
  );
};
