import * as SeparatorPrimitive from '@radix-ui/react-separator';
import {forwardRef, ElementRef, ComponentPropsWithoutRef} from 'react';

import {cn} from 'shared/utils/helpers';

const Separator = forwardRef<
  ElementRef<typeof SeparatorPrimitive.Root>,
  ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>
>(({className, orientation = 'horizontal', decorative = true, ...props}, ref) => (
  <SeparatorPrimitive.Root
    ref={ref}
    decorative={decorative}
    orientation={orientation}
    className={cn('shrink-0 bg-border', orientation === 'horizontal' ? 'h-[1px] w-full' : 'w-[1px]', className)}
    {...props}
  />
));
Separator.displayName = SeparatorPrimitive.Root.displayName;

export {Separator};
