import {IntegrationInfoBlock} from 'shared/components/IntegrationInfoBlock';
import {useCurrentCompany} from 'shared/hooks';

export const InfoBlock = () => {
  const {hasDemoStatus} = useCurrentCompany();

  if (!hasDemoStatus)
    return (
      <IntegrationInfoBlock
        title="Выбор рекламных кампаний"
        description={[
          'На этой странице вам необходимо выбрать одну или несколько рекламных кампаний, по которым вы хотите отслеживать статистику.',
          <>
            Если хотите создать новые рекламные кампании, рекомендуем воспользоваться{' '}
            <a
              className="underline text-link"
              href="https://yandex.ru/adv/edu/materials/kak-sozdat-reklamnuyu-kampaniyu"
              target="_blank"
              rel="noreferrer"
            >
              инструкцией
            </a>
            .
          </>,
        ]}
        previewChars={122}
      />
    );
  return null;
};
