import {Dispatch, ReactNode, Reducer} from 'react';

interface ConfirmState {
  show: boolean;
  config?: ConfirmConfig;
}

export interface ConfirmConfig {
  title?: string;
  description?: string | ReactNode;
  acceptButton?: string | ReactNode;
  cancelButton?: string;
}

const CONFIRM_ACTIONS_TYPES = {
  close: 'CLOSE_CONFIRM',
  open: 'OPEN_CONFIRM',
} as const;

type ConfirmActionsTypesValues = typeof CONFIRM_ACTIONS_TYPES[keyof typeof CONFIRM_ACTIONS_TYPES];
type ConfirmAction<T extends ConfirmActionsTypesValues, P = never> = {type: T; payload?: P};
type ConfirmActions = ConfirmAction<'OPEN_CONFIRM', ConfirmConfig> | ConfirmAction<'CLOSE_CONFIRM'>;
export const confirmReducer: Reducer<ConfirmState, ConfirmActions> = (state: ConfirmState, action: ConfirmActions) => {
  switch (action.type) {
    case CONFIRM_ACTIONS_TYPES.open:
      return {...state, show: true, config: action.payload};
    case CONFIRM_ACTIONS_TYPES.close:
      return {...state, show: false, config: action.payload};
    default:
      return state;
  }
};

export type ConfirmDispatch = Dispatch<ConfirmActions>;
