import {FC, PropsWithChildren} from 'react';
import {Link} from 'react-router-dom';

import s from './EnableIntegrationBtn.module.scss';

type Props = {
  to: string;
};

export const EnableIntegrationBtn: FC<PropsWithChildren<Props>> = ({to, children}) => {
  return (
    <Link className={s.enableIntegrationBtn} to={to}>
      {children}
    </Link>
  );
};
