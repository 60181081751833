import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import {Check} from 'lucide-react';
import {ElementRef, forwardRef} from 'react';

import {cn} from 'shared/utils/helpers';

type Props = {
  label?: string;
  name?: string;
  id?: string;
} & React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>;

const Checkbox = forwardRef<ElementRef<typeof CheckboxPrimitive.Root>, Props>(
  ({className, label, name, id = name, ...props}, ref) => (
    <div className={cn('flex justify-start items-center gap-3  w-full h-6', className)}>
      <CheckboxPrimitive.Root
        ref={ref}
        className={cn(
          'peer h-4 w-4 shrink-0 rounded-sm border border-neutral-300 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 cursor-pointer disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-white',
        )}
        id={id}
        {...props}
      >
        <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-current')}>
          <Check className="h-4 w-4" />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
      <label className="cursor-pointer select-none" htmlFor={id}>
        {label}
      </label>
    </div>
  ),
);
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export {Checkbox};
