import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {CompanyUserDTO, UserDTO} from 'api/dto';

import {getCompanyUserProfile, signIn, signOut} from './actions';

export type AuthStore = {
  user: UserDTO | null;
  companyProfile: CompanyUserDTO | null;
};

const initialState: AuthStore = {
  user: null,
  companyProfile: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthorizedUser(state, {payload}: PayloadAction<UserDTO>) {
      state.user = payload;
    },
    updateProfile(state, {payload}: PayloadAction<{user: UserDTO; companyProfile: CompanyUserDTO | null}>) {
      state.user = {...state.user, ...payload.user};
      state.companyProfile = payload.companyProfile ? {...state.companyProfile, ...payload.companyProfile} : null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state, {payload}) => {
      state.user = payload;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      state.user = null;
      state.companyProfile = null;
    });
    builder.addCase(getCompanyUserProfile.fulfilled, (state, {payload}) => {
      const {firstName, lastName, fio, email, userId} = payload;
      const {duplicateNotificationToEmail, ...profileWithOutDupNotifications} = payload;
      state.user = {...state.user, firstName, lastName, fullName: fio, duplicateNotificationToEmail, email, id: userId};
      state.companyProfile = profileWithOutDupNotifications;
    });
  },
});

export const {actions: authActions, caseReducers: authCaseReducers, reducer: authReducer} = authSlice;
