import {FC} from 'react';
import {ControllerRenderProps, useFieldArray, useFormContext} from 'react-hook-form';

import {HuntflowRecruitmentStageDTO} from 'api/dto';
import {ClipLoader} from 'shared/components/ClipLoader';
import {Button} from 'shared/components/shadcn-ui/Button';
import {CardActions} from 'shared/components/shadcn-ui/Card/Card';
import {ComboBox, ItemType} from 'shared/components/shadcn-ui/Command/ComboBox';
import {FormControl, FormField, FormItem, FormMessage} from 'shared/components/shadcn-ui/Form';
import {Label} from 'shared/components/shadcn-ui/Label';
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from 'shared/components/shadcn-ui/Select';
import {Separator} from 'shared/components/shadcn-ui/Separator';
import {Switch} from 'shared/components/shadcn-ui/Switch';
import {useCurrentCompany, useFormValues} from 'shared/hooks';
import {bem} from 'shared/utils';
import {cn} from 'shared/utils/helpers';

import {InfoBlock} from './components/InfoBlock';
import s from './MappingStep.module.scss';

import {FormValuesType} from '../../utils/types';

const sn = bem('mappingStep', s);

type Props = {
  onChangeTab: (newTabName: string) => void;
  isFirstSetup: boolean;
  allRecruitmentStages: HuntflowRecruitmentStageDTO[];
  isLoading: boolean;
  hasReferralProgram: boolean;
};

export const MappingStep: FC<Props> = ({
  onChangeTab,
  isFirstSetup,
  allRecruitmentStages,
  isLoading,
  hasReferralProgram,
}) => {
  const {hasDemoStatus} = useCurrentCompany();

  const form = useFormContext<FormValuesType>();
  const {control} = form;
  const {fields} = useFieldArray({control, name: 'funnelStages'});
  const formValues = useFormValues();

  const goBack = () => onChangeTab('organizationStep');

  const recruitmentStagesOptionsList = (
    field: ControllerRenderProps<FormValuesType, `funnelStages.${number}.huntflowRecruitmentStatusesIds`>,
  ): (ItemType & {isChecked: boolean})[] => {
    const fieldIndex = Number(field.name.split('.')[1]);
    // этапы которые уже выбрали
    const selectedStages = form.getValues().funnelStages.reduce((acc, stage, index) => {
      if (fieldIndex !== index) {
        acc = acc.concat(stage.huntflowRecruitmentStatusesIds.map(({value}) => Number(value)));
      }
      return acc;
    }, [] as number[]);

    return allRecruitmentStages
      .filter((item) => !selectedStages.includes(item.id))
      .map(({name, id}) => ({
        value: String(id),
        label: name,
        isChecked: field.value.some((item) => item.value === String(id)),
      }));
  };

  const onValueChange = (
    value: string,
    field: ControllerRenderProps<FormValuesType, `funnelStages.${number}.huntflowRecruitmentStatusesIds`>,
  ) => {
    form.setValue(
      field.name,
      recruitmentStagesOptionsList(field).filter((item) => item.value === value),
    );
    form.clearErrors(field.name);
  };

  const goForward = () => onChangeTab('referralStep');

  const getActionButtons = () => {
    if (!isFirstSetup) return null;
    return (
      <>
        <Separator className="my-3" />
        <CardActions className="flex items-end justify-end">
          <Button variant="outline" onClick={goBack} type="button">
            Назад
          </Button>
          {hasReferralProgram ? (
            <Button type="button" onClick={goForward} variant="primary" disabled={form.formState.isSubmitting}>
              {form.formState.isSubmitting ? 'Загрузка...' : 'Далее'}
            </Button>
          ) : (
            <Button type="submit" variant="primary" disabled={hasDemoStatus || form.formState.isSubmitting}>
              {form.formState.isSubmitting ? 'Загрузка...' : 'Сохранить'}
            </Button>
          )}
        </CardActions>
      </>
    );
  };

  if (isLoading) return <ClipLoader containerHeight={450} />;
  return (
    <div className={s.mappingStep}>
      {fields.length ? (
        <>
          <div className={sn('mapping')}>
            <h3 className={sn('subtitle')}>
              <span className={sn('subtitle_staff')}>Этапы Стафскрин</span>
              <span className={sn('subtitle_huntflow')}>Данные Хантфлоу</span>
            </h3>
            {fields.map((item, i) => {
              return (
                <>
                  <div className={sn('item')} key={`funnelStatus-${item.funnelItem}`}>
                    <span className={sn('funnelStatus')}>{item.funnelItemName}</span>
                    <FormField
                      control={control}
                      name={`funnelStages.${i}.huntflowRecruitmentStatusesIds`}
                      render={({field}) => {
                        if (field.value.find((item) => item.label === '')) return <ClipLoader />;
                        return (
                          <FormItem className="basis-[400px] grow-[2] min-h-10">
                            <FormControl>
                              <ComboBox
                                title="Данные Хантфлоу"
                                options={recruitmentStagesOptionsList(field)}
                                placeholder="Выберите источник"
                                defaultValue={field.value[0]?.value}
                                isDisabled={hasDemoStatus}
                                className={cn({
                                  'border-error': form.formState.errors.funnelStages?.[i],
                                })}
                                onValueChange={(value) => onValueChange(value, field)}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />
                  </div>
                </>
              );
            })}
            <FormField
              control={form.control}
              name="hasReferralProgram"
              render={({field}) => (
                <div className={sn('switch')}>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                    id={field.name}
                    disabled={hasDemoStatus}
                  />
                  <Label className="text-base" htmlFor={field.name}>
                    У меня есть реферальная программа
                  </Label>
                </div>
              )}
            />
            {getActionButtons()}
          </div>
        </>
      ) : (
        <ClipLoader className={sn('loader')} />
      )}
      <Separator orientation="vertical" className={sn('separator')} />
      <InfoBlock />
    </div>
  );
};
