import {forwardRef} from 'react';
import {PatternFormat} from 'react-number-format';
import {PatternFormatProps} from 'react-number-format/types/types';

import {Input} from '../shadcn-ui/Input';

export type Props = Omit<PatternFormatProps, 'format'>;

export const DateInputFormatter = forwardRef<HTMLInputElement, Props>((props, ref) => {
  return (
    <PatternFormat
      getInputRef={ref}
      placeholder="__.__.____"
      mask="_"
      format="##.##.####"
      autoComplete="off"
      customInput={Input}
      {...props}
    />
  );
});
