import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  TableOptions,
} from '@tanstack/react-table';
import {ReactNode} from 'react';

import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'shared/components/shadcn-ui/Table';
import {bem} from 'shared/utils';

import s from './DataTable.module.scss';
import {PaginationPanel} from './PaginationPanel';
import {dateBetweenFilterFn, dateWithNullSort} from './utils/helpers';

const sn = bem('dataTable', s);

type Props<TData, TValue> = {
  data: TData[];
  emptySearchResult?: ReactNode;
  scrollHeight?: number;
  className?: string;
  isDisabledBtns?: boolean;
  viewportRef?: React.MutableRefObject<any>;
} & Omit<TableOptions<TData>, 'getCoreRowModel'>;

export function DataTable<TData, TValue = any>({
  data,
  emptySearchResult,
  scrollHeight,
  className,
  isDisabledBtns = false,
  viewportRef,
  ...restOptions
}: Props<TData, TValue>) {
  const table = useReactTable({
    ...restOptions,
    data,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    filterFns: {
      dateFilter: dateBetweenFilterFn,
    },
    sortingFns: {
      dateWithNullSort: dateWithNullSort,
    },
  });
  const getContent = () => {
    if (!table.getRowModel().rows?.length) {
      if (!emptySearchResult) return null;
      return (
        <TableRow>
          <TableCell colSpan={table.getAllColumns().length} className="h-24 text-center">
            {emptySearchResult}
          </TableCell>
        </TableRow>
      );
    }
    return (
      <>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
            {row.getVisibleCells().map((cell) => (
              <TableCell
                key={cell.id}
                style={{width: cell.column.getSize()}}
                className={(cell.column.columnDef.meta as any)?.className ?? ''}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <div className={s.dataTable}>
      <Table className={className} scrollHeight={scrollHeight} viewportRef={viewportRef}>
        {(!!table.getRowModel().rows?.length || emptySearchResult) && (
          <>
            <TableCaption>
              <PaginationPanel table={table} isDisabledBtns={isDisabledBtns} />
            </TableCaption>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead
                        key={header.id}
                        className={sn('head')}
                        style={(header.column.columnDef.meta as any)?.style}
                      >
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
          </>
        )}
        <TableBody withScrollBar={!!scrollHeight}>{getContent()}</TableBody>
      </Table>
    </div>
  );
}
