import {FC} from 'react';
import {Area, AreaChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

import {CommonMetricsDTO} from 'api/dto';
import {useDashboardContext} from 'shared/components/Dashboards';

import {ClipLoader} from '../../../ClipLoader';
import {CustomTooltipContent} from '../../CustomTooltip';
import {
  DEFAULT_YAXIS_LABEL_WIDTH,
  DOMAIN_OF_EMPTY_CHART,
  getCartesianGridDefaultProps,
  getDefaultTooltipCursorProps,
  getXAxisInterval,
  getLabelFormatter,
  getLabelListDefaultStyles,
  getLegendDefaultProps,
  getXAxisDefaultProps,
  getYAxisDefaultProps,
  getYAxisWidthByValue,
  renderThinnedLabel,
  getXAxisPropsForEmptyChart,
  getCustomizedYAxisTick,
  calcDomainDataRange,
  getQuickIntervalTypeByDateRange,
} from '../../utils';

type Props = {
  data: CommonMetricsDTO['charts']['headcountDynamics'];
  isLoading?: boolean;
  className?: string;
};
export const HeadCountDynamicChart: FC<Props> = ({data, className = '', isLoading}: Props) => {
  const {dateRange} = useDashboardContext();
  const isEmptyChart = data?.length === 0;

  const getXAxisProps = () => {
    if (isEmptyChart) return getXAxisPropsForEmptyChart(dateRange);
    return getXAxisDefaultProps();
  };

  if (isLoading) {
    return <ClipLoader containerHeight={250} />;
  }

  return (
    <ResponsiveContainer width="100%" height={250} className={className}>
      <AreaChart
        width={500}
        height={400}
        data={data ?? []}
        margin={{
          top: 30,
          right: 25,
          left: 0,
          bottom: 10,
        }}
      >
        <CartesianGrid {...getCartesianGridDefaultProps()} />
        <Tooltip content={<CustomTooltipContent />} cursor={getDefaultTooltipCursorProps()} />
        <XAxis
          dataKey="date"
          {...getXAxisProps()}
          interval={getXAxisInterval(getQuickIntervalTypeByDateRange(dateRange))}
        />

        <YAxis
          {...getYAxisDefaultProps()}
          domain={isEmptyChart ? DOMAIN_OF_EMPTY_CHART : calcDomainDataRange}
          tick={getCustomizedYAxisTick}
          width={!isEmptyChart ? getYAxisWidthByValue(data ?? []) : DEFAULT_YAXIS_LABEL_WIDTH}
        />
        <Area
          type="monotone"
          fillOpacity="1"
          dataKey="value"
          stroke="#4D3FE3"
          fill="#DDD8FD"
          name="Численность"
          isAnimationActive={false}
          dot={{fill: '#4D3FE3', stroke: '#fff', strokeWidth: 1, r: 6}}
        >
          <LabelList
            dataKey="value"
            position="top"
            style={getLabelListDefaultStyles()}
            dy={-5}
            formatter={getLabelFormatter}
            content={renderThinnedLabel}
          />
        </Area>
        <Legend {...getLegendDefaultProps()} />
      </AreaChart>
    </ResponsiveContainer>
  );
};
