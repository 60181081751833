import {FC} from 'react';

import {Badge} from 'shared/components/shadcn-ui/Badge';

import {getBadgeVariant} from '../../utils/helpers';

type Props = {
  statusId: number;
  statusName: string;
};

export const StatusCell: FC<Props> = ({statusId, statusName}) => {
  return <Badge status={getBadgeVariant(statusId)}>{statusName}</Badge>;
};
