import {zodResolver} from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/browser';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router';

import {CompaniesApi} from 'api/services/companiesApi';
import {DaDataSuggestionApi} from 'api/services/daDataSuggestionApi';
import {NewCompanyUserFields} from 'shared/components/NewCompanyUserFields';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Card} from 'shared/components/shadcn-ui/Card';
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from 'shared/components/shadcn-ui/Form';
import {Input} from 'shared/components/shadcn-ui/Input';
import {toast} from 'shared/components/shadcn-ui/Toast/useToast';
import {routes} from 'shared/constants/routes';
import {bem, setValidationFormErrors} from 'shared/utils';
import {extractApiErrorMessage, isAxiosError} from 'shared/utils/axios';
import {cn} from 'shared/utils/helpers';
import {REQUIRED_FIELD_MSG} from 'shared/utils/validation';

import s from './NewCompany.module.scss';
import {Stepper} from './Stepper';
import {DEFAULT_VALUES} from './utils/constants';
import {getFormFieldName, prepareDataForServer} from './utils/helpers';
import {CompanyFormType} from './utils/types';
import {TAXPAYER_IDENTIFICATION_NUMBER_MSG, mergedSchema} from './validationSchema';

const sn = bem('newCompany', s);

export const NewCompany = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isLoadingDataByINN, setIsLoadingDataByINN] = useState<boolean>(false);
  const navigate = useNavigate();

  const form = useForm<CompanyFormType>({
    resolver: zodResolver(mergedSchema),
    defaultValues: DEFAULT_VALUES,
  });

  const cancelCompanyForm = () => {
    navigate(routes.companies);
  };

  const isValidFirstStepFields = () =>
    !form.getFieldState('inn').invalid &&
    !form.getFieldState('legalAddress').invalid &&
    !form.getFieldState('companyName').invalid;

  const nextStep = async () => {
    await form.trigger();
    if (!isValidFirstStepFields()) return;
    const inn = form.watch('inn');
    try {
      const isRegisteredInSystem = await CompaniesApi.checkCompanyByINN(inn);
      if (!isRegisteredInSystem) {
        if (currentStep === 0) setCurrentStep(currentStep + 1);
        form.clearErrors();
      } else {
        toast({
          variant: 'destructive',
          title: 'Не удалось сохранить компанию.',
          description: `Компания с ИНН ${inn} уже зарегистрирована в системе.`,
        });
      }
    } catch (error) {
      const extractedError = extractApiErrorMessage(error);
      if (typeof extractedError === 'string') {
        toast({
          variant: 'destructive',
          title: extractedError === 'string' ? extractedError : 'Не удалось проверить компанию по ИНН.',
        });
      } else {
        setValidationFormErrors<{inn: string}>(extractedError, (fieldName, fieldErrors) => {
          form.setError(fieldName, {
            message: fieldErrors[0],
          });
        });
      }
    }
  };

  const fetchCompanyData = async (inn: string) => {
    try {
      const response = await DaDataSuggestionApi.getDataByINN(inn);
      if (response) {
        const {
          data: {address, name},
        } = response;
        form.reset((prevValues) => Object.assign(prevValues, {legalAddress: address.value}, {companyName: name.full}));
      } else {
        form.setError('inn', {type: 'custom', message: TAXPAYER_IDENTIFICATION_NUMBER_MSG});
      }
    } catch (error) {
      Sentry.captureException(error);
      toast({
        variant: 'destructive',
        title: isAxiosError(error) ? error.message : 'Произошла ошибка при выполнении запроса. Повторите попытку.',
      });
    } finally {
      setIsLoadingDataByINN(false);
    }
  };

  const autoFill = () => {
    const inn = form.getValues('inn');
    if (inn && !isLoadingDataByINN) {
      setIsLoadingDataByINN(true);
      fetchCompanyData(inn);
    } else {
      form.setError('inn', {type: 'custom', message: REQUIRED_FIELD_MSG});
    }
  };

  const prevStep = () => setCurrentStep((prev) => (prev >= 1 ? prev - 1 : prev));

  const handleValidation = (fieldName: keyof CompanyFormType, fieldErrors: string[]) => {
    if (fieldName.includes('companyInfo') && currentStep !== 0) setCurrentStep(0);
    const errorFormField = getFormFieldName(fieldName);
    form.setError(errorFormField as keyof CompanyFormType, {
      message: fieldErrors[0],
    });
  };

  const onSubmit = async () => {
    try {
      await CompaniesApi.addCompany(prepareDataForServer(form.getValues()));
      toast({
        title: `Компания ${form.getValues().companyName} создана`,
      });
      navigate(routes.companies);
    } catch (error) {
      const extractedError = extractApiErrorMessage(error);
      if (typeof extractedError === 'string') {
        toast({
          variant: 'destructive',
          title: extractedError,
        });
      } else {
        setValidationFormErrors<CompanyFormType>(extractedError, handleValidation);
      }
    }
  };

  const isAdminStep = currentStep === 1;

  const onConfirm = () => (isAdminStep ? form.handleSubmit(onSubmit)() : nextStep());

  const onCancel = () => (isAdminStep ? prevStep() : cancelCompanyForm());
  return (
    <Card>
      <div className={sn('panel')}>
        <Stepper steps={['Данные компании', 'Данные администратора']} activeStepIndex={currentStep} />
        <div className={sn('actions')}>
          <Button type="button" variant="outline" onClick={onCancel}>
            Назад
          </Button>
          <Button
            type="button"
            onClick={onConfirm}
            form="companyDataForm"
            variant="primary"
            disabled={form.formState.isSubmitting}
          >
            {form.formState.isSubmitting ? 'Загрузка...' : isAdminStep ? 'Создать компанию' : 'Далее'}
          </Button>
        </div>
      </div>
      <Form {...form}>
        <form id="adminDataForm" className={sn('form')}>
          <div className={cn(sn('step'), {[sn('step_hidden')]: isAdminStep})}>
            <FormField
              control={form.control}
              name="inn"
              render={({field}) => (
                <FormItem className={sn('item')}>
                  <FormLabel>ИНН</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="ИНН"
                      type="number"
                      className={sn('taxNumber')}
                      {...field}
                      onChange={(e) => {
                        form.clearErrors('inn');
                        field.onChange(e);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              variant="outline"
              className={sn('autoComplite')}
              onClick={autoFill}
              type="button"
              disabled={isLoadingDataByINN}
            >
              {isLoadingDataByINN ? 'Загрузка...' : 'Автозаполнить поля'}
            </Button>

            <p className={sn('title')}>Поля заполнятся на основании ИНН</p>
            <div className={sn('separator')}></div>
            <FormField
              control={form.control}
              name="companyName"
              render={({field}) => (
                <FormItem className={sn('item')}>
                  <FormLabel>Наименование организации</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Наименование"
                      {...field}
                      onChange={(e) => {
                        form.clearErrors('companyName');
                        field.onChange(e);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="legalAddress"
              render={({field}) => (
                <FormItem className={sn('item')}>
                  <FormLabel>Юридический адрес</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Адрес"
                      {...field}
                      onChange={(e) => {
                        form.clearErrors('legalAddress');
                        field.onChange(e);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className={cn(sn('step'), {[sn('step_hidden')]: !isAdminStep})}>
            <NewCompanyUserFields />
          </div>
        </form>
      </Form>
    </Card>
  );
};
