import * as Sentry from '@sentry/browser';
import {useState} from 'react';

import {UsersApi} from 'api';
import {ClipLoader} from 'shared/components/ClipLoader';
import {CardHeader} from 'shared/components/shadcn-ui/Card';
import {Switch} from 'shared/components/shadcn-ui/Switch';
import {toast} from 'shared/components/shadcn-ui/Toast/useToast';
import {bem} from 'shared/utils';
import {cn, handleApiErrors} from 'shared/utils/helpers';
import {useRootDispatch, useRootSelector} from 'store';
import {authActions} from 'store/auth';
import {authSelectors} from 'store/auth/selectors';

import s from './NotificationsSetting.module.scss';

const sn = bem('notificationsSetting', s);

export const NotificationsSetting = () => {
  const [isToggling, setIsToggling] = useState(false);

  const user = useRootSelector(authSelectors.getUser);
  const companyUserProfile = useRootSelector(authSelectors.getCompanyProfile);

  const isEnabled = user?.duplicateNotificationToEmail;
  const dispatch = useRootDispatch();

  const toggleIsEnabledEmailNotifications = async (checked: boolean) => {
    if (isToggling) return;
    setIsToggling(true);
    if (user?.id) {
      try {
        await UsersApi.setDupNotifications(user?.id, checked);
        dispatch(
          authActions.updateProfile({
            user: {...user, duplicateNotificationToEmail: checked},
            companyProfile: companyUserProfile,
          }),
        );
        toast({
          title: 'Настройки уведомлений обновлены.',
        });
      } catch (error) {
        Sentry.captureException(error);
        handleApiErrors(error, 'Произошла ошибка при настройке уведомлений.');
      } finally {
        setIsToggling(false);
      }
    }
  };

  return (
    <div className={s.notificationsSetting}>
      <CardHeader className="font-bold basis-[300px]">Уведомления</CardHeader>
      <div className={sn('column')}>
        <Switch
          checked={isEnabled}
          onCheckedChange={toggleIsEnabledEmailNotifications}
          className={cn({['mr-6']: !isToggling}, 'mt-1')}
        />
        {isToggling && <ClipLoader className="max-h-6 w-6 mt-1" size={24} />}
        <p className={sn('description')}>Получать уведомления на электронную почту</p>
      </div>
    </div>
  );
};
