export enum CompanyRole {
  Admin = 'Администратор',
  User = 'Пользователь',
}

export enum CompanyStatus {
  Active = 'Активна',
  UnActive = 'Не активна',
  Demo = 'Демо',
}

export type CompanyDTO = {
  id: number;
  companyName: string;
  inn: string;
  legalAddress: string;
  createdAt: Date;
  statusExpiresAt: Date | null;
  statusName: string;
  statusId: number;
};

export type CompanyListDTO = {
  usersCount: number;
  companyAdminEmail: string;
} & CompanyDTO;

export type CompanyDetailsDTO = {
  adminInfo: CompanyUserDTO;
  users: CompanyUserDTO[];
} & CompanyDTO;

export type CreateCompanyDTO = {
  companyInfo: {inn: string; companyName: string; legalAddress: string};
  adminInfo: CreateCompanyAdminDTO;
};

export type SetStatusDTO = {statusId: number; expiresAt: Date | null};

export type CreateCompanyAdminDTO = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  jobTitle: string;
};

export type CompanyUserDTO = {
  id: number;
  userId: number;
  companyId: number;
  firstName: string;
  lastName: string;
  fio: string;
  email: string;
  emailConfirmed: boolean;
  phoneNumber: string;
  jobTitle: string;
  companyRoleId: number;
  companyRoleName: CompanyRole;
  statusId: number;
  statusName: string;
  duplicateNotificationToEmail?: boolean;
};

export enum IntegrationTypeId {
  None, // Неопределенный тип
  OneC, // 1С / Staffscreen импорт
  Huntflow, // Хантфлоу
  YandexMetrica, // Яндекс.Метрика
  YandexDirect, // Яндекс.Директ
}

export enum IntegrationStatusId {
  Undefined, // Неопределенный 0
  Info, // Информация 1
  Warning, // Есть предупреждение 2
  Error, // Произошла ошибка 3
  Ready, // Готово к использованию 4
}

export type IntegrationStatusInfo = {
  info: string;
  integrationType: string;
  integrationTypeId: IntegrationTypeId;
  isEnabled: boolean;
  status: string;
  statusId: IntegrationStatusId;
};

export type AllIntegrationsStatusesDTO = {
  integrationStatuses: IntegrationStatusInfo[];
};

export type CreateCompanyUserDTO = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  jobTitle: string;
};
export type UpdateCompanyUserDTO = Pick<
CompanyUserDTO,
'firstName' | 'lastName' | 'phoneNumber' | 'userId' | 'jobTitle'
>;
export type EditCompanyDTO = Pick<CompanyDetailsDTO, 'companyName'> & {legalAddress: string};

export type UpdateCompanyUserInfoDTO = Pick<CompanyUserDTO, 'firstName' | 'lastName' | 'phoneNumber' | 'statusId'>;

export type CompanyStaffDismissalReasonDTO = {
  id: number;
  reasonId: number;
  reasonName: string;
  dismissalDate: Date;
  qty: number;
  previousQty: number;
};

export type CreateStaffDismissalDTO = Pick<CompanyStaffDismissalReasonDTO, 'qty'> & {
  reasonId: number | null;
  dismissalDate: string;
};

export type DismissalReasonDTO = {
  id: number;
  reason: string;
};
