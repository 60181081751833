import {FC} from 'react';

import {useDashboardContext} from '../DashboardContextProvider';
import {getQuickIntervalTypeByDateRange} from '../utils';

type Props = {x: number; y: number; value: number; index: number};

export const AreaChartThinnedLabel: FC<Props> = ({x, y, value, index}) => {
  const {dateRange} = useDashboardContext();
  const period = getQuickIntervalTypeByDateRange(dateRange);
  if (value === 0 || (period?.duration === 1 && period?.period === 'months' && index % 2 !== 0)) return <></>;
  return (
    <g>
      <text
        x={x}
        y={y - 14}
        textAnchor="middle"
        dominantBaseline="middle"
        fill="#56547E"
        fillOpacity="0.9"
        fontSize="15px"
      >
        {value}
      </text>
    </g>
  );
};
