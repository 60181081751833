import {Link} from 'react-router-dom';

import {Avatar, AvatarFallback} from 'shared/components/shadcn-ui/Avatar';
import {Skeleton} from 'shared/components/shadcn-ui/Skeleton';
import {routes} from 'shared/constants/routes';
import {useUserRole} from 'shared/hooks';
import {useRootSelector} from 'store';
import {authSelectors} from 'store/auth/selectors';

import s from './UserBar.module.scss';

const UserBar = () => {
  const user = useRootSelector(authSelectors.getUser);
  const {companyRole, systemRole} = useUserRole();
  const userRole = companyRole || systemRole;
  const userName = user?.fullName || `${user?.firstName} ${user?.lastName}`;
  const initialsString = `${user?.firstName[0]}${user?.lastName[0]}`;

  return (
    <Link to={routes.settings} className={s.userBar}>
      <Avatar>
        <AvatarFallback>{initialsString}</AvatarFallback>
      </Avatar>
      <div className={s.userBar__info}>
        <span className={s.userBar__name}>{userName ?? <Skeleton className="w-[100px] h-[20px]" />}</span>
        <span className={s.userBar__role}>{userRole ?? <Skeleton className="w-[60px] h-[20px]" />}</span>
      </div>
    </Link>
  );
};

export {UserBar};
