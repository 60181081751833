import cn from 'classnames';
import {ClipboardListIcon, UserPlusIcon} from 'lucide-react';
import {useEffect, useRef, useState} from 'react';

import {DashboardsApi} from 'api';
import {IntegrationStatusId, IntegrationTypeId, RecruitmentMetricsDTO} from 'api/dto';
import {
  ChartCard,
  DashboardTopBar,
  GeneralStaffingLevelsChart,
  HeadcountAndVacanciesChart,
  MetricCard,
  RecruitmentFunnelChart,
  SourcesChart,
  useDashboardContext,
} from 'shared/components/Dashboards';
import {VerticalBarChart} from 'shared/components/Dashboards/Charts/VerticalBarChart';
import {HiringCostMetric} from 'shared/components/Dashboards/HiringCostMetric';
import {CHART_CARD_TIPS, formatValueAsPeopleNumber, MINI_CARD_TIPS} from 'shared/components/Dashboards/utils';
import {handleErrorFromFetchingData} from 'shared/components/Dashboards/utils/helpers';
import {useCurrentCompany, useDependencyOnIntegration} from 'shared/hooks';
import {useDebounce} from 'shared/hooks/useDebounce';
import {useDimensions} from 'shared/hooks/useDimensions';
import {usePrevious} from 'shared/hooks/usePrevious';
import {isEqualDates} from 'shared/utils/date';
import {useRootDispatch} from 'store';

import s from './Recruitment.module.scss';
import {MOCK_DATA} from './utils/constants';
import {VacanciesFillingTimeMetric} from './VacanciesFillingTimeMetric';

export const RecruitmentDashboard = () => {
  const {company} = useCurrentCompany();
  const {data, setData, dateRange} = useDashboardContext();
  const [isLoading, setIsLoading] = useState(true);
  const prevDateRange = usePrevious(dateRange);
  const dispatch = useRootDispatch();

  const {infoBadge: getWarningHuntflowMessage, isBlur: isBlurOfHuntflow} = useDependencyOnIntegration(
    IntegrationTypeId.Huntflow,
  );
  const {
    infoBadge: getWarningOneCMessage,
    isBlur: isBlurOfOneC,
    integrationStatus: oneCIntegrationStatus,
  } = useDependencyOnIntegration(IntegrationTypeId.OneC);

  const dashboardData = data as RecruitmentMetricsDTO;
  const apiDataCharts = dashboardData?.charts;
  const getChartsDataBasedOnBlur = (isBlur: boolean) => {
    if (isBlur) {
      return MOCK_DATA.charts;
    }
    return apiDataCharts;
  };

  const fillFunnelSpeedRef = useRef<HTMLDivElement>(null);
  const fillFunnelSpeedWidth = useDimensions(fillFunnelSpeedRef).width;

  const debouncedRefetchData = useDebounce(async () => {
    company && (await fetchData(company.id));
  }, 500);

  // запрос данных при изменении компании
  useEffect(() => {
    if (company) {
      fetchData(company.id);
    }
  }, [company]);

  // запрос данных при изменении периода
  useEffect(() => {
    if (
      dateRange &&
      prevDateRange &&
      (!isEqualDates(dateRange?.from, prevDateRange?.from) || !isEqualDates(dateRange?.to, prevDateRange?.to))
    ) {
      debouncedRefetchData();
    }
  }, [dateRange]);

  // TODO: DRY в компоненте CommonMetrics
  const fetchData = async (companyId: string | number): Promise<RecruitmentMetricsDTO | undefined> => {
    try {
      setIsLoading(true);
      const dashboardData = await DashboardsApi.getRecruitmentsData(companyId, dateRange);
      setData(dashboardData);
      return dashboardData;
    } catch (e) {
      handleErrorFromFetchingData(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <DashboardTopBar />
      <div className={s.recruitment}>
        <MetricCard
          className={s.recruitment__cardA}
          title="Списочная численность"
          value={dashboardData?.metrics?.headcount ?? null}
          description={MINI_CARD_TIPS.headcount}
          icon={<ClipboardListIcon />}
          formatter={formatValueAsPeopleNumber}
        />
        <MetricCard
          className={s.recruitment__cardB}
          title="Приёмы"
          value={dashboardData?.metrics?.hired ?? null}
          description={MINI_CARD_TIPS.hired}
          icon={<UserPlusIcon />}
          formatter={formatValueAsPeopleNumber}
        />
        <HiringCostMetric
          isBlur={isBlurOfOneC || oneCIntegrationStatus?.statusId !== IntegrationStatusId.Ready}
          className={s.recruitment__cardC}
          value={dashboardData?.metrics?.hiringCost ?? null}
          onAfterEdit={debouncedRefetchData}
        />
        <VacanciesFillingTimeMetric
          className={s.recruitment__cardD}
          fillingTime={dashboardData?.metrics?.fillingTime ?? null}
          hiringTime={dashboardData?.metrics?.hiringTime ?? null}
          onAfterEdit={debouncedRefetchData}
        />

        <ChartCard className={cn(s.recruitment__cardF)} title="Источники резюме" description={CHART_CARD_TIPS.sources}>
          <SourcesChart
            data={getChartsDataBasedOnBlur(isBlurOfHuntflow)?.sources ?? []}
            className={cn({[s.overlay]: isBlurOfHuntflow})}
            isLoading={isLoading}
          />
          {!isLoading && !apiDataCharts?.sources?.length ? getWarningHuntflowMessage : null}
        </ChartCard>
        <ChartCard
          title="Скорость воронки заполнения"
          className={cn(s.recruitment__cardH)}
          description={CHART_CARD_TIPS.fillFunnelSpeed}
          ref={fillFunnelSpeedRef}
        >
          <VerticalBarChart<{itemName: string; daysCountMedian: number | null}>
            containerWidth={fillFunnelSpeedWidth}
            height={380}
            dataKey="daysCountMedian"
            labelKey="itemName"
            color="#56BC6B"
            legendLabel="Количество дней"
            data={getChartsDataBasedOnBlur(isBlurOfHuntflow)?.fillingFunnelSpeed ?? []}
            className={cn(s.shift, {[s.overlay]: isBlurOfHuntflow})}
            isLoading={isLoading}
          />
          {!isLoading &&
          (!apiDataCharts?.fillingFunnelSpeed?.length ||
            apiDataCharts?.fillingFunnelSpeed?.every((item) => item.daysCountMedian === null))
            ? getWarningHuntflowMessage
            : null}
        </ChartCard>

        <ChartCard
          title="Численность и вакантные места"
          className={cn(s.recruitment__cardE)}
          description={CHART_CARD_TIPS.vacancies}
        >
          <HeadcountAndVacanciesChart
            data={getChartsDataBasedOnBlur(isBlurOfOneC)?.headcountAndVacancies ?? {staffHeadcount: null, values: []}}
            className={cn({[s.overlay]: isBlurOfOneC})}
            isLoading={isLoading}
          />
          {!isLoading && !apiDataCharts?.headcountAndVacancies?.values?.length ? getWarningOneCMessage : null}
        </ChartCard>
        <ChartCard
          title="Рекрутинговая воронка"
          className={cn(s.recruitment__cardG)}
          description={CHART_CARD_TIPS.recruitmentFunnelStaff}
        >
          <RecruitmentFunnelChart
            data={getChartsDataBasedOnBlur(isBlurOfHuntflow)?.recruitingFunnel ?? []}
            isLoading={isLoading}
            className={cn({[s.overlay]: isBlurOfHuntflow})}
          />
          {!isLoading && !apiDataCharts?.recruitingFunnel?.length ? getWarningHuntflowMessage : null}
        </ChartCard>
        <ChartCard
          title="Общая укомплектованность"
          className={cn(s.recruitment__cardI)}
          description={CHART_CARD_TIPS.actualStaffing}
        >
          <GeneralStaffingLevelsChart
            totalStaffingLevelPercentage={getChartsDataBasedOnBlur(isBlurOfOneC)?.totalStaffingLevelPercentage ?? null}
            isLoading={isLoading}
            className={cn({[s.overlay]: isBlurOfOneC})}
          />
          {!isLoading && apiDataCharts?.totalStaffingLevelPercentage === null ? getWarningOneCMessage : null}
        </ChartCard>
      </div>
    </>
  );
};
