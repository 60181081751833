import {PlusIcon} from 'lucide-react';
import {FC} from 'react';

import s from './EditMetricDescription.module.scss';

import {Button} from '../../shadcn-ui/Button';

type Props = {
  title: string;
  onButtonClick: () => void;
  buttonTxt?: string;
  disabledAddBtn?: boolean;
};

export const EditMetricDescription: FC<Props> = ({
  title,
  onButtonClick,
  buttonTxt = 'Добавить новую строку',
  disabledAddBtn = false,
}) => {
  return (
    <div className={s.editMetricDescription}>
      <h3 className={s.editMetricDescription__title}>{title}</h3>
      <Button variant="primary" onClick={onButtonClick} disabled={disabledAddBtn}>
        <PlusIcon /> {buttonTxt}
      </Button>
    </div>
  );
};
