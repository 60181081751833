import {useFormContext, useWatch} from 'react-hook-form';

import {YandexIntegrationType} from 'api/dto';
import {Button} from 'shared/components/shadcn-ui/Button';
import {CardDescription} from 'shared/components/shadcn-ui/Card';
import {CardActions} from 'shared/components/shadcn-ui/Card/Card';
import {Checkbox} from 'shared/components/shadcn-ui/Checkbox';
import {FormMessage} from 'shared/components/shadcn-ui/Form';
import {Separator} from 'shared/components/shadcn-ui/Separator';
import {RefreshDescription} from 'shared/components/YandexOauth/RefreshDescription';
import {useYandexIntegrationParametersContext} from 'shared/components/YandexOauth/YandexIntegrationProvider';
import {useCurrentCompany, useEffectOnce, useShowMore} from 'shared/hooks';

import {ErrorsBlock} from './components/ErrorsBlock';
import {InfoBlock} from './components/InfoBlock';
import s from './SettingsContent.module.scss';

import {FormValuesType} from '../../utils/types';

export const SettingsContent = () => {
  const form = useFormContext<FormValuesType>();
  const adsCampaigns = useWatch({control: form.control, name: 'availableAdsCampaigns'}) ?? [];
  const checkedCampaignsIds = useWatch({control: form.control, name: 'adsCampaignsIds'}) ?? [];
  const {hasDemoStatus} = useCurrentCompany();
  const [visibleCount, showMore] = useShowMore(8, 8);
  const {yandexParameters} = useYandexIntegrationParametersContext();

  // TODO any
  const renderAdsCampaigns = (campaign: any, index: number) => {
    if (index >= visibleCount) return null;
    return (
      <Checkbox
        key={`key_${campaign.id}`}
        className="mb-1"
        name={`availableAdsCampaigns.${index}`}
        label={adsCampaigns[index]['name']}
        disabled={campaign.isRemovedFromYandex || hasDemoStatus}
        defaultChecked={campaign.isChecked}
        onCheckedChange={(checked: boolean) => {
          form.setValue(`availableAdsCampaigns.${index}.isChecked`, checked);
          const newCheckedCampaignsIds = new Set(checkedCampaignsIds);
          checked ? newCheckedCampaignsIds.add(campaign.id) : newCheckedCampaignsIds.delete(campaign.id);
          form.setValue('adsCampaignsIds', [...newCheckedCampaignsIds], {shouldDirty: true});
          form.clearErrors('adsCampaignsIds');
        }}
      />
    );
  };

  const getContent = () => {
    if (!adsCampaigns.length) {
      return (
        <>
          <ErrorsBlock />
          <FormMessage className="mt-6">
            <span>{form.formState?.errors?.availableAdsCampaigns?.message}</span>
          </FormMessage>
        </>
      );
    }
    return (
      <>
        <RefreshDescription
          description={`Выберите рекламные кампании (${checkedCampaignsIds.length} из ${adsCampaigns.length})`}
          disabled={hasDemoStatus}
        />
        {adsCampaigns.map(renderAdsCampaigns)}
        {adsCampaigns.length > visibleCount && (
          <Button variant="link" onClick={showMore}>
            Показать далее
          </Button>
        )}
        <FormMessage className="mt-6">
          <span>{form.formState?.errors?.adsCampaignsIds?.message}</span>
        </FormMessage>
        <Separator className="mt-12" />

        {!yandexParameters?.isEnabled && (
          <CardActions className="w-60 ml-auto">
            <Button
              type="submit"
              form="settingsDirectForm"
              className="ml-auto mr-0 mt-12"
              variant="primary"
              disabled={hasDemoStatus}
            >
              Сохранить
            </Button>
          </CardActions>
        )}
      </>
    );
  };

  return (
    <>
      <CardDescription className="font-bold text-lg  text-neutral-900 mb-[33px] ">Настройка интеграции</CardDescription>

      <div className={s.settings}>
        <div className={s.settings__content}>{getContent()}</div>

        <Separator orientation="vertical" className={s.settings__separator} />
        <InfoBlock />
      </div>
    </>
  );
};
