import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/react';
import {Provider} from 'react-redux';
import {RouterProvider} from 'react-router-dom';
import {YMInitializer} from 'react-yandex-metrika';

import ApiClient from './api';
import {registerAuthInterceptors} from './api/interceptors/auth';
import {Router} from './Router';
import {JwtAuthServiceSingleton} from './services/JwtAuthService';
import {Toaster} from './shared/components/shadcn-ui/Toast/Toaster';
import {env} from './shared/constants/env';
import store from './store';

import 'assets/styles/general.scss';

Sentry.init({
  enabled: !import.meta.env.DEV && env.SENTRY_DSN,
  dsn: env.SENTRY_DSN,
  replaysSessionSampleRate: 1.0, // TODO: убрать в будущем. сейчас нужно записывать все сессии для поиска ошибки с разлогином
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
});

// TODO заменить на вызов метода сервиса аналитики
if (!import.meta.env.DEV && env.AMPLITUDE_MAIN) {
  amplitude.init(env.AMPLITUDE_MAIN);
}

registerAuthInterceptors(ApiClient, JwtAuthServiceSingleton);

const App = () => {
  return (
    <Provider store={store}>
      <Sentry.ErrorBoundary showDialog={true} fallback={<p>ERRROR!!</p>}>
        {env.YM_ACCOUNT ? (
          <YMInitializer accounts={[env.YM_ACCOUNT]} options={{webvisor: true, clickmap: true}} version="2" />
        ) : null}
        <RouterProvider router={Router} />
        <Toaster />
      </Sentry.ErrorBoundary>
    </Provider>
  );
};
export default App;
