import * as Sentry from '@sentry/browser';
import {ColumnDef, createColumnHelper} from '@tanstack/react-table';
import {useEffect, useState} from 'react';
import {generatePath, useNavigate} from 'react-router-dom';

import {CompaniesApi} from 'api';
import {CompanyUserDTO} from 'api/dto';
import EmptyImg from 'assets/icons/account_circle.svg';
import {ClipLoader} from 'shared/components/ClipLoader';
import {DataTable} from 'shared/components/DataTable';
import {SearchInput} from 'shared/components/SearchInput';
import {Button} from 'shared/components/shadcn-ui/Button';
import {CardDescription, CardHeader, CardTitle} from 'shared/components/shadcn-ui/Card';
import {Card, CardActions, CardContent} from 'shared/components/shadcn-ui/Card/Card';
import {routes} from 'shared/constants/routes';
import {useCurrentCompany, useEffectOnce, useUserRole} from 'shared/hooks';
import {useDebounce} from 'shared/hooks/useDebounce';
import {cn, getRawPhoneNumber, handleApiErrors} from 'shared/utils/helpers';

import {EmptySearchResult} from './components/EmptySearchResult';
import {PhoneCell} from './components/PhoneCell';
import {StatusCell} from './components/StatusCell';
import {UserActionButtons} from './components/UserActionButtons';

const columnHelper = createColumnHelper<CompanyUserDTO>();
export const UsersCard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const {company} = useCurrentCompany();
  const [userList, setUserList] = useState<CompanyUserDTO[] | null>(null);
  const [filteredUserList, setFilteredUserList] = useState<CompanyUserDTO[]>([]);
  const navigate = useNavigate();
  const {isSuperAdmin} = useUserRole();

  const fetchData = async () => {
    if (company?.id) {
      try {
        const data = await CompaniesApi.getCompanyUsers(company?.id);
        setUserList(data);
        setFilteredUserList(data);
      } catch (error) {
        Sentry.captureException(error);
        handleApiErrors(error, 'Произошла ошибка при загрузке списка пользователей.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffectOnce(() => {
    fetchData();
  });

  useEffect(() => {
    if (company?.users) {
      setUserList(company.users);
      setFilteredUserList(company.users);
      setIsLoading(false);
    }
  }, [company]);

  const addUser = () => {
    isSuperAdmin
      ? navigate(generatePath(routes.addUserBySuperAdmin, {compId: String(company?.id)}))
      : navigate(routes.addNewCompanyUser);
  };

  const getColumns = (): ColumnDef<CompanyUserDTO, string>[] => {
    return [
      columnHelper.accessor('fio', {
        header: 'Фамилия и имя',
        cell: ({row, getValue}) => <UserActionButtons data={row.original} getValue={getValue} />,
        meta: {className: 'fio'},
        id: 'fio',
      }),

      columnHelper.accessor('email', {
        header: 'Электронный адрес',
        id: 'email',
      }),

      columnHelper.accessor('phoneNumber', {
        header: 'Телефон',
        cell: ({row}) => {
          return <PhoneCell phoneNumber={row.original.phoneNumber} />;
        },
        id: 'phoneNumber',
      }),
      columnHelper.accessor('statusName', {
        header: 'Статус учетной записи',
        cell: ({row}) => {
          return <StatusCell statusId={row.original.statusId} statusName={row.original.statusName} />;
        },
        id: 'statusName',
      }),

      columnHelper.accessor('jobTitle', {
        header: 'Должность',
        id: 'jobTitle',
      }),
    ];
  };

  const columns = getColumns();

  const debouncedHandleSearchInputChange = useDebounce((searchValue) => {
    if (typeof searchValue === 'string') {
      // TODO заменить на запрос к эндпоинту по фильтрации на бэкенде
      const filteredUsers =
        userList?.filter(({fio, email, phoneNumber, jobTitle, statusName}) =>
          fio
            .concat(email, getRawPhoneNumber(phoneNumber), jobTitle, statusName)
            .toLowerCase()
            .trim()
            .includes(searchValue.toLowerCase()),
        ) ?? [];
      setFilteredUserList(filteredUsers);
    }
  }, 500);

  const getNoUsersPlaceholder = () => {
    return (
      <>
        <img src={EmptyImg} alt="Success image" className="mt-5" />
        <CardTitle>Тут не хватает пользователей</CardTitle>
        <CardDescription className="text-base max-w-sm text-center px-4">
          В этот список нужно добавить пользователей, которые тоже смогут работать с дашбордами.
        </CardDescription>
      </>
    );
  };

  return (
    <>
      <Card className={cn({['col-span-2']: isSuperAdmin})}>
        <CardHeader
          className={cn(
            {['justify-center flex-col gap-2']: !userList?.length && !isSuperAdmin},
            {['justify-start']: isSuperAdmin},
          )}
        >
          {isSuperAdmin && <CardTitle className="mr-5">Пользователи</CardTitle>}
          <SearchInput
            className={cn({['self-start']: !isSuperAdmin}, {['self-center ml-10']: isSuperAdmin})}
            placeholder="Найти по любым данным"
            onChange={debouncedHandleSearchInputChange}
          />
          {!isLoading && userList && !userList.length && !isSuperAdmin ? getNoUsersPlaceholder() : null}
          <CardActions className={cn({['ml-auto']: isSuperAdmin}, {['my-8']: !userList?.length && !isLoading})}>
            <Button variant="primary" onClick={addUser}>
              Добавить пользователя
            </Button>
          </CardActions>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <ClipLoader containerHeight={450} />
          ) : !!userList?.length || isSuperAdmin ? (
            <DataTable
              columns={columns}
              data={filteredUserList}
              emptySearchResult={isSuperAdmin ? null : <EmptySearchResult />}
            />
          ) : null}
        </CardContent>
      </Card>
    </>
  );
};
