import * as amplitude from '@amplitude/analytics-browser';
import {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router';

import {UsersApi} from 'api';
import {AccountRoles} from 'api/dto';
import {JwtAuthService} from 'services/JwtAuthService';
import {routes} from 'shared/constants/routes';
import {useEffectOnce} from 'shared/hooks';
import {useRootDispatch} from 'store';
import {authActions} from 'store/auth';
import {authAsyncActions} from 'store/auth/actions';
import {companiesActions} from 'store/companies';
import {companiesAsyncActions} from 'store/companies/actions';
import {dictionariesAsyncActions} from 'store/dictionaries/actions';

import {ClipLoader} from '../ClipLoader';

type AuthUserContext = {
  isAuth: boolean;
  initialized: boolean;
};

export const AuthContext = createContext<AuthUserContext>({
  isAuth: false,
  initialized: false,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

type Props = {
  authService: JwtAuthService;
};

export const AuthProvider = ({authService}: Props) => {
  const navigate = useNavigate();
  const dispatch = useRootDispatch();
  const [isAuth, setIsAuth] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const location = useLocation();

  useEffectOnce(() => {
    authService.checkInitAuth().then(async (user) => {
      if (user) {
        dispatch(authActions.setAuthorizedUser(user));

        // загружаем словари
        dispatch(dictionariesAsyncActions.getUserStatuses());
        dispatch(dictionariesAsyncActions.getCompanyStatuses());
        dispatch(dictionariesAsyncActions.getCompanyRoles());

        amplitude.setUserId(`amp_${user.id}`);

        if (user.companyId && user.systemRoleName !== AccountRoles.superAdmin) {
          dispatch(companiesActions.setCurrentCompanyId(user.companyId));
          dispatch(companiesAsyncActions.fetchCurrentCompany());
          dispatch(companiesAsyncActions.fetchIntegrationsStatuses());
          dispatch(authAsyncActions.getCompanyUserProfile({companyId: user.companyId, userId: user.id}));
        } else if (user.systemRoleName === AccountRoles.superAdmin) {
          const userWithJobTitle = await UsersApi.getAdminProfile(user.id);
          dispatch(authActions.updateProfile({user: {...user, ...userWithJobTitle}, companyProfile: null}));
        }
      }
      setInitialized(true);
    });
  });

  const onAuthChange = (newValue: boolean) => {
    setIsAuth(newValue);

    if (!newValue && !location.pathname.includes('reset-password') && !location.pathname.includes('confirm-email')) {
      navigate(routes.login);
    }
  };

  useEffect(() => {
    const unsubscribe = authService.registerOnAuthListener(onAuthChange);

    return () => {
      unsubscribe();
    };
  }, []);

  const providerValue = useMemo(() => ({isAuth: isAuth && initialized, initialized}), [isAuth, initialized]);

  return <AuthContext.Provider value={providerValue}>{initialized ? <Outlet /> : <ClipLoader />}</AuthContext.Provider>;
};
