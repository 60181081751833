export const MOCK_DATA = {
  metrics: {
    averageHeadcount: 94,
    flowRate: {
      value: 2,
      targetValue: 0.1,
    },
    hired: 6,
    fired: 3,
  },
  charts: {
    headcountDynamics: [
      {
        date: '24.12.23',
        value: 90,
      },
      {
        date: '25.12.23',
        value: 110,
      },
      {
        date: '26.12.23',
        value: 99,
      },
      {
        date: '27.12.23',
        value: 93,
      },
      {
        date: '28.12.23',
        value: 99,
      },
      {
        date: '29.12.23',
        value: 98,
      },
      {
        date: '30.12.23',
        value: 91,
      },
      {
        date: '31.12.23',
        value: 115,
      },
    ],
    flowRateDynamics: [
      {
        date: '24.12.23',
        value: 1,
      },
      {
        date: '25.12.23',
        value: 0,
      },
      {
        date: '26.12.23',
        value: 8,
      },
      {
        date: '27.12.23',
        value: 0,
      },
      {
        date: '28.12.23',
        value: 10,
      },
      {
        date: '29.12.23',
        value: 0,
      },
      {
        date: '30.12.23',
        value: 0,
      },
      {
        date: '31.12.23',
        value: 0,
      },
    ],
    staffingLevelInfo: {
      staffHeadcount: 70,
      values: [
        {
          date: '24.12.23',
          totalPercentageValue: 60,
          factPercentageValue: 44,
        },
        {
          date: '25.12.23',
          totalPercentageValue: 62,
          factPercentageValue: 54,
        },
        {
          date: '26.12.23',
          totalPercentageValue: 66,
          factPercentageValue: 59,
        },
        {
          date: '27.12.23',
          totalPercentageValue: 62,
          factPercentageValue: 60,
        },
        {
          date: '28.12.23',
          totalPercentageValue: 66,
          factPercentageValue: 48,
        },
        {
          date: '29.12.23',
          totalPercentageValue: 65,
          factPercentageValue: 54,
        },
        {
          date: '30.12.23',
          totalPercentageValue: 61,
          factPercentageValue: 61,
        },
        {
          date: '31.12.23',
          totalPercentageValue: 62,
          factPercentageValue: 62,
        },
      ],
    },
    hiredFiredInfo: [
      {
        date: '24.12.23',
        hired: 0,
        fired: -1,
      },
      {
        date: '25.12.23',
        hired: 1,
        fired: -4,
      },
      {
        date: '26.12.23',
        hired: 3,
        fired: 0,
      },
      {
        date: '27.12.23',
        hired: 2,
        fired: -3,
      },
      {
        date: '28.12.23',
        hired: 0,
        fired: -3,
      },
      {
        date: '29.12.23',
        hired: 0,
        fired: 0,
      },
      {
        date: '30.12.23',
        hired: 0,
        fired: 0,
      },
      {
        date: '31.12.23',
        hired: 0,
        fired: -11,
      },
    ],
    absencesDynamics: [
      {
        date: '24.12.23',
        value: 27,
      },
      {
        date: '25.12.23',
        value: 13,
      },
      {
        date: '26.12.23',
        value: 11,
      },
      {
        date: '27.12.23',
        value: 4,
      },
      {
        date: '28.12.23',
        value: 27,
      },
      {
        date: '29.12.23',
        value: 18,
      },
      {
        date: '30.12.23',
        value: 5,
      },
      {
        date: '31.12.23',
        value: 24,
      },
    ],
  },
  companyId: 1,
  period: {
    startDate: '2023-12-24T00:00:00',
    endDate: '2023-12-31T00:00:00',
  },
  availablePeriod: {
    startDate: '2020-01-01T00:00:00',
    endDate: '2023-12-31T00:00:00',
  },
};
