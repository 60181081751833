import cn from 'classnames';
import {DicesIcon, EditIcon, InfoIcon, LocateFixedIcon} from 'lucide-react';
import {cloneElement, FC, isValidElement, ReactNode, useState} from 'react';

import {Badge} from 'shared/components/shadcn-ui/Badge';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Card} from 'shared/components/shadcn-ui/Card';
import {Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger} from 'shared/components/shadcn-ui/Sheet';
import {useCurrentCompany, useUserRole} from 'shared/hooks';

import s from './MetricCard.module.scss';

type Props = {
  className?: string;
  title: string;
  value: number | null;
  description?: string;
  descriptionTitle?: string;
  icon?: ReactNode;
  targetValue?: number | null;
  targetIcon?: ReactNode;
  targetBadgeClassname?: string;
  editIcon?: ReactNode;
  onEditIconClick?: () => void;
  formatter?: (value: number) => string;
  isDisabled?: boolean;
};

export const MetricCard: FC<Props> = ({
  title,
  value,
  description,
  icon = <DicesIcon />,
  targetValue,
  className,
  targetIcon = <LocateFixedIcon />,
  targetBadgeClassname = '',
  onEditIconClick,
  editIcon = <EditIcon />,
  descriptionTitle,
  formatter,
  isDisabled,
}: Props) => {
  const [toggledInfo, setToggledInfo] = useState(false);
  const {isCompanyAdmin} = useUserRole();
  const {hasActiveCompanyStatus, hasDemoStatus} = useCurrentCompany();
  const toggle = () => {
    if (description) {
      setToggledInfo((prev) => !prev);
    }
  };

  const getValue = () => {
    if (value === null) return '...';
    if (typeof formatter === 'function') {
      return formatter(value);
    }
    return value;
  };

  return (
    <Card className={cn(s.miniCard, className)}>
      {icon && (
        <div className={cn(s.miniCard__icon, {[s.miniCard__icon_disabled]: isDisabled})}>
          {isValidElement(icon) ? cloneElement(icon, {...icon.props}) : null}
        </div>
      )}
      <div className={s.contentBox}>
        <h2 className={cn(s.contentBox__title, {[s.contentBox__title_disabled]: isDisabled})}>{title}</h2>
        <div>
          <span className={cn(s.contentBox__value, {[s.contentBox__value_disabled]: isDisabled})}>{getValue()}</span>
          {targetValue !== null && typeof targetValue !== 'undefined' && value !== null ? (
            <Badge className={cn('ml-3 bg-primary-100', targetBadgeClassname)} variant="secondary">
              <div className={s.targetValueBox}>
                {isValidElement(targetIcon) && targetValue
                  ? cloneElement(targetIcon, {...targetIcon.props, className: s.targetValueBox__icon})
                  : null}
                <span className={s.targetValueBox__value}>{`${targetValue}%`}</span>
              </div>
            </Badge>
          ) : null}
        </div>
      </div>
      <Sheet open={toggledInfo} onOpenChange={toggle}>
        <SheetTrigger asChild className="absolute top-3 right-3">
          <Button variant="ghost" size="icon" className="p-0 h-6 w-6 text-neutral-800">
            <InfoIcon />
          </Button>
        </SheetTrigger>
        <SheetContent side="right">
          <SheetHeader>
            <SheetTitle className="mb-2.5">{descriptionTitle || title}</SheetTitle>
          </SheetHeader>
          {description && <div dangerouslySetInnerHTML={{__html: description}} />}
        </SheetContent>
      </Sheet>
      {onEditIconClick && isCompanyAdmin && (hasActiveCompanyStatus || hasDemoStatus) && (
        <Button
          variant="ghost"
          size="icon"
          className="absolute bottom-2 right-2 text-neutral-800"
          onClick={onEditIconClick}
        >
          {isValidElement(editIcon) ? cloneElement(editIcon, {...editIcon.props, size: 24}) : null}
        </Button>
      )}
    </Card>
  );
};
