import ApiClient from '../client';
import {OneCSettingsDTO} from '../dto';

class OneCIntegrationService {
  getBasePath(compId: string | number): string {
    return `companies/${compId}/integrations/one-c`;
  }
  getOneCIntegrationSettings(compId: string | number) {
    return ApiClient.get<OneCSettingsDTO>(this.getBasePath(compId)).then((res) => res.data);
  }
  generateOneCApiKey(compId: string | number) {
    return ApiClient.post<string>(`${this.getBasePath(compId)}/generate-api-key`).then((res) => res.data);
  }
  setOneCIsEnabled(compId: string | number, isEnabled: boolean) {
    return ApiClient.patch<OneCSettingsDTO>(this.getBasePath(compId), null, {
      params: {enabled: isEnabled},
    }).then((res) => res.data);
  }
  updateOneCIntegrationSettings(
    compId: string | number,
    payload: Pick<OneCSettingsDTO, 'apiKey' | 'whiteIPAddresses' | 'isEnabled'>,
  ) {
    return ApiClient.put<OneCSettingsDTO>(this.getBasePath(compId), payload).then((res) => res.data);
  }
}

export const oneCIntegrationApi = new OneCIntegrationService();
