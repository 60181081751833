import {Outlet} from 'react-router';

import {useRootDispatch, useRootSelector} from 'store';
import {authAsyncActions} from 'store/auth/actions';
import {authSelectors} from 'store/auth/selectors';

export function AuthSettings() {
  const user = useRootSelector(authSelectors.getUser);
  const dispatch = useRootDispatch();
  if (user) {
    dispatch(authAsyncActions.signOut());
  }

  return <Outlet />;
}
