import ApiClient from 'api/client';

import {DictionaryTypeDTO} from '../dto/DictionaryDTO';

class DictionariesService {
  private basePath = '/dictionaries/types';
  async getCompanyStatuses() {
    return ApiClient.get<DictionaryTypeDTO[]>(`${this.basePath}/company/statuses`).then((res) => res.data);
  }
  async getCompanyUserStatuses() {
    return ApiClient.get<DictionaryTypeDTO[]>(`${this.basePath}/users/statuses`).then((res) => res.data);
  }
  async getCompanyRoles() {
    return ApiClient.get<DictionaryTypeDTO[]>(`${this.basePath}/roles/company`).then((res) => res.data);
  }
  async getDismissalReasons() {
    return ApiClient.get<DictionaryTypeDTO[]>(`${this.basePath}/dismissal-reasons`).then((res) => res.data);
  }
}

export const DictionariesApi = new DictionariesService();
