import {memo} from 'react';
import {Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

import {CommonMetricsDTO} from 'api/dto';

import {ClipLoader} from '../../../ClipLoader';
import {CustomTooltipContent} from '../../CustomTooltip';
import {useDashboardContext} from '../../DashboardContextProvider';
import {NoDataPlaceholder} from '../../NoDataPlaceholder';
import {DOMAIN_OF_EMPTY_CHART} from '../../utils';
import {
  getCartesianGridDefaultProps,
  getDefaultTooltipCursorProps,
  getLabelFormatter,
  getLabelListDefaultStyles,
  getLegendDefaultProps,
  getXAxisDefaultProps,
  getXAxisPropsForEmptyChart,
  getYAxisDefaultProps,
  measureText,
} from '../../utils/helpers';

type Props = {
  data: CommonMetricsDTO['charts']['hiredFiredInfo'] | undefined;
  isLoading?: boolean;
  className?: string;
  infoMessage?: string;
};

export const HiresAndFiresChart = memo(({data, isLoading = false, className = '', infoMessage}: Props) => {
  const isEmptyChart = data?.length === 0;
  const {dateRange} = useDashboardContext();

  const getXAxisProps = () => {
    if (isEmptyChart) return getXAxisPropsForEmptyChart(dateRange);
    return getXAxisDefaultProps();
  };

  const getTicks = () => {
    const range =
      data?.reduce((acc, item) => {
        if (!acc.length) {
          // min
          acc.push(item.fired ?? 0);
          // max
          acc.push(item.hired ?? 0);
          return acc;
        }
        acc[0] = Math.min(item.fired ?? 0, acc[0]);
        acc[1] = Math.max(item.hired ?? 0, acc[1]);
        return acc;
      }, [] as number[]) || [];

    const absMax = Math.max(Math.abs(range[0] ?? 0), Math.abs(range[1] ?? 0), 5);
    const step = Math.ceil(absMax / 3);
    return [-3 * step, -2 * step, -step, 0, step, 2 * step, 3 * step];
  };
  if (isLoading) {
    return <ClipLoader containerHeight={250} />;
  }
  if (infoMessage || !data) {
    return <NoDataPlaceholder height={250} text={infoMessage} />;
  }

  return (
    <>
      <ResponsiveContainer width="100%" height={300} className={className}>
        <BarChart
          width={500}
          height={250}
          barSize={22}
          data={data}
          margin={{
            top: 30,
            right: 25,
            left: 0,
            bottom: 10,
          }}
        >
          <CartesianGrid {...getCartesianGridDefaultProps()} />
          <XAxis dataKey="date" {...getXAxisProps()} />
          <Tooltip content={<CustomTooltipContent />} cursor={getDefaultTooltipCursorProps()} />
          <YAxis
            {...getYAxisDefaultProps()}
            ticks={!isEmptyChart ? getTicks() : undefined}
            width={measureText(String(Math.max(...getTicks())), 14) + 30}
            domain={isEmptyChart ? DOMAIN_OF_EMPTY_CHART : undefined}
          />
          <Bar dataKey="fired" fill="#F3A0B2" radius={[4, 4, 0, 0]} name="Увольнения">
            <LabelList
              dataKey="fired"
              position="top"
              style={getLabelListDefaultStyles()}
              formatter={getLabelFormatter}
            />
          </Bar>
          <Bar dataKey="hired" fill="#56BC6B" radius={[4, 4, 0, 0]} name="Приёмы">
            <LabelList
              dataKey="hired"
              position="top"
              style={getLabelListDefaultStyles()}
              formatter={getLabelFormatter}
            />
          </Bar>
          <Legend {...getLegendDefaultProps()} />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
});
