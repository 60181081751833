import {Check, ChevronDown, ChevronUp} from 'lucide-react';
import * as React from 'react';
import {useState} from 'react';

import {bem} from 'shared/utils';
import {cn} from 'shared/utils/helpers';

import s from './Combobox.module.scss';
import {Command, CommandEmpty, CommandGroup, CommandHeader, CommandInput, CommandItem} from './Command';

import {Button} from '../Button';
import {Popover, PopoverContent, PopoverTrigger} from '../Popover';
import {ScrollArea} from '../ScrollArea';

export type ItemType = {
  value: string;
  label: string;
};

interface ComboBoxProps {
  title?: string;
  options: ItemType[];
  defaultValue?: string;
  placeholder: string;
  className?: string;
  onValueChange: (value: string) => void;
  isDisabled?: boolean;
}

const sn = bem('combobox', s);

export const ComboBox: React.FC<ComboBoxProps> = ({
  title,
  options,
  defaultValue = '',
  placeholder,
  className,
  onValueChange,
  isDisabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');

  const resetInputValue = () => setInputValue('');

  const handleSelect = (currentValue: string) => {
    setOpen(false);
    onValueChange(currentValue);
  };

  const onChangeInput = (value: string) => {
    setInputValue(value);
  };

  const getCurrentIcon = () => {
    if (open) {
      return <ChevronUp className="h-4 w-4 opacity-50 ml-2" />;
    }
    return <ChevronDown className="h-4 w-4 opacity-50 ml-2" />;
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="ComboBox"
          aria-expanded={open}
          className={cn('w-[100%] justify-between', {'text-muted-foreground': !defaultValue}, className)}
          disabled={isDisabled}
        >
          {defaultValue ? options.find((option) => option.value === defaultValue)?.label || placeholder : placeholder}
          {getCurrentIcon()}
        </Button>
      </PopoverTrigger>
      {!isDisabled && (
        <PopoverContent withPortal={false} className={sn('menu')}>
          <Command>
            {title && <CommandHeader title={title} onResetClick={resetInputValue} />}
            <CommandInput value={inputValue} onValueChange={onChangeInput} placeholder={placeholder} />
            <CommandEmpty>Данные отсутствуют.</CommandEmpty>
            <ScrollArea className="h-48">
              <CommandGroup>
                {options.map((option) => (
                  <CommandItem key={option.value} onSelect={() => handleSelect(option.value)}>
                    <Check
                      className={cn('mr-2 h-4 w-4', defaultValue === option.value ? 'opacity-100' : 'opacity-0')}
                    />
                    {option.label}
                  </CommandItem>
                ))}
              </CommandGroup>
            </ScrollArea>
          </Command>
        </PopoverContent>
      )}
    </Popover>
  );
};
