import {HuntflowRecruitmentStageDTO, HuntflowRecruitmentMapItemDTO} from 'api/dto';
import {RecruitmentStatusItemMapModel} from 'shared/models/huntflowModel';

export const formatMap = (
  mapDTO: HuntflowRecruitmentMapItemDTO[],
  stagesList: HuntflowRecruitmentStageDTO[],
): RecruitmentStatusItemMapModel[] => {
  return mapDTO.map((item) => {
    const {huntflowRecruitmentStatusesIds, ...rest} = item;
    const transformedIds =
      huntflowRecruitmentStatusesIds?.map((id) => {
        const stage = stagesList.find((status) => status.id === id);
        return {
          value: String(stage?.id || id),
          label: stage?.name || '',
        };
      }) || [];
    return {
      ...rest,
      huntflowRecruitmentStatusesIds: transformedIds,
    };
  });
};

export const prepareMapBeforeSave = (
  funnelStages: RecruitmentStatusItemMapModel[],
): HuntflowRecruitmentMapItemDTO[] => {
  return funnelStages.map((stage) => ({
    ...stage,
    huntflowRecruitmentStatusesIds: stage.huntflowRecruitmentStatusesIds.map(({value}) => Number(value)),
  }));
};
