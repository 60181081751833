export enum AccountRoles {
  user = 'Пользователь',
  superAdmin = 'Супер Администратор',
}
export enum CompanyRoles {
  user = 'Пользователь',
  admin = 'Администратор',
}
export enum AccountStatuses {
  disabled = 'Не активна',
  active = 'Активна',
}
export enum OnboardingStatus {
  notStarted,
  started,
  completed,
}

export interface UserDTO {
  id: number;
  firstName: string;
  lastName: string;
  fullName?: string;
  systemRoleName?: AccountRoles;
  systemRoleId?: number;
  onboardingState?: OnboardingStatus;
  duplicateNotificationToEmail?: boolean;
  email: string;
}
export type UpdateUserDTO = Pick<UserDTO, 'firstName' | 'lastName'>;
export interface UserDTOWithAuthData extends UserDTO {
  role?: string;
  companyId?: string | number | null;
}
