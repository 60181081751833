import {zodResolver} from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/browser';
import {useCallback, useEffect, useRef, useState} from 'react';
import {Controller, FieldErrors, useForm, useWatch} from 'react-hook-form';

import {YandexApi} from 'api';
import {
  IntegrationStatusId,
  IntegrationTypeId,
  UpdatedYandexDirectParametersDTO,
  YandexDirectSettingsViewDTO,
} from 'api/dto';
import {ClipLoader} from 'shared/components/ClipLoader';
import {NoIntegrationPlaceholder} from 'shared/components/NoIntegrationPlaceholder';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Card, CardContent, CardHeader, CardTitle} from 'shared/components/shadcn-ui/Card';
import {Form} from 'shared/components/shadcn-ui/Form';
import {Separator} from 'shared/components/shadcn-ui/Separator';
import {Switch} from 'shared/components/shadcn-ui/Switch';
import {toast} from 'shared/components/shadcn-ui/Toast/useToast';
import {useYandexIntegrationParametersContext} from 'shared/components/YandexOauth/YandexIntegrationProvider';
import {useCurrentCompany, useFullAccessRequest, useIntegrationStatuses, useUnmount} from 'shared/hooks';
import {FormFieldsApiError} from 'shared/models/apiError';
import {bem} from 'shared/utils';
import {extractApiErrorMessage} from 'shared/utils/axios';
import {handleApiErrors} from 'shared/utils/helpers';
import {useRootDispatch} from 'store';
import {companiesAsyncActions} from 'store/companies/actions';
import {YandexIntegrationStep} from 'typings/yandexOauth';

import {SettingsContent} from './components/SettingsContent';
import {ErrorsBlock} from './components/SettingsContent/components/ErrorsBlock';
import {SuccessIntegrationInfo} from './components/SuccessIntegrationInfo';
import {FormValuesType} from './utils/types';
import {FormSchema} from './utils/validationSchema';
import s from './YandexDirectIntegration.module.scss';

const sn = bem('yandexDirectIntegration', s);

export const YandexDirectIntegration = () => {
  const [isToggling, setIsToggling] = useState(false);
  const {hasDemoStatus, company} = useCurrentCompany();
  const {sendRequest} = useFullAccessRequest();
  const initialized = useRef(false);
  const dispatch = useRootDispatch();
  const directIntegrationSettings = useIntegrationStatuses(IntegrationTypeId.YandexDirect);
  const isYandexDirectEnabled = directIntegrationSettings?.isEnabled;

  const {
    isLoading,
    yandexParameters,
    integrationStep,
    setIntegrationStep,
    authenticated,
    init,
    error,
    refreshParameters,
    setIsLoading,
    reset,
    oAuthConfig,
  } = useYandexIntegrationParametersContext();

  const yandexDirectParameters = yandexParameters as YandexDirectSettingsViewDTO;

  const getFormValuesFromParameters = (yandexDirectParameters: YandexDirectSettingsViewDTO) => ({
    addAllCampaigns: yandexDirectParameters?.selectedAllCampaigns,
    availableAdsCampaigns: yandexDirectParameters?.availableAdsCampaigns
      ?.map((campaign) => ({
        ...campaign,
        isChecked: yandexDirectParameters?.selectedAdsCampaigns?.includes(campaign.id),
      }))
      // сортируем, помещая в начало массива выбранные кампании
      .sort((a, b) => {
        return Number(b.isChecked) - Number(a.isChecked);
      }),
    isEnabled: yandexDirectParameters?.isEnabled,
    adsCampaignsIds: yandexDirectParameters?.selectedAdsCampaigns,
    accessToken: yandexDirectParameters?.accessToken,
    ...oAuthConfig,
  });

  const form = useForm<FormValuesType>({
    resolver: zodResolver(FormSchema),
    defaultValues: getFormValuesFromParameters(yandexDirectParameters),
  });

  const adsCampaigns = useWatch({control: form.control, name: 'availableAdsCampaigns'}) ?? [];
  const isShownErrorsBlock = yandexParameters?.isEnabled && !adsCampaigns.length;
  const isShownSaveBtnAtHeader =
    (!isToggling && !isLoading && !isShownErrorsBlock && integrationStep === YandexIntegrationStep.showSettings) ||
    (isShownErrorsBlock && form.formState.isDirty);

  const onSwitchIntegrationStatus = async (isEnabled: boolean) => {
    if (isToggling || !company?.id) return;
    setIsToggling(true);
    form.setValue('isEnabled', isEnabled, {shouldDirty: true});
    if (isEnabled) await init();
    setIsToggling(false);
    isEnabled ? setIntegrationStep(null) : setIntegrationStep(YandexIntegrationStep.showSettings);
  };

  useEffect(() => {
    setIsToggling(false);
    if (authenticated) {
      refreshParameters();
      setIntegrationStep(YandexIntegrationStep.showSettings);
      dispatch(companiesAsyncActions.fetchIntegrationsStatuses());
      toast({
        title: 'Изменения успешно сохранены.',
      });
    }
    if (authenticated === false) {
      form.setValue('isEnabled', false);
      setIntegrationStep(YandexIntegrationStep.showPlaceholder);
      reset();
    }
  }, [authenticated]);

  useEffect(() => {
    if (!initialized.current && yandexDirectParameters) {
      initialized.current = true;
    }
    form.reset({
      ...getFormValuesFromParameters(yandexDirectParameters),
    });
  }, [yandexDirectParameters]);

  useEffect(() => {
    if (error) {
      toast({
        title: error.title ?? 'Произошла непредвиденная ошибка интеграции с Яндекс Директ.',
        variant: 'destructive',
      });
    }
  }, [error]);

  useUnmount(() => {
    reset();
    initialized.current = false;
  });

  const setValidationErrors = (extractedError: FormFieldsApiError) => {
    if ('availableAdsCampaigns' in extractedError) {
      form.setError('availableAdsCampaigns', {
        message: extractedError.goals[0],
      });
    }
  };

  const setError = (error: unknown) => {
    Sentry.captureException(error);
    const extractedError = extractApiErrorMessage(error);
    if (typeof extractedError === 'string') {
      toast({
        variant: 'destructive',
        title: extractedError,
      });
    } else {
      setValidationErrors(extractedError);
      handleApiErrors(error);
    }
  };

  const save = async (data: UpdatedYandexDirectParametersDTO) => {
    if (!company?.id) return;
    setIsLoading(true);
    try {
      await YandexApi.setYandexDirectIntegrationSettings(company.id, data);
      dispatch(companiesAsyncActions.fetchIntegrationsStatuses());
      toast({
        title: 'Изменения успешно сохранены.',
      });
      reset();
      setIntegrationStep(data.isEnabled ? YandexIntegrationStep.showSuccess : YandexIntegrationStep.showPlaceholder);
    } catch (error) {
      Sentry.captureException(error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const submit = async (formData: FormValuesType) => {
    const {availableAdsCampaigns, ...values} = formData;
    await save(values);
  };

  const getCardHeader = () => (
    <CardHeader className="justify-start items-center h-10 mb-2">
      <div className={sn('switch')}>
        <Controller
          control={form.control}
          render={({field}) => (
            <Switch
              checked={hasDemoStatus || field.value}
              onCheckedChange={hasDemoStatus ? sendRequest : onSwitchIntegrationStatus}
              disabled={isToggling}
              title={hasDemoStatus ? 'Вы используете демодоступ.' : ''}
            />
          )}
          name="isEnabled"
        />

        {isToggling && <ClipLoader className="max-h-6 w-6" size={24} />}
      </div>
      <CardTitle className="leading-[24px] basis-[1fr] mr-auto">Яндекс Директ</CardTitle>
      {isShownSaveBtnAtHeader && (
        <Button
          type="submit"
          form="settingsDirectForm"
          variant="primary"
          disabled={hasDemoStatus || !form.formState.isDirty}
        >
          Сохранить
        </Button>
      )}
    </CardHeader>
  );

  const getSettingContent = () => {
    if (isShownErrorsBlock) {
      return <ErrorsBlock />;
    }

    if (yandexParameters && isYandexDirectEnabled && adsCampaigns.length) return <SettingsContent />;
    return null;
  };

  // TODO уточнить что показывать при каком statusId
  const getCardContent = () => {
    switch (integrationStep) {
      case YandexIntegrationStep.showPlaceholder:
        return (
          <NoIntegrationPlaceholder
            disabled={isToggling}
            title="Интеграция с Яндекс Директом не подключена"
            onStart={() => onSwitchIntegrationStatus(true)}
          />
        );
      case YandexIntegrationStep.showSuccess:
        return <SuccessIntegrationInfo />;
      case YandexIntegrationStep.showSettings:
        return getSettingContent();
      default:
        return null;
    }
  };

  const onErrorSubmit = (errors: FieldErrors<FormValuesType>) => {
    form.trigger();
  };

  return (
    <Card className="p-14 mt-0 w-full min-h-[320px] relative">
      {getCardHeader()}
      <Separator />
      <CardContent className="mt-8 min-h-[350px]">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(submit, onErrorSubmit)} className="w-full" id="settingsDirectForm">
            {getCardContent()}
          </form>
        </Form>
      </CardContent>
      {isLoading && (
        <div className={sn('overlay')}>
          <ClipLoader className="max-h-[320px]" />
        </div>
      )}
    </Card>
  );
};
