import {camelizeKeys} from 'humps';

import {HuntflowIntegration, HuntflowOrganizationModel} from 'shared/models/huntflowModel';

import ApiClient from '../client';
import {
  HuntflowIntegrationSettingsDTO,
  OrganizationDTO,
  HuntflowRecruitmentStageDTO,
  HuntflowRecruitmentMapItemDTO,
  HuntflowResumeSourceDTO,
} from '../dto';

class HuntflowIntegrationApi {
  getBasePath(compId: string | number): string {
    return `companies/${compId}/integrations/huntflow`;
  }
  getHuntflowIntegrationSettingsView(compId: string | number) {
    return ApiClient.get<HuntflowIntegrationSettingsDTO>(this.getBasePath(compId)).then((res) => res.data);
  }
  setHuntflowIntegrationSettings(compId: string | number, payload: HuntflowIntegration) {
    return ApiClient.post<HuntflowIntegration>(this.getBasePath(compId), payload).then((res) => res.data);
  }
  getAvailableOrganizations(huntflowAccessToken: string) {
    return ApiClient.get<OrganizationDTO[]>('huntflow-api/available-organizations', {
      headers: {
        Huntflowaccesstoken: huntflowAccessToken,
      },
    }).then(
      (res) =>
        camelizeKeys(res.data, (key, convert) => {
          return key === 'member_type' ? key : convert(key);
        }) as HuntflowOrganizationModel[],
    );
  }
  getReferralProgramSettings(compId: string | number, huntflowOrgId: number) {
    return ApiClient.get<HuntflowResumeSourceDTO[]>(
      `${this.getBasePath(compId)}/${huntflowOrgId}/referral-program-sources`,
    ).then((res) => res.data);
  }

  getReferralProgramSources(compId: string | number, huntflowOrgId: number, huntflowAccessToken: string) {
    return ApiClient.get<HuntflowResumeSourceDTO[]>(`huntflow-api/${huntflowOrgId}/resume-sources`, {
      headers: {
        Huntflowaccesstoken: huntflowAccessToken,
      },
    }).then((res) => res.data);
  }

  getRecruitmentStagesList(huntflowOrgId: number, huntflowAccessToken: string) {
    return ApiClient.get<HuntflowRecruitmentStageDTO[]>(`/huntflow-api/${huntflowOrgId}/recruitment-statuses`, {
      headers: {Huntflowaccesstoken: huntflowAccessToken},
    }).then((res) => res.data);
  }
  getRecruitmentStagesMap(compId: string | number, huntflowOrgId: number, huntflowAccessToken: string) {
    return ApiClient.get<HuntflowRecruitmentMapItemDTO[]>(
      `${this.getBasePath(compId)}/${huntflowOrgId}/recruitment-statuses-map`,
      {
        headers: {
          Huntflowaccesstoken: huntflowAccessToken,
        },
      },
    ).then((res) => res.data);
  }
}

export const huntflowIntegrationApi = new HuntflowIntegrationApi();
