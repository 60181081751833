import cn from 'classnames';
import {FC, useContext, useState} from 'react';
import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts';

import {HrBrandMetricsDTO, IntegrationTypeId} from 'api/dto';
import {Button} from 'shared/components/shadcn-ui/Button';
import {useDependencyOnIntegration} from 'shared/hooks';
import {BaseLayoutContext} from 'shared/layouts/BaseLayout/BaseLayoutContext';

import {CustomTooltip} from './components/CustomTooltip';
import s from './SiteAudienceChart.module.scss';

import {ClipLoader} from '../../../ClipLoader';
import {ChartCard} from '../../Cards';

type Props = {
  data: HrBrandMetricsDTO['yandexMetricaData']['siteAudience'];
  className?: string;
  description: string;
  isLoading: boolean;
};

const TABS = [
  {name: 'ageData', label: 'Возраст'},
  {name: 'deviceTypeData', label: 'Тип устройств'},
  {name: 'genderData', label: 'Пол'},
] as const;

const DATA_FOR_EMPTY_PIE = [
  {name: '2', value: 280},
  {name: '2', value: 360},
];

const COLORS = ['#4D3FE3', '#968BF6', '#60A3F8', '#7FD689', '#FDDDDB', '#F3A0B2', '#FFC93F'];
const COLORS_OF_EMPTY_CHART = ['#F7F8FA', '#F0EFF9'];
const RADIAN = Math.PI / 180;

export const SiteAudienceChart: FC<Props> = ({data, className, description, isLoading}: Props) => {
  const [activeGroup, setActiveGroup] = useState<typeof TABS[number]>(TABS[0]);
  const ellipseColor = '#F0EFF9';
  const ellipseActiveColor = '#C4C2DE';
  const {isCollapsedAsideMenu} = useContext(BaseLayoutContext);

  const {infoBadge, isBlur} = useDependencyOnIntegration(IntegrationTypeId.YandexMetrica);
  const isNonEmptyData = Object.values(data).every((arr) => !!arr.length);

  const isShowShortLabel =
    (window.innerWidth <= 1720 && !isCollapsedAsideMenu) || (window.innerWidth <= 1500 && isCollapsedAsideMenu);

  const isSmallLabelSize = window.innerWidth <= 1600;
  const isSmallPieRadius = window.innerWidth <= 1300;

  const renderCustomOutsideLabel = ({cx, cy, midAngle, outerRadius, index}: any) => {
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 20) * cos;
    const my = cy + (outerRadius + 20) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 15;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    const entry = data[activeGroup.name][index];

    return (
      <>
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={COLORS[index % COLORS.length]} fill="none" />
        <circle cx={ex} cy={ey} r={4} fill={COLORS[index % COLORS.length]} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          fill="#56547E"
          textAnchor={textAnchor}
          dominantBaseline="central"
          className={cn(s.label, {[s.label_sm]: isSmallLabelSize})}
        >
          {isShowShortLabel ? `${entry?.name.replace('лет', '').trim()}` : `${entry?.name} (${entry?.value}%)`}
        </text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey + 15}
          fill="#56547E"
          textAnchor={cos >= 0 ? 'start' : 'end'}
          dominantBaseline="central"
          className={cn(s.label, {[s.label_sm]: isSmallLabelSize})}
        >
          {isShowShortLabel && `(${entry?.value}%)`}
        </text>
      </>
    );
  };

  const onChangeActiveGroup = (tabName: typeof TABS[number]) => {
    setActiveGroup(tabName);
  };

  const getContent = () => {
    if (isLoading) {
      return <ClipLoader />;
    }
    const dataToShow = isNonEmptyData ? data[activeGroup.name] : DATA_FOR_EMPTY_PIE;

    return (
      <>
        <ResponsiveContainer width="100%" height={350} className={cn({[s.overlay]: isBlur})}>
          <PieChart height={220}>
            <Pie
              dataKey="value"
              data={dataToShow}
              label={isNonEmptyData ? renderCustomOutsideLabel : undefined}
              type="number"
              paddingAngle={2}
              innerRadius={isSmallPieRadius ? 60 : 70}
              outerRadius={isSmallPieRadius ? 90 : 110}
              cornerRadius={9}
            >
              {dataToShow.map((entry, index) => (
                <Cell
                  style={{outline: 'none'}}
                  key={`cell-${entry.name}`}
                  fill={isNonEmptyData ? COLORS[index % COLORS.length] : COLORS_OF_EMPTY_CHART[index]}
                ></Cell>
              ))}
            </Pie>
            {isShowShortLabel && <Tooltip content={<CustomTooltip />} />}
          </PieChart>
        </ResponsiveContainer>
      </>
    );
  };

  return (
    <ChartCard
      title="Аудитория сайта"
      description={description}
      className={className}
      footer={
        <div className={s.ellipseBox}>
          {TABS.map((tab, i) => (
            <Button key={`${tab.name}_${i}`} variant="ghost" onClick={() => onChangeActiveGroup(TABS[i])}>
              <svg
                className={s.ellipseBox__item}
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
              >
                <circle cx="5" cy="5" r="5" fill={activeGroup.name === tab.name ? ellipseActiveColor : ellipseColor} />
              </svg>
            </Button>
          ))}
        </div>
      }
      headerActions={
        <>
          {TABS.map(({name, label}, i) => (
            <Button
              key={`${name}_${label}_${i}`}
              className={cn(s.tabButton, activeGroup.name === name && s['tabButton--active'])}
              variant="outline"
              onClick={() => onChangeActiveGroup(TABS[i])}
            >
              {label}
            </Button>
          ))}
        </>
      }
    >
      {getContent()}
      {!isLoading && !isNonEmptyData ? infoBadge : null}
    </ChartCard>
  );
};
