import {useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';

import {YandexMetricaBaseModelWithCounter, YandexMetricaSettingsViewDTO, YandexSegmentDTO} from 'api/dto';
import {ClipLoader} from 'shared/components/ClipLoader';
import {FieldBox} from 'shared/components/FieldBox';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Separator} from 'shared/components/shadcn-ui/Separator';
import {useYandexIntegrationParametersContext} from 'shared/components/YandexOauth/YandexIntegrationProvider';
import {useEffectOnce, useShowMore} from 'shared/hooks';
import {bem} from 'shared/utils';
import {YandexIntegrationStep} from 'typings/yandexOauth';

import {InfoBlock} from './components/InfoBlock';
import s from './SuccessIntegrationInfo.module.scss';

import {FormValuesType} from '../../utils/types';

const sn = bem('successIntegrationInfo', s);

type BlockProps = {
  title: string;
  items: YandexMetricaBaseModelWithCounter[] | YandexSegmentDTO[];
  visibleCount: number;
  showMoreHandler: () => void;
};

export const SuccessIntegrationInfo = () => {
  const {yandexParameters, refreshParameters, setIntegrationStep} = useYandexIntegrationParametersContext();
  const yandexMetricaParameters = yandexParameters as YandexMetricaSettingsViewDTO;

  const activeSegments = yandexMetricaParameters?.segments?.filter((item) => item.isActive);
  const activeGoals = yandexMetricaParameters?.goals?.filter((item) => item.isActive);
  const activeCounterName = yandexMetricaParameters?.counters?.find((item) => item.isActive)?.name;
  const [isLoading, setIsLoading] = useState(true);
  const form = useFormContext<FormValuesType>();
  const [visibleGoalsCount, showMoreGoals] = useShowMore(8, 8);
  const [visibleSegmentsCount, showMoreSegments] = useShowMore(8, 8);

  useEffectOnce(async () => {
    setIsLoading(true);
    await refreshParameters();
    setIsLoading(false);
  });

  const edit = () => {
    setIntegrationStep(YandexIntegrationStep.showSettings);
    form.reset({...form.getValues(), isEnabled: yandexMetricaParameters?.isEnabled});
  };

  useEffect(() => {
    if (!isLoading && !yandexMetricaParameters?.counters.find((item) => item.isActive)) edit();
  }, [yandexMetricaParameters]);

  if (isLoading || !yandexMetricaParameters) return <ClipLoader />;

  const renderBlock = ({title, items, visibleCount, showMoreHandler}: BlockProps) => (
    <div className={sn('parameter')}>
      <h3 className={sn('title')}>{title}</h3>
      {items?.map((item, index) => {
        if (index >= visibleCount) return null;
        return <FieldBox className={sn('fieldBox')} key={`index__${item.id}`} value={item.name} />;
      })}
      {items?.length && items.length > visibleCount && (
        <Button variant="link" onClick={showMoreHandler}>
          Показать далее
        </Button>
      )}
    </div>
  );

  return (
    <div className={sn('content')}>
      <div className={sn('parametersBlock')}>
        <div className={sn('parameter')}>
          <h3 className={sn('title')}>Счётчик</h3>
          <FieldBox className={sn('fieldBox')} value={activeCounterName ?? ''} />
        </div>
        {!!activeGoals?.length &&
          renderBlock({
            title: 'Цели',
            items: activeGoals,
            visibleCount: visibleGoalsCount,
            showMoreHandler: showMoreGoals,
          })}
        {!!activeSegments?.length &&
          renderBlock({
            title: 'Сегменты',
            items: activeSegments,
            visibleCount: visibleSegmentsCount,
            showMoreHandler: showMoreSegments,
          })}
        <Separator />
        <Button type="button" variant="primary" onClick={edit} className="mt-12 self-end mr-0">
          Редактировать
        </Button>
      </div>
      <Separator orientation="vertical" className={sn('separator')} />
      <InfoBlock />
    </div>
  );
};
