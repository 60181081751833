import {cloneElement, forwardRef, isValidElement, PropsWithChildren} from 'react';
import {useFormContext} from 'react-hook-form';

import {FormControl, FormField, FormItem, FormMessage} from 'shared/components/shadcn-ui/Form';
import {cn} from 'shared/utils/helpers';

type Props = {
  rowIndex: number;
  columName: string;
  initialValue?: string;
  withErrorClear?: boolean;
};

export const EditableCell = forwardRef<HTMLElement, PropsWithChildren<Props>>(
  ({rowIndex, columName, initialValue, children}, ref) => {
    const {
      getValues,
      formState: {errors},
    } = useFormContext();

    const defaultValue = initialValue || getValues().rows[rowIndex]?.[columName];

    return (
      <FormField
        name={`rows.${rowIndex}.${columName}`}
        defaultValue={defaultValue}
        render={({field}) => (
          <FormItem className={cn('min-h-[45px] mb-0', {['min-h-[65px] mb-[-20px]']: errors})}>
            <FormControl>
              {isValidElement(children) ? cloneElement(children, {...field, ref, ...children.props}) : null}
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    );
  },
);
