import {zodResolver} from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/browser';
import {useForm} from 'react-hook-form';
import * as z from 'zod';

import {CompaniesApi} from 'api';
import {NewCompanyUserFields} from 'shared/components/NewCompanyUserFields';
import {Button} from 'shared/components/shadcn-ui/Button';
import {CardHeader} from 'shared/components/shadcn-ui/Card';
import {Form} from 'shared/components/shadcn-ui/Form';
import {toast} from 'shared/components/shadcn-ui/Toast/useToast';
import {useCurrentCompany, useUserRole} from 'shared/hooks';
import {bem, setValidationFormErrors} from 'shared/utils';
import {extractApiErrorMessage} from 'shared/utils/axios';
import {getRawPhoneNumber, handleApiErrors} from 'shared/utils/helpers';
import {CompanyUserSchema} from 'shared/utils/validation';
import {useRootDispatch, useRootSelector} from 'store';
import {authAsyncActions} from 'store/auth/actions';
import {authSelectors} from 'store/auth/selectors';

import s from './CompanyUserFields.module.scss';

const sn = bem('companyUserFields', s);
export const CompanyUserFields = () => {
  const {company, hasDemoStatus} = useCurrentCompany();
  const user = useRootSelector(authSelectors.getUser);
  const companyProfile = useRootSelector(authSelectors.getCompanyProfile);
  const dispatch = useRootDispatch();
  const {isCompanyAdmin} = useUserRole();

  const form = useForm<z.infer<typeof CompanyUserSchema>>({
    resolver: zodResolver(CompanyUserSchema),
    defaultValues: {
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      email: user?.email ?? '',
      phoneNumber: companyProfile?.phoneNumber ?? '',
      jobTitle: companyProfile?.jobTitle ?? '',
    },
  });

  const onSubmit = async (data: z.infer<typeof CompanyUserSchema>) => {
    const {phoneNumber, ...dataWithoutPhoneNumber} = data;
    const updatedPhoneNumber = {phoneNumber: getRawPhoneNumber(phoneNumber)};

    if (user?.id && isCompanyAdmin && company) {
      try {
        await CompaniesApi.updateCompanyAdmin(company.id, {...dataWithoutPhoneNumber, ...updatedPhoneNumber});
        dispatch(authAsyncActions.getCompanyUserProfile({companyId: company.id, userId: user.id}));
        toast({
          title: 'Настройки профиля обновлены.',
        });
      } catch (error) {
        Sentry.captureException(error);
        const extractedError = extractApiErrorMessage(error);
        if (typeof extractedError === 'string') {
          form.reset();
          handleApiErrors(error);
        } else {
          setValidationFormErrors<typeof data>(extractedError, (fieldName, fieldErrors) => {
            form.setError(fieldName, {
              message: fieldErrors[0],
            });
          });
        }
      }
    }
    if (user?.id && !isCompanyAdmin && company) {
      try {
        await CompaniesApi.updateCompanyUser(company.id, user.id, {
          ...dataWithoutPhoneNumber,
          ...updatedPhoneNumber,
        });
        dispatch(authAsyncActions.getCompanyUserProfile({companyId: company.id, userId: user.id}));
        toast({
          description: 'Настройки профиля обновлены.',
        });
      } catch (error) {
        Sentry.captureException(error);
        const extractedError = extractApiErrorMessage(error);
        if (typeof extractedError === 'string') {
          form.reset();
          handleApiErrors(error);
        } else {
          setValidationFormErrors<typeof data>(extractedError, (fieldName, fieldErrors) => {
            form.setError(fieldName, {
              message: fieldErrors[0],
            });
          });
        }
      }
    }
  };

  return (
    <div className={s.companyUserFields}>
      <div className={sn('column')}>
        <CardHeader className="font-bold">Данные пользователя</CardHeader>
      </div>
      <Form {...form}>
        <form id="editCompanyUserForm" className={sn('form')}>
          <NewCompanyUserFields withDisabledEmail={true} readonly={hasDemoStatus} />
          <Button
            onClick={form.handleSubmit(onSubmit)}
            variant="primary"
            className="self-end mb-6 ml-[168px] min-w-[120px]"
            disabled={hasDemoStatus}
          >
            {form.formState.isSubmitting ? 'Загрузка...' : 'Сохранить'}
          </Button>
        </form>
      </Form>
    </div>
  );
};
