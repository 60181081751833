import {useDashboardContext} from 'shared/components/Dashboards';
import {Tabs, TabsContent, TabsList, TabsTrigger} from 'shared/components/shadcn-ui/Tabs/Tabs';
import {useLoadCompany} from 'shared/hooks/useLoadCompany';
import {useProvideBreadcrumbsData} from 'shared/layouts/BaseLayout/BaseLayoutContext';

import {CommonMetricsDashboard} from '../../User/CommonMetrics';
import {HrBrandDashboard} from '../../User/HrBrand';
import {RecruitmentDashboard} from '../../User/Recruitment';

export const CompanyDashboard = () => {
  const {company} = useLoadCompany();
  useProvideBreadcrumbsData(company);
  const {resetState} = useDashboardContext();

  return (
    <>
      <Tabs defaultValue="common" onValueChange={resetState}>
        <TabsList>
          <TabsTrigger value="common">Общие Метрики</TabsTrigger>
          <TabsTrigger value="recruitment">Подбор персонала</TabsTrigger>
          <TabsTrigger value="hrBrand">HR-бренд</TabsTrigger>
        </TabsList>
        <TabsContent value="common">
          <CommonMetricsDashboard />
        </TabsContent>
        <TabsContent value="recruitment">
          <RecruitmentDashboard />
        </TabsContent>
        <TabsContent value="hrBrand">
          <HrBrandDashboard />
        </TabsContent>
      </Tabs>
    </>
  );
};
