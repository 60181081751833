import * as Sentry from '@sentry/browser';
import {addSeconds} from 'date-fns';
import {FC, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import {YandexIntegrationType} from 'api/dto';
import {YandexApi} from 'api/services';
import {useCurrentCompany} from 'shared/hooks';
import {isAxiosError} from 'shared/utils/axios';

type Props = {
  type: YandexIntegrationType;
};

export const OAuthYandexSystemPage: FC<Props> = ({type}) => {
  const {company} = useCurrentCompany();
  const location = useLocation();

  const parsedOAuthToken = function () {
    const params = new URLSearchParams(location.hash.slice(1));
    const token = params.get('access_token');
    const expiresIn = params.get('expires_in');
    return {token, expiresIn};
  };

  const sendOAuthYandexData = async () => {
    const {expiresIn, token} = parsedOAuthToken();
    const accessToken = token ?? '';
    const expiresAt = expiresIn ? addSeconds(new Date(), +expiresIn) : null;

    try {
      if (expiresAt && company?.id) {
        type === YandexIntegrationType.direct
          ? await YandexApi.setYandexDirectIntegrationSettings(company.id, {
            accessToken,
            expiresAt,
            isEnabled: true,
          })
          : await YandexApi.setYandexMetricaIntegrationSettings(company.id, {
            accessToken,
            expiresAt,
          });
        window.opener.postMessage({event: 'success', error: '', accessToken, expiresAt});
      }
    } catch (error) {
      Sentry.captureException(error);
      window.opener.postMessage({
        event: 'reject',
        error: JSON.stringify(
          isAxiosError(error) && error?.response
            ? error.response.data
            : {title: 'Произошла непредвиденная ошибка при настройке интеграции.', status: 500},
        ),
        accessToken: token,
        expiresAt,
      });
    }
  };

  useEffect(() => {
    if (!company?.id) return;
    sendOAuthYandexData();
  }, [company]);

  return <></>;
};
