import React, {FC, ReactNode, useState} from 'react';

import {Button} from 'shared/components/shadcn-ui/Button';
import {MEDIA_BREAKPOINT_L} from 'shared/constants';
import {bem, reactNodeToString} from 'shared/utils';

import s from './IntegrationInfoBlock.module.scss';

import {ConditionalWrapper} from '../ConditionalWrapper';

const cn = bem('integrationInfoBlock', s);

type Props = {
  title?: string;
  description?: string[] | ReactNode[];
  previewChars?: number;
};

export const IntegrationInfoBlock: FC<Props> = ({title, description, previewChars = 50}) => {
  const isLargeScreen = window.innerWidth >= MEDIA_BREAKPOINT_L;
  const [isFullInfo, setIsFullInfo] = useState(isLargeScreen);

  const toggle = () => setIsFullInfo((prev) => !prev);

  const isFragment = (item: ReactNode) =>
    item && typeof item === 'object' && 'type' in item && item?.type === React.Fragment;

  const renderNode = (item: ReactNode, index: number, length: number) => {
    return (
      <ConditionalWrapper
        key={`key_${index}`}
        condition={
          isFragment(item) || typeof item === 'string' || typeof item === 'number' || typeof item === 'boolean'
        }
        wrapper={(children) => (
          <p className={cn('paragraph')} key={`paragraph-${reactNodeToString(item).substring(0, 10)}`}>
            {children}
          </p>
        )}
      >
        <>
          {item}
          {index === length - 1 && isFullInfo && !isLargeScreen && (
            <Button variant="link" onClick={toggle} className="h-[5px]">
              Скрыть
            </Button>
          )}
        </>
      </ConditionalWrapper>
    );
  };

  if (!title && !description) return <div className={s.integrationInfoBlock}></div>;

  return (
    <div className={s.integrationInfoBlock}>
      <h3 className={cn('title')}>{title}</h3>
      {isFullInfo ? (
        description?.map((item, index, array) => renderNode(item, index, array.length))
      ) : (
        <p className={cn('paragraph')}>
          {reactNodeToString(description).substring(0, previewChars)}
          <Button variant="link" onClick={toggle} className="h-[5px]">
            Подробнее
          </Button>
        </p>
      )}
    </div>
  );
};
