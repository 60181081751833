import {createColumnHelper} from '@tanstack/react-table';
import {PlusIcon} from 'lucide-react';
import {Dispatch, FC, SetStateAction, useMemo, useState} from 'react';
import {useFieldArray, useFormContext} from 'react-hook-form';

import {YandexMetricaSettingsViewDTO, YandexSegmentDTO} from 'api/dto';
import {DataTable} from 'shared/components/DataTable';
import {EditableCell} from 'shared/components/EditableCell';
import {RowActions} from 'shared/components/RowActions';
import {Button} from 'shared/components/shadcn-ui/Button';
import {CardActions} from 'shared/components/shadcn-ui/Card/Card';
import {Checkbox} from 'shared/components/shadcn-ui/Checkbox';
import {FormMessage} from 'shared/components/shadcn-ui/Form';
import {Input} from 'shared/components/shadcn-ui/Input';
import {Separator} from 'shared/components/shadcn-ui/Separator';
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from 'shared/components/shadcn-ui/Tooltip';
import {useYandexIntegrationParametersContext} from 'shared/components/YandexOauth/YandexIntegrationProvider';
import {useCurrentCompany, useFormValues} from 'shared/hooks';
import {bem} from 'shared/utils';

import {InfoBlock} from './InfoBlock';
import s from './SegmentStep.module.scss';

import {FormValuesType} from '../../../../utils/types';
import {StepType} from '../../utils/types';

type Props = {
  setTabValue: Dispatch<SetStateAction<StepType>>;
};
type TableColumnsType = Omit<YandexSegmentDTO, 'counterId'>;

const sn = bem('segmentStep', s);

export const SegmentStep: FC<Props> = ({setTabValue}) => {
  const {control, getValues, setValue, formState} = useFormContext<FormValuesType>();
  const {yandexParameters, setDeletedSegments} = useYandexIntegrationParametersContext();
  const yandexMetricaParameters = yandexParameters as YandexMetricaSettingsViewDTO;

  const [activeEditingRow, setActiveEditingRow] = useState<number | null>(null);
  const {hasDemoStatus} = useCurrentCompany();

  const {
    rows: tableRows,
    counterStatus: {counterId: selectedCounterId},
  } = useFormValues<FormValuesType>();

  const {prepend, remove} = useFieldArray({control: control, name: 'rows'});

  const removeRow = (rowIndex: number) => {
    const record = getValues().rows[rowIndex];
    remove(rowIndex);
    if (record?.id) {
      setDeletedSegments((prev) => prev.concat({...record, isDeleted: true}));
    }
  };

  const addNewRow = () => {
    prepend(
      {
        id: null,
        url: '',
        name: '',
        counterId: selectedCounterId,
        isActive: true,
        isDeleted: false,
      },
      {shouldFocus: true},
    );
  };

  const tableColumns = useMemo(() => {
    const columnHelper = createColumnHelper<TableColumnsType>();
    const columns = [
      columnHelper.accessor('isActive', {
        header: '',
        size: 50,
        cell: ({row: {index, original}}) => {
          return (
            <Checkbox
              disabled={hasDemoStatus}
              name={`rows.${index}.isActive`}
              defaultChecked={tableRows[index].isActive}
              checked={original.isActive}
              onCheckedChange={(checked: boolean) => {
                setValue(`rows.${index}.isActive`, checked);
              }}
            />
          );
        },
      }),
      columnHelper.accessor('name', {
        header: 'Название',
        size: 200,
        meta: {classNames: 'px-3'},
        cell: ({row: {id, index, original}, column}) => {
          if (id && original.name && activeEditingRow !== original.id) {
            return (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <span className={s.segmentStep__text}>{original.name}</span>
                  </TooltipTrigger>
                  <TooltipContent>{original.name}</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            );
          }
          return (
            <EditableCell columName={column.id} rowIndex={index}>
              <Input disabled={!!original.id && original.id !== activeEditingRow} />
            </EditableCell>
          );
        },
      }),
      columnHelper.accessor('url', {
        header: 'URL',
        size: 250,
        meta: {className: 'max-w-[250px] px-3 pr-0'},
        cell: ({row: {id, index, original}, column}) => {
          if (id && original.url && activeEditingRow !== original.id) {
            return (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <span className={s.segmentStep__text}>{original.url}</span>
                  </TooltipTrigger>
                  <TooltipContent>{original.url}</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            );
          }
          return (
            <EditableCell columName={column.id} rowIndex={index}>
              <Input disabled={!!original.id && original.id !== activeEditingRow} />
            </EditableCell>
          );
        },
      }),
    ];
    if (!hasDemoStatus) {
      columns.push(
        columnHelper.display({
          id: 'actions',
          size: 50,
          meta: {
            className: 'pl-6',
            activeEditingRow,
          },
          cell: RowActions,
        }),
      );
    }
    return columns;
  }, [activeEditingRow, tableRows, hasDemoStatus]);

  const goBack = () => {
    setTabValue(StepType.goalsStep);
  };

  return (
    <div className={s.segmentStep}>
      <div className={sn('content')}>
        <div className="font-bold text-lg text-neutral-900 mb-8 ">
          <div>Выберите или создайте сегмент</div>
          <Button variant="primary" className="ml-auto mt-6" type="button" onClick={addNewRow} disabled={hasDemoStatus}>
            <PlusIcon /> Добавить сегмент
          </Button>
        </div>
        {!tableRows?.length && (
          <>
            <h4 className={sn('subtitle')}>Для выбранного счётчика не найдено ни одного активного сегмента</h4>
            <p className={sn('paragraf')}>
              Добавьте сегмент для выбранного счётчика, чтобы можно было завершить настройку интеграции.
            </p>
          </>
        )}
        {!!tableRows?.length && (
          <DataTable<TableColumnsType>
            state={{pagination: {pageSize: 100, pageIndex: 0}}}
            columns={tableColumns}
            data={tableRows}
            meta={{removeRow, startEditingRow: setActiveEditingRow}}
          />
        )}
        <FormMessage className="mt-6">
          <span>{formState.errors.rows?.message || (formState.errors.rows?.root?.message as string)}</span>
        </FormMessage>
        {!yandexMetricaParameters?.isEnabled && (
          <>
            <Separator className="mt-12" />
            <CardActions className="w-60 ml-auto">
              <Button variant="outline" onClick={goBack} type="button" className="mt-12 block ml-auto">
                Назад
              </Button>
              <Button
                type="submit"
                form="settingsMetricaForm"
                className="mt-12"
                variant="primary"
                disabled={hasDemoStatus}
              >
                Сохранить
              </Button>
            </CardActions>
          </>
        )}
      </div>
      <Separator orientation="vertical" className={s.segmentStep__separator} />
      <InfoBlock />
    </div>
  );
};
