function safeParseFromBrowserStorage<ReturnV>(key: string, storage: Storage): ReturnV | null {
  try {
    const value = storage.getItem(key);
    if (value != null) {
      return JSON.parse(value) as ReturnV;
    }
    return null;
  } catch (e) {
    console.warn(`Invalid data in Browser's storage [${key}]`);
    return null;
  }
}

const cache = new Map<string, unknown>();

export function useBrowserStorageValue<V = unknown>(
  storage: Storage,
  {
    key,
    defaultValue,
    path,
    enabled = true,
  }: {
    key: string;
    path?: string;
    defaultValue: V;
    enabled?: boolean;
  },
) {
  const cacheKey = `${key},${path}`;

  const getStorageValue = <V>(storage: Storage, key: string, path?: string | undefined): V | null => {
    const parts = path?.split('.') ?? [];
    const storageValue = safeParseFromBrowserStorage(key, storage);
    return parts?.reduce((acc: V | null, cur: string) => {
      if (acc && (acc as any)?.[cur] !== null && (acc as any)?.[cur] !== undefined) return (acc as any)[cur] as V;
      return null;
    }, storageValue as V | null) as V;
  };

  const setStorageValue = <V>(storage: Storage, key: string, value: V, path?: string | undefined) => {
    try {
      const parts = path?.split('.') || [];
      let current: any;
      let valueToSave = (current = safeParseFromBrowserStorage(key, storage) || (path ? {} : null));
      if (path) {
        // if path exist and stored value not an object, override ls value
        if (typeof valueToSave !== 'object') valueToSave = current = {};
        parts.forEach((part, index) => {
          if (typeof current !== 'object') current = {};
          if (index !== parts.length - 1) {
            if (!current[part]) current[part] = {};
            current = current[part];
          } else {
            current[part] = value;
          }
        });
      } else {
        valueToSave = value;
      }
      storage.setItem(key, JSON.stringify(valueToSave));
    } catch (error) {
      console.warn(error);
    }
  };

  const setValue = (value: V) => {
    if (enabled) {
      setStorageValue(storage, key, value, path);
      cache.set(cacheKey, value);
    }
  };

  const getValue = () => {
    if (cache.has(cacheKey)) {
      return (cache.get(cacheKey) ?? defaultValue) as V;
    } else {
      const value = getStorageValue<V>(storage, key, path);
      cache.set(cacheKey, value);
      return value ?? defaultValue;
    }
  };

  return [getValue, setValue] as const;
}
