import {Bar, BarChart, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

import {ClipLoader} from 'shared/components/ClipLoader';

import {CustomTooltipContent} from '../../CustomTooltip';
import {useDashboardContext} from '../../DashboardContextProvider';
import {
  fittingString,
  getCustomizedYAxisTick,
  getDefaultTooltipCursorProps,
  getLegendDefaultProps,
  getXAxisDefaultProps,
  getXAxisPropsForEmptyChart,
  getYAxisDefaultProps,
} from '../../utils';

type Props<CData> = {
  data: CData extends Array<CData> ? CData : CData[];
  dataKey: keyof CData;
  labelKey: keyof CData;
  color: string;
  legendLabel: string;
  height?: number;
  className?: string;
  isLoading: boolean;
  infoMessage?: string;
  containerWidth?: number | null;
};

const DEFAULT_LABEL_WIDTH = 60;
const LABEL_MARGIN = 80;

export const VerticalBarChart = <CData,>({
  data,
  dataKey,
  labelKey,
  color,
  legendLabel,
  height = 335,
  className,
  isLoading,
  containerWidth,
}: Props<CData>) => {
  const labelFormatter = (value: any): string => `${value}${dataKey === 'percentage' ? '%' : ''}`;
  const {dateRange} = useDashboardContext();
  const isEmptyChart = data?.length === 0;
  const isDisabled = data.every((item) => item[dataKey] === null || item[dataKey] === 0);
  const labelWidth = containerWidth ? containerWidth * 0.5 - LABEL_MARGIN : DEFAULT_LABEL_WIDTH;

  const getXAxisProps = () => {
    if (isEmptyChart) return getXAxisPropsForEmptyChart(dateRange);
    return getXAxisDefaultProps();
  };

  const showData = isDisabled
    ? data.map((item) => {
      return {...item, [dataKey]: 0};
    })
    : data;

  if (isLoading) {
    return <ClipLoader />;
  }

  const CustomizedTick = ({x, y, payload}: {x: any; y: any; payload: any}) => {
    const text = fittingString(payload.value, labelWidth, 16);
    return (
      <svg width={labelWidth}>
        <text
          {...{x, y, payload}}
          textAnchor="start"
          dx={-x}
          dy={5}
          style={{stroke: 'none', fontSize: 16, fill: isDisabled ? '#C4C2DE' : '#101936'}}
        >
          <title>{payload.value}</title>
          {text}
        </text>
      </svg>
    );
  };

  const getYAxis = () => {
    if (isEmptyChart) {
      return <YAxis {...getYAxisDefaultProps()} tick={getCustomizedYAxisTick} dataKey="value" domain={[12, 0]} />;
    }

    return (
      <YAxis
        {...getYAxisDefaultProps()}
        yAxisId={0}
        stroke={isDisabled ? '#F0EFF9' : color}
        dataKey={labelKey as string}
        type="category"
        axisLine={true}
        tickLine={false}
        tick={CustomizedTick}
        tickMargin={dataKey === 'percentage' ? 70 : 40}
        strokeWidth={3}
        width={containerWidth ? containerWidth * 0.5 : undefined}
      />
    );
  };

  return (
    <ResponsiveContainer height={height} className={className}>
      <BarChart data={showData} layout="vertical" margin={{bottom: 18, top: 25}}>
        <XAxis hide axisLine={true} type="number" {...getXAxisProps()} />
        {getYAxis()}
        <Bar
          name={legendLabel}
          fill={isDisabled ? '#C4C2DE' : color}
          dataKey={dataKey as string}
          radius={[0, 7, 7, 0]}
          barSize={28}
        >
          <LabelList
            dataKey={dataKey as string}
            dx={-5}
            position="left"
            style={{fontSize: 16, fill: isDisabled ? '#C4C2DE' : '#101936'}}
            formatter={labelFormatter}
          />
        </Bar>
        {!isDisabled && !isEmptyChart && (
          <Tooltip content={<CustomTooltipContent title={null} />} cursor={getDefaultTooltipCursorProps()} />
        )}
        <Legend
          {...getLegendDefaultProps()}
          payload={[{value: legendLabel, type: 'circle', id: 'ID01', color: color}]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
