import axios, {AxiosError} from 'axios';

import {BasicApiError, FormFieldsApiError} from '../models/apiError';

export function isAxiosError<T>(payload: unknown): payload is AxiosError<T> {
  return axios.isAxiosError(payload);
}

export const UNEXPECTED_ERROR = 'Произошла ошибка при выполнении запроса. Попробуйте перезагрузить страницу.';

export function extractApiErrorMessage(
  error: unknown,
  unexpectedError = UNEXPECTED_ERROR,
): FormFieldsApiError | string {
  if (isAxiosError<BasicApiError>(error)) {
    const {response} = error;
    if (response && response.data) {
      const {data} = response;
      return data.errors ? data.errors : data.title;
    }
  }
  return unexpectedError;
}
