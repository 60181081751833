import {zodResolver} from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/browser';
import {useEffect, useRef, useState} from 'react';
import {Controller, FieldErrors, useForm, useWatch} from 'react-hook-form';

import {YandexApi} from 'api';
import {
  IntegrationTypeId,
  UpdatedYandexMetricaParametersDTO,
  YandexMetricaBaseModel,
  YandexMetricaSettingsViewDTO,
} from 'api/dto';
import {ClipLoader} from 'shared/components/ClipLoader';
import {NoIntegrationPlaceholder} from 'shared/components/NoIntegrationPlaceholder';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Card, CardContent, CardHeader, CardTitle} from 'shared/components/shadcn-ui/Card';
import {Form} from 'shared/components/shadcn-ui/Form';
import {Separator} from 'shared/components/shadcn-ui/Separator';
import {Switch} from 'shared/components/shadcn-ui/Switch';
import {toast} from 'shared/components/shadcn-ui/Toast/useToast';
import {useYandexIntegrationParametersContext} from 'shared/components/YandexOauth/YandexIntegrationProvider';
import {useCurrentCompany, useFullAccessRequest, useIntegrationStatuses} from 'shared/hooks';
import {FormFieldsApiError} from 'shared/models/apiError';
import {bem} from 'shared/utils';
import {extractApiErrorMessage} from 'shared/utils/axios';
import {safeFormatDate} from 'shared/utils/date';
import {handleApiErrors} from 'shared/utils/helpers';
import {useRootDispatch} from 'store';
import {companiesAsyncActions} from 'store/companies/actions';
import {YandexIntegrationStep} from 'typings/yandexOauth';

import {SettingsTabs} from './components/SettingsTabs';
import {StepType} from './components/SettingsTabs/utils/types';
import {SuccessIntegrationInfo} from './components/SuccessIntegrationInfo';
import {getDefaultValues} from './utils/helpers';
import {FormValuesType} from './utils/types';
import {FormSchema} from './utils/validationSchema';
import s from './YandexMetricaIntegration.module.scss';

const sn = bem('yandexMetricaIntegration', s);
const DATE_WITH_TIME_FORMAT = 'dd MMMM yyyy kk:mm';

export const YandexMetricaIntegration = () => {
  const [isToggling, setIsToggling] = useState(false);
  const {hasDemoStatus, company} = useCurrentCompany();
  const {sendRequest} = useFullAccessRequest();
  const initialized = useRef(false);
  const dispatch = useRootDispatch();
  const isYandexMetricaEnabled = useIntegrationStatuses(IntegrationTypeId.YandexMetrica)?.isEnabled;

  const [tabValue, setTabValue] = useState<StepType>(StepType.counterStep);

  const {
    isLoading,
    yandexParameters,
    integrationStep,
    setIntegrationStep,
    authenticated,
    init,
    error,
    refreshParameters,
    setIsLoading,
    deletedSegments,
    reset,
  } = useYandexIntegrationParametersContext();
  const yandexMetricaParameters = yandexParameters as YandexMetricaSettingsViewDTO;

  const savedCounterId = yandexMetricaParameters?.counters?.find((item) => item.isActive)?.id;
  const updateDate = yandexMetricaParameters?.updateDate;

  const form = useForm<FormValuesType>({
    resolver: zodResolver(FormSchema),
    defaultValues: getDefaultValues(yandexMetricaParameters),
  });
  const currentCounterId = useWatch({control: form.control, name: 'counterStatus.counterId'});

  const onSwitchIntegrationStatus = async (isEnabled: boolean) => {
    if (isToggling || !company?.id) return;
    setIsToggling(true);
    form.setValue('isEnabled', isEnabled);
    if (isEnabled) await init();
    setIsToggling(false);
    setIntegrationStep(YandexIntegrationStep.showSettings);
  };

  useEffect(() => {
    setIsToggling(false);
    if (authenticated) {
      refreshParameters();
      setIntegrationStep(YandexIntegrationStep.showSettings);
    }
    if (authenticated === false) {
      form.setValue('isEnabled', false);
      setIntegrationStep(YandexIntegrationStep.showPlaceholder);
      reset();
    }
  }, [authenticated]);

  useEffect(() => {
    if (!initialized.current && yandexMetricaParameters) {
      const {isEnabled: isEnabledFromForm} = form.getValues();
      form.reset(
        getDefaultValues({
          ...yandexMetricaParameters,
          isEnabled: !authenticated ? yandexMetricaParameters.isEnabled : isEnabledFromForm,
        }),
      );
      initialized.current = true;
    }
  }, [yandexMetricaParameters]);

  useEffect(() => {
    if (error) {
      toast({
        title: error.title ?? 'Произошла непредвиденная ошибка интеграции с Яндекс Метрикой.',
        variant: 'destructive',
      });
    }
  }, [error]);

  const setRemovedSegmentsError = () => {
    form.setError('rows', {
      type: 'value',
      message: 'Выбраны удалённые сегменты. Выберите другие или добавьте новые.',
    });
  };

  const setRemovedGoalsError = () => {
    form.setError('goals', {
      type: 'value',
      message: 'Выбраны удалённые цели. Выберите другие или добавьте новые.',
    });
  };

  const additionalValidate = (data: UpdatedYandexMetricaParametersDTO) => {
    const checkIsRemovedFromYandex = (
      source: Pick<YandexMetricaBaseModel, 'id' | 'isRemovedFromYandex'>[] | undefined,
      itemId: number,
    ): boolean => source?.some(({id, isRemovedFromYandex}) => isRemovedFromYandex && id === itemId) ?? false;

    // TODO Разобраться почему не работает console.log(form.getFieldState('counterStatus.counterId').isDirty);
    if (
      data.counterStatus.counterId === savedCounterId &&
      checkIsRemovedFromYandex(yandexMetricaParameters?.counters, data.counterStatus.counterId)
    ) {
      form.setError('counterStatus.counterId', {
        type: 'value',
        message: 'Выбран удалённый счётчик. Выберите другой или добавьте новый.',
      });
      setRemovedGoalsError();
      setRemovedSegmentsError();
      return false;
    }

    if (data.goals.every((itemId) => checkIsRemovedFromYandex(yandexMetricaParameters?.goals, itemId))) {
      setRemovedGoalsError();
      return false;
    }
    return true;
  };

  const setValidationErrors = (extractedError: FormFieldsApiError) => {
    if ('segments' in extractedError) {
      form.setError('rows', {
        message: extractedError.segments[0],
      });
      setTabValue(StepType.segmentStep);
    }
    if ('goals' in extractedError) {
      form.setError('goals', {
        message: extractedError.goals[0],
      });
      setTabValue(StepType.goalsStep);
    }
    if ('counterStatus' in extractedError || 'counterId' in extractedError) {
      form.setError('counterStatus.counterId', {
        message: extractedError.counterStatus[0],
      });
      setTabValue(StepType.counterStep);
    }
  };

  const setError = (error: unknown) => {
    Sentry.captureException(error);
    const extractedError = extractApiErrorMessage(error);
    if (typeof extractedError === 'string') {
      toast({
        variant: 'destructive',
        title: extractedError,
      });
    } else {
      setValidationErrors(extractedError);
      handleApiErrors(error);
    }
  };

  const save = async (data: UpdatedYandexMetricaParametersDTO) => {
    if (!company?.id) return;
    setIsLoading(true);
    try {
      await YandexApi.setYandexMetricaCounters(company.id, data);

      dispatch(companiesAsyncActions.fetchIntegrationsStatuses());
      toast({
        title: 'Изменения успешно сохранены.',
      });
      const {segments, ...restValues} = data;
      form.reset({...restValues, rows: form.getValues().rows});
      reset();
      setIntegrationStep(YandexIntegrationStep.showSuccess);
    } catch (error) {
      Sentry.captureException(error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const submit = async (formData: FormValuesType) => {
    const {rows, ...values} = formData;
    // нужно явно указывать состояние для ВСЕХ сегментов, т.е. добавить isActive=false для сегментов, не относящихся к выбранному счетчику
    const segmentsOfOtherCounters =
      yandexMetricaParameters?.segments
        .map((item) => ({...item, isActive: false}))
        .filter((item) => item.counterId !== currentCounterId) ?? [];
    const model = {...values, segments: (rows || []).concat(deletedSegments, segmentsOfOtherCounters)};
    if (additionalValidate(model)) await save(model);
  };

  const getCardHeader = () => (
    <CardHeader className="justify-start items-center h-10 mb-2">
      <div className={sn('switch')}>
        <Controller
          control={form.control}
          render={({field}) => (
            <Switch
              checked={hasDemoStatus || field.value}
              onCheckedChange={hasDemoStatus ? sendRequest : onSwitchIntegrationStatus}
              disabled={isToggling}
              title={hasDemoStatus ? 'Вы используете демодоступ.' : ''}
            />
          )}
          name="isEnabled"
        />

        {isToggling && <ClipLoader className="max-h-6 w-6" size={24} />}
      </div>
      <CardTitle className="leading-[24px] basis-[1fr] mr-auto">Яндекс Метрика</CardTitle>
      {!!updateDate && isYandexMetricaEnabled && (
        <span className={sn('date')}>
          Сохранено {safeFormatDate(updateDate, DATE_WITH_TIME_FORMAT).replaceAll(' ', '\u00A0')}
        </span>
      )}
      {yandexMetricaParameters?.isEnabled &&
        integrationStep === YandexIntegrationStep.showSettings &&
        (authenticated || isYandexMetricaEnabled) && (
        <Button type="submit" form="settingsMetricaForm" variant="primary" disabled={hasDemoStatus}>
            Сохранить
        </Button>
      )}
    </CardHeader>
  );

  const getCardContent = () => {
    switch (integrationStep) {
      case YandexIntegrationStep.showPlaceholder:
        return (
          <NoIntegrationPlaceholder
            disabled={isToggling}
            title="Интеграция с Яндекс Метрикой не подключена"
            onStart={() => onSwitchIntegrationStatus(true)}
          />
        );
      case YandexIntegrationStep.showSuccess:
        return <SuccessIntegrationInfo />;
      case YandexIntegrationStep.showSettings:
        return yandexMetricaParameters && (authenticated || isYandexMetricaEnabled) ? (
          <SettingsTabs tabValue={tabValue} setTabValue={setTabValue} />
        ) : null;
      default:
        return null;
    }
  };

  const onErrorSubmit = (errors: FieldErrors<FormValuesType>) => {
    if (errors.counterStatus || errors.isCareerProfile) {
      setTabValue(StepType.counterStep);
    } else if (errors.goals) {
      setTabValue(StepType.goalsStep);
    } else if (errors.rows) {
      setTabValue(StepType.segmentStep);
    }
    form.trigger();
  };

  return (
    <Card className="p-14 mt-0 w-full min-h-[320px] relative">
      {getCardHeader()}
      <Separator />
      <CardContent className="mt-8 min-h-[350px]">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(submit, onErrorSubmit)} className="w-full" id="settingsMetricaForm">
            {getCardContent()}
          </form>
        </Form>
      </CardContent>
      {isLoading && (
        <div className={sn('overlay')}>
          <ClipLoader className="max-h-[320px]" />
        </div>
      )}
    </Card>
  );
};
