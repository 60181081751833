import {zodResolver} from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/browser';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Link, Navigate, useLocation, useNavigate} from 'react-router-dom';
import * as z from 'zod';

import {CompaniesApi} from 'api';
import {CompanyStatus} from 'api/dto';
import {NewCompanyUserFields} from 'shared/components/NewCompanyUserFields';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Card, CardHeader} from 'shared/components/shadcn-ui/Card';
import {CardActions} from 'shared/components/shadcn-ui/Card/Card';
import {Form, FormControl, FormField, FormItem, FormLabel} from 'shared/components/shadcn-ui/Form';
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from 'shared/components/shadcn-ui/Select';
import {Separator} from 'shared/components/shadcn-ui/Separator';
import {toast} from 'shared/components/shadcn-ui/Toast/useToast';
import {routes} from 'shared/constants/routes';
import {useCurrentCompany} from 'shared/hooks';
import {setValidationFormErrors} from 'shared/utils';
import {extractApiErrorMessage} from 'shared/utils/axios';
import {getRawPhoneNumber, handleApiErrors} from 'shared/utils/helpers';
import {useRootDispatch, useRootSelector} from 'store';
import {companiesAsyncActions} from 'store/companies/actions';
import {dictionariesSelector} from 'store/dictionaries/selectors';

import s from './CompanyUserEditForm.module.scss';
import {getDefaultValues} from './helpers';
import {CompanyUserEditFormSchema} from './validationSchema';

export const CompanyUserEditForm = () => {
  const navigate = useNavigate();
  const {company} = useCurrentCompany();
  const {state} = useLocation();
  const id = state?.userId ?? null;
  const userStatuses = useRootSelector(dictionariesSelector.getDictionaries).userStatuses;
  const dispatch = useRootDispatch();
  const user = company?.users.find(({userId}) => userId === Number(id));
  const companyStatusName = company?.statusName;

  const form = useForm<z.infer<typeof CompanyUserEditFormSchema>>({
    resolver: zodResolver(CompanyUserEditFormSchema),
    defaultValues: getDefaultValues(user),
  });

  useEffect(() => {
    if (user) form.reset(getDefaultValues(user));
  }, [user]);

  const onSubmit = async (data: z.infer<typeof CompanyUserEditFormSchema>) => {
    const {phoneNumber, statusId, ...rest} = data;
    if (company) {
      try {
        await CompaniesApi.updateCompanyUser(
          company?.id,
          id,
          Object.assign(rest, {
            statusId: Number(statusId),
            phoneNumber: phoneNumber ? getRawPhoneNumber(phoneNumber) : undefined,
          }),
        );
        dispatch(companiesAsyncActions.fetchCurrentCompany());
        toast({
          description: `Изменения для пользователя ${rest.firstName} ${rest.lastName} сохранены.`,
        });
        navigate(routes.companyUsersSettings);
      } catch (error) {
        Sentry.captureException(error);
        const extractedError = extractApiErrorMessage(error);
        if (typeof extractedError === 'string') {
          handleApiErrors(error);
        } else {
          setValidationFormErrors<typeof data>(extractedError, (fieldName, fieldErrors) => {
            form.setError(fieldName, {
              message: fieldErrors[0],
            });
          });
        }
      }
    }
  };

  const cancel = () => {
    navigate(routes.companyUsersSettings);
  };

  if (!(companyStatusName === CompanyStatus.Active || companyStatusName === CompanyStatus.UnActive)) {
    return <Navigate replace to={routes.settings} />;
  }

  return (
    <Card className="flex items-start gap-x-10">
      <CardHeader className="font-bold basis-[30%]">Данные пользователя</CardHeader>
      <Form {...form}>
        <form id="companyUserForm" onSubmit={form.handleSubmit(onSubmit)} className={s.companyUserEditForm}>
          <NewCompanyUserFields />
          <FormField
            control={form.control}
            name="statusId"
            render={({field}) => (
              <FormItem className="h-[92px] basis-72">
                <FormLabel>Статус учетной записи</FormLabel>
                <FormControl>
                  <Select onValueChange={field.onChange} value={String(field.value)}>
                    <SelectTrigger className="text-base">
                      <SelectValue placeholder={user?.statusName} />
                    </SelectTrigger>
                    <SelectContent>
                      {userStatuses?.map(({value, description}) => (
                        <SelectItem key={`${description}_${value}`} value={String(value)}>
                          {description}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          />
          <Separator className="mt-4" />
          <CardActions className="w-[300px] mt-4 mr-0 ml-auto">
            <Button type="button" variant="outline" className="grow-[2]" onClick={cancel}>
              Отмена
            </Button>
            <Button type="submit" variant="primary" disabled={form.formState.isSubmitting}>
              {form.formState.isSubmitting ? 'Загрузка...' : 'Сохранить и закрыть'}
            </Button>
          </CardActions>
        </form>
      </Form>
      <Link to={routes.settings} state={{tab: 'users'}} />
    </Card>
  );
};
