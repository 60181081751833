import {ru} from 'date-fns/locale';
import {CalendarDaysIcon, ChevronLeft, ChevronRight} from 'lucide-react';
import * as React from 'react';
import {cloneElement, FC, isValidElement, useState} from 'react';
import {ActiveModifiers, DateRange, DayClickEventHandler, DayPicker} from 'react-day-picker';

import {cn} from 'shared/utils/helpers';

import s from './Calendar.module.scss';

import {DEFAULT_DATE_FORMAT, safeFormatDate} from '../../../utils/date';
import {Button, buttonVariants} from '../Button';
import {Popover, PopoverContent, PopoverTrigger} from '../Popover';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

const IconLeft = ({...props}) => <ChevronLeft {...props} />;
const IconRight = ({...props}) => <ChevronRight {...props} />;

function Calendar({className, classNames, showOutsideDays = true, ...props}: CalendarProps) {
  return (
    <DayPicker
      locale={ru}
      showOutsideDays={showOutsideDays}
      className={cn('p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4 capitalize',
        caption: 'flex justify-start pt-1 relative items-center',
        caption_label: 'text-m font-medium pl-2', // TODO смогла стилизовать только месяц и год. Нужно сделать год светлее. Вероятно, что это можно сделать только JS
        nav: 'space-x-1 flex items-center',
        nav_button: 'h-6 w-6 bg-transparent p-0',
        nav_icon: 'h-6 w-6',
        nav_button_previous: 'absolute right-8 text-neutral',
        nav_button_next: 'absolute right-1 text-neutral',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell: 'text-muted-foreground  w-8 font-normal text-[0.8rem] mx-1 text-neutral-400',
        row: 'flex w-full mt-2',
        cell: 'text-center mx-1 text-sm p-0 relative [&:has([aria-selected])]:bg-accent   [&:has([aria-selected])]:text-neutral-900 focus-within:outline-0 ',
        day: cn(
          buttonVariants({variant: 'ghost'}),
          'h-8 w-8 p-0 font-normal aria-selected:opacity-100 focus-within:outline-0 focus:ring-0',
        ),
        day_selected:
          'bg-primary-300 rounded-md text-neutral-0 hover:bg-primary-300 hover:text-neutral-0 focus:bg-primary-300 focus:text-neutral-0',
        day_today:
          'text-accent-neutral-0 rounded-full border border-primary-100 aria-selected:rounded rounded-[50%]  aria-selected:border-0',
        day_outside: 'text-muted-neutral-0 opacity-50',
        day_disabled: 'text-muted-neutral-0 opacity-50',
        day_range_middle: 'aria-selected:bg-neutral-100 rounded-md aria-selected:text-neutral-900 ',
        tfoot: s.footer,
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        IconLeft,
        IconRight,
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

type PopoverCalendarProps = {
  popoverTriggerClassNames?: string;
  isLoading?: boolean;
  withPortal?: boolean;
  isDisabled?: boolean;
  autoClosing?: boolean;
  onDayClick?: DayClickEventHandler;
} & CalendarProps;

const PopoverCalendar: FC<PopoverCalendarProps> = ({
  popoverTriggerClassNames,
  isLoading = false,
  withPortal = true,
  isDisabled = false,
  autoClosing = false,
  onDayClick,
  footer,
  ...calendarProps
}: PopoverCalendarProps) => {
  const [isToggled, setIsToggled] = useState(false);
  const toggle = () => setIsToggled((prev) => !prev);

  const handleDayClick = (day: Date, activeModifiers: ActiveModifiers, e: React.MouseEvent) => {
    if (onDayClick) onDayClick(day, activeModifiers, e);
    if (autoClosing) toggle();
  };

  const getLabelText = (): string => {
    const {selected} = calendarProps;
    const SELECT_DATE_STR = 'Выберите дату';

    if (selected === undefined) return SELECT_DATE_STR;

    if (calendarProps.mode === 'single') {
      return selected ? safeFormatDate(selected as Date, DEFAULT_DATE_FORMAT) : SELECT_DATE_STR;
    }

    if (calendarProps.mode === 'multiple') {
      return (selected as Date[]).length
        ? (selected as Date[]).map((d: Date) => safeFormatDate(d, DEFAULT_DATE_FORMAT)).join(', ')
        : SELECT_DATE_STR;
    }

    if (calendarProps.mode === 'range') {
      if ((selected as DateRange)?.to && (selected as DateRange)?.from) {
        return `${safeFormatDate((selected as unknown as DateRange).from)} &mdash; ${safeFormatDate(
          (selected as DateRange).to,
        )}`;
      }
    }

    return SELECT_DATE_STR;
  };

  return (
    <Popover open={isToggled} onOpenChange={toggle}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className={cn('w-[200px] pl-3 text-base font-normal', popoverTriggerClassNames)}
          disabled={isLoading || isDisabled}
        >
          <span dangerouslySetInnerHTML={{__html: getLabelText()}} />
          <CalendarDaysIcon className="ml-auto" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start" withPortal={withPortal}>
        <Calendar
          {...calendarProps}
          onDayClick={handleDayClick}
          footer={
            isValidElement(footer)
              ? cloneElement(footer, {onAfterSubmit: () => setIsToggled(false), ...footer.props})
              : footer
          }
        />
      </PopoverContent>
    </Popover>
  );
};
export {Calendar, PopoverCalendar};
