import {EditIcon} from 'lucide-react';
import {patternFormatter} from 'react-number-format';
import {generatePath, useNavigate} from 'react-router-dom';

import {FieldBox} from 'shared/components/FieldBox';
import {Avatar, AvatarFallback} from 'shared/components/shadcn-ui/Avatar';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Card, CardContent, CardHeader, CardTitle} from 'shared/components/shadcn-ui/Card';
import {CardActions} from 'shared/components/shadcn-ui/Card/Card';
import {routes} from 'shared/constants/routes';
import {useCurrentCompany} from 'shared/hooks';

import s from './CompanyAdminCard.module.scss';
import {ReplaceAdminButton} from './ReplaceAdminButton';

import {AdminEmailConfirmation} from '../AdminEmailConfirmation';

export const CompanyAdminCard = () => {
  const {company} = useCurrentCompany();
  const navigate = useNavigate();

  if (!company?.adminInfo) {
    return null;
  }

  function getAdminInitials(name: string) {
    const [firstName, lastName] = name.split(' ');
    return firstName[0] + lastName[0];
  }

  const startEdit = () => {
    if (company?.adminInfo) {
      navigate(generatePath(routes.companyUser, {compId: company.id, userId: company.adminInfo?.userId}));
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Администратор</CardTitle>
        <CardActions>
          <>
            <Button variant="outline" size="icon" onClick={startEdit}>
              <EditIcon />
            </Button>
            <ReplaceAdminButton />
          </>
        </CardActions>
      </CardHeader>
      <CardContent>
        <div className={s.companyAdminCard}>
          <div className={s.companyAdminBar}>
            <Avatar>
              <AvatarFallback>{getAdminInitials(company?.adminInfo.fio)}</AvatarFallback>
            </Avatar>
            <div className={s.adminEmailAndName}>
              <span className={s.adminEmailAndName__email}>{company?.adminInfo.email}</span>
              <span className={s.adminEmailAndName__name}>{company?.adminInfo.fio}</span>
            </div>
          </div>
          <AdminEmailConfirmation className={s.emailConfirmationAlert} admin={company?.adminInfo} />
          <div className={s.companyAdminContactData}>
            <FieldBox
              className={s.companyAdminContactData__fieldBox}
              label="Контактный телефон"
              value={patternFormatter(company?.adminInfo.phoneNumber, {format: '+# (###) #### ###'}) || 'Не указан'}
            />
            <FieldBox
              className={s.companyAdminContactData__fieldBox}
              label="Должность"
              value={company?.adminInfo.jobTitle}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
