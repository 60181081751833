import {Link, useMatch} from 'react-router-dom';

import {CompanyStatus} from 'api/dto';
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
  navigationMenuTriggerStyle,
  ACTIVE_LINK_STYLE,
} from 'shared/components/shadcn-ui/NavigationMenu';
import {routes} from 'shared/constants/routes';
import {useCurrentCompany, useEffectOnce} from 'shared/hooks';
import {cn} from 'shared/utils/helpers';
import {useRootSelector} from 'store';

import {useSettingsContext} from '../SettingsContext';

export const Menu = () => {
  const isProfilePage = useMatch({path: routes.profileSettings});
  const isCompanyPage = useMatch({path: routes.companySettings, end: true});
  const isUsersPage = useMatch({path: routes.companyUsersSettings, end: false});
  const isSourcePage = useMatch({path: routes.sourcesSettings, end: false});
  const {showTitle} = useSettingsContext();
  const companyStatusName = useRootSelector((state) => state.companies.currentCompany)?.statusName;
  const {hasDemoStatus, hasActiveCompanyStatus} = useCurrentCompany();

  const canShowUsers = hasActiveCompanyStatus || companyStatusName === CompanyStatus.UnActive || hasDemoStatus;

  const canShowSources = hasActiveCompanyStatus || hasDemoStatus;

  useEffectOnce(() => {
    showTitle();
  });
  return (
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationMenuItem>
          <Link
            to={routes.profileSettings}
            className={cn(navigationMenuTriggerStyle(), {[ACTIVE_LINK_STYLE]: isProfilePage})}
          >
            Мой профиль
          </Link>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <Link
            to={routes.companySettings}
            className={cn(navigationMenuTriggerStyle(), {[ACTIVE_LINK_STYLE]: isCompanyPage})}
          >
            Компания
          </Link>
        </NavigationMenuItem>
        {canShowUsers && (
          <NavigationMenuItem>
            <Link
              to={routes.companyUsersSettings}
              className={cn(navigationMenuTriggerStyle(), {[ACTIVE_LINK_STYLE]: isUsersPage})}
            >
              Пользователи
            </Link>
          </NavigationMenuItem>
        )}
        {canShowSources && (
          <NavigationMenuItem>
            <Link
              to={routes.sourcesSettings}
              className={cn(navigationMenuTriggerStyle(), {[ACTIVE_LINK_STYLE]: isSourcePage})}
            >
              Источники данных
            </Link>
          </NavigationMenuItem>
        )}
      </NavigationMenuList>
    </NavigationMenu>
  );
};
