import {Navigate, Outlet} from 'react-router';

import {AccountRoles} from 'api/dto';
import {routes} from 'shared/constants/routes';
import {useMountedState} from 'shared/hooks/useMounted';
import {useRootSelector} from 'store';

import {useAuth} from '../AuthProvider';

/* Redirect if user already authenticated, check run only before first mount */
export const UnauthenticatedRoute = () => {
  const {isAuth} = useAuth();
  const user = useRootSelector((state) => state.auth.user);
  const isMounted = useMountedState();
  if (!isMounted() && isAuth) {
    const redirectPath = user?.systemRoleName === AccountRoles.superAdmin ? routes.companies : routes.home;
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};
