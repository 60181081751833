export const getBadgeVariant = (id: number) => {
  switch (id) {
    case 1:
      return 'active';
    case 2:
      return 'unactive';
    case 3:
      return 'archive';
    default:
      return null;
  }
};
