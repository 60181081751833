import {Dispatch, FC, SetStateAction, useEffect, useMemo} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';

import {YandexMetricaSettingsViewDTO} from 'api/dto';
import {Button} from 'shared/components/shadcn-ui/Button';
import {CardDescription} from 'shared/components/shadcn-ui/Card';
import {Tabs, TabsContent, TabsList, TabsTrigger} from 'shared/components/shadcn-ui/Tabs';
import {useYandexIntegrationParametersContext} from 'shared/components/YandexOauth/YandexIntegrationProvider';
import {useCurrentCompany} from 'shared/hooks';

import {CounterStep} from './components/CounterStep';
import {GoalsStep} from './components/GoalsStep';
import {SegmentStep} from './components/SegmentStep';
import {StepType} from './utils/types';

type Props = {tabValue: string; setTabValue: Dispatch<SetStateAction<StepType>>};

export const SettingsTabs: FC<Props> = ({tabValue, setTabValue}) => {
  const {yandexParameters} = useYandexIntegrationParametersContext();
  const yandexMetricaParameters = yandexParameters as YandexMetricaSettingsViewDTO;

  const {hasDemoStatus} = useCurrentCompany();
  const isCareerProfile = yandexMetricaParameters?.isCareerProfile;
  const {formState, control} = useFormContext();
  const hasCareerProfile = useWatch({control, name: 'isCareerProfile', defaultValue: isCareerProfile});

  useEffect(() => {
    if (formState.errors?.counterStatus) {
      setTabValue(StepType.counterStep);
      return;
    }
    if (formState.errors?.goals) {
      setTabValue(StepType.goalsStep);
    }
  }, [formState.errors]);

  const getSubmitButton = useMemo(() => {
    if (!hasCareerProfile && tabValue !== StepType.segmentStep) return null;

    return (
      <Button type="submit" form="settingsMetricaForm" className="mt-12" variant="primary" disabled={hasDemoStatus}>
        Сохранить
      </Button>
    );
  }, [hasCareerProfile, hasDemoStatus, tabValue]);

  return (
    <>
      <CardDescription className="font-bold text-lg  text-neutral-900 mb-[33px] ">Настройка интеграции</CardDescription>
      <Tabs
        onValueChange={(value) => {
          setTabValue(value as StepType);
        }}
        value={tabValue}
      >
        <TabsList className="bg-neutral-200 w-full flex ">
          <TabsTrigger value={StepType.counterStep} className="data-[state=active]:bg-neutral-0 basis-2/6 grow">
            {yandexMetricaParameters ? 'Счётчик' : 'Шаг 1. Счётчик'}
          </TabsTrigger>
          <TabsTrigger value={StepType.goalsStep} className="data-[state=active]:bg-neutral-0 basis-2/6 grow">
            {yandexMetricaParameters ? 'Цели' : 'Шаг 2. Цели'}
          </TabsTrigger>
          {!hasCareerProfile && (
            <TabsTrigger value={StepType.segmentStep} className="data-[state=active]:bg-neutral-0 basis-2/6">
              {yandexMetricaParameters ? 'Сегмент' : ' Шаг 3. Сегмент'}
            </TabsTrigger>
          )}
        </TabsList>

        <div className="w-full">
          <TabsContent value={StepType.counterStep}>
            <CounterStep setTabValue={setTabValue} />
          </TabsContent>
          <TabsContent value={StepType.goalsStep}>
            <GoalsStep setTabValue={setTabValue} submitButton={getSubmitButton} />
          </TabsContent>
          <TabsContent value={StepType.segmentStep}>
            <SegmentStep setTabValue={setTabValue} />
          </TabsContent>
        </div>
      </Tabs>
    </>
  );
};
