import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import {useDispatch, useSelector, TypedUseSelectorHook} from 'react-redux';
import {createLogger} from 'redux-logger';

import {authReducer} from './auth';
import {companiesReducer} from './companies';
import {dictionariesReducer} from './dictionaries';

const store = configureStore({
  reducer: {
    auth: authReducer,
    companies: companiesReducer,
    dictionaries: dictionariesReducer,
  },
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware();
    if (process.env.NODE_ENV === 'production') {
      return middleware;
    }
    return middleware.concat(createLogger({collapsed: true}));
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;
export type RootThunk<R = unknown> = ThunkAction<R, RootState, unknown, Action>;

export function useRootDispatch() {
  return useDispatch<RootDispatch>();
}
export const useRootSelector: TypedUseSelectorHook<RootState> = (...args) => {
  return useSelector(...args);
};
export default store;
