import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {AllIntegrationsStatusesDTO, CompanyDetailsDTO} from 'api/dto';
import {authAsyncActions} from 'store/auth/actions';

import {fetchCurrentCompany, fetchIntegrationsStatuses} from './actions';

export type CompaniesStore = {
  currentCompany: CompanyDetailsDTO | null;
  currentCompanyId: string | number;
  integrationsStatuses: AllIntegrationsStatusesDTO['integrationStatuses'] | null;
  loaded: boolean;
};

const initialState: CompaniesStore = {
  currentCompanyId: '',
  currentCompany: null,
  integrationsStatuses: null,
  loaded: false,
};

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCurrentCompany: (state, {payload}: PayloadAction<CompanyDetailsDTO | null>) => {
      state.currentCompany = payload;
    },
    setCurrentCompanyId: (state, {payload}: PayloadAction<string | number>) => {
      state.currentCompanyId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentCompany.pending, (state) => {
      state.loaded = true;
    });
    builder.addCase(fetchCurrentCompany.fulfilled, (state, {payload}) => {
      state.currentCompany = payload;
      state.loaded = false;
    });
    builder.addCase(fetchIntegrationsStatuses.fulfilled, (state, {payload}) => {
      state.integrationsStatuses = payload.integrationStatuses;
    });
    builder.addCase(authAsyncActions.signOut.fulfilled, (state) => {
      return initialState;
    });
  },
});

export const {actions: companiesActions, reducer: companiesReducer} = companiesSlice;
