import {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';

import {YandexIntegrationType, YandexMetricaSettingsViewDTO} from 'api/dto';
import {Button} from 'shared/components/shadcn-ui/Button';
import {CardActions} from 'shared/components/shadcn-ui/Card/Card';
import {FormField, FormMessage} from 'shared/components/shadcn-ui/Form';
import {Label} from 'shared/components/shadcn-ui/Label';
import {RadioGroup, RadioGroupItem} from 'shared/components/shadcn-ui/RadioGroup';
import {Separator} from 'shared/components/shadcn-ui/Separator';
import {Switch} from 'shared/components/shadcn-ui/Switch';
import {RefreshDescription} from 'shared/components/YandexOauth/RefreshDescription';
import {useYandexIntegrationParametersContext} from 'shared/components/YandexOauth/YandexIntegrationProvider';
import {useCurrentCompany} from 'shared/hooks';
import {bem} from 'shared/utils';
import {cn} from 'shared/utils/helpers';

import {CounterError} from './components/CounterError';
import {InfoBlock} from './components/InfoBlock';
import s from './CounterStep.module.scss';

import {FormValuesType} from '../../../../utils/types';
import {StepType} from '../../utils/types';

type Props = {
  setTabValue: Dispatch<SetStateAction<StepType>>;
};

const sn = bem('counterStep', s);

export const CounterStep: FC<Props> = ({setTabValue}) => {
  const {yandexParameters} = useYandexIntegrationParametersContext();
  const counters = (yandexParameters as YandexMetricaSettingsViewDTO)?.counters ?? [];
  const form = useFormContext<FormValuesType>();
  const selectedCounterId = Number(useWatch({control: form.control, name: 'counterStatus.counterId'}));
  const withAvailableCounters = counters.find((item) => !item.isRemovedFromYandex);
  const {hasDemoStatus} = useCurrentCompany();

  const onValueChangeCounter = (value: string) => {
    const values = {...form.getValues()};
    const {counterStatus, ...restValues} = values;
    form.reset({
      ...restValues,
      goals: (yandexParameters as YandexMetricaSettingsViewDTO)?.goals
        .filter((item) => item.isActive && item.counterId === Number(value))
        .map((item) => item.id),
      counterStatus: {counterId: Number(value)},
      rows:
        (yandexParameters as YandexMetricaSettingsViewDTO)?.segments.filter(
          (item) => item.counterId === Number(value),
        ) ?? [],
    });
  };

  const submit = () => {
    setTabValue(StepType.goalsStep);
  };

  const getContent = () => {
    if (!counters.length)
      return (
        <>
          <CounterError />
          <FormMessage className="absolute top-[530px]">
            <span>{form.formState.errors.counterStatus?.counterId?.message}</span>
          </FormMessage>
        </>
      );

    const children = (
      <>
        <FormField
          name="counterStatus"
          render={({field}) => (
            <RadioGroup defaultValue={field.value} onValueChange={onValueChangeCounter} className="gap-3 mt-6">
              {counters.map((counter) => {
                return (
                  <FormField
                    control={form.control}
                    name={'counterStatus.counterId'}
                    key={`key_${counter.yandexId}`}
                    render={() => {
                      return (
                        <div className="flex items-center space-x-2 ">
                          <RadioGroupItem
                            value={counter.id.toString()}
                            id={`counter_${counter.id}`}
                            disabled={counter.isRemovedFromYandex || hasDemoStatus}
                            checked={counter.id === selectedCounterId}
                          />
                          <Label
                            className={cn('text-base', {['opacity-70']: counter.isRemovedFromYandex})}
                            htmlFor={`counter_${counter.id}`}
                          >
                            {counter.name}
                          </Label>
                        </div>
                      );
                    }}
                  />
                );
              })}
            </RadioGroup>
          )}
        />
        <FormMessage className="mt-6">
          <span>
            {
              withAvailableCounters && !selectedCounterId
                ? form.formState.errors.counterStatus?.counterId
                  ?.message /* сюда попадает ошибка из схемы валидации FormSchema */
                : form.formState.errors.counterStatus
                  ?.message /* сюда попадает ошибка о том что выбранный счетчик удален */
            }
          </span>
        </FormMessage>
        <FormField
          control={form.control}
          name="isCareerProfile"
          render={({field}) => (
            <div className={sn('switch')}>
              <Switch checked={field.value} onCheckedChange={field.onChange} id={field.name} disabled={hasDemoStatus} />
              <Label className="text-base" htmlFor={field.name}>
                У меня есть карьерный сайт
              </Label>
            </div>
          )}
        />
        {!yandexParameters?.isEnabled && (
          <>
            <Separator className="mt-12" />
            <CardActions className="ml-auto justify-end">
              <Button variant="primary" type="button" onClick={submit} className="m-0 mt-12">
                Далее
              </Button>
            </CardActions>
          </>
        )}
      </>
    );

    if (!withAvailableCounters) {
      return <CounterError>{children}</CounterError>;
    }

    return (
      <>
        <RefreshDescription description="Выберите счётчик" disabled={hasDemoStatus} />
        {children}
      </>
    );
  };

  return (
    <div className={s.counterStep}>
      <div className={sn('content')}>{getContent()}</div>
      <Separator orientation="vertical" className={s.counterStep__separator} />
      <InfoBlock />
    </div>
  );
};
