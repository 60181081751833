import {FC, memo} from 'react';
import {Cell, Label, Pie, PieChart, ResponsiveContainer} from 'recharts';

import {RecruitmentMetricsDTO} from 'api/dto';
import {cn} from 'shared/utils/helpers';

import s from './GeneralStaffingLevelsChart.module.scss';

import {ClipLoader} from '../../../ClipLoader';
import {NoDataPlaceholder} from '../../NoDataPlaceholder';

type Props = {
  totalStaffingLevelPercentage: RecruitmentMetricsDTO['charts']['totalStaffingLevelPercentage'];
  isLoading: boolean;
  className?: string;
  infoMessage?: string;
};

export const GeneralStaffingLevelsChart: FC<Props> = memo(
  ({totalStaffingLevelPercentage, className = '', isLoading, infoMessage}) => {
    const isEmptyChart = !totalStaffingLevelPercentage;

    if (isLoading) {
      return <ClipLoader containerHeight={250} />;
    }
    if (infoMessage) {
      return <NoDataPlaceholder height={250} text={infoMessage} />;
    }

    const preparedData = [
      {
        name: 'не хватает',
        value: Math.floor(100 - (totalStaffingLevelPercentage ?? 0)),
        color: isEmptyChart ? '#F7F8FA' : '#D8E5F0',
      },
      {
        name: 'укомплектованность',
        value: isEmptyChart ? 360 : totalStaffingLevelPercentage,
        color: isEmptyChart ? '#F0EFF9' : '#0066FF',
      },
    ];

    return (
      <>
        <div className={cn(s.generalStaffingChart, className)}>
          {!isEmptyChart && <span>{totalStaffingLevelPercentage}% / 100%</span>}
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                startAngle={-270}
                dataKey="value"
                data={preparedData}
                cx="50%"
                cy="50%"
                cornerRadius={10}
                outerRadius="97"
                innerRadius="62"
                paddingAngle={-5}
                labelLine={false}
              >
                {!isEmptyChart && (
                  <Label
                    value={`${totalStaffingLevelPercentage}%`}
                    position="center"
                    fill="#101936"
                    style={{fontSize: 30, fontWeight: 600, letterSpacing: '-0.225px', fill: '#101936'}}
                  />
                )}
                {preparedData.map((entry) => (
                  <Cell key={`cell-${entry.value}`} fill={entry.color} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </>
    );
  },
);
