import {AlertTriangle, Check} from 'lucide-react';

import {cn} from 'shared/utils/helpers';

import {Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport} from './Toast';
import s from './Toaster.module.scss';
import {useToast} from './useToast';

export function Toaster() {
  const {toasts} = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({id, title, description, action, variant, ...props}) {
        return (
          <Toast key={id} {...props} variant={variant}>
            <div
              className={cn(
                s.toaster__icon,
                {[s.toaster__icon_error]: variant === 'destructive'},
                {[s.toaster__icon_check]: variant !== 'destructive'},
              )}
            >
              {variant === 'destructive' ? (
                <AlertTriangle className="h-3.5 w-3.5 text-error" />
              ) : (
                <Check className="h-3.5 w-3.5 text-white rounded-[50%] bg-success-600" />
              )}
            </div>
            <div className="w-[310px]">
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && <ToastDescription>{description}</ToastDescription>}
            </div>
            {action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
