import ApiClient from 'api/client';
import {HiringExpensesDTO, HiringExpensesDataDTO} from 'api/dto';

class CompanyOperationsService {
  private getBasePath(compId: string | number): string {
    return `company-operations/${compId}/operations/hiring-expenses`;
  }

  setHiringExpense(compId: string | number, payload: Omit<HiringExpensesDTO, 'id'>[]) {
    return ApiClient.post(this.getBasePath(compId), payload).then((res) => res.data);
  }
  getHiringExpensesData(compId: string | number, year?: number) {
    return ApiClient.get<HiringExpensesDataDTO>(this.getBasePath(compId), {
      params: {
        year: year || undefined,
      },
    }).then((res) => res.data);
  }
  deleteHiringExpensesList(companyId: string | number, ids: number[]) {
    return ApiClient.delete(this.getBasePath(companyId), {data: ids}).then((res) => res.data);
  }
}

export const CompanyOperationsApi = new CompanyOperationsService();
