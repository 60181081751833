import {zodResolver} from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/browser';
import {createColumnHelper} from '@tanstack/react-table';
import {formatISO} from 'date-fns';
import {Coins} from 'lucide-react';
import {FC, useEffect, useMemo, useState} from 'react';
import {useFieldArray, useForm} from 'react-hook-form';
import {z} from 'zod';

import {CompanyOperationsApi} from 'api';
import {HiringExpensesDataDTO} from 'api/dto';
import {MetricCard} from 'shared/components/Dashboards';
import {DataTable} from 'shared/components/DataTable';
import {DateCell} from 'shared/components/DateCell';
import {EditableCell} from 'shared/components/EditableCell';
import {RowActions} from 'shared/components/RowActions';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Form} from 'shared/components/shadcn-ui/Form';
import {Input} from 'shared/components/shadcn-ui/Input';
import {ScrollArea} from 'shared/components/shadcn-ui/ScrollArea';
import {Separator} from 'shared/components/shadcn-ui/Separator';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from 'shared/components/shadcn-ui/Sheet';
import {toast} from 'shared/components/shadcn-ui/Toast/useToast';
import {HEIGHT_MEDIA_BREAKPOINT_L} from 'shared/constants';
import {useCurrentCompany, useConfirmation, usePrevious, useDimensions} from 'shared/hooks';
import {setValidationFormErrors} from 'shared/utils';
import {extractApiErrorMessage} from 'shared/utils/axios';
import {safeFormatDate, safeParseDate} from 'shared/utils/date';
import {cn, handleApiErrors} from 'shared/utils/helpers';

import {InfoSheet} from './components/InfoSheet';
import s from './HiringCost.module.scss';
import {filterCurrentYear} from './utils/helpers';
import {FormSchema} from './utils/validationSchema';

import {EditMetricDescription} from '../EditMetricDescription';
import {DIRTY_FORM_CONFIRM_OPTIONS, formatValueAsCost, MINI_CARD_TIPS} from '../utils';

const CURRENT_YEAR = new Date().getFullYear();
type TableRowType = z.infer<typeof FormSchema>['rows'][0];
type TableRowTypeWithStringOfDate = Omit<TableRowType, 'date'> & {date: string};

type Props = {
  className?: string;
  isBlur: boolean;
  value: number | null;
  onAfterEdit?: () => void;
};

export const HiringCostMetric: FC<Props> = ({isBlur, value, className, onAfterEdit}: Props) => {
  const {company, hasDemoStatus} = useCurrentCompany();
  const [activeEditingRow, setActiveEditingRow] = useState<number | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const {confirm} = useConfirmation();
  const [currentYear, setCurrentYear] = useState<number>(CURRENT_YEAR);
  const prevYear = usePrevious(currentYear);
  const [years, setYears] = useState([CURRENT_YEAR]);
  const [apiData, setApiData] = useState<HiringExpensesDataDTO | null>(null);
  const [disabledMonths, setDisabledMonths] = useState<string[]>([]);
  const [globalData, setGlobalData] = useState<{[key: number]: TableRowType[]}>({});
  const [visibleStartIndex, setVisibleStartIndex] = useState(0);
  const screenHeight = useDimensions().height ?? HEIGHT_MEDIA_BREAKPOINT_L;

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      rows: [],
    },
  });

  const tableRows = form.watch('rows') ?? [];
  const {prepend, remove} = useFieldArray({control: form.control, name: 'rows'});
  const [removedRecords, setRemovedRecords] = useState<TableRowTypeWithStringOfDate[]>([]);
  const [newRecordsAdded, setNewRecordsAdded] = useState<boolean>(false);

  const resetForm = (dataForYear: z.infer<any>[]) => {
    form.reset({
      rows: dataForYear.map(({date, ...rest}) => ({
        date: safeParseDate(date),
        ...rest,
      })),
    });
  };

  const updateDisabledMonth = (prevDisabledMonths: string[]) => {
    const selectedDates = new Set(tableRows.map((row) => safeFormatDate(row.date)));
    const newDisabledMonths = [...prevDisabledMonths];

    selectedDates.forEach((date) => {
      if (!newDisabledMonths.includes(date)) {
        newDisabledMonths.push(date);
      }
    });
    removedRecords.forEach((record) => {
      const index = newDisabledMonths.indexOf(record.date);
      if (index !== -1) {
        newDisabledMonths.splice(index, 1);
      }
    });
    setDisabledMonths(newDisabledMonths);
  };

  const handleSetMonth = (month: Date) => {
    const newDisabledMonths = [
      ...disabledMonths.filter((date: string) => {
        return date !== safeFormatDate(month);
      }),
      safeFormatDate(month),
    ];

    updateDisabledMonth(newDisabledMonths);
  };

  useEffect(() => {
    if (apiData && currentYear !== prevYear) {
      const dataForYear = globalData[currentYear] || apiData.yearGroups.find(({year}) => year === currentYear)?.data;
      if (!dataForYear) return;

      if (!form.formState.isDirty) {
        resetForm(dataForYear);
      } else {
        form.trigger().then((isValid) => {
          if (isValid) {
            setGlobalData((prevState) => ({
              ...prevState,
              [Number(prevYear)]: tableRows,
            }));
            resetForm(dataForYear);
          }
        });
      }
    }
  }, [apiData, currentYear, form.formState.isDirty, globalData]);

  useEffect(() => {
    updateDisabledMonth(disabledMonths);
  }, [tableRows, removedRecords, currentYear]);

  const onEditDialogVisibleChange = async (isOpened: boolean) => {
    if (!isOpened && form.formState.isDirty && !(await confirm(DIRTY_FORM_CONFIRM_OPTIONS))) return;

    cancelEdit();
    setIsEditing(isOpened);
  };

  const onChange = (
    value: string,
    name:
    | `rows.${number}.recruitmentTools`
    | `rows.${number}.recruitmentAgencies`
    | `rows.${number}.referralProgram`
    | `rows.${number}.advertisingCampaigns`
    | `rows.${number}.recruiterSalary`,
  ) => {
    form.setValue(name, Number(value));
  };

  const removeRow = (rowIndex: number) => {
    const removedRow = tableRows[rowIndex];
    remove(rowIndex);
    setRemovedRecords((prev) => prev.concat([{...removedRow, date: safeFormatDate(removedRow.date)}]));
  };

  const fetchTableData = async () => {
    if (company?.id) {
      try {
        const data = await CompanyOperationsApi.getHiringExpensesData(company.id);
        setApiData(data);
        setYears([...data.years].sort((a, b) => b - a));
        const newCurrentYear = data.years.includes(currentYear) ? currentYear : data.years[0];
        setCurrentYear(newCurrentYear);
        const dataForYear = data.yearGroups.find(({year}) => year === newCurrentYear)?.data ?? [];
        resetForm(dataForYear ?? []);
        setActiveEditingRow(null);
      } catch (error) {
        Sentry.captureException(error);
        handleApiErrors(error);
      }
    }
  };

  const onSubmit = async () => {
    if (!company?.id) return;
    setActiveEditingRow(null);

    const newValues = {...filterCurrentYear(globalData, currentYear), [currentYear]: tableRows};
    const allData = Object.values(newValues)
      .flat()
      .map(({date, ...item}) => ({
        ...item,
        date: date ? formatISO(date, {representation: 'date'}) : undefined,
      }));

    try {
      if (removedRecords?.some((item) => item.id)) {
        const removedRecordsIds = Array.from(new Set(removedRecords)).reduce((acc, {id}) => {
          if (id) acc.push(id);
          return acc;
        }, [] as number[]);

        await CompanyOperationsApi.deleteHiringExpensesList(company.id, removedRecordsIds);
      }

      if (newRecordsAdded || activeEditingRow) {
        await CompanyOperationsApi.setHiringExpense(company.id, allData);
        setNewRecordsAdded(false);
      }

      cancelEdit();
      setIsEditing(false);
      toast({
        title: 'Данные успешно сохранены.',
      });
    } catch (error) {
      Sentry.captureException(error);
      const extractedError = extractApiErrorMessage(error);

      if (typeof extractedError === 'string') {
        handleApiErrors(error);
      } else {
        setValidationFormErrors<z.infer<typeof FormSchema>>(extractedError, (fieldName, fieldErrors) => {
          form.setError(fieldName, {
            message: fieldErrors[0],
          });
        });
      }
    }
  };

  const startEdit = () => {
    setCurrentYear(CURRENT_YEAR);
    setDisabledMonths([]);
    fetchTableData();
    setIsEditing(true);
  };

  const cancelEdit = () => {
    if (onAfterEdit) onAfterEdit();
    setRemovedRecords([]);
    setDisabledMonths([]);
    setGlobalData({});
    setCurrentYear(CURRENT_YEAR);
    form.reset({});
    setVisibleStartIndex(0);
  };

  const isDisabledSubmitBtn =
    hasDemoStatus ||
    (!Object.values(globalData)
      .flat()
      .some((item) => item.id === null) &&
      !removedRecords.length &&
      !tableRows.some((item) => item.id === null) &&
      !activeEditingRow);

  const addNewRow = () => {
    const getNextAvailableYear = (year: number): number => {
      if (tableRows.filter((item) => item.year === year).length < 12) {
        return year;
      } else {
        return getNextAvailableYear(year + 1);
      }
    };

    const year = tableRows.length < 12 ? currentYear : getNextAvailableYear(currentYear + 1);

    setNewRecordsAdded(true);

    prepend({
      id: null,
      date: null,
      recruitmentTools: 0,
      recruitmentAgencies: 0,
      referralProgram: 0,
      advertisingCampaigns: 0,
      recruiterSalary: 0,
      year,
    });
  };

  const onYearChange = async (year: number) => {
    const isValid = await form.trigger();
    if (isValid) {
      setCurrentYear(year);
      if (CURRENT_YEAR - year <= 5) {
        setGlobalData((prevState) => ({
          ...prevState,
          [currentYear]: tableRows,
        }));
      } else {
        if (!company?.id) return;
        const yearData = await CompanyOperationsApi.getHiringExpensesData(company.id, year);

        setApiData((prevState) => ({
          years: yearData.years,
          yearGroups: [...(prevState?.yearGroups ?? []), ...yearData.yearGroups],
        }));

        const dataForYear = yearData.yearGroups[0].data ?? [];

        resetForm(dataForYear);
        setActiveEditingRow(null);
      }
    }
  };

  const getColumns = useMemo(() => {
    const columnHelper = createColumnHelper<TableRowType>();
    return [
      columnHelper.accessor('date', {
        header: 'Дата',
        size: 200,
        cell: DateCell,
        meta: {
          activeEditingRow,
          type: 'month',
          currentYear,
          disabledMonths,
          setDisabledMonths: handleSetMonth,
          className: 'w-[200px]',
        },
      }),
      columnHelper.accessor('recruitmentTools', {
        size: 130,
        header: 'Инструменты найма',
        meta: {activeEditingRow, className: 'pl-0'},
        cell: ({row, column}) => {
          return (
            <EditableCell columName={column.id} rowIndex={row.index}>
              <Input
                type="number"
                min={0}
                onChange={(e) => onChange(e.target.value, `rows.${row.index}.recruitmentTools`)}
                defaultValue={row.original.recruitmentTools}
                disabled={!!row.original.id && row.original.id !== activeEditingRow}
              />
            </EditableCell>
          );
        },
      }),
      columnHelper.accessor('recruitmentAgencies', {
        header: 'Кадровые агентства',
        meta: {activeEditingRow, className: 'pl-0'},
        size: 120,
        cell: ({row, column}) => (
          <EditableCell columName={column.id} rowIndex={row.index}>
            <Input
              type="number"
              onChange={(e) => onChange(e.target.value, `rows.${row.index}.recruitmentAgencies`)}
              min={0}
              defaultValue={row.original.recruitmentAgencies}
              disabled={!!row.original.id && row.original.id !== activeEditingRow}
            />
          </EditableCell>
        ),
      }),
      columnHelper.accessor('referralProgram', {
        header: 'Реферальная программа',
        meta: {activeEditingRow, className: 'pl-0'},
        size: 120,
        cell: ({row, column}) => (
          <EditableCell columName={column.id} rowIndex={row.index}>
            <Input
              type="number"
              onChange={(e) => onChange(e.target.value, `rows.${row.index}.referralProgram`)}
              min={0}
              defaultValue={row.original.referralProgram}
              disabled={!!row.original.id && row.original.id !== activeEditingRow}
            />
          </EditableCell>
        ),
      }),
      columnHelper.accessor('advertisingCampaigns', {
        header: 'Рекламные кампании',
        meta: {activeEditingRow, className: 'pl-0'},
        size: 120,
        cell: ({row, column}) => (
          <EditableCell columName={column.id} rowIndex={row.index}>
            <Input
              type="number"
              onChange={(e) => onChange(e.target.value, `rows.${row.index}.advertisingCampaigns`)}
              min={0}
              defaultValue={row.original.advertisingCampaigns}
              disabled={!!row.original.id && row.original.id !== activeEditingRow}
            />
          </EditableCell>
        ),
      }),
      columnHelper.accessor('recruiterSalary', {
        header: 'Зарплата рекрутеров',
        size: 120,
        meta: {activeEditingRow, className: 'pl-0'},
        cell: ({row, column}) => (
          <EditableCell columName={column.id} rowIndex={row.index}>
            <Input
              type="number"
              onChange={(e) => onChange(e.target.value, `rows.${row.index}.recruiterSalary`)}
              min={0}
              defaultValue={row.original.recruiterSalary}
              disabled={!!row.original.id && row.original.id !== activeEditingRow}
            />
          </EditableCell>
        ),
      }),
      columnHelper.display({
        header: InfoSheet,
        size: 130,
        meta: {
          activeEditingRow,
        },
        id: 'actions',
        cell: ({row, column, table}) => <RowActions row={row} column={column} table={table} />,
      }),
    ];
  }, [tableRows, currentYear, disabledMonths, activeEditingRow, removedRecords]);

  const visibleYears = years.slice(visibleStartIndex, visibleStartIndex + 5);

  return (
    <>
      <MetricCard
        className={className}
        title="Стоимость найма"
        value={value}
        description={MINI_CARD_TIPS.hiringCost}
        icon={<Coins />}
        formatter={formatValueAsCost}
        onEditIconClick={isBlur || onAfterEdit ? undefined : startEdit}
      />

      <Sheet open={isEditing} onOpenChange={onEditDialogVisibleChange}>
        <SheetContent className="lg:max-w-6xl">
          <ScrollArea className="h-full ">
            <SheetHeader>
              <SheetTitle>Редактирование стоимости найма</SheetTitle>
              <SheetDescription>
                Для корректной работы показателя заполните поля ниже. Для этого выберите месяц и затраты на найм по
                каждой статье расхода. После добавления данных на дашборде вы увидите среднюю стоимость нанятого
                кандидата за месяц.
              </SheetDescription>
            </SheetHeader>
            <Form {...form}>
              <section className={s.hiringCost}>
                <div className={s.hiringCost__actions}>
                  <EditMetricDescription
                    title="Ручные данные стоимости найма"
                    onButtonClick={addNewRow}
                    disabledAddBtn={hasDemoStatus}
                  />
                  <div className={s.hiringCost__years}>
                    {visibleStartIndex > 0 && (
                      <Button
                        className={s.hiringCost__year}
                        variant="outline"
                        onClick={() => setVisibleStartIndex((prev) => Math.max(0, prev - 3))}
                      >
                        &laquo;
                      </Button>
                    )}
                    {visibleYears.map((item, idx) => (
                      <Button
                        key={`${idx}|${item}`}
                        className={cn(s.hiringCost__year, {
                          [s.hiringCost__year_active]: item === currentYear,
                        })}
                        variant="outline"
                        onClick={() => onYearChange(item)}
                      >
                        {item}
                      </Button>
                    ))}
                    {visibleStartIndex + 5 < years.length && (
                      <Button
                        className={s.hiringCost__year}
                        variant="outline"
                        onClick={() => setVisibleStartIndex((prev) => Math.min(years.length - 5, prev + 3))}
                      >
                        &raquo;
                      </Button>
                    )}
                  </div>
                </div>
                <form id="hiringCostForm" onSubmit={form.handleSubmit(onSubmit)}>
                  <DataTable<TableRowType>
                    columns={getColumns}
                    data={tableRows}
                    meta={{
                      removeRow,
                      startEditingRow: setActiveEditingRow,
                    }}
                    emptySearchResult="Данные отсутствуют."
                    scrollHeight={screenHeight > HEIGHT_MEDIA_BREAKPOINT_L ? 500 : 400}
                    state={{pagination: {pageSize: 100, pageIndex: 0}}}
                  />
                </form>
              </section>
            </Form>
            <Separator className="mt-4 mb-4" />
            <SheetFooter className="justify-start">
              <Button
                variant="primary"
                type="submit"
                form="hiringCostForm"
                disabled={isDisabledSubmitBtn}
                className="mr-3"
              >
                Сохранить изменения
              </Button>
            </SheetFooter>
          </ScrollArea>
        </SheetContent>
      </Sheet>
    </>
  );
};
