import {FC, memo} from 'react';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import {RecruitmentMetricsDTO} from 'api/dto';

import {ClipLoader} from '../../../ClipLoader';
import {CustomTooltipContent} from '../../CustomTooltip';
import {useDashboardContext} from '../../DashboardContextProvider';
import {
  DOMAIN_OF_EMPTY_CHART,
  getCartesianGridDefaultProps,
  getDefaultTooltipCursorProps,
  getXAxisInterval,
  getLabelFormatter,
  getLabelListDefaultStyles,
  getLegendDefaultProps,
  getXAxisDefaultProps,
  getYAxisDefaultProps,
  measureText,
  renderThinnedLabel,
  getXAxisPropsForEmptyChart,
  getCustomizedYAxisTick,
  getQuickIntervalTypeByDateRange,
} from '../../utils';

type Props = {
  data: RecruitmentMetricsDTO['charts']['headcountAndVacancies'];
  className?: string;
  isLoading: boolean;
};

export const HeadcountAndVacanciesChart: FC<Props> = memo(({data, className = '', isLoading}) => {
  const isEmptyChart = !data?.values?.length;
  const {dateRange} = useDashboardContext();

  const getXAxisProps = () => {
    if (isEmptyChart) return getXAxisPropsForEmptyChart(dateRange);
    return getXAxisDefaultProps();
  };

  function calcDomainDataRange(minAndMax: [number, number]): [number, number] {
    if (isEmptyChart) return DOMAIN_OF_EMPTY_CHART;
    const [min, max] = minAndMax;
    // увеличиваем немного расстояние чтобы ref.line не прилипала к верхней границе оси Y
    const ADDITIONAL_RANGE = Math.max(Math.floor((max - min) / 5), 10);
    const resultMax = Math.max(data?.staffHeadcount ?? 0, max);
    return [min - 10 > 0 ? min - 10 : 0, resultMax + ADDITIONAL_RANGE];
  }

  if (isLoading) {
    return <ClipLoader containerHeight={500} />;
  }

  return (
    <>
      <ResponsiveContainer width="100%" height={400} className={className}>
        <ComposedChart
          width={500}
          height={400}
          data={data?.values ?? []}
          margin={{
            top: 24,
            right: 15,
            left: 0,
            bottom: 15,
          }}
        >
          <CartesianGrid {...getCartesianGridDefaultProps()} />

          <XAxis
            dataKey="date"
            {...getXAxisProps()}
            padding={{right: 10}}
            interval={getXAxisInterval(getQuickIntervalTypeByDateRange(dateRange))}
          />
          <YAxis
            {...getYAxisDefaultProps()}
            domain={calcDomainDataRange}
            tick={getCustomizedYAxisTick}
            width={measureText(String(data?.staffHeadcount ?? ''), 14) + 15}
          />

          <Area
            fillOpacity={1}
            type="monotone"
            dataKey="vacancies"
            stroke="#DDD8FD"
            fill="#DDD8FD"
            isAnimationActive={false}
            name="none"
          />

          <Area
            fillOpacity={1}
            type="monotone"
            dataKey="headcount"
            stroke="#FFF8D8"
            fill="#FFF8D8"
            isAnimationActive={false}
            name="none"
          />
          <Line
            type="monotone"
            dataKey="headcount"
            stroke="#FFC93F"
            dot={{fill: '#FFC93F', stroke: '#FFC93F', strokeWidth: 1, r: 6}}
            name="Списочная численность"
          >
            <LabelList
              dataKey="headcount"
              position="top"
              dy={-5}
              style={{...getLabelListDefaultStyles(), fontSize: 14}}
              formatter={getLabelFormatter}
              content={renderThinnedLabel}
            />
          </Line>

          <Line
            type="monotone"
            dataKey="vacancies"
            stroke="#4D3FE3"
            dot={{fill: '#4D3FE3', stroke: '#4D3FE3', strokeWidth: 1, r: 6}}
            name="Вакантные места"
          >
            <LabelList
              dataKey="vacancies"
              position="top"
              dy={-5}
              style={{...getLabelListDefaultStyles(), fontSize: 14}}
              formatter={getLabelFormatter}
              content={renderThinnedLabel}
            />
          </Line>
          {data?.staffHeadcount ? (
            <ReferenceLine ifOverflow="visible" y={data.staffHeadcount} stroke="#767493" />
          ) : null}
          <Tooltip content={<CustomTooltipContent />} cursor={getDefaultTooltipCursorProps()} />

          <Legend
            {...getLegendDefaultProps()}
            payload={[
              {value: 'Списочная численность', type: 'circle', id: 'ID01', color: '#FFC93F'},
              {value: 'Вакантные места', type: 'circle', id: 'ID02', color: '#4D3FE3'},
              {value: 'Штатная численность', type: 'circle', id: 'ID03', color: '#767493'},
            ]}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
});
