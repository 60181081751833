import {ChevronDown, ChevronUp, FilterIcon, SortAscIcon, SortDescIcon} from 'lucide-react';
import {FC} from 'react';

import s from './CurrentIconForSortedHead.module.scss';

type Props = {
  isToggled: boolean;
  sortingDirection: 'asc' | 'desc' | 'none';
  isFilteredByDate?: boolean;
};

export const CurrentIconForSortedHead: FC<Props> = ({isToggled, sortingDirection, isFilteredByDate = false}) => {
  if (isFilteredByDate && !isToggled && sortingDirection === 'none') {
    return <FilterIcon className={s.icon} />;
  }
  if (isToggled) {
    return <ChevronUp className={s.icon} />;
  }
  if (sortingDirection === 'asc') {
    return <SortAscIcon className={s.icon} />;
  }
  if (sortingDirection === 'desc') {
    return <SortDescIcon className={s.icon} />;
  }
  return <ChevronDown className={s.icon} />;
};
