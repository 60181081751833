import {FC} from 'react';
import {NavLink} from 'react-router-dom';

import {bem} from 'shared/utils';
import {cn} from 'shared/utils/helpers';

import s from './MenuItem.module.scss';

import {MenuItem as MenuItemType} from '../utils/constants';

const sn = bem('menuItem', s);

type Props = {
  isToggled: boolean;
} & MenuItemType;

export const MenuItem: FC<Props> = ({title, subtitle, icon, route, subItems, isToggled}) => {
  return (
    <li className={s.menuItem}>
      {!isToggled && !!title && <h2 className={sn('title')}>{`${title}`}</h2>}
      <NavLink
        title={subtitle}
        className={({isActive}) => cn(sn('link'), {[sn('link_active')]: isActive}, {[sn('link_collapsed')]: isToggled})}
        to={route}
      >
        {icon}
        {!isToggled && <span className={sn('subtitle')}>{subtitle}</span>}
      </NavLink>
      {!!subItems?.length && (
        <ul>
          {subItems.map((subItem, index) => (
            <li className={s.menuItem} key={`${subItem.route}_${index}`}>
              <NavLink
                title={subItem.subtitle}
                className={({isActive}) =>
                  cn(sn('link'), {[sn('link_active')]: isActive}, {[sn('link_collapsed')]: isToggled})
                }
                to={subItem.route}
              >
                {!isToggled && <span>{subItem.subtitle}</span>}
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};
