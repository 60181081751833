import {FC} from 'react';
import {useFormContext} from 'react-hook-form';

import {Button} from 'shared/components/shadcn-ui/Button';
import {FormControl, FormField, FormItem, FormLabel, FormMessage} from 'shared/components/shadcn-ui/Form';
import {Input} from 'shared/components/shadcn-ui/Input';
import {Separator} from 'shared/components/shadcn-ui/Separator';
import {useCurrentCompany, useFormValues} from 'shared/hooks';
import {bem} from 'shared/utils';

import {InfoBlock} from './components/InfoBlock';
import s from './TokensStep.module.scss';

import {FormValuesType} from '../../utils/types';

const sn = bem('tokensStep', s);
type Props = {
  isFirstSetup: boolean;
  onChangeTab: (newTabName: string) => void;
};
export const TokensStep: FC<Props> = ({isFirstSetup, onChangeTab}) => {
  const form = useFormContext();
  const {hasDemoStatus} = useCurrentCompany();
  const formValues = useFormValues<FormValuesType>();
  const isDisabledControls = hasDemoStatus || !formValues.isEnabled;

  const goForward = () => onChangeTab('organizationStep');

  const getActionButton = () => {
    if (!isFirstSetup) return null;
    return (
      <>
        <Separator className="mt-12 mb-12" />
        <Button
          type="button"
          variant="primary"
          disabled={form.formState.isSubmitting}
          className="block mr-0 ml-auto"
          onClick={goForward}
        >
          {form.formState.isSubmitting ? 'Загрузка...' : 'Далее'}
        </Button>
      </>
    );
  };

  return (
    <div className={s.tokensStep}>
      <div className={sn('form')}>
        <FormField
          control={form.control}
          name="accessToken"
          render={({field}) => (
            <FormItem className="h-[92px]">
              <FormLabel>Access Token</FormLabel>
              <FormControl>
                <Input placeholder="Access Token" disabled={isDisabledControls} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="refreshToken"
          render={({field}) => (
            <FormItem className="h-[92px]">
              <FormLabel>Refresh Token</FormLabel>
              <FormControl>
                <Input placeholder="Refresh Token" disabled={isDisabledControls} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {getActionButton()}
      </div>
      <Separator orientation="vertical" className={sn('separator')} />
      <InfoBlock />
    </div>
  );
};
