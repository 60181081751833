import {ChevronDown, ChevronUp, Filter} from 'lucide-react';
import {FC, useEffect, useState} from 'react';

import {CompanyListDTO} from 'api/dto';
import {HeaderProps} from 'shared/components/DataTable/tableTypes';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Checkbox} from 'shared/components/shadcn-ui/Checkbox';
import {Popover, PopoverTrigger, PopoverContent} from 'shared/components/shadcn-ui/Popover';
import {bem} from 'shared/utils';
import {cn} from 'shared/utils/helpers';
import {useRootSelector} from 'store';
import {dictionariesSelector} from 'store/dictionaries/selectors';

import s from './FilteredByStatusTableTitle.module.scss';

const sn = bem('filteredByStatusTableTitle', s);
export const FilteredByStatusTableTitle: FC<HeaderProps<CompanyListDTO, unknown>> = ({column, title}) => {
  const [filters, setFilters] = useState<string[]>([]);
  const [isToggled, setIsToggled] = useState(false);
  const companyStatusList = useRootSelector(dictionariesSelector.getDictionaries).companyStatuses;

  const toggleMenu = () => setIsToggled((prev) => !prev);

  const handleSort = (selectedStatus: string[] | null) => {
    column.setFilterValue(selectedStatus ?? '');
  };

  const handleFilter = (mode: string | null) => {
    if (mode === null) {
      setFilters([]);
    } else {
      if (filters.includes(mode)) {
        setFilters(filters.filter((filter) => filter !== mode));
      } else {
        setFilters([...filters, mode]);
      }
    }
  };

  const clear = () => {
    handleFilter(null);
    toggleMenu();
  };

  useEffect(() => {
    handleSort(filters.length > 0 ? filters : null);
  }, [filters]);

  const getCurrentIcon = () => {
    if (isToggled) {
      return <ChevronUp className={sn('icon')} />;
    }
    if (filters.length) {
      return <Filter className={sn('icon')} />;
    }
    return <ChevronDown className={sn('icon')} />;
  };

  return (
    <div className={s.filteredByStatusTableTitle}>
      <Popover open={isToggled} onOpenChange={toggleMenu}>
        <PopoverTrigger asChild>
          <Button variant="tableHeader" className={cn(sn('button'), {[sn('button_active')]: filters.length})}>
            <span className={sn('span')}>{title}</span>
            {getCurrentIcon()}
          </Button>
        </PopoverTrigger>
        <PopoverContent className={sn('menu')} align="end">
          <div className={sn('header')}>
            <p className={sn('label')}>Фильтр</p>
            <Button className={sn('clear')} variant="link" onClick={clear}>
              СБРОСИТЬ
            </Button>
          </div>
          <div className={sn('separator')} />
          <div className={sn('group')}>
            {companyStatusList?.map(({description, value}) => {
              return (
                <Checkbox
                  className={sn('item')}
                  onClick={() => handleFilter(description)}
                  checked={filters.includes(description)}
                  label={description}
                  key={`status_${value}`}
                  name={`status_${value}`}
                ></Checkbox>
              );
            })}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};
