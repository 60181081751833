import {createSlice} from '@reduxjs/toolkit';

import {DictionaryTypeDTO} from 'api/dto/DictionaryDTO';

import {getCompanyRoles, getCompanyStatuses, getUserStatuses} from './actions';

export type DictionariesStore = {
  systemRoles: DictionaryTypeDTO[] | null;
  companyRoles: DictionaryTypeDTO[] | null;
  companyStatuses: DictionaryTypeDTO[] | null;
  userStatuses: DictionaryTypeDTO[] | null;
  loadingCompanyRoles: boolean;
};

const initialState: DictionariesStore = {
  systemRoles: null,
  companyRoles: null,
  companyStatuses: null,
  userStatuses: null,
  loadingCompanyRoles: true,
};

const dictionariesSlice = createSlice({
  name: 'dictionaries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompanyRoles.pending, (state) => {
      state.loadingCompanyRoles = true;
    });
    builder.addCase(getUserStatuses.fulfilled, (state, {payload}) => {
      state.userStatuses = payload;
    });

    builder.addCase(getCompanyStatuses.fulfilled, (state, {payload}) => {
      state.companyStatuses = payload;
    });
    builder.addCase(getCompanyRoles.fulfilled, (state, {payload}) => {
      state.companyRoles = payload;
      state.loadingCompanyRoles = false;
    });
  },
});

export const {reducer: dictionariesReducer} = dictionariesSlice;
