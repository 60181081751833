import {YandexMetricaSettingsViewDTO} from 'api/dto';

import {FormValuesType} from './types';

export const getDefaultValues = (
  yandexMetricaParameters: YandexMetricaSettingsViewDTO | null,
): Partial<FormValuesType> => {
  const savedCounterId = yandexMetricaParameters?.counters?.find(
    (item) => item.isActive && !item.isRemovedFromYandex,
  )?.id;
  const isCareerProfile = yandexMetricaParameters?.isCareerProfile ?? false;
  const activeGoals = yandexMetricaParameters?.goals?.filter((item) => item.isActive);
  const segments = yandexMetricaParameters?.segments?.filter((item) => item.counterId === savedCounterId) ?? [];

  return {
    counterStatus: {counterId: savedCounterId ?? 0},
    goals: activeGoals?.map((item) => item.id),
    rows: segments,
    isCareerProfile: isCareerProfile,
    isEnabled: yandexMetricaParameters?.isEnabled,
  };
};
