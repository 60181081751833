export const routes = {
  home: '/',
  dashboard: '/dashboard',
  generalDashboard: '/dashboard/general',
  recruitmentDashboard: '/dashboard/recruitment',
  hrBrandDashboard: '/dashboard/hr-brand',
  login: '/login',
  resetPassword: '/auth/reset-password',
  confirmEmail: '/auth/confirm-email',
  forgotPassword: '/auth/forgot-password',
  companies: '/companies',
  addNewCompany: '/companies/add',
  company: '/companies/:compId',
  companyEdit: '/companies/:compId/edit',
  settings: '/settings',
  profileSettings: '/settings/profile',
  companySettings: '/settings/company',
  companyUsersSettings: '/settings/company/users',
  sourcesSettings: '/settings/sources',
  oneCIntegration: '/settings/sources/one-c',
  huntflowIntegration: '/settings/sources/huntflow',
  yandexMetricIntegration: '/settings/sources/metrics',
  yandexDirectIntegration: '/settings/sources/direct',
  oauthYandexMetricaSystemPage: '/oauth/yandex-metrica-system-page',
  oauthYandexDirectSystemPage: '/oauth/yandex-direct-system-page',
  addNewCompanyUser: '/settings/company/users/add-user',
  editCompanyUser: '/settings/company/users/edit-user',
  companyUser: '/companies/:compId/users/:userId/',
  addUserBySuperAdmin: '/companies/:compId/users/add-user',
  companyDashboard: '/companies/:compId/dashboards',
  permissionDenied: '/permission-denied',
  requests: '/requests',
  profile: '/profile',
};
