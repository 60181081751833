import {Search} from 'lucide-react';
import {ChangeEvent, FC} from 'react';

import {bem, debounce} from 'shared/utils';
import {cn} from 'shared/utils/helpers';

import s from './SearchInput.module.scss';

const sn = bem('searchInput', s);

type Props = {
  placeholder?: string;
  onChange: (value: string) => void;
  className?: string;
};

export const SearchInput: FC<Props> = ({placeholder = 'Поиск...', onChange, className}) => {
  const debouncedOnChange = debounce(onChange, 500);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.trim();
    debouncedOnChange(newValue);
  };

  return (
    <div className={cn(s.searchInput, className)}>
      <Search className={cn(sn('icon'), 'text-neutral-400')} />
      <input className={sn('input')} placeholder={placeholder} onChange={handleInputChange} />
    </div>
  );
};
