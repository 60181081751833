import {useEffect, useState} from 'react';
import {DateRange} from 'react-day-picker';

import {HeaderProps} from 'shared/components/DataTable/tableTypes';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Calendar} from 'shared/components/shadcn-ui/Calendar';
import {Popover, PopoverContent, PopoverTrigger} from 'shared/components/shadcn-ui/Popover';
import {useMount} from 'shared/hooks';
import {bem} from 'shared/utils';
import {cn} from 'shared/utils/helpers';

import s from './DatePickerTableTitle.module.scss';

import {AscOrDesSortBlock} from '../../../modules/SuperAdmin/Companies/components/AscOrDesSortBlock';
import {CurrentIconForSortedHead} from '../../../modules/SuperAdmin/Companies/components/CurrentIconForSortedHead';

const sn = bem('datePickerTableTitle', s);

export type DirType = 'asc' | 'desc' | 'none';

export const DatePickerTableTitle = <TData, TValue>({
  title,
  column,
  resetFilters,
  dateSortDir,
  setDateSortDir,
}: HeaderProps<TData, TValue>) => {
  const [isToggled, setIsToggled] = useState(false);
  const [selectedRange, setSelectedRange] = useState<DateRange | undefined>();

  const isFilteredByDate = !!(selectedRange?.from || selectedRange?.to);

  useMount(() => {
    if (dateSortDir) handleSort(dateSortDir);
  });

  const toggleMenu = () => setIsToggled((prev) => !prev);

  useEffect(() => {
    column.setFilterValue(selectedRange);
  }, [selectedRange]);

  const handleSort = (mode: DirType) => {
    if (resetFilters) resetFilters();
    if (mode === 'asc') {
      column.toggleSorting(false);
    } else if (mode === 'desc') {
      column.toggleSorting(true);
    } else if (mode === 'none') {
      column.clearSorting();
    }
  };

  const handleSortingClick = (direction: 'asc' | 'desc' | 'none') => {
    if (direction && setDateSortDir) {
      setDateSortDir(direction);
      handleSort(direction);
    }
  };

  const clear = () => {
    if (setDateSortDir) setDateSortDir('none');
    handleSort('none');
    setSelectedRange(undefined);
    toggleMenu();
  };

  return (
    <div className={s.DatePickerTableTitle}>
      <Popover open={isToggled} onOpenChange={toggleMenu}>
        <PopoverTrigger asChild>
          <Button
            variant="tableHeader"
            className={cn(sn('button'), {[sn('button_active')]: dateSortDir !== 'none' || isFilteredByDate})}
          >
            <span className={sn('span')}> {title}</span>
            <CurrentIconForSortedHead
              isToggled={isToggled}
              sortingDirection={dateSortDir || 'none'}
              isFilteredByDate={isFilteredByDate}
            />
          </Button>
        </PopoverTrigger>
        <PopoverContent className={sn('popover')} align="end">
          <div className={sn('head')}>
            <p className={sn('label')}>Фильтр</p>
            <Button className={sn('clear')} value="none" variant="link" onClick={clear}>
              СБРОСИТЬ
            </Button>
          </div>
          <div className={sn('separator')} />
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={selectedRange?.from}
            selected={selectedRange}
            onSelect={setSelectedRange}
            numberOfMonths={1}
            footer={
              <AscOrDesSortBlock
                sortingDirection="none"
                handleSortingClick={handleSortingClick}
                hasClearBtn={false}
                toggleMenu={toggleMenu}
              />
            }
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};
