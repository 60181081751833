import {forwardRef} from 'react';

import {RecruitmentMetricsDTO} from 'api/dto';
import {ClipLoader} from 'shared/components/ClipLoader';
import {cn} from 'shared/utils/helpers';

import s from './RecruitmentFunnelChart.module.scss';

import {EMPTY_RECRUITMENT_FUNNEL} from '../../utils';

type Props = {
  data: RecruitmentMetricsDTO['charts']['recruitingFunnel'];
  isLoading?: boolean;
  className?: string;
  legendText?: string;
};

export const RecruitmentFunnelChart = forwardRef<HTMLDivElement, Props>(
  ({data, isLoading = false, className = '', legendText = 'Количество соискателей'}, ref) => {
    const isEmptyChart = !data?.length;

    const showData = isEmptyChart ? EMPTY_RECRUITMENT_FUNNEL : data;

    if (isLoading) {
      return <ClipLoader containerHeight={250} />;
    }

    const formatPercentage = (percentage: number) => {
      return `${parseFloat(String(percentage)).toFixed(2)}%`;
    };

    return (
      <>
        <div className={cn(s.funnelChart, className)} ref={ref}>
          {showData?.map(({itemName, applicantsCount, percentage}, index) => (
            <div key={`stair__${itemName}`} className={cn(s.row, {[s.row_empty]: isEmptyChart})}>
              <span>{itemName}</span>
              <div className={s.stairBox}>
                {!isEmptyChart && (
                  <div
                    className={s.stairBox__stair}
                    style={{marginLeft: `${12 * index}px`, marginRight: `${12 * index}px`}}
                  >
                    <span>{applicantsCount}</span>
                  </div>
                )}
              </div>
              <span className={s.row__percentage}>{formatPercentage(percentage ?? 0)}</span>
            </div>
          ))}

          <div className={s.legend}>
            <svg width="10" height="10" viewBox="0 0 32 32" className={s.legend__circle}>
              <path
                fill="#ccf04c"
                cx="16"
                cy="16"
                transform="translate(16, 16)"
                d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0"
              ></path>
            </svg>
            <span>{legendText}</span>
          </div>
        </div>
      </>
    );
  },
);
