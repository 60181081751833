import {createContext, Dispatch, SetStateAction, useContext, useEffect} from 'react';

import {getIsAsideCollapsedFromLS} from 'shared/utils/asideCollapseHandler';

export type BaseLayoutData = unknown;

export const BaseLayoutContext = createContext<{
  breadcrumbsData: BaseLayoutData;
  setBreadcrumbsData: Dispatch<SetStateAction<BaseLayoutData>>;
  isCollapsedAsideMenu: boolean;
  toggleAsideMenu: Dispatch<SetStateAction<boolean>>;
}>({
  breadcrumbsData: null,
  setBreadcrumbsData: null as unknown as Dispatch<SetStateAction<BaseLayoutData>>,
  isCollapsedAsideMenu: getIsAsideCollapsedFromLS(),
  toggleAsideMenu: null as unknown as Dispatch<SetStateAction<boolean>>,
});

export const useProvideBreadcrumbsData = (data: BaseLayoutData) => {
  const {setBreadcrumbsData} = useContext(BaseLayoutContext);
  useEffect(() => {
    if (data) {
      setBreadcrumbsData(data);
    }
  }, [data]);
};
