import {isSameDay} from 'date-fns';
import * as z from 'zod';

const DEFAULT_MSG = 'Проверьте значение';

export const FormSchema = z.object({
  rows: z
    .array(
      z
        .object({
          id: z.coerce.number().nullable(),
          date: z.coerce.date().nullable(),
          recruitmentTools: z.coerce.number().nonnegative(),
          recruitmentAgencies: z.coerce.number().nonnegative(),
          referralProgram: z.coerce.number().nonnegative(),
          advertisingCampaigns: z.coerce.number().nonnegative(),
          recruiterSalary: z.coerce.number().nonnegative(),
          year: z.number().optional(),
        })
        .superRefine((data, context) => {
          const {recruitmentTools, recruitmentAgencies, referralProgram, advertisingCampaigns, recruiterSalary} = data;

          if (
            recruitmentTools === 0 &&
            recruitmentAgencies === 0 &&
            referralProgram === 0 &&
            advertisingCampaigns === 0 &&
            recruiterSalary === 0
          ) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: DEFAULT_MSG,
              path: ['recruitmentTools'],
            });
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: DEFAULT_MSG,
              path: ['recruitmentAgencies'],
            });
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: DEFAULT_MSG,
              path: ['referralProgram'],
            });
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: DEFAULT_MSG,
              path: ['advertisingCampaigns'],
            });
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: DEFAULT_MSG,
              path: ['recruiterSalary'],
            });
          }
        }),
    )
    .superRefine((rows, ctx) => {
      rows.forEach(({date, id}, index) => {
        if (!date) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Дата должна быть заполнена',
            path: [index, 'date'],
          });
        } else {
          const duplicatesMap = rows.reduce<number[]>((acc, row, ind) => {
            if (row.date && isSameDay(date, row.date) && (row.id === id || row.id === null || id === null)) {
              acc.push(ind);
            }
            return acc;
          }, []);

          if (duplicatesMap.length > 1) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Месяц уже заполнен',
              path: [index, 'date'],
            });

            // для ранее заполненного месяца
            duplicatesMap.forEach((duplicateIndex) => {
              if (duplicateIndex !== index) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: ' ',
                  path: [duplicateIndex, 'date'],
                });
              }
            });
          }
        }
      });
    }),
});
