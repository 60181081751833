import {useMemo, useState} from 'react';
import {Outlet} from 'react-router';

import {OnboardingStatus} from 'api/dto';
import {Onboarding} from 'shared/components/Onboarding';
import {useUserRole} from 'shared/hooks';
import {getIsAsideCollapsedFromLS} from 'shared/utils/asideCollapseHandler';
import {useRootSelector} from 'store';
import {authSelectors} from 'store/auth/selectors';

import {AsideMenu} from './AsideMenu';
import s from './BaseLayout.module.scss';
import {BaseLayoutData, BaseLayoutContext} from './BaseLayoutContext';
import {Header} from './Header';

export const BaseLayout = () => {
  const [breadcrumbsData, setBreadcrumbsData] = useState<BaseLayoutData>('');
  const [isCollapsedAsideMenu, toggleAsideMenu] = useState<boolean>(getIsAsideCollapsedFromLS());
  const user = useRootSelector(authSelectors.getUser);
  const onboardingStatus = user?.onboardingState;
  const {isSuperAdmin} = useUserRole();

  const memoized = useMemo(
    () => ({
      breadcrumbsData,
      setBreadcrumbsData,
      isCollapsedAsideMenu,
      toggleAsideMenu,
    }),
    [breadcrumbsData, isCollapsedAsideMenu],
  );

  return (
    <BaseLayoutContext.Provider value={memoized}>
      <div className={s.screen}>
        <AsideMenu />
        <div className={s.screen__content}>
          <Header className={s.screen__header} />
          <Outlet />
        </div>
        {onboardingStatus !== OnboardingStatus.completed && !isSuperAdmin && <Onboarding />}
      </div>
    </BaseLayoutContext.Provider>
  );
};
BaseLayout.displayName = 'BaseLayout';
