import {FC} from 'react';
import {patternFormatter} from 'react-number-format';

type Props = {
  phoneNumber: string;
};

export const PhoneCell: FC<Props> = ({phoneNumber}) => {
  return <span>{patternFormatter(phoneNumber, {format: '+# (###) ###−##−##'}) || '–'}</span>;
};
