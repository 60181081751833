import {useFormContext, useWatch} from 'react-hook-form';

export function useFormValues<T extends Record<string, unknown>>() {
  const {getValues} = useFormContext<T>();

  return {
    ...useWatch(),
    ...getValues(),
  };
}
