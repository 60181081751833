import * as Sentry from '@sentry/browser';
import {XIcon} from 'lucide-react';
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useNavigate} from 'react-router';

import {NotificationDTO, NotificationType} from 'api/dto/NotificationDTO';
import {NotificationsApi} from 'api/services/notificationsApi';
import {AlertDialogContent, AlertDialog, AlertDialogTrigger} from 'shared/components/shadcn-ui/AlertDialog';
import {Button} from 'shared/components/shadcn-ui/Button';
import {routes} from 'shared/constants/routes';
import {useDebounce, usePrevious} from 'shared/hooks';
import {TEMPORARY_TYPE} from 'shared/layouts/BaseLayout/Header/Notifications/constants';
import {handleApiErrors} from 'shared/utils/helpers';
import {useRootSelector} from 'store';
import {authSelectors} from 'store/auth/selectors';

type NotificationContextType = {
  hasUnread?: boolean;
  idOfFirstRead?: number;
  endReached: () => void;
  notificationList: NotificationDTO[];
  setPage: Dispatch<SetStateAction<number>>;
  setRecentlyRead: Dispatch<SetStateAction<number[]>>;
  setTemporary: Dispatch<SetStateAction<NotificationDTO[]>>;
};

const NotificationContext = createContext<NotificationContextType>({
  hasUnread: false,
  idOfFirstRead: undefined,
  endReached: null as unknown as () => void,
  notificationList: [],
  setPage: null as unknown as Dispatch<SetStateAction<number>>,
  setRecentlyRead: null as unknown as Dispatch<SetStateAction<number[]>>,
  setTemporary: null as unknown as Dispatch<SetStateAction<NotificationDTO[]>>,
});

export const useNotificationsContext = () => useContext(NotificationContext);

const PAGE_SIZE = 5;

export const NotificationsContextProvider: FC<PropsWithChildren> = (props) => {
  const [hasUnread, setHasUnread] = useState<boolean>();
  const user = useRootSelector(authSelectors.getUser);

  const [notificationList, setNotificationList] = useState<NotificationDTO[]>([]);
  const [page, setPage] = useState<number>(1);
  const prevPage = usePrevious<number>(page);
  const [totalNumberOfNotifications, setTotalNumberOfNotifications] = useState<number>(0);

  const [idOfFirstRead, setIdOfFirstRead] = useState<number | undefined>();
  const [recentlyReadNotifications, setRecentlyReadNotifications] = useState<number[]>([]);
  const [readNotifications, setReadNotifications] = useState<NotificationDTO[]>([]);
  const [temporaryNotifications, setTemporaryNotifications] = useState<NotificationDTO[]>([]);
  const [isToggledPopoverNotification, setIsToggledPopoverNotification] = useState<boolean>(
    !!temporaryNotifications.length,
  );

  const showTemporaryMessage = temporaryNotifications[0];
  const navigate = useNavigate();

  const debouncedMarkAsReadNotifications = useDebounce(async () => {
    try {
      if (user && recentlyReadNotifications.length) {
        await NotificationsApi.markAsReadNotifications(user.id, recentlyReadNotifications);
        setRecentlyReadNotifications([]);
        setReadNotifications((prev) => [
          ...prev,
          ...notificationList.filter((item) => recentlyReadNotifications.includes(item.id)),
        ]);
      }
    } catch (error) {
      Sentry.captureException(error);
      handleApiErrors(error, 'Не удалось пометить прочитанные уведомления.');
    }
  }, 1500);

  useEffect(() => {
    if (recentlyReadNotifications.length) debouncedMarkAsReadNotifications();
  }, [recentlyReadNotifications]);

  useEffect(() => {
    readNotifications.length < notificationList.length ? setHasUnread(true) : setHasUnread(false);
  }, [readNotifications]);

  useEffect(() => {
    if (temporaryNotifications.length) setIsToggledPopoverNotification(true);
  }, [temporaryNotifications]);

  const fetchNotifications = async () => {
    if (user) {
      try {
        const response = await NotificationsApi.getNotifications(user.id, page, PAGE_SIZE);
        if (page === 1) {
          setTotalNumberOfNotifications(response.total);
          setNotificationList(response.items);
          setReadNotifications(response.items.filter((item) => item.isRead));
          setIdOfFirstRead(response.items.find((item) => item.isRead)?.id);
        } else {
          setNotificationList((prev) => {
            const newNotificationList = [...prev, ...response.items];
            const updatedReadNotificationList = newNotificationList.filter((item) => item.isRead);
            setReadNotifications(updatedReadNotificationList);
            if (updatedReadNotificationList.length) setIdOfFirstRead(updatedReadNotificationList[0].id);
            return newNotificationList;
          });
        }
      } catch (error) {
        handleApiErrors(error, 'Не удалось получить список уведомлений.');
      }
    }
  };

  useEffect(() => {
    if (prevPage && page !== prevPage) {
      fetchNotifications();
    }
  }, [page, prevPage]);

  useEffect(() => {
    const checkHasUnreadNotifications = async () => {
      if (user) {
        try {
          const res = await NotificationsApi.getNotifications(user.id, page, PAGE_SIZE);
          setHasUnread(res.items[0] ? !res.items[0].isRead : false);
          setTemporaryNotifications(res.items.filter((item) => item.severityType === TEMPORARY_TYPE && !item.isRead));
          if (res && page === 1) {
            setTotalNumberOfNotifications(res.total);
            setNotificationList(res.items);
            setReadNotifications(res.items.filter((item) => item.isRead));
            setIdOfFirstRead(res.items.find((item) => item.isRead)?.id);
          }
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    };
    checkHasUnreadNotifications();
    const timer = setInterval(checkHasUnreadNotifications, 300_000); // 5min
    return () => clearTimeout(timer);
  }, []);

  const endReached = () => {
    if (totalNumberOfNotifications && page < Math.ceil(totalNumberOfNotifications / PAGE_SIZE)) {
      setPage((prev) => prev + 1);
    }
  };

  const togglePopoverNotification = async () => {
    try {
      if (user?.id && temporaryNotifications[0]) {
        await NotificationsApi.markAsReadNotifications(user.id, [temporaryNotifications[0].id]);
      }
      await fetchNotifications();
    } catch (error) {
      handleApiErrors(error, 'Не удалось прочесть список уведомлений.');
    } finally {
      setIsToggledPopoverNotification(false);
    }
  };
  const inviteNewUsers = () => {
    navigate(routes.companyUsersSettings);
    togglePopoverNotification();
  };

  const contextValue = useMemo(
    () => ({
      hasUnread,
      idOfFirstRead,
      endReached,
      notificationList,
      setPage,
      setRecentlyRead: setRecentlyReadNotifications,
      setTemporary: setTemporaryNotifications,
    }),
    [
      hasUnread,
      idOfFirstRead,
      endReached,
      notificationList,
      setPage,
      setRecentlyReadNotifications,
      setTemporaryNotifications,
    ],
  );

  return (
    <NotificationContext.Provider value={contextValue}>
      <AlertDialog open={isToggledPopoverNotification} onOpenChange={togglePopoverNotification}>
        <AlertDialogTrigger />
        <AlertDialogContent className="w-80 h-64 p-7 px-9">
          <Button
            className="m-0 absolute right-2.5 top-2.5 p-0"
            type="button"
            variant="ghost"
            onClick={togglePopoverNotification}
          >
            <XIcon />
          </Button>
          {showTemporaryMessage?.message}
          {showTemporaryMessage?.notificationEventType === NotificationType.invitingNewUsers && (
            <Button variant="primary" className="w-52 ml-auto mr-auto" onClick={inviteNewUsers}>
              Добавить пользователей
            </Button>
          )}
        </AlertDialogContent>
      </AlertDialog>
      {props.children}
    </NotificationContext.Provider>
  );
};
