import {zodResolver} from '@hookform/resolvers/zod';
import {AwardIcon, CalendarDaysIcon, Trash2Icon} from 'lucide-react';
import React, {FC, useState} from 'react';
import {DateRange} from 'react-day-picker';
import {useFieldArray, useForm} from 'react-hook-form';
import * as z from 'zod';

import {MetricCard} from 'shared/components/Dashboards';
import {EditMetricDescription} from 'shared/components/Dashboards/EditMetricDescription';
import {MINI_CARD_TIPS, DIRTY_FORM_CONFIRM_OPTIONS, formatValuePercentage} from 'shared/components/Dashboards/utils';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Calendar} from 'shared/components/shadcn-ui/Calendar';
import {Form, FormControl, FormField, FormItem, FormMessage} from 'shared/components/shadcn-ui/Form';
import {Input} from 'shared/components/shadcn-ui/Input';
import {Popover, PopoverContent, PopoverTrigger} from 'shared/components/shadcn-ui/Popover';
import {ScrollArea, ScrollBar} from 'shared/components/shadcn-ui/ScrollArea';
import {Separator} from 'shared/components/shadcn-ui/Separator';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from 'shared/components/shadcn-ui/Sheet';
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from 'shared/components/shadcn-ui/Table';
import {useConfirmation} from 'shared/hooks';
import {safeFormatDate} from 'shared/utils/date';

import {FormSchema} from './validationSchema';

type Props = {
  value: number | null;
};

export const ReferralPerformanceMetricCard: FC<Props> = ({value}: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const {confirm} = useConfirmation();

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      rows: [
        {
          datePeriod: {from: new Date(), to: new Date()},
          successfulHiresNumber: 0,
        },
      ],
    },
  });

  const onEditDialogVisibleChange = async (isOpened: boolean) => {
    if (!isOpened) {
      if (form.formState.isDirty && !(await confirm(DIRTY_FORM_CONFIRM_OPTIONS))) return;
      form.reset(form.formState.defaultValues);
    }
    setIsEditing(isOpened);
  };
  const cancelEdit = () => {
    form.reset();
    setIsEditing(false);
  };

  const {fields, prepend, remove} = useFieldArray({control: form.control, name: 'rows'});

  const onSubmit = (values: z.infer<typeof FormSchema>) => {
    cancelEdit();
    // TODO: отправка данных на сервер
  };

  const getDateRangeLabel = (datePeriod: Partial<DateRange>) => {
    if (datePeriod.from && datePeriod?.to) {
      return `${safeFormatDate(datePeriod.from)} &mdash; ${safeFormatDate(datePeriod.to)}`;
    }
    return '__.__.____ &mdash; __.__.____';
  };

  const addNewRow = () => {
    prepend({
      datePeriod: {from: undefined, to: undefined},
      successfulHiresNumber: 0,
    });
  };

  const onSelectDate = (rowIndex: number, range: DateRange | undefined) => {
    form.setValue(`rows.${rowIndex}.datePeriod`, range ?? {from: undefined, to: undefined});
  };

  function calculatePercentage() {
    return '--';
  }

  return (
    <>
      <MetricCard
        title="Реферальная эффективность"
        value={value ?? null}
        formatter={formatValuePercentage}
        icon={<AwardIcon />}
        /* onEditIconClick={() => setIsEditing(true)} TODO вернуть функционал редактирования после добавления соответствующего АПИ*/
        description={MINI_CARD_TIPS.referralPerformance}
        descriptionTitle="Реферальная эффективность"
      />
      <Sheet open={isEditing} onOpenChange={onEditDialogVisibleChange}>
        <SheetContent className="lg:max-w-5xl">
          <SheetHeader>
            <SheetTitle>Редактирование эффективности реферальной программы</SheetTitle>
            <SheetDescription>
              Для корректной работы метрики необходимо заполнить таблицу ниже. Укажите временной период, за который вы
              производили расчёт показателя, и количество нанятых сотрудников при помощи реферальной программы. Система
              автоматически посчитает эффективность реферальной программы и отразит её на дашборде.
            </SheetDescription>
          </SheetHeader>
          <Form {...form}>
            <section>
              <EditMetricDescription title="Ручные данные показателя" onButtonClick={addNewRow} />
              <form id="editReturnedEmployeesForm" onSubmit={form.handleSubmit(onSubmit)}>
                <ScrollArea className="h-[270px]">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead className="min-w-[140px]">Дата</TableHead>
                        <TableHead>Кол-во успешных наймов, чел</TableHead>
                        <TableHead>Эффект.программы</TableHead>
                        <TableHead></TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {fields.map((row, index) => (
                        <TableRow key={row.id}>
                          <TableCell>
                            <FormField
                              control={form.control}
                              name={`rows.${index}.datePeriod`}
                              render={({field}) => {
                                return (
                                  <FormItem>
                                    <FormControl>
                                      <Popover>
                                        <PopoverTrigger asChild>
                                          <Button variant="outline" className="justify-start">
                                            <span dangerouslySetInnerHTML={{__html: getDateRangeLabel(field.value)}} />
                                            <CalendarDaysIcon className="ml-2" />
                                          </Button>
                                        </PopoverTrigger>
                                        <PopoverContent className="w-73" withPortal={false}>
                                          <Calendar
                                            mode="range"
                                            defaultMonth={new Date()}
                                            selected={
                                              field.value?.from
                                                ? {to: field.value.to, from: field.value.from}
                                                : undefined
                                            }
                                            onSelect={(range) => onSelectDate(index, range)}
                                            fromDate={new Date('1990-01-01')}
                                            toDate={new Date()}
                                            {...field}
                                          />
                                        </PopoverContent>
                                      </Popover>
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <FormField
                              control={form.control}
                              name={`rows.${index}.successfulHiresNumber`}
                              render={({field}) => (
                                <FormItem>
                                  <FormControl>
                                    <Input type="number" min={0} {...field} />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </TableCell>
                          <TableCell>{calculatePercentage()}</TableCell>
                          <TableCell>
                            <Button variant="ghost" onClick={() => remove(index)}>
                              <Trash2Icon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <ScrollBar orientation="horizontal" />
                </ScrollArea>
              </form>
            </section>
          </Form>
          <Separator className="mt-4 mb-4" />
          <SheetFooter className="justify-start">
            <Button variant="primary" type="submit" form="editReturnedEmployeesForm">
              Сохранить изменения
            </Button>
          </SheetFooter>
        </SheetContent>
      </Sheet>
    </>
  );
};
