import {ChevronRight} from 'lucide-react';
import {FC} from 'react';

import {bem} from 'shared/utils';
import {cn} from 'shared/utils/helpers';

import s from './Stepper.module.scss';

const sn = bem('stepper', s);
type Props = {
  steps: string[];
  activeStepIndex?: number | null;
};

export const Stepper: FC<Props> = ({steps, activeStepIndex = 0}) => {
  return (
    <>
      <div className={s.stepper}>
        <ol className={sn('list')}>
          {steps.map((step, index) => {
            return (
              <li className={sn('step')} key={`key_${step}`}>
                <span className={cn(sn('name'), {[sn('name_active')]: index === activeStepIndex})}>
                  {`${index + 1} шаг: ${step}`}
                </span>
                {index !== steps.length - 1 && <ChevronRight className={sn('icon')} />}
              </li>
            );
          })}
        </ol>
      </div>
    </>
  );
};
