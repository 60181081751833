import {Row} from '@tanstack/react-table';

export const dateBetweenFilterFn = (row: Row<any>, id: string, filterValues: {from: Date; to: Date}) => {
  const {from, to} = filterValues;
  if (!from && !to) {
    return true;
  }
  const cellDate = new Date(row.original[id]);
  if (from && to) {
    return cellDate >= from && cellDate <= to;
  }
  if (from) {
    return cellDate >= from;
  }
  if (to) {
    return cellDate <= to;
  }
  return true;
};

export const dateWithNullSort = (rowA: Row<any>, rowB: Row<any>, columnId: string): number => {
  const [a, b] = [rowA.original[columnId], rowB.original[columnId]];
  // если дата окончания равна null ("Без даты"), то это самая поздняя дата
  if (a === null) return 1;
  if (b === null) return -1;
  return a.getTime() - b.getTime();
};
