export type YandexMetricaBaseModel = {
  id: number;
  yandexId: number;
  isActive: boolean;
  isRemovedFromYandex: boolean;
  name: string;
};

export type YandexMetricaBaseModelWithCounter = YandexMetricaBaseModel & {
  counterId: number;
};

type YandexDirectReport = {
  companyName: string;
  fields: Array<{
    name: string;
  }>;
};
export type AdsCampaignType = {
  id: number;
  name: string;
};

export type YandexDirectSettingsViewDTO = {
  companyId: number;
  accessToken: string;
  tokenIsExpired: boolean;
  selectedAllCampaigns: boolean;
  selectedAdsCampaigns: number[];
  availableAdsCampaigns: AdsCampaignType[];
  isEnabled: boolean;
};

export type UpdatedYandexDirectParametersDTO = {
  isEnabled?: boolean;
  accessToken?: string;
  expiresAt?: Date;
  addAllCampaigns?: boolean;
  adsCampaignsIds?: number[];
};

export type YandexMetricaSettingsViewDTO = {
  accessToken: string;
  settingsId: number;
  companyId: number;
  isEnabled: boolean;
  counters: YandexMetricaBaseModel[];
  segments: YandexSegmentDTO[];
  goals: YandexMetricaBaseModelWithCounter[];
  tokenExpiresAt: string;
  stateId: number;
  updateDate?: Date | null;
  isCareerProfile: boolean;
};
export type UpdatedYandexMetricaParametersDTO = {
  isEnabled?: boolean;
  isCareerProfile: boolean;
  counterStatus: Pick<YandexMetricaBaseModelWithCounter, 'counterId'>;
  goals: number[];
  segments?: YandexSegmentDTO[];
};

export type YandexSegmentDTO = {
  id: number | null;
  name: string;
  url: string;
  isDeleted?: boolean;
  isActive: boolean;
  counterId: number | null;
};

export enum YandexIntegrationType {
  'metrica',
  'direct',
}

export type YandexOAuthSettingsDTO = {
  accessToken: string;
  expiresAt?: Date;
};
