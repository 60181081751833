import {useRef} from 'react';
import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts';

import {RecruitmentMetricsDTO} from 'api/dto';
import {cn} from 'shared/utils/helpers';

import {
  COLORS,
  COLORS_OF_EMPTY_CHART,
  PERCENTAGE_OF_EMPTY_CHART,
  REMAINING_SOURCES_LABEL,
  TOP_SOURCES_TO_SHOW,
} from './constants';
import s from './Source.module.scss';

import {ClipLoader} from '../../../ClipLoader';

type Props = {
  data: RecruitmentMetricsDTO['charts']['sources'] | undefined;
  className?: string;
  isLoading: boolean;
  infoMessage?: string;
};

export const SourcesChart = ({data, isLoading, className = ''}: Props) => {
  const isEmptyChart = !data?.length;

  const remainingLabel = useRef<string[]>([]);
  if (isLoading) {
    return <ClipLoader containerHeight={250} />;
  }

  const getFormattedData = (data: RecruitmentMetricsDTO['charts']['sources']) => {
    if (isEmptyChart)
      return [
        {sourceName: '', percentage: PERCENTAGE_OF_EMPTY_CHART},
        {sourceName: '', percentage: 380 - PERCENTAGE_OF_EMPTY_CHART},
      ];

    if (data) {
      if (data.length <= TOP_SOURCES_TO_SHOW) {
        return data.map((item) => ({...item, displayPercentage: item.percentage}));
      }

      const otherSourceInd = data.findIndex(({sourceName}) => sourceName === 'Другой');
      const mostPopular = data.slice(0, TOP_SOURCES_TO_SHOW);

      const losers = data.slice(TOP_SOURCES_TO_SHOW, data.length);

      if (otherSourceInd !== -1 && otherSourceInd <= TOP_SOURCES_TO_SHOW) {
        mostPopular.splice(otherSourceInd, 1);
        mostPopular.push(losers[0]);
        losers.splice(0, 1, data[otherSourceInd]);
      }

      const losersTotalPercentage = losers.reduce((acc, {percentage}) => (acc += percentage), 0);
      remainingLabel.current = losers.map(({sourceName, percentage}) => `${sourceName} ${percentage}%`);

      const adjustedData = mostPopular.concat([
        {sourceName: REMAINING_SOURCES_LABEL, percentage: +Number(losersTotalPercentage).toFixed(2)},
      ]);

      const finalData = adjustedData.map((entry) => ({
        ...entry,
        displayPercentage: Math.max(entry.percentage, 2.2),
      }));

      return finalData;
    }

    return [];
  };

  const formattedData = getFormattedData(data ?? []);

  const renderCustomLabel = ({cx, cy, midAngle, innerRadius, outerRadius, index}: any) => {
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    if (data) {
      const entry = formattedData[index];
      return (
        <>
          <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={COLORS[index % COLORS.length]} fill="none" />
          <circle cx={ex} cy={ey} r={4} fill={COLORS[index % COLORS.length]} stroke="none" />
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            fill="#56547E"
            textAnchor={textAnchor}
            dominantBaseline="central"
          >
            {`${entry?.sourceName} (${entry?.percentage}%)`}
          </text>
        </>
      );
    }
  };

  const CustomTooltip = ({active, payload}: any) => {
    if (active && payload[0]?.payload.sourceName === REMAINING_SOURCES_LABEL) {
      return (
        <>
          {!!remainingLabel.current.length && (
            <div className={s.tooltip}>
              <ul>
                {remainingLabel.current.map((item) => (
                  <li key={`key_${item}`}>{item}</li>
                ))}
              </ul>
            </div>
          )}
        </>
      );
    }

    return null;
  };

  return (
    <>
      <ResponsiveContainer width="100%" height={384} className={cn(className)}>
        <PieChart
          width={600}
          height={400}
          margin={{
            top: 24,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <Pie
            dataKey="displayPercentage"
            data={formattedData}
            label={isEmptyChart ? undefined : renderCustomLabel}
            type="number"
            paddingAngle={1.5}
            innerRadius={95}
            cornerRadius={9}
          >
            {formattedData.map((e, index) => (
              <Cell
                style={{outline: 'none'}}
                key={`cell-${e.sourceName}`}
                fill={isEmptyChart ? COLORS_OF_EMPTY_CHART[index % COLORS.length] : COLORS[index % COLORS.length]}
              ></Cell>
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};
