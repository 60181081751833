import * as z from 'zod';

export const FormSchema = z.object({
  isEnabled: z.boolean(),
  apiKey: z.string(),
  ipList: z
    .object({
      ip: z.string().ip({version: 'v4', message: 'Введите корректный IP-адрес'}),
    })
    .array(),
});
