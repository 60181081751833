import * as Sentry from '@sentry/browser';
import {intervalToDuration, subDays} from 'date-fns';
import {DateRange} from 'react-day-picker';
import {LegendType} from 'recharts/types/util/types';

import {toast} from 'shared/components/shadcn-ui/Toast/useToast';
import {extractApiErrorMessage} from 'shared/utils/axios';
import {roundToNearest} from 'shared/utils/helpers';

import {generateDateListByPeriod, isEqualDates} from '../../../utils/date';
import {AreaChartThinnedLabel} from '../AreaChartThinnedLabel/AreaChartThinnedLabel';
import {QuickPeriodType} from '../DashboardTopBar/utils';

type EntryType = {
  value: any;
  id?: string;
  type?: LegendType;
  color?: string;
  payload?: {
    strokeDasharray: string;
    value?: any;
  };
};

export function getCartesianGridDefaultProps(): Record<string, unknown> {
  return {
    strokeDasharray: '3 3',
    horizontal: true,
    vertical: false,
    stroke: '#EBF2F7',
  };
}
export function getXAxisDefaultProps(): Record<string, unknown> {
  return {
    axisLine: false,
    tickLine: false,
    stroke: '#C4C2DE',
    tick: {
      fontSize: 14,
    },
    tickMargin: 10,
  };
}

export function getXAxisPropsForEmptyChart(range: DateRange): Record<string, unknown> {
  const dateListForEmptyCharts = generateDateListByPeriod(range);

  return {
    axisLine: false,
    tickLine: false,
    stroke: '#C4C2DE',
    tick: {
      fontSize: 14,
    },
    domain: [...dateListForEmptyCharts],
    overflow: 'visible',
  };
}

export function getYAxisDefaultProps(): Record<string, unknown> {
  return {
    tickMargin: 10,
    axisLine: false,
    tickLine: false,
    stroke: '#C4C2DE',
    with: 60,
    tick: {
      fontSize: 14,
    },
  };
}

export function getLabelListDefaultStyles(): Record<string, unknown> {
  return {
    fontSize: 14,
    color: '#56547E',
  };
}

export const getLabelFormatter = (value: any): string => (value === 0 ? '' : `${Math.round(value)}`);

export function getLegendDefaultProps(wrapperStyles: Record<string, unknown> = {}): Record<string, unknown> {
  return {
    width: 'calc(100% - 50px)',
    align: 'left',
    iconType: 'circle',
    iconSize: 10,
    wrapperStyle: {
      position: 'relative',
      left: 0,
      bottom: 15,
      fontSize: '14px',
      ...wrapperStyles,
    },
    formatter: (value: any, entry: EntryType, index: number) => (
      <span style={{color: '#3C3A69', marginLeft: '6px', marginRight: '24px'}}>{value}</span>
    ),
  };
}

export function getLongestLabelLength(data: any[]): number {
  let max = 0;
  for (const title of data) {
    for (const part of title.itemName.split(' ')) {
      if (part.length > max) {
        max = part.length;
      }
    }
  }
  return max;
}

export function getDefaultTooltipCursorProps(): Record<string, unknown> {
  return {fill: 'rgba(211,202,202,0.35)'};
}

export function calcDomainDataRange(minAndMax: [number, number]): [number, number] {
  const [min, max] = minAndMax;
  return [min - 5 > 0 ? roundToNearest(min - 5) : 0, roundToNearest(max + 5)];
}

export function handleErrorFromFetchingData(e: unknown) {
  const extractedError = extractApiErrorMessage(e);
  let message = 'Произошла ошибка во время загрузки данных.';
  if (typeof extractedError === 'string') {
    message = extractedError;
  } else {
    if (Object.values(extractedError ?? {}).length) {
      const messages = Array.from(new Set(Object.values(extractedError).flat(1)));
      message = messages[0];
    }
  }
  Sentry.captureException(e);
  toast({
    variant: 'destructive',
    title: message,
  });
}

export function getQuickIntervalTypeByDateRange(dateRange: DateRange | undefined): QuickPeriodType | null {
  if (dateRange?.to && dateRange?.from) {
    const {to: finish, from} = dateRange;
    // отнимаем 1 день, тк при выборе quickPeriod к началу добавляем 1 день
    // см. src/shared/components/Dashboards/DashboardTopBar/DashboardTopBar.tsx:70
    const start = subDays(from, 1);
    const {months, days, years} = intervalToDuration({start: start, end: finish});
    if (days === 7 && months === 0 && years === 0) {
      return {period: 'weeks', duration: 1};
    }
    if (months === 1 && days === 0 && years === 0) {
      return {period: 'months', duration: 1};
    }
    if (months === 3 && days == 0 && years === 0) {
      return {period: 'months', duration: 3};
    }
    if (years === 1 && months === 0 && days === 0) {
      return {period: 'months', duration: 12};
    }
  }
  return null;
}

export function getQuickIntervalTypeByDateRangeFromToday(dateRange: DateRange | undefined): QuickPeriodType | null {
  return isEqualDates(dateRange?.to, new Date()) ? getQuickIntervalTypeByDateRange(dateRange) : null;
}

export const formatValueAsPeopleNumber = (value: number) => `${value} чел`;
export const formatValuePercentage = (value: number) => `${value}%`;
export const formatValueAsCost = (value: number) => `${value} руб`;

export const roundValue = (value: number) => {
  return parseFloat(Number(value).toFixed(2))?.toString() ?? `${value}`;
};

export const getXAxisInterval = (quickPeriod: QuickPeriodType | null): number | 'preserveEnd' => {
  if (quickPeriod?.period === 'months') {
    if (quickPeriod?.duration === 3) return 3;
    if (quickPeriod?.duration === 1) return 2;
  }
  return 'preserveEnd'; // 'preserveEnd' дефолтное значение для interval, отвечающее за автоматический показ тиков
};

export const renderThinnedLabel = (props: any) => {
  const {index, value, x, y} = props;
  return <AreaChartThinnedLabel index={index} value={value} x={x} y={y} />;
};

export const measureText = (text: string, size: number): number => {
  const ctx = document.createElement('canvas').getContext('2d')!;
  ctx.font = `${size}px InterVariable`;
  return ctx.measureText(text).width;
};

export const fittingString = (str: string, maxWidth: number, fontSize = 16): string => {
  let textWidth = measureText(str, fontSize);
  const ellipsisWidth = measureText('...', fontSize);

  if (textWidth <= maxWidth || textWidth <= ellipsisWidth) {
    return str;
  }
  let result = str;
  let len = str.length;

  while (textWidth >= maxWidth - ellipsisWidth && len-- > 0) {
    result = str.slice(0, len);
    textWidth = measureText(result, fontSize);
  }

  return `${result}...`;
};

export const getYAxisWidthByValue = (data: {value: number | string}[]): number =>
  Math.max(...(data ?? []).map((item) => measureText(String(item.value), 14))) + 20;

export const checkEmptinessByValue = (data: {value: number | null}[]) => {
  return !data?.length || data.every((item) => item.value === 0) || data.every((item) => item.value === 0);
};

export const getCustomizedYAxisTick = ({x, y, payload}: {x: any; y: any; payload: any}) => {
  return (
    <g>
      <text
        {...{x, y, payload}}
        dx={-x}
        dy={4}
        textAnchor="start"
        style={{stroke: 'none', fontSize: 14, fill: '#C4C2DE'}}
      >
        {payload.value}
      </text>
    </g>
  );
};
