import {FC} from 'react';

import {IntegrationTypeId} from 'api/dto';
import {IntegrationInfoBlock} from 'shared/components/IntegrationInfoBlock';
import {useCurrentCompany, useIntegrationStatuses} from 'shared/hooks';

type Props = {
  className?: string;
};

export const InfoBlock: FC<Props> = ({className}) => {
  const {hasDemoStatus} = useCurrentCompany();
  const hasHuntflowSettings = useIntegrationStatuses(IntegrationTypeId.Huntflow);

  if (hasDemoStatus) return <IntegrationInfoBlock />;

  return (
    <IntegrationInfoBlock
      title={hasHuntflowSettings ? 'Этапы воронки' : 'Шаг 3. Этапы воронки'}
      previewChars={154}
      description={[
        'В Стафскрин реализована возможность маппинга данных, с помощью которой вы сможете сопоставить ваши этапы воронки подбора в Хантфлоу с этапами в Стафскрин.',
        'Выберите в выпадающих списках по одному этапу, которые соответствуют этапам Стафскрин. Крайне важно, чтобы все этапы слева нашли свою пару справа, так воронка получится целостной.',
        'После сопоставления этапов отметьте, есть ли у вас в компании реферальная программа. Если есть, перейдите к следующему шагу, нет — сохраните текущие настройки.',
      ]}
    />
  );
};
