export const MINI_CARD_TIPS = {
  averageHeadcount:
    '<p><b>Среднесписочная численность</b> — это усреднённый показатель количества сотрудников, которые числились на предприятии в течение определённого временного интервала.</p> <br> <p>Данный параметр требуется при заполнении расчёта по страховым взносам, а также используется в отчётности по взносам за травматизм. С 2023 года ССЧ указывается в титульной части раздела 2 формы ЕФС-1, которая пришла на смену 4-ФСС.</p>',

  headcount:
    'Общее количество сотрудников в компании на текущий момент. В неё включают всех работников по трудовым договорам, для которых место работы является основным. Работал ли человек, был в отпуске, в декрете, на больничном — не важно.',
  hired: 'Количество нанятых сотрудников за период.',
  hiringCost:
    'Метрика, помогающая оценить стоимость одного нанятого кандидата за период времени. Чтобы отследить стоимость найма, необходимо рассчитать внутренние и внешние расходы на найм персонала, также общее количество нанятых сотрудников за определённый период, например месяц или год.',
  hiringTime:
    'Показатель, демонстрирующий отношение дней, потраченных на этапы «Выставлен оффер» и «Выход сотрудника на работу», к общему количеству закрытых вакансий.',
  fillingTime:
    'Метрика показывает количество времени, затраченного на поиск кандидатов на открытую вакансию. Время заполнения измеряет дни между утверждением заявки на вакансию и принятием кандидатом предложения о работе.',
  fillingAndHiringTime:
    '<p><b>Время заполнения</b> &mdash; метрика показывает количество времени, затраченного на поиск кандидатов на открытую вакансию. Время заполнения измеряет дни между утверждением заявки на вакансию и принятием кандидатом предложения о работе.</p> <br /> <p><b>Время найма</b> &mdash; показатель, демонстрирующий отношение дней, потраченных на этапы «Выставлен оффер» и «Выход сотрудника на работу», к общему количеству закрытых вакансий.</p>',
  fluidity:
    'Процент сотрудников, покинувших компанию, за указанный период. Рассчитывается как отношение уволенных к среднесписочной численности, умноженное на 100.',
  layoffs: 'Количество уволенных сотрудников за период.',
  timeFilling:
    'Метрика показывает среднее количество времени, затраченное на закрытие вакансий за выбранный период. Время заполнения учитывает дни от сбора требований и публикации вакансии до выхода сотрудника на работу.',
  loyalty: {
    1: 'Индекс удовлетворённости персонала показывает, с каким шансом сотрудники порекомендуют вашу компанию соискателям. В системе сравниваются 2 показателя, например, за два последних полугодия.',
    2: 'Индекс удовлетворённости кандидатов процессом найма. Показывает, насколько соискатель доволен своим движением по воронке найма. В системе сравниваются 2 показателя, например, за два последних полугодия.',
  },
  percentageOfWhoReturned:
    'Показатель, отслеживающий процент сотрудников, которые вновь вернулись на прежнее место работы.',
  referralPerformance:
    'Метрика для оценки эффективности реферальной программы. Считается как отношение количества успешных реферальных наймов к общему количеству наймов за определённый момент времени. Итог выражается в процентах, поэтому необходимо умножить итог на 100.',
  averageWorkExp:
    'Метрика отражает количество лет стажа у сотрудников по компании в среднем. \n Помогает косвенно оценить эффективность внутреннего HR-бренда',
  yandexDirect: {
    views: 'Количество показов рекламного объявления посетителям сайтов.',
    clicks: 'Переходы пользователей по ссылке объявления на сайт.',
    ctr: 'Кликабельность объявления. Отношение числа кликов на объявление к числу его показов.',
    viewDepth: 'Показатель количества страниц, просмотренных пользователем в течение одного сеанса',
    totalCost: 'Затраты на рекламу за выбранный период без учёта НДС.',
    costPerClick: 'Стоимость клика пользователя по рекламному объявлению.',
    goalCost: 'Стоимость выполнения целевого действия пользователем.',
    conversion:
      'Количество визитов пользователей на сайт, в рамках которых они выполнили целевое действие. \n Например, побывали хотя бы раз в соответствующем разделе, посетили определённое количество страниц, оставили заявку.',
  },
};
export const CHART_CARD_TIPS = {
  absences:
    'Данный показатель даёт возможность проанализировать общий процент потерь производительного времени сотрудников за определённый период по причине их отсутствия на рабочем месте. Формула: Рабочее время, пропущенное сотрудниками в течение периода / (Норма рабочего времени за период — Отсутствия по ув.причине).',
  hiredAndLayoffs: 'Гистограмма отражает изменения в показателях приёма и увольнения сотрудников.',
  populationDynamics:
    'Линейный график отражает изменения среднесписочной численности (ССЧ) в сравнении с предыдущими месяцам или годами.',
  fluidityDynamics: 'Линейный график отражает изменения текучести в сравнении с предыдущими временными промежутками.',
  actualStaffing:
    'Гистограмма показывает отношение фактической и общей укомплектованности к штатному расписанию компании. На графике сравнивается фактическая укомплектованность (с учётом прогулов) с общей укомплектованностью.',
  employeesWorkExp:
    'Горизонтальная гистограмма, отражающая 3 медианных параметра: количество сотрудников со стажем до 1 года, от 1 до 5 лет, более 5 лет за выбранный диапазон. Помогает оценить эффективность внутреннего HR-бренда.',
  reasonsForFired: 'Список самых частых причин увольнений из exit-интервью бывших сотрудников.',
  sources:
    'Кольцевая диаграмма показывает эффективность каналов привлечения кандидатов. Чем длиннее дуга диаграммы, тем больше резюме приносит конкретный источник.',
  vacancies:
    'Линейный график с тремя показателями, показывающий отношение между списочной численностью, количеством необходимых для найма специалистов и общей численностью для эффективной работы компании.',
  fillFunnelSpeed:
    'Воронка подбора разбивается на этапы: работа со сбором резюме, отбор резюме, интервью с кандидатами, выставление оффера и выход сотрудника на работу. Метрика позволяет оценить, насколько каждый из этапов эффективен, сколько тратится времени на каждый шаг.',

  recruitmentFunnelStaff:
    '<p>График описывает основные этапы найма персонала: от первого взаимодействия кандидатов с компанией до их устройства на работу.</p><p>Воронка демонстрирует путь кандидата в вашу компанию. Понимая конверсию кандидатов на каждом этапе, вы можете выявить слабые места в процессе и оценить достаточность кандидатов для успешного завершения найма. <p>На дашборде «Подбор персонала» отображены этапы воронки, относящиеся к рекрутингу. Более полный вариант воронки представлен на дашборде «HR-бренд».</p>',
  recruitmentFunnelHr:
    '<p>График описывает основные этапы найма персонала: от первого взаимодействия кандидатов с компанией до их устройства на работу.</p><p>Воронка демонстрирует путь кандидата в вашу компанию. Понимая конверсию кандидатов на каждом этапе, вы можете выявить слабые места в процессе и оценить достаточность кандидатов для успешного завершения найма.</p>  <p>В разрезе HR-бренда график поможет оценить насколько эффективно работают ваши каналы продвижения, а также качество привлекаемых кандидатов. <p>',
  yandexMetric: {
    topSearchQueries: 'Список самых популярных поисковых фраз, благодаря которым пользователи зашли к вам на сайт.',
    siteAudience:
      'Кольцевая диаграмма, показывающая демографические данные аудитории и тип устройств, с которых они зашли на сайт.',
    conversion:
      'Линейный график, отражающий количество совершённых целевых действий на сайте. Например, просмотр определённого количества страниц пользователями, отправка заявки и так далее.',
    visitors:
      'Линейный график, отражающий количество посетителей и новых посетителей карьерного сайта/страницы с вакансиями с учётом выбранного временного диапазона.',
  },
};
export const DIRTY_FORM_CONFIRM_OPTIONS = {
  title: 'Изменения не сохранены',
  description: 'Вы действительно хотите закрыть окно без сохранения?',
  acceptButton: 'Закрыть без сохранения',
  cancelButton: 'Отмена',
};

export const DOMAIN_OF_EMPTY_CHART: [number, number] = [0, 100];
export const DEFAULT_DOMAIN = [0, 'auto'];
export const DEFAULT_YAXIS_LABEL_WIDTH = 60;
export const MISSING_DATA_MSG = 'За выбранный период отсутствуют данные';

export const EMPTY_RECRUITMENT_FUNNEL = [
  {applicantsCount: 0, itemName: 'Новый кандидат', percentage: 0},
  {applicantsCount: 0, itemName: 'Отобранный кандидат', percentage: 0},
  {applicantsCount: 0, itemName: 'Интервью с рекрутером', percentage: 0},
  {applicantsCount: 0, itemName: 'Техническое интервью', percentage: 0},
  {applicantsCount: 0, itemName: 'Выставлен оффер', percentage: 0},
  {applicantsCount: 0, itemName: 'Оффер принят', percentage: 0},
  {applicantsCount: 0, itemName: 'Испытат. срок пройден'},
];

export const EMPTY_REASONS_FOR_FIRED = [
  {percentage: 0, itemName: 'Другое'},
  {percentage: 0, itemName: 'Стресс и выгорание'},
  {percentage: 0, itemName: 'Нарушение трудовой дисциплины'},
  {percentage: 0, itemName: 'Неудовлетворительная работа'},
  {percentage: 0, itemName: 'Сокращение штата'},
  {percentage: 0, itemName: 'Личные причины'},
  {percentage: 0, itemName: 'Конфликты в коллективе'},
  {percentage: 0, itemName: 'Отсутствие карьерных перспектив'},
  {percentage: 0, itemName: 'Неудовлетворенность оплатой труда'},
  {percentage: 0, itemName: 'Другие'},
];
