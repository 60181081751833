import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import {Check, Circle} from 'lucide-react';
import * as React from 'react';

import {cn} from 'shared/utils/helpers';

const RadioGroup = React.forwardRef<
React.ElementRef<typeof RadioGroupPrimitive.Root>,
React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({className, ...props}, ref) => {
  return <RadioGroupPrimitive.Root className={cn('grid gap-2', className)} {...props} ref={ref} />;
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const RadioGroupItem = React.forwardRef<
React.ElementRef<typeof RadioGroupPrimitive.Item>,
React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> & {indicatorType?: 'check' | 'circle'}
>(({className, children, indicatorType = 'circle', ...props}, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(
        'flex',
        {
          'cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-accent data-[state=open]:bg-accent':
            indicatorType === 'check',
        },
        {
          'aspect-square w-4 rounded-full border border-neutral-300 text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50':
            indicatorType === 'circle',
        },
        className,
      )}
      {...props}
    >
      <RadioGroupPrimitive.Indicator className="flex items-center justify-center h-full w-full">
        {indicatorType === 'check' ? (
          <Check className="h-4 w-4" />
        ) : (
          <Circle className="h-2 w-2 fill-primary text-current" />
        )}
      </RadioGroupPrimitive.Indicator>
      {children}
    </RadioGroupPrimitive.Item>
  );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

export {RadioGroup, RadioGroupItem};
