import {XIcon} from 'lucide-react';
import {useCallback, useEffect, useState} from 'react';

import {Popover, PopoverClose, PopoverContent, PopoverTrigger} from 'shared/components/shadcn-ui/Popover';
import {Separator} from 'shared/components/shadcn-ui/Separator';
import {bem} from 'shared/utils';

import {ButtonWithBell} from './components/ButtonWithBell/ButtonWithBell';
import {NotificationList} from './components/NotificationList';
import {useNotificationsContext} from './NotificationContext';
import s from './Notifications.module.scss';

const sn = bem('notifications', s);

const Notifications = () => {
  const [isToggledNotifications, setIsToggledNotifications] = useState(false);
  const {setPage} = useNotificationsContext();

  const toggleNotifications = useCallback(() => {
    setIsToggledNotifications((prev) => !prev);
  }, []);

  useEffect(() => {
    if (!isToggledNotifications && setPage) setPage(1);
  }, [isToggledNotifications]);

  return (
    <>
      <div className={s.notifications}>
        <Popover open={isToggledNotifications} onOpenChange={toggleNotifications}>
          <PopoverTrigger asChild>
            <ButtonWithBell />
          </PopoverTrigger>
          <PopoverContent className="w-80" align="end">
            <div className={sn('header')}>
              <h4 className="font-medium">Уведомления</h4>
              <PopoverClose>
                <XIcon className="text-neutral-500" />
              </PopoverClose>
            </div>
            <Separator className="my-3" />
            <NotificationList toggleNotifications={toggleNotifications} />
          </PopoverContent>
        </Popover>
      </div>
    </>
  );
};

export {Notifications};
