import * as z from 'zod';

export const FormSchema = z
  .object({
    password: z.string().min(2, {
      message: 'Пароль не может быть короче 2 символов',
    }),
    confirmPassword: z.string().min(2, {
      message: 'Пароль не может быть короче 2 символов',
    }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Пароли отличаются, проверьте их.',
    path: ['repeatedPassword'],
  });
