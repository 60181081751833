import * as Sentry from '@sentry/browser';
import {Axios} from 'axios';

import {JwtAuthService} from 'services/JwtAuthService';
import store from 'store';
import {authAsyncActions} from 'store/auth/actions';

import {RefreshTokenResponse} from '../dto';

let refreshTokenRequest: Promise<RefreshTokenResponse> | null = null;

export async function registerAuthInterceptors(api: Axios, jwtService: JwtAuthService) {
  api.interceptors.request.use(async (req) => {
    const token = await jwtService.getAccessToken();
    if (!!token && req.headers && !req.headers?.Authorization) {
      req.headers.Authorization = `Bearer ${token}`;
    }
    return req;
  });
  api.interceptors.response.use(
    async (res) => res,
    async (error) => {
      const {config: originalRequest} = error;
      if (error.response.status === 401 && !originalRequest._retryRefresh) {
        originalRequest.headers.Authorization = null;
        originalRequest._retryRefresh = true;
        try {
          if (refreshTokenRequest === null) {
            refreshTokenRequest = jwtService.refreshToken();
          }
          const {accessToken} = await refreshTokenRequest;
          jwtService.setAccessToken(accessToken);
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return api.request(originalRequest);
        } catch (e) {
          Sentry.captureException(e);
          console.error(e);
          const state = store.getState();
          if (state.auth.user) {
            await store.dispatch(authAsyncActions.signOut());
          }
        } finally {
          refreshTokenRequest = null;
        }
      }
      return Promise.reject(error);
    },
  );
}
