import {AuthApi} from 'api';
import {RefreshTokenResponse, SignInParams, UserDTOWithAuthData} from 'api/dto';

import {AuthStrategy, AuthTokenProvider, TokenPayload} from './types';

import {FingerprintService} from '../FingerprintService';

export class JwtAuthService implements AuthStrategy, AuthTokenProvider {
  private static instance: JwtAuthService;

  private fingerprintService: FingerprintService;

  private isRememberMe = false;

  private accessToken: string | null = null;

  constructor() {
    this.fingerprintService = new FingerprintService();
  }
  private listeners = new Map<string, (isAuth: boolean) => void>();

  private isRememberMeKey = 'isRememberMe';

  private isAuth = false;

  private jwtPayload: TokenPayload | null = null;

  static getInstance(): JwtAuthService {
    if (!JwtAuthService.instance) {
      JwtAuthService.instance = new JwtAuthService();
    }
    return JwtAuthService.instance;
  }

  private removeFingerPrint(): void {
    localStorage.removeItem(this.isRememberMeKey);
    localStorage.removeItem('fingerprint');
  }

  registerOnAuthListener(listener: (isAuth: boolean) => void) {
    const id = String(this.listeners.size);
    this.listeners.set(id, listener);
    return () => {
      return this.listeners.delete(id);
    };
  }

  private callAuthListeners(): void {
    for (const [, listener] of this.listeners) {
      listener(this.isAuth);
    }
  }

  async checkInitAuth(): Promise<UserDTOWithAuthData | null> {
    if (this.hasUserCheckedRememberFlag() && (await this.fingerprintService.get())) {
      try {
        const {accessToken, userInfo} = await this.refreshToken();
        this.accessToken = accessToken;
        this.jwtPayload = this.parseJwt(accessToken);
        this.isAuth = true;
        return {...userInfo, ...this.getUserInfoFromToken()};
      } catch (e) {
        this.isAuth = false;
        this.removeFingerPrint();
      } finally {
        this.callAuthListeners();
      }
    }
    return null;
  }

  async signIn(params: Omit<SignInParams, 'fingerprint'>): Promise<UserDTOWithAuthData> {
    const {isRememberMe, password, email} = params;
    const fingerprint = await this.fingerprintService.create();
    const {accessToken, userInfo} = await AuthApi.signIn({email, password, fingerprint});

    this.accessToken = accessToken;
    this.isAuth = true;
    this.jwtPayload = this.parseJwt(accessToken);

    this.storeFlagAndFingerprint(isRememberMe, fingerprint);
    this.callAuthListeners();
    return {...userInfo, ...this.getUserInfoFromToken()};
  }

  async signOut(): Promise<void> {
    this.removeFingerPrint();
    this.isAuth = false;
    this.accessToken = null;
    this.callAuthListeners();
  }

  async refreshToken(): Promise<RefreshTokenResponse> {
    if (localStorage?.getItem('isRefreshing')) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
    localStorage?.setItem('isRefreshing', 'true');
    const res = await AuthApi.refreshToken({
      fingerprint: await this.fingerprintService.get(),
    });
    localStorage?.removeItem('isRefreshing');
    return res;
  }

  async getAccessToken(): Promise<string | null> {
    return this.accessToken;
  }

  storeFlagAndFingerprint(value: boolean, fingerprint: string): void {
    localStorage.setItem('fingerprint', fingerprint);
    localStorage.setItem(this.isRememberMeKey, value ? 'true' : 'false');
  }

  getIsRememberFlag(): string {
    return localStorage?.getItem(this.isRememberMeKey) ?? '';
  }

  hasUserCheckedRememberFlag(): boolean {
    return this.getIsRememberFlag() === 'true';
  }

  setAccessToken(token: string) {
    this.accessToken = token;
  }

  parseJwt(token: string): TokenPayload | null {
    try {
      const [, base64Url] = token.split('.');
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
          .join(''),
      );
      return JSON.parse(jsonPayload);
    } catch (e) {
      return null;
    }
  }

  getJwtPayload() {
    return this.jwtPayload;
  }

  getUserInfoFromToken(): Pick<UserDTOWithAuthData, 'id' | 'email' | 'companyId'> {
    return {
      id: Number(this.jwtPayload?.user_id),
      email: this.jwtPayload?.sub ?? '',
      companyId: this.jwtPayload?.company_id ?? null,
    };
  }
}

export const JwtAuthServiceSingleton = JwtAuthService.getInstance();
