import {DialogOverlay} from '@radix-ui/react-dialog';
import * as Sentry from '@sentry/browser';
import {RefreshCcwIcon} from 'lucide-react';
import {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import {CompaniesApi} from 'api';
import {CompanyUserDTO} from 'api/dto';
import {Button} from 'shared/components/shadcn-ui/Button';
import {ComboBox} from 'shared/components/shadcn-ui/Command/ComboBox';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'shared/components/shadcn-ui/Dialog';
import {Separator} from 'shared/components/shadcn-ui/Separator';
import {toast} from 'shared/components/shadcn-ui/Toast/useToast';
import {handleApiErrors} from 'shared/utils/helpers';
import {useRootDispatch} from 'store';
import {companiesAsyncActions} from 'store/companies/actions';

export const ReplaceAdminButton = () => {
  const dispatch = useRootDispatch();
  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const {compId} = useParams<{compId: string}>();
  const [companyUsers, setCompanyUsers] = useState<CompanyUserDTO[]>([]);

  const hideDialog = () => setIsOpenDialog(false);
  const openDialog = () => setIsOpenDialog(true);

  useEffect(() => {
    if (isOpenDialog) {
      fetchCompanyUsers();
    }
  }, [isOpenDialog]);

  const fetchCompanyUsers = async () => {
    if (compId) {
      try {
        const res = await CompaniesApi.getCompanyUsers(compId);
        setCompanyUsers(res);
      } catch (e) {
        Sentry.captureException(e);
        handleApiErrors(e, 'Произошла ошибка при запросе списка пользователей.');
      }
    } else {
      setCompanyUsers([]);
    }
  };

  const companyUsersEmails = useMemo(() => {
    return companyUsers.map(({userId, email}) => ({
      value: String(userId),
      label: email,
    }));
  }, [companyUsers]);

  const submit = async () => {
    if (compId && selectedUserId) {
      try {
        await CompaniesApi.replaceCompanyAdmin(compId, selectedUserId);
        hideDialog();
        toast({
          title: 'Администратор компании заменен.',
        });
        dispatch(companiesAsyncActions.fetchCurrentCompany());
      } catch (e) {
        handleApiErrors(e);
      }
    }
  };

  return (
    <Dialog open={isOpenDialog} onOpenChange={setIsOpenDialog}>
      <DialogTrigger asChild>
        <Button variant="outline" onClick={openDialog}>
          <RefreshCcwIcon className="mr-1" />
          Замена
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]" onPointerDownOutside={(e) => e.preventDefault()}>
        <DialogOverlay />
        <DialogHeader>
          <DialogTitle>Замена администратора</DialogTitle>
          <DialogDescription>Выберите другого пользователя, чтобы заменить администратора.</DialogDescription>
        </DialogHeader>
        <ComboBox
          title="Пользователи"
          options={companyUsersEmails}
          placeholder="Найти по электронной почте"
          defaultValue={selectedUserId}
          onValueChange={setSelectedUserId}
        />
        <Separator />
        <DialogFooter>
          <Button type="button" onClick={hideDialog}>
            Отмена
          </Button>
          <Button type="button" variant="primary" onClick={submit}>
            Заменить администратора
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
