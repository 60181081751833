import {InfoIcon} from 'lucide-react';
import {useState} from 'react';

import {Button} from 'shared/components/shadcn-ui/Button';
import {Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger} from 'shared/components/shadcn-ui/Sheet';

import s from './InfoSheet.module.scss';

export const InfoSheet = () => {
  const [toggledInfo, setToggledInfo] = useState(false);

  const toggle = () => {
    setToggledInfo((prev) => !prev);
  };
  return (
    <Sheet open={toggledInfo} onOpenChange={toggle}>
      <SheetTrigger asChild className="ml-4">
        <Button variant="ghost" size="icon" className="p-0 h-6 w-6 text-neutral-800 ml-auto mr-4 block">
          <InfoIcon />
        </Button>
      </SheetTrigger>
      <SheetContent side="right">
        <SheetHeader>
          <SheetTitle className="mb-2">Описание статей расходов на найм</SheetTitle>
        </SheetHeader>
        <p className={s.infoSheet__paragraf}>
          <span className={s.infoSheet__accent}>Инструменты найма</span> — системы для автоматизации HR-процессов: HRMS,
          ATS, LMS, HRIS. Либо любые другие программы, которые облегчают работу HR-функции. Например, CRM-система для
          автоматизации рекрутмента «Хантфлоу».
        </p>
        <p className={s.infoSheet__paragraf}>
          <span className={s.infoSheet__accent}>Кадровые агентства</span> — рекрутинговые организации, посредники между
          работодателем и кандидатом, которые оказывают услуги по подбору персонала.
        </p>
        <p className={s.infoSheet__paragraf}>
          <span className={s.infoSheet__accent}>Реферальная программа</span> — способ привлечения кандидатов с помощью
          рекомендаций уже работающих сотрудников или других лояльных к HR-бренду людей. Обычно программа подразумевает
          денежное вознаграждение для рефералов.
        </p>
        <p className={s.infoSheet__paragraf}>
          <span className={s.infoSheet__accent}>Рекламные кампании</span> — комплекс мероприятий, направленный на
          привлечение внимания соискателей к открытым вакансиям.
        </p>
        <p className={s.infoSheet__paragraf}>
          <span className={s.infoSheet__accent}>Зарплата рекрутеров</span> — статья расходов, в которую входят часы
          HR-менеджеров, потраченные на найм сотрудников, и их ставка.
        </p>
      </SheetContent>
    </Sheet>
  );
};
