import {CSSProperties} from 'react';

import {cn} from 'shared/utils/helpers';

import s from './ClipLoader.module.scss';
import {createAnimation, cssValue} from './helpers';
import {LoaderSizeProps} from './types';

const clip = createAnimation(
  'ClipLoader',
  '0% {transform: rotate(0deg) scale(1)} 50% {transform: rotate(180deg) scale(0.8)} 100% {transform: rotate(360deg) scale(1)}',
  'clip',
);

export const ClipLoader = ({
  loading = true,
  color = '#ccf04c',
  speedMultiplier = 1,
  cssOverride = {},
  size = 50,
  containerHeight,
  className = '',
  ...resProps
}: LoaderSizeProps): JSX.Element | null => {
  const style: CSSProperties = {
    background: 'transparent !important',
    width: cssValue(size),
    height: cssValue(size),
    borderRadius: '100%',
    border: '2px solid',
    borderTopColor: color,
    borderBottomColor: 'transparent',
    borderLeftColor: color,
    borderRightColor: color,
    display: 'inline-block',
    animation: `${clip} ${0.75 / speedMultiplier}s 0s infinite linear`,
    animationFillMode: 'both',
    ...cssOverride,
  };

  if (!loading) {
    return null;
  }

  return (
    <div className={cn(s.clipLoader, className)} style={containerHeight ? {height: `${containerHeight}px`} : undefined}>
      <span style={style} {...resProps} />
    </div>
  );
};
