import {zodResolver} from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/browser';
import {LocateFixedIcon, RefreshCwIcon} from 'lucide-react';
import {FC, useEffect, useState} from 'react';
import {useForm, useWatch} from 'react-hook-form';
import {NumericFormat} from 'react-number-format';
import {NumberFormatValues} from 'react-number-format/types/types';
import {z} from 'zod';

import {DashboardsApi} from 'api';
import {ValueWithTarget} from 'api/dto';
import {MetricCard} from 'shared/components/Dashboards';
import {DIRTY_FORM_CONFIRM_OPTIONS, formatValuePercentage, MINI_CARD_TIPS} from 'shared/components/Dashboards/utils';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from 'shared/components/shadcn-ui/Form';
import {Input} from 'shared/components/shadcn-ui/Input';
import {Separator} from 'shared/components/shadcn-ui/Separator';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from 'shared/components/shadcn-ui/Sheet';
import {toast} from 'shared/components/shadcn-ui/Toast/useToast';
import {useCurrentCompany, useConfirmation} from 'shared/hooks';
import {bem, setValidationFormErrors} from 'shared/utils';
import {extractApiErrorMessage} from 'shared/utils/axios';

import s from './FlowRateKpi.module.scss';
import {EditFlowRateKpiSchema} from './validationSchema';

const sn = bem('flowRateKpi', s);

type Props = {
  flowRate: ValueWithTarget | null;
  onAfterEdit: () => void;
};

export const FlowRateKpiMetric: FC<Props> = ({flowRate, onAfterEdit}: Props) => {
  const {company, hasDemoStatus} = useCurrentCompany();
  const [isEditing, setIsEditing] = useState(false);
  const {confirm} = useConfirmation();

  const form = useForm<z.infer<typeof EditFlowRateKpiSchema>>({
    resolver: zodResolver(EditFlowRateKpiSchema),
    defaultValues: {
      flowRateKpiValue: flowRate?.value,
    },
  });
  const flowRateKpiValue = useWatch({control: form.control, name: 'flowRateKpiValue'});

  const onEditDialogVisibleChange = async (isOpened: boolean) => {
    if (!isOpened) {
      if (form.formState.isDirty && !(await confirm(DIRTY_FORM_CONFIRM_OPTIONS))) return;
      form.reset(form.formState.defaultValues);
    }
    setIsEditing(isOpened);
  };

  // TODO: в будущем бэк сделает 1 АПИ точку для редактирования и тогда можно будет переиспользовать редактирование
  const onSubmit = async (data: z.infer<typeof EditFlowRateKpiSchema>) => {
    const {flowRateKpiValue} = data;

    if (!company?.id || flowRateKpiValue === null) return;
    try {
      await DashboardsApi.setFlowRateKpi(company.id, flowRateKpiValue);
      toast({
        title: 'Целевой показатель успешно изменён.',
      });
      onAfterEdit();
      cancelEdit();
    } catch (e) {
      Sentry.captureException(e);
      const extractedError = extractApiErrorMessage(e);
      if (typeof extractedError === 'string') {
        toast({
          title: extractedError,
          variant: 'destructive',
        });
      } else {
        setValidationFormErrors<typeof data>(extractedError, (fieldName, fieldErrors) => {
          form.setError(fieldName, {
            message: fieldErrors[0],
          });
        });
      }
    }
  };

  useEffect(() => {
    form.reset({flowRateKpiValue: flowRate?.targetValue});
  }, [flowRate?.targetValue]);

  const startEdit = () => setIsEditing(true);

  const cancelEdit = () => {
    form.reset();
    setIsEditing(false);
  };

  const onValueChange = ({floatValue}: NumberFormatValues) => {
    form.setValue('flowRateKpiValue', Number(floatValue ?? 0), {shouldDirty: true, shouldValidate: true});
  };

  const resetValue = () => {
    form.reset({flowRateKpiValue: flowRate?.value ?? 0});
  };

  return (
    <>
      <MetricCard
        title="Текучесть"
        value={flowRate?.value ?? null}
        targetValue={flowRate?.targetValue ?? 0}
        targetIcon={<LocateFixedIcon />}
        description={MINI_CARD_TIPS.fluidity}
        icon={<RefreshCwIcon />}
        editIcon={<LocateFixedIcon />}
        onEditIconClick={startEdit}
        formatter={formatValuePercentage}
      />
      <Sheet open={isEditing} onOpenChange={onEditDialogVisibleChange}>
        <SheetContent side="right">
          <SheetHeader>
            <SheetTitle>KPI показателя текучести</SheetTitle>
            <SheetDescription>Укажите KPI, чтобы отслеживать его на дашборде.</SheetDescription>
          </SheetHeader>
          <Form {...form}>
            <form id="editFlowKpiMetricForm" className={sn('form')} onSubmit={form.handleSubmit(onSubmit)}>
              <FormField
                control={form.control}
                name="flowRateKpiValue"
                render={({field}) => (
                  <FormItem>
                    <FormLabel>KPI текучести</FormLabel>
                    <div className={sn('formField')}>
                      <FormControl>
                        <NumericFormat
                          defaultValue={0}
                          customInput={Input}
                          value={field.value}
                          suffix=" %"
                          onValueChange={onValueChange}
                          disabled={hasDemoStatus}
                          className="disabled:cursor-default"
                        />
                      </FormControl>
                      <Button disabled={hasDemoStatus} variant="link" type="button" onClick={resetValue}>
                        Сбросить
                      </Button>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
          <MetricCard
            title="Текучесть"
            value={flowRate?.value ?? 0}
            targetValue={flowRateKpiValue ?? 0}
            targetIcon={<LocateFixedIcon />}
            description={MINI_CARD_TIPS.fluidity}
            icon={<RefreshCwIcon />}
            formatter={formatValuePercentage}
          />
          <Separator className="mt-4 mb-4" />
          <SheetFooter className="justify-start">
            <Button
              variant="primary"
              type="submit"
              form="editFlowKpiMetricForm"
              disabled={hasDemoStatus || flowRateKpiValue === flowRate?.value}
            >
              Сохранить изменения
            </Button>
          </SheetFooter>
        </SheetContent>
      </Sheet>
    </>
  );
};
export default FlowRateKpiMetric;
