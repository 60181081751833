import cn from 'classnames';
import {FC} from 'react';

import {ClipLoader} from 'shared/components/ClipLoader';

import s from '../HrBrand.module.scss';

type Props = {
  data: Array<{phrase: string; visits: number}>;
  isLoading: boolean;
  className?: string;
};

export const TopSearchQueriesList: FC<Props> = ({data, isLoading, className}: Props) => {
  if (isLoading) {
    return <ClipLoader />;
  }

  return (
    <>
      <div className={cn(s.topSearchQueriesCard, className)}>
        <div
          className={cn(s.topSearchQueriesCard__itemQuery, s.topSearchQueriesCard__itemQuery_header, {
            [s.topSearchQueriesCard__itemQuery_disabled]: !data?.length,
          })}
        >
          <span>Последняя поисковая фраза</span>
          <span>Визиты</span>
        </div>
        {data.map((query) => (
          <div key={`${query.phrase}_${query.visits}_key`} className={s.topSearchQueriesCard__itemQuery}>
            <a
              target="_blank"
              href={`https://yandex.ru/search/?text=${query.phrase}`}
              className={s.topSearchQueriesCard__link}
              rel="noreferrer"
            >
              {query.phrase}
            </a>
            <span>{query.visits}</span>
          </div>
        ))}
      </div>
    </>
  );
};
