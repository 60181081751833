import {Navigate, Route, createRoutesFromElements, generatePath} from 'react-router';
import {Link, createBrowserRouter} from 'react-router-dom';

import {
  AccountRoles,
  CompanyDetailsDTO,
  CompanyDTO,
  CompanyRoles,
  IntegrationTypeId,
  YandexIntegrationType,
} from 'api/dto';
import {AuthSettings} from 'modules/AuthSettings/AuthSettings';
import {ConfirmEmail} from 'modules/AuthSettings/ConfirmEmail';
import {ForgotPasswordForm} from 'modules/AuthSettings/ForgotPasswordForm';
import {NewPasswordForm} from 'modules/AuthSettings/NewPasswordForm';
import {Home} from 'modules/Home';
import {Login} from 'modules/Login';
import {Settings} from 'modules/Settings';
import {CompanyUserEditForm} from 'modules/Settings/CompanyUserEditForm';
import {NewCompanyUser} from 'modules/Settings/NewCompanyUser';
import {Profile} from 'modules/Settings/Profile';
import {Sources} from 'modules/Settings/Sources';
import {HuntflowIntegration} from 'modules/Settings/Sources/components/HuntflowIntegration';
import {OneCIntegration} from 'modules/Settings/Sources/components/OneCIntegration';
import {YandexDirectIntegration} from 'modules/Settings/Sources/components/YandexDirectIntegration';
import {Companies} from 'modules/SuperAdmin/Companies';
import {CompanyDetails} from 'modules/SuperAdmin/Company';
import {CompanyUser} from 'modules/SuperAdmin/CompanyUser';
import {NewCompany} from 'modules/SuperAdmin/NewCompany';
import {Requests} from 'modules/SuperAdmin/Requests/Requests';
import {JwtAuthServiceSingleton} from 'services/JwtAuthService';
import {AuthProvider} from 'shared/components/AuthProvider';
import {ConfirmationProvider} from 'shared/components/ConfirmationProvider/ConfirmationProvider';
import {EditCompany} from 'shared/components/EditCompany';
import {ProtectedRoute} from 'shared/components/ProtectedRoute';
import {ProtectedRouteByCompanyRole} from 'shared/components/ProtectedRouteByCompanyRole';
import {UnauthenticatedRoute} from 'shared/components/UnauthenticatedRoute';
import {UsersCard} from 'shared/components/UsersCard';
import {OAuthYandexSystemPage} from 'shared/components/YandexOauth/OAuthYandexSystemPage';
import {YandexIntegrationProvider} from 'shared/components/YandexOauth/YandexIntegrationProvider';
import {routes} from 'shared/constants/routes';
import {AuthLayout} from 'shared/layouts/AuthLayout';
import {BaseLayout} from 'shared/layouts/BaseLayout';

import {PermissionDenied} from './modules/PermissionDenied';
import {SettingsContextProvider} from './modules/Settings/SettingsContext';
import {YandexMetricaIntegration} from './modules/Settings/Sources/components/YandexMetricaIntegration';
import {CompanyDashboard} from './modules/SuperAdmin/CompanyDashboard';
import {CommonMetricsDashboard} from './modules/User/CommonMetrics';
import {HrBrandDashboard} from './modules/User/HrBrand';
import {RecruitmentDashboard} from './modules/User/Recruitment';
import {DashboardContextProvider} from './shared/components/Dashboards';

export const Router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<ConfirmationProvider />}>
      <Route element={<AuthProvider authService={JwtAuthServiceSingleton} />}>
        <Route element={<ProtectedRoute />}>
          <Route path={routes.home} element={<Home />} />
          {/* Callback страницы для приложений Яндекса */}
          <Route
            path={routes.oauthYandexMetricaSystemPage}
            element={<OAuthYandexSystemPage type={YandexIntegrationType.metrica} />}
          />
          <Route
            path={routes.oauthYandexDirectSystemPage}
            element={<OAuthYandexSystemPage type={YandexIntegrationType.direct} />}
          />
          {/* END Callback страницы для приложений Яндекса */}

          <Route element={<BaseLayout />}>
            <Route element={<SettingsContextProvider />}>
              <Route
                path={routes.settings}
                element={<Settings />}
                handle={{crumb: () => <Link to={routes.settings}>Настройки</Link>}}
              >
                <Route index path={routes.profileSettings} element={<Profile />} />
                <Route element={<ProtectedRouteByCompanyRole allowedRoles={[CompanyRoles.admin]} />}>
                  <Route path={routes.companySettings} element={<EditCompany />} />
                  <Route path={routes.sourcesSettings} element={<Sources />}>
                    <Route index element={<OneCIntegration />} />
                    <Route index path={routes.oneCIntegration} element={<OneCIntegration />} />
                    <Route path={routes.huntflowIntegration} element={<HuntflowIntegration />} />

                    <Route
                      element={
                        <YandexIntegrationProvider
                          type={YandexIntegrationType.metrica}
                          key={`${YandexIntegrationType.metrica}-type`}
                        />
                      }
                    >
                      <Route path={routes.yandexMetricIntegration} element={<YandexMetricaIntegration />} />
                    </Route>
                    <Route
                      element={
                        <YandexIntegrationProvider
                          type={YandexIntegrationType.direct}
                          key={`${YandexIntegrationType.direct}-type`}
                        />
                      }
                    >
                      <Route path={routes.yandexDirectIntegration} element={<YandexDirectIntegration />} />
                    </Route>
                  </Route>
                  <Route path={routes.companyUsersSettings}>
                    <Route index element={<UsersCard />} />
                    <Route
                      handle={{
                        crumb: () => <span>Добавление нового пользователя</span>,
                        prevCrumb: () => (
                          <Link to={routes.companyUsersSettings} state={{tab: 'users'}}>
                            Пользователи
                          </Link>
                        ),
                      }}
                      path={routes.addNewCompanyUser}
                      element={<NewCompanyUser />}
                    />
                    <Route
                      handle={{
                        crumb: () => <span>Редактирование пользователя</span>,
                        prevCrumb: () => (
                          <Link to={routes.companyUsersSettings} state={{tab: 'users'}}>
                            Пользователи
                          </Link>
                        ),
                      }}
                      path={routes.editCompanyUser}
                      element={<CompanyUserEditForm />}
                    />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route element={<ProtectedRoute roles={[AccountRoles.superAdmin]} />}>
              <Route path={routes.companies} handle={{crumb: () => <Link to={routes.companies}>Kомпании</Link>}}>
                <Route index element={<Companies />} />
                <Route
                  path={routes.addNewCompany}
                  element={<NewCompany />}
                  handle={{crumb: () => <span>Создание новой компании</span>}}
                />
                <Route
                  path={routes.company}
                  handle={{
                    crumb: (data: CompanyDTO) => (
                      <Link className="max-w-2xl" to={generatePath(routes.company, {compId: data?.id ?? 1})}>
                        Компания &#171;{data?.companyName || ''}&#187;
                      </Link>
                    ),
                    isShownTitleTooltip: true,
                  }}
                >
                  <Route index element={<CompanyDetails />} />
                  <Route
                    path={routes.companyUser}
                    element={<CompanyUser />}
                    handle={{
                      crumb: (company: CompanyDetailsDTO & {isAdminEditing: boolean}) => {
                        return (
                          <span>Редактирование {company?.isAdminEditing ? 'администратора' : 'пользователя'}</span>
                        );
                      },
                    }}
                  />
                  <Route
                    path={routes.addUserBySuperAdmin}
                    element={<NewCompanyUser />}
                    handle={{
                      crumb: () => <span>Добавление нового пользователя</span>,
                    }}
                  />
                  <Route
                    path={routes.companyEdit}
                    element={<EditCompany />}
                    handle={{
                      crumb: () => 'Редактирование компании',
                    }}
                  />
                  <Route element={<DashboardContextProvider />}>
                    <Route
                      path={routes.companyDashboard}
                      element={<CompanyDashboard />}
                      handle={{
                        crumb: () => 'Дашборды',
                      }}
                    />
                  </Route>
                </Route>
              </Route>
              <Route
                path={routes.requests}
                handle={{crumb: () => <Link to={routes.requests}>Заявки на полный доступ к системе</Link>}}
              >
                <Route index element={<Requests />} />
              </Route>
            </Route>
            <Route element={<ProtectedRoute roles={[AccountRoles.user]} />}>
              <Route path={routes.dashboard} element={<Navigate to={routes.generalDashboard} />} />
              <Route element={<DashboardContextProvider />}>
                <Route
                  index={true}
                  path={routes.generalDashboard}
                  element={<CommonMetricsDashboard />}
                  handle={{crumb: () => <Link to={routes.generalDashboard}>Общие метрики</Link>}}
                />
                <Route
                  path={routes.recruitmentDashboard}
                  element={<RecruitmentDashboard />}
                  handle={{crumb: () => <Link to={routes.recruitmentDashboard}>Подбор персонала</Link>}}
                />
                <Route
                  path={routes.hrBrandDashboard}
                  element={<HrBrandDashboard />}
                  handle={{crumb: () => <Link to={routes.hrBrandDashboard}>HR-бренд</Link>}}
                />
              </Route>
            </Route>
          </Route>
        </Route>

        <Route element={<AuthSettings />}>
          <Route element={<AuthLayout />}>
            <Route path={routes.confirmEmail} element={<ConfirmEmail />} />
            <Route path={routes.forgotPassword} element={<ForgotPasswordForm />} />
            <Route path={routes.resetPassword} element={<NewPasswordForm />} />
          </Route>
        </Route>
        <Route element={<UnauthenticatedRoute />}>
          <Route path={routes.login} element={<AuthLayout />}>
            <Route index element={<Login />} />
          </Route>
        </Route>
        <Route element={<UnauthenticatedRoute />}>
          <Route path={routes.login} element={<AuthLayout />}>
            <Route index element={<Login />} />
          </Route>
        </Route>
      </Route>

      <Route path="/permission-denied" element={<PermissionDenied />} />
      <Route path="*" element={<Navigate replace to={routes.login} />} />
    </Route>,
  ),
);
