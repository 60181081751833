import {ReactElement} from 'react';
import {Navigate, Outlet} from 'react-router';

import {AccountRoles} from 'api/dto';
import {routes} from 'shared/constants/routes';
import {useRootSelector} from 'store';
import {authSelectors} from 'store/auth/selectors';

import {useAuth} from '../AuthProvider';

type Props = {
  roles?: AccountRoles | AccountRoles[];
  path?: string;
  children?: ReactElement;
};

export const ProtectedRoute = ({roles, children}: Props) => {
  const {isAuth, initialized} = useAuth();
  const user = useRootSelector(authSelectors.getUser);
  const allowedRoles = ([] as AccountRoles[]).concat(roles || []);

  if (!isAuth && initialized) return <Navigate to={routes.login} replace />;

  if (allowedRoles.length && user) {
    const hasPermission = allowedRoles.some((role) => user?.systemRoleName?.includes(role));
    if (!hasPermission) return <Navigate to={routes.permissionDenied} replace />;
  }

  return children ? children : <Outlet />;
};
