import {
  format,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  parse,
  parseISO,
  subWeeks,
  eachDayOfInterval,
  differenceInDays,
  eachMonthOfInterval,
  isMonday,
  isValid,
} from 'date-fns';
// eslint-disable-next-line no-duplicate-imports
import {ru} from 'date-fns/locale';
import {DateRange} from 'react-day-picker';

export const SHORT_ISO = 'yyyy-MM-dd';
export const DEFAULT_DATE_FORMAT = 'dd.MM.yyyy';

export function safeFormatDate(date: Date | string | null | undefined, formatPattern = DEFAULT_DATE_FORMAT): string {
  if (!date) return '';
  if (!(date instanceof Date)) {
    date = safeParseDate(date);
  }
  return date ? format(date, formatPattern, {locale: ru}) : '';
}

export function safeParseDate(date: string | Date | null | undefined, format?: string): Date | null {
  if (!date) return null;

  let res: Date;
  if (date instanceof Date) {
    res = date;
  } else {
    try {
      res = !format ? parseISO(date) : parse(date, format, new Date());
    } catch (error) {
      res = new Date(date);
    }
  }
  return !isNaN(res.getTime()) ? res : null;
}

export const isBeforeOrSame = (source: Date, target: Date): boolean => {
  return isBefore(source, target) || isEqual(source, target);
};

export const isAfterOrSame = (source: Date, target: Date): boolean => {
  return isAfter(source, target) || isEqual(source, target);
};

export const addSeconds = (startDate: Date, amount: number): Date => {
  return addSeconds(startDate, amount);
};

export const prepareDateRange = (dateRange: DateRange | undefined): Partial<{startDate: string; endDate: string}> => {
  const today = new Date();
  const isEndDateAfterToday = dateRange?.to && isBefore(today, dateRange?.to);
  const isStartDateAfterToday = dateRange?.from && isBefore(today, dateRange?.from);
  const startDate = isStartDateAfterToday ? subWeeks(today, 1) : dateRange?.from;
  const endDate = isEndDateAfterToday ? today : dateRange?.to;
  return {
    startDate: safeFormatDate(startDate, SHORT_ISO) || undefined,
    endDate: safeFormatDate(endDate, SHORT_ISO) || undefined,
  };
};

export const isEqualDates = (first: Date | undefined, second: Date | undefined): boolean => {
  if (!first || !second) return false;
  return isSameDay(first, second);
};

export const generateDateListByPeriod = (range: DateRange): string[] => {
  const {from, to} = range;

  const isValidInterval = from && to && isValid(to) && isValid(from) && isBefore(from, to);
  if (!isValidInterval) return [];

  const diffInDays = differenceInDays(to, from);
  const dateList = eachDayOfInterval({start: from, end: to});

  if (diffInDays <= 31) {
    return dateList.map((date) => format(date, DEFAULT_DATE_FORMAT));
  }
  if (diffInDays <= 92) {
    return dateList.reduce((acc: string[], day) => {
      if (isMonday(day)) acc.push(format(day, DEFAULT_DATE_FORMAT));
      return acc;
    }, []);
  }

  const monthsList = eachMonthOfInterval({
    start: from,
    end: to,
  }).map((item) => format(item, 'MM.yyyy'));

  return monthsList;
};
