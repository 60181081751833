import * as Sentry from '@sentry/browser';
import {useRef, useState} from 'react';
import {useNavigate} from 'react-router';
import {useSearchParams} from 'react-router-dom';

import {UsersApi} from 'api';
import SuccessImg from 'assets/icons/tick.svg';
import {Button} from 'shared/components/shadcn-ui/Button';
import {routes} from 'shared/constants/routes';
import {useEffectOnce} from 'shared/hooks';
import {bem} from 'shared/utils';

import s from './ConfirmEmail.module.scss';

const sn = bem('confirmEmail', s);

export const ConfirmEmail = () => {
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email') || '';
  const emailToken = searchParams.get('token') || '';
  const passwordToken = useRef<string>();

  const sendRequest = async () => {
    try {
      if (emailToken && email && !isLoading) {
        setIsLoading(true);
        const {token} = await UsersApi.confirmEmail(email, emailToken);
        passwordToken.current = token;
        setIsSuccess(true);
      } else {
        openLogin();
      }
    } catch (e) {
      setIsSuccess(false);
      Sentry.captureException(e);
    } finally {
      setIsLoading(false);
    }
  };

  const setPassword = () => {
    const url = `${routes.resetPassword}?email=${email}&token=${passwordToken.current}`;
    navigate(url);
  };

  useEffectOnce(() => {
    sendRequest();
  }, []);

  const openLogin = () => {
    navigate(routes.login);
  };
  const getMessage = () => {
    return isSuccess
      ? `Ваш электронный адрес ${email} успешно подтвержден.`
      : 'Ссылка имеет неправильный формат или срок ее жизни истек.';
  };
  const getTitle = () => {
    return isSuccess ? 'Поздравляем' : 'Внимание';
  };
  return (
    <div className={s.confirmEmail}>
      <div className={s.confirmEmail__content}>
        {
          <>
            <h1 className={s.confirmEmail__title}>{isLoading ? 'Идет загрузка' : getTitle()}</h1>
            <p className={s.confirmEmail__subtitle}>{isLoading ? 'Пожалуйста, подождите...' : getMessage()}</p>
            {isSuccess && <img className={sn('success')} src={SuccessImg} alt="Success image" />}
            {
              <Button variant="primary" onClick={isSuccess ? setPassword : openLogin}>
                {isSuccess ? 'Установить новый пароль' : 'Перейти на страницу входа'}
              </Button>
            }
          </>
        }
      </div>
    </div>
  );
};
