import {zodResolver} from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/browser';
import {useEffect, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {generatePath, useNavigate, useParams} from 'react-router-dom';
import * as z from 'zod';

import {CompaniesApi} from 'api';
import {CompanyRole} from 'api/dto';
import {PhoneInput} from 'shared/components/PhoneInput';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Card} from 'shared/components/shadcn-ui/Card';
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from 'shared/components/shadcn-ui/Form';
import {Input} from 'shared/components/shadcn-ui/Input';
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from 'shared/components/shadcn-ui/Select';
import {useToast} from 'shared/components/shadcn-ui/Toast/useToast';
import {routes} from 'shared/constants/routes';
import {useLoadCompany} from 'shared/hooks/useLoadCompany';
import {useProvideBreadcrumbsData} from 'shared/layouts/BaseLayout/BaseLayoutContext';
import {setValidationFormErrors} from 'shared/utils';
import {extractApiErrorMessage} from 'shared/utils/axios';
import {getRawPhoneNumber} from 'shared/utils/helpers';
import {useRootSelector} from 'store';
import {dictionariesSelector} from 'store/dictionaries/selectors';

import s from './CompanyUser.module.scss';
import {FormSchema} from './validationSchema';

export const CompanyUser = () => {
  const {company, loaded} = useLoadCompany();
  const navigate = useNavigate();
  const {compId, userId} = useParams<{compId: string; userId: string}>();
  const {toast} = useToast();
  const userStatuses = useRootSelector(dictionariesSelector.getDictionaries).userStatuses;

  const allCompanyUsers = company?.users.concat(company?.adminInfo) ?? [];
  const user = allCompanyUsers.find((company) => company.userId === Number(userId));

  const isAdminUser = user?.companyRoleName === CompanyRole.Admin;
  const breadcrumbsData = useMemo(() => ({...company, isAdminEditing: isAdminUser}), [user]);

  useProvideBreadcrumbsData(breadcrumbsData);

  useEffect(() => {
    if (company && !loaded && !user) {
      goToCompanyPage();
    }
  }, [company, loaded, user]);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      phoneNumber: user?.phoneNumber,
      statusId: String(user?.statusId),
    },
  });

  const goToCompanyPage = () => {
    navigate(generatePath(routes.company, {compId: company?.id}));
  };

  useEffect(() => {
    if (user) {
      form.reset({
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
        statusId: String(user.statusId),
      });
    }
  }, [user]);

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    if (compId && userId && user) {
      try {
        const model = {
          ...data,
          statusId: parseInt(data.statusId),
          phoneNumber: data.phoneNumber ? getRawPhoneNumber(data.phoneNumber) : '',
        };
        // TODO: в будущем бэк уйдет от разделения эндпоинтов
        if (isAdminUser) {
          await CompaniesApi.updateCompanyAdmin(compId, model);
        } else {
          await CompaniesApi.updateCompanyUser(compId, user.userId, model);
        }

        toast({
          title: 'Изменения сохранены',
        });
        goToCompanyPage();
      } catch (e) {
        Sentry.captureException(e);
        const extractedError = extractApiErrorMessage(e);
        if (typeof extractedError === 'string') {
          toast({
            variant: 'destructive',
            title: extractedError,
          });
        } else {
          setValidationFormErrors<typeof data>(extractedError, (fieldName, fieldErrors) => {
            form.setError(fieldName, {
              message: fieldErrors[0],
            });
          });
        }
      }
    }
  };

  return (
    <Card className={s.companyUser}>
      <Form {...form}>
        <form id="companyUserForm" className={s.companyUser__form} onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="firstName"
            render={({field}) => (
              <FormItem className="h-[92px]">
                <FormLabel>Имя</FormLabel>
                <FormControl>
                  <Input placeholder="Имя" autoComplete="off" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            render={({field}) => (
              <FormItem className="h-[92px]">
                <FormLabel>Фамилия</FormLabel>
                <FormControl>
                  <Input placeholder="Фамилия" autoComplete="off" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="phoneNumber"
            render={({field}) => (
              <FormItem className="h-[92px]">
                <FormLabel>Контактный телефон</FormLabel>
                <FormControl>
                  <PhoneInput {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="statusId"
            render={({field}) => (
              <FormItem className="h-[92px]">
                <FormLabel>Статус учетной записи</FormLabel>
                <FormControl>
                  <Select onValueChange={field.onChange} value={String(field.value)}>
                    <SelectTrigger className="text-base">
                      <SelectValue placeholder={user?.statusName} />
                    </SelectTrigger>
                    <SelectContent>
                      {userStatuses?.map(({value, description}) => (
                        <SelectItem key={`${description}_${value}`} value={String(value)}>
                          {description}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          />
        </form>
        <div className={s.companyUser__actions}>
          <Button type="button" variant="outline" onClick={goToCompanyPage}>
            Отмена
          </Button>
          <Button type="submit" form="companyUserForm" variant="primary" disabled={form.formState.isSubmitting}>
            {form.formState.isSubmitting ? 'Загрузка...' : 'Cохранить и закрыть'}
          </Button>
        </div>
      </Form>
    </Card>
  );
};
