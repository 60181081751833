import EmptyImg from 'assets/icons/account_circle.svg';
import {CardDescription, CardTitle} from 'shared/components/shadcn-ui/Card';

export const EmptySearchResult = () => {
  return (
    <div className='flex items-center flex-space-y-1.5 justify-center flex-col gap-2 my-5"'>
      <img src={EmptyImg} alt="Success image" className="mt-5" />
      <CardTitle>Не удалось найти пользователей</CardTitle>
      <CardDescription className="text-base max-w-sm text-center px-4">
        Попробуйте изменить фильтры поиска или указать другие данные
      </CardDescription>
    </div>
  );
};
