import cn from 'classnames';
import {differenceInDays, differenceInHours} from 'date-fns';
import {AlertCircleIcon, CheckCircleIcon} from 'lucide-react';
import {FC} from 'react';

import {Alert, AlertDescription, AlertTitle} from 'shared/components/shadcn-ui/Alert';
import {safeFormatDate} from 'shared/utils/date';
import {pluralizeDays} from 'shared/utils/helpers';

type Props = {
  className?: string;
  statusExpiresAt: Date;
};

export const ActiveCompanyBadge: FC<Props> = ({statusExpiresAt, className = ''}: Props) => {
  const subscriptionPeriodInHours = differenceInHours(statusExpiresAt, new Date());
  const subscriptionPeriodInDays = differenceInDays(statusExpiresAt, new Date());
  const formattedDate = safeFormatDate(statusExpiresAt, 'dd.MM.yyyy');

  const getTitle = () => {
    if (subscriptionPeriodInDays > 7) {
      return 'Подписка активна.';
    } else if (subscriptionPeriodInDays > 0 && subscriptionPeriodInDays <= 7) {
      return `Подписка заканчивается через ${pluralizeDays(subscriptionPeriodInDays)}.`;
    } else if (subscriptionPeriodInDays === 0 && subscriptionPeriodInHours > 0) {
      return 'Подписка заканчивается завтра.';
    } else {
      return `Подписка истекла ${formattedDate}.`;
    }
  };
  return (
    <Alert
      className={cn(
        'bg-success-100 text-success-700 max-w-xl mb-8',
        {
          ['bg-error-100 text-error']: subscriptionPeriodInDays <= 7,
        },
        className,
      )}
    >
      {subscriptionPeriodInDays > 7 ? (
        <CheckCircleIcon className="stroke-success-700" />
      ) : (
        <AlertCircleIcon className="stroke-error" />
      )}
      <AlertTitle>{getTitle()}</AlertTitle>
      <AlertDescription>Дата окончания подписки: {formattedDate}.</AlertDescription>
    </Alert>
  );
};
