import {useRef, useState} from 'react';

import {YandexIntegrationType, YandexOAuthSettingsDTO} from 'api/dto';
import {YandexApi} from 'api/services';
import {toast} from 'shared/components/shadcn-ui/Toast/useToast';
import {useUnmount, useCurrentCompany} from 'shared/hooks';

interface MessageData {
  event: 'success' | 'reject';
  error?: string;
  accessToken: string;
  expiresAt: Date;
}

export interface ErrorData {
  title: string;
  status: number;
}

export function useYandexOAuth(type: YandexIntegrationType) {
  const {company} = useCurrentCompany();
  const popup = useRef<Window | null>();
  const closeCheckTimer = useRef<number | null>(null);
  const [oAuthConfig, setOAuthConfig] = useState<Omit<YandexOAuthSettingsDTO, 'type'> | null>(null);
  const [authenticated, setAuthenticated] = useState<boolean | null>(null);
  const [error, setError] = useState<ErrorData | null>(null);

  async function init() {
    try {
      if (company?.id) {
        const link =
          type === YandexIntegrationType.direct
            ? await YandexApi.getYandexDirectRedirectLink(company?.id)
            : await YandexApi.getYandexMetricaRedirectLink(company?.id);

        if (link) window.addEventListener('message', onMessageReceived);

        popup.current = window.open(link, 'Авторизация по Яндекс.ID', 'left=100,top=100,width=600,height=600');
        closeCheckTimer.current = window.setInterval(() => {
          if (popup.current?.closed) {
            clear();
            setAuthenticated(false);
            toast({
              description: 'Не удалось авторизоваться по Яндекс ID.',
              variant: 'destructive',
            });
          }
        }, 300);
        popup.current?.focus();
      }
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }

  const onMessageReceived = (e: MessageEvent<MessageData>) => {
    if (e.origin !== location.origin) return;
    const {accessToken, expiresAt} = e.data;
    setOAuthConfig(accessToken && expiresAt ? {accessToken, expiresAt} : null);

    if (e.origin === location.origin) {
      if (e.data.event === 'success') {
        setAuthenticated(true);
        clear();
      }
      if (e.data.event === 'reject') {
        setAuthenticated(false);
        const parcedError = e.data.error
          ? JSON.parse(e.data.error)
          : {title: 'Не удалось подключить интеграцию с вашим профилем Яндекс ID.', status: 500};
        setError(parcedError);
        clear();
        setOAuthConfig(null);
      }
    }
  };

  function clear() {
    window.removeEventListener('message', onMessageReceived);
    if (closeCheckTimer.current) {
      clearInterval(closeCheckTimer.current);
    }
    popup.current?.close();
    popup.current = undefined;
  }

  function reset() {
    setAuthenticated(null);
  }

  useUnmount(() => {
    clear();
  });

  return {authenticated, init, reset, clear, popup, oAuthConfig, setOAuthConfig, error};
}
