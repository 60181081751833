import * as z from 'zod';

export const FormSchema = z.object({
  email: z
    .string()
    .transform((email) => email.trim())
    .pipe(
      z.string().email({
        message: 'Некорректный email',
      }),
    ),
  password: z
    .string()
    .min(8, {
      message: 'Пароль должен содержать от 8 до 15 символов',
    })
    .max(15, {message: 'Пароль должен содержать от 8 до 15 символов'}),
  isRememberMe: z.boolean().default(false),
});
