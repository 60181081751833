import {FilterFnOption, createColumnHelper} from '@tanstack/react-table';
import {generatePath, Link} from 'react-router-dom';

import {FullAccessRequestDTO} from 'api/dto/FullAccessRequestDTO';
import {DatePickerTableTitle, DirType} from 'shared/components/DatePickerTableTitle';
import {routes} from 'shared/constants/routes';
import {DEFAULT_DATE_FORMAT, safeFormatDate} from 'shared/utils/date';

const columnHelper = createColumnHelper<FullAccessRequestDTO>();

export const getColumns = () => {
  // startDateSortDir и endDateSortDir не могут быть отличны от 'none' одновременно,
  // потому что одновременная сортировка по дате создания и по дате окончания нелогична
  let startDateSortDir: DirType = 'none';
  let endDateSortDir: DirType = 'none';

  const setEndDateSortDir = (dir: DirType) => {
    startDateSortDir = 'none';
    endDateSortDir = dir;
  };

  return [
    columnHelper.accessor('createdDate', {
      sortingFn: 'datetime',
      filterFn: 'dateFilter' as FilterFnOption<FullAccessRequestDTO>,
      header: (info) => {
        return (
          <DatePickerTableTitle
            title="Дата создания"
            column={info.column}
            dateSortDir={endDateSortDir}
            setDateSortDir={setEndDateSortDir}
          />
        );
      },
      cell: (info) => <div>{safeFormatDate(info.getValue(), DEFAULT_DATE_FORMAT)}</div>,
      id: 'createdDate',
    }),

    columnHelper.accessor('fio', {
      header: 'Имя',
      cell: ({row}) => (
        <a href={`mailto:${row.original.email}`} className="text-primary">
          {row.original.fio}
        </a>
      ),
      id: 'fio',
    }),

    columnHelper.accessor('phoneNumber', {
      header: 'Телефон',
      id: 'phoneNumber',
    }),

    columnHelper.accessor('companyName', {
      header: 'Название компании',
      cell: ({row}) => {
        if (row.original.hasOwnProperty('companyId')) {
          return (
            <Link to={generatePath(routes.company, {compId: String(row.original.companyId)})} className="text-primary">
              {row.original.companyName}
            </Link>
          );
        } else {
          return <> {row.original.companyName}</>;
        }
      },
      meta: {className: 'grow'},
      id: 'name',
    }),
  ];
};
