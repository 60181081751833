import cn from 'classnames';
import {InfoIcon} from 'lucide-react';
import {HTMLAttributes, ReactNode, forwardRef, useState} from 'react';

import {Button} from 'shared/components/shadcn-ui/Button';
import {Card, CardContent, CardFooter, CardTitle} from 'shared/components/shadcn-ui/Card';
import {Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger} from 'shared/components/shadcn-ui/Sheet';

import s from './ChartCard.module.scss';

export type PropsChartCardType = {
  title: string;
  description?: string;
  className?: string;
  footer?: ReactNode;
  headerActions?: ReactNode;
};

export const ChartCard = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement> & PropsChartCardType>(
  ({title, className, description = '', footer, headerActions, children}, ref) => {
    const [toggledInfo, setToggledInfo] = useState(false);
    const toggle = () => {
      if (description) {
        setToggledInfo((prev) => !prev);
      }
    };
    return (
      <Card className={cn(s.chartCard, className)} ref={ref}>
        <div className={s.header}>
          <CardTitle className={s.header__title}>{title}</CardTitle>
          <Sheet open={toggledInfo} onOpenChange={toggle}>
            <SheetTrigger asChild>
              <Button variant="ghost" size="icon" className="ml-3 p-0 w-6 h-6">
                <InfoIcon />
              </Button>
            </SheetTrigger>
            <SheetContent side="right">
              <SheetHeader>
                <SheetTitle className="mb-2.5">{title}</SheetTitle>
              </SheetHeader>
              {description && (
                <div className={s.chartCard__description} dangerouslySetInnerHTML={{__html: description}} />
              )}
            </SheetContent>
          </Sheet>
          {!!headerActions && <div className={s.chartCard__headerActions}>{headerActions}</div>}
        </div>
        <CardContent className="mt-0">{children}</CardContent>
        {footer && <CardFooter>{footer}</CardFooter>}
      </Card>
    );
  },
);
