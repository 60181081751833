import {ChevronLeft, ChevronRight} from 'lucide-react';
import {FC, MouseEventHandler} from 'react';

import s from './AsideToggleBtn.module.scss';

type Props = {
  isToggled: boolean;
  toggle: MouseEventHandler<HTMLButtonElement>;
};

export const AsideToggleBtn: FC<Props> = ({isToggled, toggle}) => {
  return (
    <button className={s.asideToggleBtn} onClick={toggle}>
      {isToggled ? <ChevronRight /> : <ChevronLeft />}
    </button>
  );
};
