import {EditIcon} from 'lucide-react';
import {generatePath, useNavigate} from 'react-router-dom';

import {FieldBox} from 'shared/components/FieldBox';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Card, CardContent, CardFooter, CardHeader, CardTitle} from 'shared/components/shadcn-ui/Card';
import {CardActions} from 'shared/components/shadcn-ui/Card/Card';
import {routes} from 'shared/constants/routes';
import {useCurrentCompany} from 'shared/hooks';
import {DEFAULT_DATE_FORMAT, safeFormatDate} from 'shared/utils/date';

import s from './CompanyCard.module.scss';

import {SubscriptionBlock} from '../SubscriptionBlock';

export const CompanyInfoCard = () => {
  const {company} = useCurrentCompany();
  const navigate = useNavigate();

  const openDashboard = () => {
    navigate(generatePath(routes.companyDashboard, {compId: company?.id}));
  };

  const openEdit = () => {
    navigate(generatePath(routes.companyEdit, {compId: company?.id}));
  };

  if (!company) {
    return null;
  }

  return (
    <Card className="box-border">
      <CardHeader>
        <CardTitle>Детали компании</CardTitle>
        <CardActions>
          <Button variant="outline" size="icon" onClick={openEdit}>
            <EditIcon />
          </Button>
          <Button variant="primary" onClick={openDashboard}>
            Просмотреть дашборды
          </Button>
        </CardActions>
      </CardHeader>
      <CardContent>
        <div className={s.companyCardContent}>
          <FieldBox
            className={s.companyCardContent__fieldBox}
            label="Наименование организации"
            value={company.companyName}
          />
          <FieldBox className={s.companyCardContent__fieldBox} label="ИНН" value={company.inn} />
          <FieldBox className={s.companyCardContent__fieldBox} label="Юридический адрес" value={company.legalAddress} />
          <FieldBox
            className={s.companyCardContent__fieldBox}
            label="Дата создания"
            value={safeFormatDate(company.createdAt, DEFAULT_DATE_FORMAT)}
          />
        </div>
      </CardContent>
      <CardFooter>
        <SubscriptionBlock company={company} />
      </CardFooter>
    </Card>
  );
};
