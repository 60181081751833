import cn from 'classnames';
import {CSSProperties, FC} from 'react';

import s from './FieldBox.module.scss';

type Props = {
  className?: string;
  style?: CSSProperties;
  label?: string;
  value: string;
};

export const FieldBox: FC<Props> = ({className, style, label, value}: Props) => {
  return (
    <div style={style} className={cn(s.fieldBox, className)}>
      {!!label && <label className={s.fieldBox__label}>{label}</label>}
      <span className={s.fieldBox__value}>{value}</span>
    </div>
  );
};
