import {CompanyUserDTO} from 'api/dto';

export const getDefaultValues = (user?: CompanyUserDTO) => ({
  firstName: user?.firstName || '',
  lastName: user?.lastName || '',
  email: user?.email || '',
  jobTitle: user?.jobTitle || '',
  phoneNumber: user?.phoneNumber || '',
  statusId: String(user?.statusId) || '',
});
