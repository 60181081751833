import {memo} from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  LabelList,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import {CommonMetricsDTO} from 'api/dto';
import {
  getCartesianGridDefaultProps,
  getCustomizedYAxisTick,
  getDefaultTooltipCursorProps,
  getLabelFormatter,
  getLegendDefaultProps,
  getXAxisDefaultProps,
  getXAxisPropsForEmptyChart,
  getYAxisDefaultProps,
  measureText,
} from 'shared/components/Dashboards/utils/helpers';

import {ClipLoader} from '../../../ClipLoader';
import {CustomTooltipContent} from '../../CustomTooltip';
import {useDashboardContext} from '../../DashboardContextProvider';
import {DOMAIN_OF_EMPTY_CHART} from '../../utils';

type Props = {
  data: CommonMetricsDTO['charts']['staffingLevelInfo'];
  isLoading?: boolean;
  className?: string;
  infoMessage?: string;
  containerWidth: number | null;
};

const DEFAULT_BOTTOM_MARGIN = 15;
const BOTTOM_MARGIN_FOR_LONG_LEGEND = 37;

export const ActualStaffingChart = memo(({data, isLoading = false, className = '', containerWidth}: Props) => {
  const {dateRange} = useDashboardContext();

  const getXAxisProps = () => {
    if (isEmptyChart) return getXAxisPropsForEmptyChart(dateRange);
    return getXAxisDefaultProps();
  };

  if (isLoading) {
    return <ClipLoader containerHeight={250} />;
  }

  const valuesLength = data?.values.length;
  const LIMIT_LENGTH = 18;
  const MAX_LEGEND_WIDTH = 779;

  const isEmptyChart = data?.values.length === 0;

  return (
    <>
      <ResponsiveContainer width="100%" height={300} className={className}>
        <BarChart
          width={500}
          height={400}
          barSize={14}
          data={data?.values}
          margin={{
            top: 30,
            right: 25,
            left: 0,
            bottom: 10,
          }}
        >
          <Tooltip content={<CustomTooltipContent />} cursor={getDefaultTooltipCursorProps()} />
          <CartesianGrid {...getCartesianGridDefaultProps()} />
          {data?.staffHeadcount ? (
            <ReferenceLine ifOverflow="visible" y={data.staffHeadcount} stroke="#56547E">
              <Label position="insideTopRight" value={`${data?.staffHeadcount} чел`} />
            </ReferenceLine>
          ) : null}

          <XAxis dataKey="date" {...getXAxisProps()} />
          <YAxis
            {...getYAxisDefaultProps()}
            domain={isEmptyChart ? DOMAIN_OF_EMPTY_CHART : ['dataMin - 5', data?.staffHeadcount ?? 0]}
            width={measureText(String(data?.staffHeadcount ?? 0), 14) + 15}
            tick={getCustomizedYAxisTick}
          />
          <Bar
            fill="#FFC93F"
            dataKey="totalPercentageValue"
            name="Общая укомплектованность"
            radius={[4, 4, 4, 4]}
            barSize={valuesLength <= LIMIT_LENGTH ? 14 : 4}
          >
            {valuesLength <= LIMIT_LENGTH ? (
              <LabelList
                dataKey="totalPercentageValue"
                position="top"
                style={{fontSize: 12, color: '#56547E'}}
                formatter={getLabelFormatter}
              />
            ) : null}
          </Bar>
          <Bar
            fill="#796DEE"
            dataKey="factPercentageValue"
            radius={[4, 4, 4, 4]}
            name="Фактическая укомплектованность"
            barSize={valuesLength <= LIMIT_LENGTH ? 14 : 4}
          >
            {valuesLength <= LIMIT_LENGTH ? (
              <LabelList
                dataKey="factPercentageValue"
                position="top"
                style={{fontSize: 12, color: '#56547E'}}
                formatter={getLabelFormatter}
              />
            ) : null}
          </Bar>
          <Legend
            {...getLegendDefaultProps()}
            wrapperStyle={{
              position: 'relative',
              left: 0,
              bottom:
                containerWidth && containerWidth >= MAX_LEGEND_WIDTH
                  ? DEFAULT_BOTTOM_MARGIN
                  : BOTTOM_MARGIN_FOR_LONG_LEGEND,
              fontSize: 14,
            }}
            payload={[
              {value: 'Общая укомплектованность', type: 'circle', id: 'ID01', color: '#FFC93F'},
              {value: 'Фактическая укомплектованность', type: 'circle', id: 'ID02', color: '#796DEE'},
              {value: 'Штатная численность', type: 'circle', id: 'ID03', color: '#56547E'},
            ]}
          ></Legend>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
});
