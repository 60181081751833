export const MOCK_DATA = {
  metrics: {
    hiringCost: 50159,
    fillingTime: 64,
    enpsPercentage: {
      value: 12,
      targetValue: 3.27,
    },
    npsPercentage: {
      value: 20,
      targetValue: 5,
    },
    flowRate: {
      value: 2.9,
      targetValue: 3.7,
    },
    percentageOfReturnedPersons: 12,
    referralPerformance: 25,
    averageWorkExp: 4.2,
  },
  yandexDirectData: {
    views: 105507,
    clicks: 7421,
    ctr: 2.26,
    conversion: 459,
    viewDepth: 1.07,
    totalCost: 48339,
    clickGoal: 14.24,
    goalCost: 241.31,
  },
  charts: {
    recruitingFunnel: [
      {itemName: 'Новый кандидат', applicantsCount: 10389, percentage: 100},
      {itemName: 'Отобранный кандидат', applicantsCount: 1178, percentage: 11.3},
      {itemName: 'Интервью с рекрутером', applicantsCount: 642, percentage: 6.2},
      {itemName: 'Техническое интервью', applicantsCount: 226, percentage: 2.2},
      {itemName: 'Выставлен оффер', applicantsCount: 182, percentage: 1.8},
      {itemName: 'Оффер принят', applicantsCount: 77, percentage: 0.7},
      {itemName: 'Выход на работу', applicantsCount: 32, percentage: 0.3},
    ],
    dismissalReasonsFunnel: [
      {itemName: 'Более оплачиваемая работа', percentage: 28},
      {itemName: 'Недоволен должностью', percentage: 18},
      {itemName: 'Несоответствует должности', percentage: 6},
      {itemName: 'Личные обстоятельства', percentage: 6},
      {itemName: 'Недоволен компанией', percentage: 3},
      {itemName: 'Другое', percentage: 1},
    ],
    absencesDynamics: [
      {value: 21, date: '2023-12-01'},
      {value: 18, date: '2023-11-01'},
      {value: 14, date: '2023-10-01'},
      {value: 24, date: '2023-09-01'},
      {value: 7, date: '2023-08-01'},
      {value: 10, date: '2023-07-01'},
      {value: 24, date: '2023-06-01'},
      {value: 7, date: '2023-05-01'},
    ],
    workExperienceFunnel: [
      {itemName: 'До 1 года', value: 18},
      {itemName: '1-5 лет', value: 20},
      {itemName: 'Более 5 лет', value: 9},
    ],
  },
  yandexMetricaData: {
    topSearchQueries: [
      {phrase: 'иностудио', visits: 161},
      {phrase: 'инобрайт', visits: 150},
      {phrase: 'какой-то очень важный запрос', visits: 144},
      {phrase: 'как приручить дракона', visits: 89},
      {phrase: 'что такое TDD', visits: 77},
      {phrase: 'data driven подходы в recruitment', visits: 41},
      {phrase: 'hr аналитика', visits: 36},
      {phrase: 'Лучшие HR', visits: 24},
      {phrase: 'data driven подходы в управлении', visits: 18},
      {phrase: 'Стафскрин', visits: 16},
    ],
    visitorsDynamics: [
      {visitors: 42, newVisitors: 25, date: '2023-12-01'},
      {visitors: 80, newVisitors: 2, date: '2023-11-01'},
      {visitors: 114, newVisitors: 10, date: '2023-10-01'},
      {visitors: 33, newVisitors: 12, date: '2023-09-01'},
      {visitors: 31, newVisitors: 9, date: '2023-08-01'},
      {visitors: 17, newVisitors: 8, date: '2023-07-01'},
      {visitors: 11, newVisitors: 22, date: '2023-06-01'},
      {visitors: 22, newVisitors: 15, date: '2023-05-01'},
      {visitors: 16, newVisitors: 4, date: '2023-04-01'},
      {visitors: 25, newVisitors: 5, date: '2023-03-01'},
      {visitors: 44, newVisitors: 25, date: '2023-02-01'},
      {visitors: 32, newVisitors: 25, date: '2023-01-01'},
    ],
    conversionsDynamics: {
      items: [
        {visitors: 42, date: '2023-12-01'},
        {visitors: 80, date: '2023-11-01'},
        {visitors: 114, date: '2023-10-01'},
        {visitors: 33, date: '2023-09-01'},
        {visitors: 10, date: '2023-08-01'},
        {visitors: 3, date: '2023-07-01'},
        {visitors: 11, date: '2023-06-01'},
        {visitors: 22, date: '2023-05-01'},
        {visitors: 11, date: '2023-04-01'},
        {visitors: 25, date: '2023-03-01'},
        {visitors: 44, date: '2023-02-01'},
        {visitors: 32, date: '2023-01-01'},
      ],
      averageValue: 2.8,
    },
    siteAudience: {
      genderData: [
        {name: 'Мужчины', value: 50},
        {name: 'Женщины', value: 50},
      ],
      deviceTypeData: [
        {name: 'Mobile', value: 74},
        {name: 'Desktop', value: 26},
      ],
      ageData: [
        {name: '18-24', value: 50},
        {name: '24-35', value: 20},
        {name: '35-45', value: 20},
        {name: '45-55', value: 8},
        {name: '55 и выше', value: 2},
      ],
    },
  },
};
