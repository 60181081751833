import {IntegrationInfoBlock} from 'shared/components/IntegrationInfoBlock';

export const InfoBlock = () => {
  return (
    <IntegrationInfoBlock
      title="Интеграция с Яндекс Метрикой настроена"
      previewChars={132}
      description={[
        'Поздравляем! Вы успешно справились с подключением источника данных. Через некоторое время данные отобразятся на дашборде «HR-бренд».',
        'Слева показаны выбранные вами счётчик, цели и сегменты (если указали, что у вас нет карьерного сайта). Эти параметры вы можете отредактировать в любой момент, нажав на кнопку «Редактировать».',
        'Если вы хотите изменить счётчик, действуйте — данные с прошлого счётчика останутся в системе, они не потеряются.',
        'В правом верхнем углу указаны дата и время с момента вашего последнего сохранения настроек интеграции.',

        <>
          Если остались вопросы, пожалуйста, свяжитесь с менеджером системы:{' '}
          <a className="underline text-link" href="mailto:hello@staffscreen.ru">
            hello@staffscreen.ru
          </a>
        </>,
      ]}
    />
  );
};
