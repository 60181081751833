import ApiClient from 'api/client';
import {NotificationDTO} from 'api/dto/NotificationDTO';

class NotificationsService {
  private basePath = '/notifications';
  async getNotifications(userId: number | string, page: number, size: number) {
    return ApiClient.get<{items: NotificationDTO[]; total: number}>(`${this.basePath}/${userId}`, {
      params: {page, size},
    }).then((res) => res.data);
  }
  async markAsReadNotifications(userId: number | string, payload: number[]) {
    return ApiClient.patch(`${this.basePath}/${userId}/mark-as-read`, payload).then((res) => res.data);
  }
}

export const NotificationsApi = new NotificationsService();
