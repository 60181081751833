import {IntegrationTypeId} from 'api/dto';
import {IntegrationInfoBlock} from 'shared/components/IntegrationInfoBlock';
import {useCurrentCompany, useIntegrationStatuses} from 'shared/hooks';
import {bem} from 'shared/utils';

import s from './InfoBlock.module.scss';

const sn = bem('infoBlock', s);

export const InfoBlock = () => {
  const {hasDemoStatus} = useCurrentCompany();
  const hasHuntflowSettings = useIntegrationStatuses(IntegrationTypeId.Huntflow);

  if (hasDemoStatus) return <IntegrationInfoBlock />;

  return (
    <IntegrationInfoBlock
      title={hasHuntflowSettings ? 'Указание токенов' : 'Шаг 1. Указание токенов'}
      previewChars={108}
      description={[
        'Сгенерировать токены для API могут управляющие рекрутеры в Huntflow. Для генерации нового токена необходимо:',

        <ol className={sn('list')} key="list1">
          <li className={sn('item')}>Зайти в настройки Хантфлоу и выбрать раздел “API”.</li>

          <li className={sn('item')}>
            Нажать «Добавить токен», назвать его, например, «Стафскрин», затем сгенерировать его.
          </li>
          <li className={sn('item')}>
            Скопировать полученную ссылку и открыть её в новой вкладке браузера. Нажать «Получить токен».
          </li>
          <li className={sn('item')}>
            Скопировать токены Access и Refresh. Мы рекомендуем не закрывать вкладку до конца настройки. Если токены
            потерялись или их срок жизни закончился, необходимо открыть ранее созданный токен и нажать на кнопку
            «Сгенерировать новый», затем вернуться на предыдущий этап.
          </li>
          <li className={sn('item')}>Вставить скопированные токены в соответствующие поля и перейти далее.</li>
        </ol>,
      ]}
    />
  );
};
