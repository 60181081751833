import {FC} from 'react';
import {Area, AreaChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

import {IntegrationTypeId, VisitorsByDate} from 'api/dto';
import {useDependencyOnIntegration} from 'shared/hooks';

import {ClipLoader} from '../../../ClipLoader';
import {CustomTooltipContent} from '../../CustomTooltip';
import {useDashboardContext} from '../../DashboardContextProvider';
import {
  DOMAIN_OF_EMPTY_CHART,
  getCartesianGridDefaultProps,
  getCustomizedYAxisTick,
  getDefaultTooltipCursorProps,
  getLabelFormatter,
  getLabelListDefaultStyles,
  getLegendDefaultProps,
  getQuickIntervalTypeByDateRange,
  getXAxisDefaultProps,
  getXAxisInterval,
  getXAxisPropsForEmptyChart,
  getYAxisDefaultProps,
  getYAxisWidthByValue,
} from '../../utils';

type Props = {
  data: VisitorsByDate[];
  className: string;
  isLoading: boolean;
};

export const ConversionChart: FC<Props> = ({data, className, isLoading}: Props) => {
  const isEmptyChart = !data?.length || data.every((item) => item.visitors === 0);
  const {dateRange} = useDashboardContext();
  const {infoBadge} = useDependencyOnIntegration(IntegrationTypeId.YandexMetrica);

  const getXAxisProps = () => {
    if (isEmptyChart) return getXAxisPropsForEmptyChart(dateRange);
    return getXAxisDefaultProps();
  };

  if (isLoading) {
    return <ClipLoader />;
  }

  return (
    <>
      <ResponsiveContainer width="100%" height={400} className={className}>
        <AreaChart
          width={500}
          height={400}
          data={isEmptyChart ? [] : data}
          margin={{
            top: 30,
            right: 25,
            left: 0,
            bottom: 10,
          }}
        >
          <CartesianGrid {...getCartesianGridDefaultProps()} />
          <XAxis
            dataKey="date"
            interval={getXAxisInterval(getQuickIntervalTypeByDateRange(dateRange))}
            {...getXAxisProps()}
          />
          <YAxis
            {...getYAxisDefaultProps()}
            tick={getCustomizedYAxisTick}
            width={getYAxisWidthByValue(
              data.map((item) => {
                return {value: item.visitors};
              }),
            )}
            domain={isEmptyChart ? DOMAIN_OF_EMPTY_CHART : undefined}
          />
          <Tooltip content={<CustomTooltipContent />} cursor={getDefaultTooltipCursorProps()} />

          <Area
            fillOpacity="1"
            type="monotone"
            dataKey="visitors"
            stroke="#4D3FE3"
            fill="#DDD8FD"
            name="Целевые действия"
            isAnimationActive={false}
            dot={{fill: '#4D3FE3', stroke: '#4D3FE3', strokeWidth: 1, r: 6}}
          >
            <LabelList
              dataKey="visitors"
              position="top"
              dy={-5}
              style={{...getLabelListDefaultStyles(), fontSize: 15}}
              formatter={getLabelFormatter}
            />
          </Area>
          <Legend
            {...getLegendDefaultProps()}
            payload={[{value: 'Целевые действия', type: 'circle', id: 'ID01', color: '#4D3FE3'}]}
          />
        </AreaChart>
      </ResponsiveContainer>
      {!isLoading && isEmptyChart ? infoBadge : null}
    </>
  );
};
