import {zodResolver} from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/browser';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import * as z from 'zod';

import {UsersApi} from 'api';
import {Button} from 'shared/components/shadcn-ui/Button';
import {CardTitle} from 'shared/components/shadcn-ui/Card/Card';
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from 'shared/components/shadcn-ui/Form';
import {Input} from 'shared/components/shadcn-ui/Input';
import {toast} from 'shared/components/shadcn-ui/Toast/useToast';
import {bem, setValidationFormErrors} from 'shared/utils';
import {extractApiErrorMessage} from 'shared/utils/axios';
import {handleApiErrors} from 'shared/utils/helpers';
import {useRootDispatch, useRootSelector} from 'store';
import {authActions} from 'store/auth';
import {authSelectors} from 'store/auth/selectors';

import s from './EditSystemUserProfile.module.scss';
import {EditSystemUserProfileSchema} from './validationSchema';

const sn = bem('editSystemUserProfile', s);

export const EditSystemUserProfile = () => {
  const dispatch = useRootDispatch();
  const user = useRootSelector(authSelectors.getUser);

  const form = useForm<z.infer<typeof EditSystemUserProfileSchema>>({
    resolver: zodResolver(EditSystemUserProfileSchema),
    defaultValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
    },
  });

  const onSubmit = async (data: z.infer<typeof EditSystemUserProfileSchema>) => {
    if (user) {
      try {
        const {email, ...valuesWithoutEmail} = data;
        const model = {...user, ...valuesWithoutEmail};
        await UsersApi.updateProfile(model);
        dispatch(authActions.updateProfile({user: model, companyProfile: null}));
        toast({
          title: 'Настройки профиля обновлены.',
        });
      } catch (error) {
        Sentry.captureException(error);
        const extractedError = extractApiErrorMessage(error);
        if (typeof extractedError === 'string') {
          form.reset();
          handleApiErrors(error);
        } else {
          setValidationFormErrors<typeof data>(extractedError, (fieldName, fieldErrors) => {
            form.setError(fieldName, {
              message: fieldErrors[0],
            });
          });
        }
      }
    }
  };

  useEffect(() => {
    if (user) {
      form.reset(user);
    }
  }, [user]);

  return (
    <div className={s.editSystemUserProfile}>
      <div className={sn('column')}>
        <CardTitle className="mt-0 text-base">Данные профиля</CardTitle>
      </div>
      <Form {...form}>
        <form id="profileForm" className={sn('form')} onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="firstName"
            render={({field}) => (
              <FormItem className="h-[80px] max-w-[276px]">
                <FormLabel className="mb-0">Имя</FormLabel>
                <FormControl>
                  <Input placeholder="Имя" autoComplete="off" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            render={({field}) => (
              <FormItem className="h-[80px] max-w-[276px]">
                <FormLabel className="mb-0">Фамилия</FormLabel>
                <FormControl>
                  <Input placeholder="Фамилия" autoComplete="off" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({field}) => (
              <FormItem className="h-[80px] max-w-[276px]">
                <FormLabel className="mb-0">Email</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Электронный адрес"
                    readOnly={true}
                    autoComplete="off"
                    disabled={true}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </form>
      </Form>
      <Button
        type="submit"
        form="profileForm"
        variant="primary"
        className="self-end mb-5 min-w-[120px]"
        disabled={form.formState.isSubmitting}
      >
        {form.formState.isSubmitting ? 'Загрузка...' : 'Сохранить'}
      </Button>
    </div>
  );
};
