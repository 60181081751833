import {CreateCompanyAdminDTO, CreateCompanyDTO} from 'api/dto';
import {getRawPhoneNumber} from 'shared/utils/helpers';

import {CompanyFormType} from './types';

export const prepareDataForServer = (values: CompanyFormType) => {
  const {phoneNumber, inn, companyName, legalAddress, ...rest} = values;
  const newPhoneNumber = phoneNumber ? {phoneNumber: getRawPhoneNumber(phoneNumber)} : {};

  const admin: CreateCompanyAdminDTO = {
    ...rest,
    ...newPhoneNumber,
  };

  const companyDTO: CreateCompanyDTO = {
    companyInfo: {
      inn,
      companyName,
      legalAddress,
    },
    adminInfo: admin,
  };
  return companyDTO;
};

export const getFormFieldName = (str: string): string => {
  const [, name] = str.split('.');
  return name[0].toLowerCase() + name.slice(1);
};
