import ApiClient from 'api/client';
import {OnboardingStatus, ResetPasswordDTO, UserDTO} from 'api/dto';

class UsersService {
  private basePath = '/users';

  async sendConfirmationEmailLink(userId: number | string) {
    await ApiClient.post(`${this.basePath}/sending-confirmation-mail`, {}, {params: {id: userId}}).then(
      (res) => res.data,
    );
  }
  async updateProfile(payload: UserDTO) {
    const {id, ...updatedUser} = payload;
    await ApiClient.put(`${this.basePath}/${id}/profile`, updatedUser).then((res) => res.data);
  }
  async getUser(userId: number | string) {
    return ApiClient.get<UserDTO>(`${this.basePath}/${userId}`).then((res) => res.data);
  }
  async confirmEmail(email: string, token: string) {
    return ApiClient.put<Pick<ResetPasswordDTO, 'email' | 'token'>>(`${this.basePath}/confirm-email`, {
      email,
      token,
    }).then((res) => res.data);
  }
  async getAdminProfile(userId: number | string) {
    return ApiClient.get<UserDTO>(`${this.basePath}/super-admin/${userId}`).then((res) => res.data);
  }
  async setDupNotifications(userId: number | string, dupNotificationToEmail: boolean) {
    return ApiClient.put(`${this.basePath}/${userId}/dup-notification`, {}, {params: {dupNotificationToEmail}}).then(
      (res) => res.data,
    );
  }
  async setOnboardingState(userId: number | string, state: OnboardingStatus) {
    return ApiClient.put(`${this.basePath}/${userId}/onboarding-state`, {}, {params: {state}}).then((res) => res.data);
  }
}

export const UsersApi = new UsersService();
