import ApiClient from 'api/client';
import {
  ForgotPassword,
  RefreshToken,
  RefreshTokenResponse,
  SignInParams,
  SignInResponse,
  ResetPasswordDTO,
} from 'api/dto/JwtAuthDTO';

class AuthApiService {
  private basePath = '/auth';
  async signIn(payload: Omit<SignInParams, 'isRememberMe'>) {
    return ApiClient.post<SignInResponse>(`${this.basePath}/sign-in`, payload).then((res) => res.data);
  }
  async signOut() {
    return ApiClient.post(`${this.basePath}/sign-out`).then((res) => res.data);
  }
  async refreshToken(payload: RefreshToken) {
    return ApiClient.post<RefreshTokenResponse>(`${this.basePath}/refresh`, payload).then((res) => res.data);
  }
  async forgotPassword(payload: ForgotPassword) {
    return ApiClient.post(`${this.basePath}/forgot-password`, payload).then((res) => res.data);
  }
  async resetPassword(payload: ResetPasswordDTO) {
    return ApiClient.put(`${this.basePath}/reset-password`, payload).then((res) => res.data);
  }
}

export const AuthApi = new AuthApiService();
