import * as z from 'zod';

import {getRawPhoneNumber} from './helpers';

const EMAIL_MSG = 'Проверьте адрес электронной почты';
export const REQUIRED_FIELD_MSG = 'Заполните поле';
const PHONE_NUMBER_MESSAGE = 'Проверьте номер телефона';
export const phoneFieldValidation = z.string().refine(
  (value) => {
    return value === '' || getRawPhoneNumber(value).match(/(^7|\+7)((\d{10})|(\s\(\d{3}\)\s\d{3}\s\d{2}\s\d{2}))/);
  },
  {
    message: PHONE_NUMBER_MESSAGE,
  },
);
export const CompanyUserSchema = z.object({
  firstName: z.string().nonempty({message: REQUIRED_FIELD_MSG}).min(3, {message: REQUIRED_FIELD_MSG}).max(180),
  lastName: z.string().nonempty({message: REQUIRED_FIELD_MSG}).min(3, {message: REQUIRED_FIELD_MSG}).max(180),
  email: z.string().nonempty({message: REQUIRED_FIELD_MSG}).email({message: EMAIL_MSG}),
  phoneNumber: phoneFieldValidation,
  jobTitle: z.string().nonempty({message: REQUIRED_FIELD_MSG}).min(3, {message: REQUIRED_FIELD_MSG}).max(180),
});
