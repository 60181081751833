export enum NotificationType {
  invitingNewUsers = 35,
}
export interface NotificationDTO {
  id: number;
  severityType: number;
  isRead: boolean;
  createAt: string;
  relevantDate: string;
  message: string;
  notificationEventType: number;
}
