import ApiClient from 'api/client';
import {
  AllIntegrationsStatusesDTO,
  CompanyDetailsDTO,
  CompanyListDTO,
  CompanyStaffDismissalReasonDTO,
  CompanyUserDTO,
  CreateCompanyDTO,
  CreateCompanyUserDTO,
  CreateStaffDismissalDTO,
  DismissalReasonDTO,
  EditCompanyDTO,
  SetStatusDTO,
  UpdateCompanyUserInfoDTO,
} from 'api/dto';

import {RequestPaginationParams, WithPagination} from '../../shared/models/requestPagination';

class CompaniesService {
  private basePath = '/companies';

  private getBasePath = (companyId: number | string) => {
    return `${this.basePath}/${companyId}`;
  };
  async addCompany(payload: CreateCompanyDTO) {
    return ApiClient.post(`${this.basePath}/with-admin`, payload).then((res) => res.data);
  }
  async getAllCompanies() {
    return ApiClient.get<CompanyListDTO[]>(this.basePath).then((res) => res.data);
  }
  async getCompanyById(id: number | string) {
    return ApiClient.get<CompanyDetailsDTO>(`${this.basePath}/${id}`).then((res) => res.data);
  }
  async updateCompany(id: number | string, payload: EditCompanyDTO) {
    return ApiClient.put(`${this.basePath}/${id}`, payload).then((res) => res.data);
  }
  async getCompanyUsers(companyId: number | string) {
    return ApiClient.get<CompanyUserDTO[]>(`${this.basePath}/${companyId}/company-users`).then((res) => res.data);
  }
  async replaceCompanyAdmin(companyId: number | string, userId: number | string) {
    return ApiClient.post(`${this.basePath}/${companyId}/company-users/admin/replace`, {userId}).then(
      (res) => res.data,
    );
  }
  async setStatus(payload: SetStatusDTO, companyId: number) {
    return ApiClient.patch(`${this.basePath}/${companyId}/status`, payload).then((res) => res.data);
  }
  async updateCompanyUser(
    companyId: number | string,
    userId: number | string,
    payload: Partial<UpdateCompanyUserInfoDTO>,
  ) {
    return ApiClient.put(`${this.basePath}/${companyId}/company-users/user/${userId}`, payload).then((res) => res.data);
  }
  async deleteCompanyUser(companyId: number | string, userId: number | string) {
    return ApiClient.delete(`${this.basePath}/${companyId}/company-users/${userId}`).then((res) => res.data);
  }
  async addCompanyUser(companyId: number | string, payload: Partial<CreateCompanyUserDTO>) {
    return ApiClient.post(`${this.basePath}/${companyId}/company-users`, payload).then((res) => res.data);
  }
  async updateCompanyAdmin(companyId: number | string, payload: Partial<UpdateCompanyUserInfoDTO>) {
    return ApiClient.put(`${this.basePath}/${companyId}/company-users/admin`, payload).then((res) => res.data);
  }

  async getCompanyAdmin(companyId: number | string) {
    return ApiClient.get<CompanyUserDTO>(`${this.basePath}/${companyId}/company-users/admin`).then((res) => res.data);
  }
  async getCompanyUserById(companyId: number | string, userId: number | string) {
    return ApiClient.get<CompanyUserDTO>(`${this.basePath}/${companyId}/company-users/${userId}`).then(
      (res) => res.data,
    );
  }
  async getAllIntegrationsStatuses(companyId: number | string) {
    return ApiClient.get<AllIntegrationsStatusesDTO>(`${this.basePath}/${companyId}/integrations-statuses`).then(
      (res) => res.data,
    );
  }
  async checkCompanyByINN(searchQuery: string) {
    return ApiClient.get<boolean>(`${this.basePath}/any`, {
      params: {inn: searchQuery},
    }).then((res) => res.data);
  }
  getCompanyStaffDismissalReasonsList(companyId: string | number, {page = 1, size = 5}: RequestPaginationParams) {
    return ApiClient.get<WithPagination<CompanyStaffDismissalReasonDTO>>(
      `/company-operations/${companyId}/operations/staff-dismissal`,
      {params: {page, size}},
    ).then((res) => res.data);
  }
  deleteCompanyStaffDismissalReasons(companyId: string | number, dismissalReasonIds: number[]) {
    return ApiClient.delete<CompanyStaffDismissalReasonDTO>(
      `/company-operations/${companyId}/operations/staff-dismissal`,
      {data: dismissalReasonIds},
    ).then((res) => res.data);
  }

  saveCompanyStaffDismissalReasons(companyId: string | number, payload: CreateStaffDismissalDTO[]) {
    return ApiClient.post(`/company-operations/${companyId}/operations/staff-dismissal/save-reasons`, payload).then(
      (res) => res.data,
    );
  }
}

export const CompaniesApi = new CompaniesService();
