import {useMemo, useReducer} from 'react';
import {Outlet} from 'react-router';

import {ConfirmationContext} from './components/ConfirmationContext';
import {ConfirmationDialog} from './components/ConfirmationDialog';
import {confirmReducer} from './state';

export const ConfirmationProvider = () => {
  const [state, dispatch] = useReducer(confirmReducer, {show: false, config: {}});
  const {config, show} = state;
  const providerValue = useMemo(() => ({dispatch}), [dispatch]);
  return (
    <ConfirmationContext.Provider value={providerValue}>
      <Outlet />
      {show && config && (
        <ConfirmationDialog
          title={config.title ?? ''}
          description={config.description ?? 'Вы уверены?'}
          acceptButton={config.acceptButton ?? 'Подтвердить'}
          cancelButton={config.cancelButton ?? 'Отмена'}
          isOpen={show}
        />
      )}
    </ConfirmationContext.Provider>
  );
};
