import {useFormContext} from 'react-hook-form';
import {z} from 'zod';

import {Option} from 'shared/components/CreatableSelect/utils/types';
import {EditableCell} from 'shared/components/EditableCell/EditableCell';
import {ComboBox} from 'shared/components/shadcn-ui/Command/ComboBox';
import {FormMessage} from 'shared/components/shadcn-ui/Form';
import {useCurrentCompany} from 'shared/hooks';
import {cn} from 'shared/utils/helpers';

import {FormSchema} from '../utils/validationSchema';

export const ReasonCell = ({
  row: {original, index, ...row},
  column: {columnDef, id: columnId, ...column},
  table,
}: any) => {
  const {formState} = useFormContext<z.infer<typeof FormSchema>>();
  const hasReason = columnDef.meta?.reasonList.some((item: Option) => Number(item.value) === original.reasonId);
  const {hasDemoStatus} = useCurrentCompany();

  if (original.id && hasReason && columnDef.meta?.activeEditingRow !== original.id && !hasDemoStatus) {
    return <span>{original.reason}</span>;
  }

  return (
    <EditableCell columName={columnId} rowIndex={index}>
      <ComboBox
        isDisabled={table.options.meta?.isDisabled}
        options={columnDef.meta?.reasonList}
        placeholder="Выберите причину"
        onValueChange={(value) => columnDef.meta?.onChangeRowReason(value, index)}
        defaultValue={String(original?.reasonId)}
        className={cn({['border-error']: formState.errors?.rows?.[index]?.reason})}
      />
    </EditableCell>
  );
};
