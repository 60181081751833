import {FC} from 'react';
import {Area, AreaChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

import {IntegrationTypeId, VisitorsDynamic} from 'api/dto';
import {useDependencyOnIntegration} from 'shared/hooks';

import {ClipLoader} from '../../../ClipLoader';
import {CustomTooltipContent} from '../../CustomTooltip';
import {useDashboardContext} from '../../DashboardContextProvider';
import {
  DOMAIN_OF_EMPTY_CHART,
  getCartesianGridDefaultProps,
  getCustomizedYAxisTick,
  getDefaultTooltipCursorProps,
  getLabelFormatter,
  getLabelListDefaultStyles,
  getLegendDefaultProps,
  getQuickIntervalTypeByDateRange,
  getXAxisDefaultProps,
  getXAxisInterval,
  getXAxisPropsForEmptyChart,
  getYAxisDefaultProps,
  getYAxisWidthByValue,
} from '../../utils';

type Props = {
  data: VisitorsDynamic[];
  className?: string;
  isLoading?: boolean;
};

export const VisitorsChart: FC<Props> = ({data, className, isLoading}: Props) => {
  const isEmptyChart = !data?.length;
  const {dateRange} = useDashboardContext();
  const {infoBadge} = useDependencyOnIntegration(IntegrationTypeId.YandexMetrica);

  const getXAxisProps = () => {
    if (isEmptyChart) return getXAxisPropsForEmptyChart(dateRange);
    return getXAxisDefaultProps();
  };

  if (isLoading) {
    return <ClipLoader />;
  }

  return (
    <>
      <ResponsiveContainer width="100%" height={400} className={className}>
        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 24,
            right: 25,
            left: 0,
            bottom: 10,
          }}
        >
          <CartesianGrid {...getCartesianGridDefaultProps()} />
          <XAxis
            dataKey="date"
            {...getXAxisProps()}
            interval={getXAxisInterval(getQuickIntervalTypeByDateRange(dateRange))}
            overflow={'visible'}
          />
          <YAxis
            {...getYAxisDefaultProps()}
            tick={getCustomizedYAxisTick}
            width={
              isEmptyChart
                ? 50
                : getYAxisWidthByValue(
                    data.map((item) => {
                      return {value: item.visitors};
                    }),
                  )
            }
            domain={isEmptyChart ? DOMAIN_OF_EMPTY_CHART : undefined}
          />
          <Tooltip content={<CustomTooltipContent />} cursor={getDefaultTooltipCursorProps()} />

          <Area
            fillOpacity="1"
            type="monotone"
            dataKey="visitors"
            stroke="#FFC93F"
            fill="#FFF8D8"
            name="Посетители"
            isAnimationActive={false}
            dot={{fill: '#FFC93F', stroke: '#FFC93F', strokeWidth: 1, r: 6}}
          >
            <LabelList
              dataKey="visitors"
              position="top"
              dy={-5}
              style={{...getLabelListDefaultStyles(), fontSize: 15}}
              formatter={getLabelFormatter}
            />
          </Area>
          <Area
            fillOpacity="1"
            name="Новые посетители"
            type="monotone"
            dataKey="newVisitors"
            stroke="#68C01C"
            fill="#F1FDD3"
            isAnimationActive={false}
            dot={{fill: '#68C01C', stroke: '#68C01C', strokeWidth: 1, r: 6}}
          >
            <LabelList
              dataKey="newVisitors"
              position="top"
              dy={-5}
              style={getLabelListDefaultStyles()}
              formatter={getLabelFormatter}
            />
          </Area>
          <Legend
            {...getLegendDefaultProps()}
            payload={[
              {value: 'Посетители', type: 'circle', id: 'ID01', color: '#FFC93F'},
              {value: 'Новые посетители', type: 'circle', id: 'ID02', color: '#68C01C'},
            ]}
          />
        </AreaChart>
      </ResponsiveContainer>
      {!isLoading && isEmptyChart ? infoBadge : null}
    </>
  );
};
