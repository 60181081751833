import {FC} from 'react';

import {Button} from 'shared/components/shadcn-ui/Button';
import {useCurrentCompany} from 'shared/hooks';
import {DOTS} from 'shared/hooks/usePagination';
import {bem} from 'shared/utils';
import {cn} from 'shared/utils/helpers';

import s from './Pagination.module.scss';

type Props = {
  paginationRange: (number | string)[];
  paginationNumber: number;
  isDisabledBtns?: boolean;
  changePaginationNumber: (tableIndex: number) => void;
};
const sn = bem('pagination', s);

export const Pagination: FC<Props> = ({
  paginationRange,
  changePaginationNumber,
  paginationNumber,
  isDisabledBtns = false,
}) => {
  return (
    <div className={s.pagination}>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <div key={`key_${pageNumber}`} className={cn(sn('item'), sn('dots'))}>
              &#8230;
            </div>
          );
        }

        return (
          <Button
            key={`key_${pageNumber}`}
            variant={'ghost'}
            className={cn(sn('item'), {[sn('item_active')]: paginationNumber + 1 === pageNumber})}
            disabled={isDisabledBtns}
            onClick={() => changePaginationNumber(Number(pageNumber) - 1)}
          >
            {pageNumber}
          </Button>
        );
      })}
    </div>
  );
};
