import {Bell} from 'lucide-react';
import {forwardRef} from 'react';

import {Button, ButtonProps} from 'shared/components/shadcn-ui/Button';

import s from './ButtonWithBell.module.scss';

import {useNotificationsContext} from '../../NotificationContext';

export const ButtonWithBell = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {hasUnread} = useNotificationsContext();

  return (
    <Button variant="icon" size="icon" ref={ref} {...props}>
      <Bell className="text-neutral-500" />
      {hasUnread && <span className={s.buttonWithBell__dot} />}
    </Button>
  );
});
