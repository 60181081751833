import {FC, HTMLAttributes} from 'react';

import LogoImage from 'assets/icons/logo.svg';
import LogoImageLight from 'assets/icons/logo_light.svg';

type Props = {
  className?: string;
  theme?: 'light';
} & HTMLAttributes<HTMLDivElement>;

const Logo: FC<Props> = ({className = '', theme}) => {
  return (
    <div className={className}>
      <img src={theme === 'light' ? LogoImageLight : LogoImage} alt="Логотип" />
    </div>
  );
};

export {Logo};
