import cn from 'classnames';
import {
  ActivityIcon,
  BookOpenIcon,
  ClockIcon,
  EyeIcon,
  Hammer,
  LocateFixedIcon,
  MousePointerIcon,
  PieChartIcon,
  RussianRubleIcon,
  TagIcon,
} from 'lucide-react';
import {useEffect, useRef, useState} from 'react';

import {DashboardsApi} from 'api';
import {HrBrandMetricsDTO, IntegrationStatusId, IntegrationTypeId} from 'api/dto';
import {
  AbsencesDynamicsChart,
  ChartCard,
  DashboardTopBar,
  MetricCard,
  RecruitmentFunnelChart,
  useDashboardContext,
} from 'shared/components/Dashboards';
import {ChartInfoBadge} from 'shared/components/Dashboards/ChartInfoBadge';
import {ConversionChart} from 'shared/components/Dashboards/Charts/ConversionChart';
import {SiteAudienceChart} from 'shared/components/Dashboards/Charts/SiteAudienceChart';
import {VerticalBarChart} from 'shared/components/Dashboards/Charts/VerticalBarChart';
import {VisitorsChart} from 'shared/components/Dashboards/Charts/VisitorsChart';
import {FlowRateKpiMetric} from 'shared/components/Dashboards/FlowRateKpiMetric';
import {HiringCostMetric} from 'shared/components/Dashboards/HiringCostMetric';
import {
  CHART_CARD_TIPS,
  handleErrorFromFetchingData,
  MINI_CARD_TIPS,
  roundValue,
} from 'shared/components/Dashboards/utils';
import {Badge} from 'shared/components/shadcn-ui/Badge';
import {
  useCurrentCompany,
  useDebounce,
  useDependencyOnIntegration,
  useIntegrationStatuses,
  usePrevious,
} from 'shared/hooks';
import {useDimensions} from 'shared/hooks/useDimensions';
import {isEqualDates} from 'shared/utils/date';
import {pluralizeDays} from 'shared/utils/helpers';
import {useRootDispatch} from 'store';
import {companiesAsyncActions} from 'store/companies/actions';

import s from './HrBrand.module.scss';
import {NpsOrEnpsMetricCard} from './NpsOrEnpsMetricCard';
import {NpsOrEnpsType} from './NpsOrEnpsMetricCard/NpsOrEnpsMetricCard';
import {ReasonsForFiredChart} from './ReasonsForFiredChart';
import {ReferralPerformanceMetricCard} from './ReferralPerformanceMetricCard';
import {TopSearchQueriesList} from './TopSearchQueriesList';
import {MOCK_DATA} from './utils/constants';

export const HrBrandDashboard = () => {
  const {company} = useCurrentCompany();
  const {data, setData, dateRange} = useDashboardContext();
  const prevDateRange = usePrevious(dateRange);
  const [isLoading, setIsLoading] = useState(false);
  const huntflowIntegrationStatus = useIntegrationStatuses(IntegrationTypeId.Huntflow);
  const dispatch = useRootDispatch();

  const recruitingFunnelContainerRef = useRef<HTMLDivElement>(null);
  const employeesWorkExpRef = useRef<HTMLDivElement>(null);
  const employeesWorkExpChartWidth = useDimensions(employeesWorkExpRef).width;

  const {infoBadge: warningHuntflowMessage, isBlur: isBlurOfHuntflow} = useDependencyOnIntegration(
    IntegrationTypeId.Huntflow,
  );
  const {
    infoBadge: warningOneCMessage,
    isBlur: isBlurOfOneC,
    integrationStatus: oneCIntegrationStatus,
  } = useDependencyOnIntegration(IntegrationTypeId.OneC);
  const {
    infoBadge: warningYandexMetricaMessage,
    isBlur: isBlurOfYaMetric,
    integrationStatus: yaMetricIntegrationStatus,
  } = useDependencyOnIntegration(IntegrationTypeId.YandexMetrica);

  const {
    infoBadge: warningYandexDirectMessage,
    isBlur: isBlurOfYaDirect,
    integrationStatus: yaDirectIntegrationStatus,
  } = useDependencyOnIntegration(IntegrationTypeId.YandexDirect);

  const dashboardData = data as HrBrandMetricsDTO;
  const {charts: apiDataCharts} = dashboardData || {};
  const {yandexMetricaData: apiDataYandexMetric} = dashboardData || {};
  const isShownReferralPerformanceMetricCard =
    dashboardData?.metrics?.hasReferralProgram || !huntflowIntegrationStatus?.isEnabled;

  const getChartsDataBasedOnBlur = (isBlur: boolean) => {
    if (isBlur) return MOCK_DATA.charts;
    return apiDataCharts;
  };
  const getYandexMetricData = (isBlur: boolean) => {
    if (isBlur) return MOCK_DATA.yandexMetricaData;
    return apiDataYandexMetric;
  };

  const debouncedRefetchData = useDebounce(async () => {
    company && (await fetchData(company?.id));
  }, 500);

  // запрос данных при изменении компании
  useEffect(() => {
    if (company) {
      fetchData(company.id);
      dispatch(companiesAsyncActions.fetchIntegrationsStatuses());
    }
  }, [company]);

  // запрос данных при изменении периода
  useEffect(() => {
    if (
      dateRange &&
      prevDateRange &&
      (!isEqualDates(dateRange?.from, prevDateRange?.from) || !isEqualDates(dateRange?.to, prevDateRange?.to))
    ) {
      debouncedRefetchData();
    }
  }, [dateRange]);

  // TODO: DRY
  const fetchData = async (companyId: string | number) => {
    try {
      setIsLoading(true);
      const dashboardData = await DashboardsApi.getHrBrandData(companyId, dateRange);
      setData(dashboardData);
    } catch (e) {
      handleErrorFromFetchingData(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <DashboardTopBar />
      <div className={s.hrBrand}>
        <section className={s.hrBrand__part}>
          <div className={s.hrBrand__grid}>
            <HiringCostMetric
              isBlur={isBlurOfOneC || oneCIntegrationStatus?.statusId !== IntegrationStatusId.Ready}
              value={dashboardData?.metrics?.hiringCost ?? null}
            />
            <MetricCard
              title="Время заполнения"
              description={MINI_CARD_TIPS.timeFilling}
              value={dashboardData?.metrics?.fillingTime ?? null}
              icon={<ClockIcon />}
              formatter={(value) => pluralizeDays(+value)}
            />
            <NpsOrEnpsMetricCard
              type={NpsOrEnpsType.enps}
              value={dashboardData?.metrics?.enpsPercentage?.value ?? null}
              compareDif={dashboardData?.metrics?.enpsPercentage?.compareDif ?? null}
              compareResult={dashboardData?.metrics?.enpsPercentage?.compareResult ?? null}
              onAfterEdit={debouncedRefetchData}
            />
            <NpsOrEnpsMetricCard
              type={NpsOrEnpsType.nps}
              value={dashboardData?.metrics?.npsPercentage?.value ?? null}
              compareDif={dashboardData?.metrics?.npsPercentage?.compareDif ?? null}
              compareResult={dashboardData?.metrics?.npsPercentage?.compareResult ?? null}
              onAfterEdit={debouncedRefetchData}
            />
            <FlowRateKpiMetric onAfterEdit={debouncedRefetchData} flowRate={dashboardData?.metrics?.flowRate ?? null} />
            {isShownReferralPerformanceMetricCard && (
              <ReferralPerformanceMetricCard value={dashboardData?.metrics?.referralPerformance} />
            )}

            <MetricCard
              title="Средний стаж"
              value={dashboardData?.metrics?.averageWorkExp ?? null}
              icon={<ActivityIcon />}
              description={MINI_CARD_TIPS.averageWorkExp}
            />
            {!isShownReferralPerformanceMetricCard && <div />}
            <ChartCard
              title="Рекрутинговая воронка"
              className={s.hrBrand__chartCard}
              description={CHART_CARD_TIPS.recruitmentFunnelHr}
              ref={recruitingFunnelContainerRef}
            >
              <RecruitmentFunnelChart
                data={getChartsDataBasedOnBlur(isBlurOfHuntflow)?.recruitingFunnel ?? []}
                className={cn({[s.overlay]: isBlurOfHuntflow})}
                legendText="Количество соискателей"
              />
              {!isLoading && !apiDataCharts?.recruitingFunnel?.length ? warningHuntflowMessage : null}
            </ChartCard>

            <ReasonsForFiredChart
              className={cn(s.hrBrand__chartCard, {[s.overlay]: isBlurOfOneC})}
              data={apiDataCharts?.dismissalReasonsFunnel ?? []}
              isLoading={isLoading}
              onAfterEdit={debouncedRefetchData}
            />

            <ChartCard title="Отсутствия" className={s.hrBrand__chartCard} description={CHART_CARD_TIPS.absences}>
              <AbsencesDynamicsChart
                color="#2D7DF4"
                data={getChartsDataBasedOnBlur(isBlurOfOneC)?.absencesDynamics ?? []}
                className={cn({[s.overlay]: isBlurOfOneC})}
                isLoading={isLoading}
              />
              {!isLoading && !apiDataCharts?.absencesDynamics?.length ? warningOneCMessage : null}
            </ChartCard>
            <ChartCard
              title="Стаж работы сотрудников"
              className={s.hrBrand__chartCard}
              description={CHART_CARD_TIPS.employeesWorkExp}
              ref={employeesWorkExpRef}
            >
              <VerticalBarChart<{itemName: string; value: number}>
                containerWidth={employeesWorkExpChartWidth}
                height={250}
                dataKey="value"
                labelKey="itemName"
                color="#56BC6B"
                legendLabel="Количество сотрудников"
                data={getChartsDataBasedOnBlur(isBlurOfOneC)?.workExperienceFunnel ?? []}
                className={cn({[s.overlay]: isBlurOfOneC})}
                isLoading={isLoading}
              />
              {!isLoading && !apiDataCharts?.workExperienceFunnel?.length ? warningOneCMessage : null}
            </ChartCard>
          </div>
        </section>
        <section className={s.hrBrand__part}>
          <div className={s.hrBrand__header}>
            <h2 className={cn(s.hrBrand__title, s.hrBrand__title_with_badge)}>Яндекс Директ </h2>
            {yaDirectIntegrationStatus?.info && yaDirectIntegrationStatus?.statusId !== IntegrationStatusId.Ready && (
              <div className={s.hrBrand__badge}>
                <ChartInfoBadge
                  type={yaDirectIntegrationStatus?.statusId}
                  message={yaDirectIntegrationStatus?.info}
                  isGeneralForSection={false}
                  className="top-0"
                />
              </div>
            )}
          </div>
          <section className={s.hrBrand__grid}>
            <MetricCard
              title="Показы"
              value={dashboardData?.yandexDirectData?.views ?? null}
              icon={<EyeIcon />}
              description={MINI_CARD_TIPS.yandexDirect.views}
              formatter={roundValue}
            />
            <MetricCard
              title="Клики"
              value={dashboardData?.yandexDirectData?.clicks ?? null}
              icon={<MousePointerIcon />}
              description={MINI_CARD_TIPS.yandexDirect.clicks}
              formatter={roundValue}
            />
            <MetricCard
              title="CTR"
              value={dashboardData?.yandexDirectData?.ctr ?? null}
              icon={<PieChartIcon />}
              description={MINI_CARD_TIPS.yandexDirect.ctr}
              formatter={roundValue}
            />
            <MetricCard
              title="Конверсия"
              value={dashboardData?.yandexDirectData?.conversion ?? null}
              icon={<LocateFixedIcon />}
              description={MINI_CARD_TIPS.yandexDirect.conversion}
              formatter={roundValue}
            />
            <MetricCard
              title="Глубина просмотра"
              value={dashboardData?.yandexDirectData?.viewDepth ?? null}
              icon={<BookOpenIcon />}
              description={MINI_CARD_TIPS.yandexDirect.viewDepth}
              formatter={roundValue}
            />
            <MetricCard
              title="Расход всего"
              value={dashboardData?.yandexDirectData?.totalCost ?? null}
              icon={<RussianRubleIcon />}
              description={MINI_CARD_TIPS.yandexDirect.totalCost}
              formatter={roundValue}
            />
            <MetricCard
              title="Цена клика"
              value={dashboardData?.yandexDirectData?.clickGoal ?? null}
              icon={<TagIcon />}
              description={MINI_CARD_TIPS.yandexDirect.costPerClick}
              formatter={roundValue}
            />
            <MetricCard
              title="Цена цели"
              value={dashboardData?.yandexDirectData?.goalCost ?? null}
              icon={<TagIcon />}
              description={MINI_CARD_TIPS.yandexDirect.goalCost}
              formatter={roundValue}
            />
          </section>
        </section>
        <section className={s.hrBrand__part}>
          <h2 className={s.hrBrand__title}>Яндекс Метрика</h2>
          <section className={s.hrBrand__grid}>
            <ChartCard
              title="Посетители"
              className={s.hrBrand__chartCard}
              description={CHART_CARD_TIPS.yandexMetric.visitors}
            >
              <VisitorsChart
                data={getYandexMetricData(isBlurOfYaMetric)?.visitorsDynamics ?? []}
                className={cn({[s.overlay]: isBlurOfYaMetric})}
                isLoading={isLoading}
              />
              {!isLoading && !apiDataYandexMetric?.visitorsDynamics?.length ? warningYandexMetricaMessage : null}
            </ChartCard>
            <ChartCard
              title="Конверсия"
              className={s.hrBrand__chartCard}
              description={CHART_CARD_TIPS.yandexMetric.conversion}
              headerActions={
                !isLoading &&
                !isBlurOfYaMetric &&
                !yaMetricIntegrationStatus?.info &&
                getYandexMetricData(isBlurOfYaMetric)?.conversionsDynamics.averageValue ? (
                    <Badge className="bg-secondary-300 py-1 px-3 text-sm">
                    Средний показатель
                      {getYandexMetricData(isBlurOfYaMetric)?.conversionsDynamics.averageValue}%
                    </Badge>
                  ) : null
              }
            >
              <ConversionChart
                data={getYandexMetricData(isBlurOfYaMetric)?.conversionsDynamics.items ?? []}
                className={cn({[s.overlay]: isBlurOfYaMetric})}
                isLoading={isLoading}
              />
              {!isLoading && !apiDataYandexMetric?.conversionsDynamics.items?.length
                ? warningYandexMetricaMessage
                : null}
            </ChartCard>

            <ChartCard
              title="Топ-10 поисковых запросов"
              className={s.hrBrand__chartCard}
              description={CHART_CARD_TIPS.yandexMetric.topSearchQueries}
            >
              <TopSearchQueriesList
                className={cn({[s.overlay]: isBlurOfYaMetric})}
                data={getYandexMetricData(isBlurOfYaMetric)?.topSearchQueries ?? []}
                isLoading={isLoading}
              />
              {!isLoading && !apiDataYandexMetric?.topSearchQueries?.length ? warningYandexMetricaMessage : null}
            </ChartCard>

            <SiteAudienceChart
              className={s.hrBrand__chartCard}
              description={CHART_CARD_TIPS.yandexMetric.siteAudience}
              data={
                apiDataYandexMetric?.siteAudience ?? {
                  genderData: [],
                  deviceTypeData: [],
                  ageData: [],
                }
              }
              isLoading={isLoading}
            />
          </section>
        </section>
      </div>
    </>
  );
};
