export const MOCK_DATA = {
  metrics: {
    headcount: 108,
    hired: 6,
    hiringCost: 10,
    hiringTime: 19,
    fillingTime: 5000,
  },
  charts: {
    headcountAndVacancies: {
      staffHeadcount: 60,
      values: [
        {
          date: '24.12.23',
          headcount: 50,
          vacancies: 29,
        },
        {
          date: '25.12.23',
          headcount: 40,
          vacancies: 45,
        },
        {
          date: '26.12.23',
          headcount: 40,
          vacancies: 60,
        },
        {
          date: '27.12.23',
          headcount: 111,
          vacancies: 39,
        },
        {
          date: '28.12.23',
          headcount: 120,
          vacancies: 30,
        },
        {
          date: '29.12.23',
          headcount: 101,
          vacancies: 49,
        },
        {
          date: '30.12.23',
          headcount: 109,
          vacancies: 41,
        },
        {
          date: '31.12.23',
          headcount: 108,
          vacancies: 42,
        },
      ],
    },
    sources: [
      {
        sourceName: 'Avito',
        percentage: 14.63,
      },
      {
        sourceName: 'Rabota.ru',
        percentage: 21.96,
      },
      {
        sourceName: 'Artstation',
        percentage: 7.32,
      },
      {
        sourceName: 'LinkedIn',
        percentage: 7.32,
      },
      {
        sourceName: 'Отклик с HeadHunter',
        percentage: 7.32,
      },
      {
        sourceName: 'Behance',
        percentage: 4.88,
      },
      {
        sourceName: 'Github',
        percentage: 4.88,
      },
      {
        sourceName: 'HeadHunter',
        percentage: 4.88,
      },
      {
        sourceName: 'Отклик с SuperJob',
        percentage: 4.88,
      },
      {
        sourceName: 'AmazingHiring',
        percentage: 2.44,
      },
      {
        sourceName: 'Facebook',
        percentage: 2.44,
      },
      {
        sourceName: 'Rabota.by',
        percentage: 2.44,
      },
      {
        sourceName: 'VK',
        percentage: 2.44,
      },
      {
        sourceName: 'Другой',
        percentage: 2.44,
      },
      {
        sourceName: 'Yandex.Talents',
        percentage: 2.44,
      },
      {
        sourceName: 'Агентство',
        percentage: 2.44,
      },
      {
        sourceName: 'Зарплата.ру',
        percentage: 2.44,
      },
      {
        sourceName: 'Отклик с Avito',
        percentage: 2.44,
      },
    ],
    recruitingFunnel: [
      {
        itemName: 'Посетители сайта',
        applicantsCount: 10506,
        percentage: 100,
      },
      {
        itemName: 'Новый кандидат',
        applicantsCount: 1071,
        percentage: 10.2,
      },
      {
        itemName: 'Отобранный кандидат',
        applicantsCount: 882,
        percentage: 8.4,
      },
      {
        itemName: 'Интервью с рекрутером',
        applicantsCount: 346,
        percentage: 3.3,
      },
      {
        itemName: 'Техническое интервью',
        applicantsCount: 409,
        percentage: 3.9,
      },
      {
        itemName: 'Принятие решения',
        applicantsCount: 168,
        percentage: 1.6,
      },
      {
        itemName: 'Выставление оффера',
        applicantsCount: 73,
        percentage: 0.7,
      },
      {
        itemName: 'Выход на работу',
        applicantsCount: 21,
        percentage: 0.2,
      },
    ],
    fillingFunnelSpeed: [
      {
        itemName: 'Новый кандидат',
        daysCountMedian: 8,
      },
      {
        itemName: 'Отобранный кандидат',
        daysCountMedian: 9,
      },
      {
        itemName: 'Интервью с рекрутером',
        daysCountMedian: 1,
      },
      {
        itemName: 'Техническое интервью',
        daysCountMedian: 6,
      },
      {
        itemName: 'Выставлен оффер',
        daysCountMedian: 6,
      },
      {
        itemName: 'Оффер принят',
        daysCountMedian: 8,
      },
      {
        itemName: 'Выход на работу',
        daysCountMedian: 4,
      },
    ],
    totalStaffingLevelPercentage: 63,
  },
  companyId: 1,
  period: {
    startDate: '2023-12-24T00:00:00',
    endDate: '2023-12-31T00:00:00',
  },
  availablePeriod: {
    startDate: '2020-01-01T00:00:00',
    endDate: '2023-12-31T00:00:00',
  },
};
