import {createAsyncThunk} from '@reduxjs/toolkit';

import {serializeError} from 'store/serializeErrors';

import {DictionariesApi} from '../../api';

export const getUserStatuses = createAsyncThunk(
  'dictionaries/getUserStatuses',
  async () => {
    return await DictionariesApi.getCompanyUserStatuses();
  },
  {serializeError},
);
export const getCompanyStatuses = createAsyncThunk(
  'dictionaries/getCompanyStatuses',
  async () => {
    return await DictionariesApi.getCompanyStatuses();
  },
  {serializeError},
);
export const getCompanyRoles = createAsyncThunk(
  'dictionaries/getCompanyRoles',
  async () => {
    return await DictionariesApi.getCompanyRoles();
  },
  {serializeError},
);
export const dictionariesAsyncActions = {
  getUserStatuses,
  getCompanyStatuses,
  getCompanyRoles,
};
