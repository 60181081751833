import {FC} from 'react';

import s from './NoDataPlaceholder.module.scss';

type Props = {
  height?: number;
  text?: string[] | string;
};

export const NoDataPlaceholder: FC<Props> = ({height = 200, text}: Props) => {
  return (
    <div className={s.noDataPlaceholder} style={{height: `${height}px`}}>
      {!text?.length && <p className={s.noDataPlaceholder__text}>Нет данных за выбранный период.</p>}
      {typeof text === 'string' && <p className={s.noDataPlaceholder__text}>{text}</p>}
      {Array.isArray(text) && !!text.length
        ? text.map((message, index) => (
          <p key={`${message}-${index}`} className={s.noDataPlaceholder__text}>
            {message}
          </p>
        ))
        : null}
    </div>
  );
};
