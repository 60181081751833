import {XIcon} from 'lucide-react';
import {FC, useState} from 'react';

import Onboarding1 from 'assets/video/Onboard-1.webm';
import Onboarding2 from 'assets/video/Onboard-2.webm';
import Onboarding3 from 'assets/video/Onboard-3.webm';
import Onboarding4 from 'assets/video/Onboard-4.webm';
import {Button} from 'shared/components/shadcn-ui/Button';
import {DialogDescription, DialogTitle} from 'shared/components/shadcn-ui/Dialog';
import {Tabs, TabsContent, TabsList, TabsTrigger} from 'shared/components/shadcn-ui/Tabs/Tabs';
import {cn} from 'shared/utils/helpers';

import {TABS_CONTENT_STYLE, TABS_TITLE_STYLE, TABS_TRIGGER_STYLE} from './constants';

import s from '../../Onboarding.module.scss';

export enum OnboardinTabValueType {
  first = 'first',
  second = 'second',
  third = 'third',
  fourth = 'fourth',
}

type Props = {
  postponeOnboarding: () => void;
  completeOnboarding: () => void;
};

export const OnboardingTabs: FC<Props> = ({completeOnboarding, postponeOnboarding}) => {
  const [tabValue, setTabValue] = useState<OnboardinTabValueType>(OnboardinTabValueType.first);
  const onChangeValue = (tab: string) => setTabValue(tab as OnboardinTabValueType);

  const changeTab = async () => {
    switch (tabValue) {
      case OnboardinTabValueType.first:
        setTabValue(OnboardinTabValueType.second);
        break;
      case OnboardinTabValueType.second:
        setTabValue(OnboardinTabValueType.third);
        break;
      case OnboardinTabValueType.third:
        setTabValue(OnboardinTabValueType.fourth);
        break;
      case OnboardinTabValueType.fourth:
        completeOnboarding();
        break;
      default:
        return null;
    }
  };

  const getCloseBtn = () => {
    return (
      <Button
        variant={'icon'}
        onClick={postponeOnboarding}
        className="absolute z-50 right-7 top-7 focus:outline-none p-0"
      >
        <XIcon className="text-neutral-500" />
      </Button>
    );
  };

  return (
    <>
      <Tabs value={tabValue} onValueChange={onChangeValue}>
        <TabsList className="absolute bottom-9 left-12 z-50 bg-[#FCFAFE]">
          <TabsTrigger value={OnboardinTabValueType.first} className={TABS_TRIGGER_STYLE} />
          <TabsTrigger value={OnboardinTabValueType.second} className={TABS_TRIGGER_STYLE} />
          <TabsTrigger value={OnboardinTabValueType.third} className={TABS_TRIGGER_STYLE} />
          <TabsTrigger value={OnboardinTabValueType.fourth} className={TABS_TRIGGER_STYLE} />
        </TabsList>

        <TabsContent value={OnboardinTabValueType.first} className={cn(TABS_CONTENT_STYLE, 'bg-transparent')}>
          <video
            src={Onboarding1}
            loop
            controls
            playsInline
            className={cn(s.onboarding__video, s.onboarding__video_first)}
          />

          <div className={cn(s.onboarding__text, s.onboarding__text_with_offset)}>
            <DialogTitle className="mb-3">
              <h3>Рады, что вы стали частью Стафскрин</h3>
            </DialogTitle>
            <DialogDescription>
              В качестве нашей благодарности подготовили для вас полезный тур с&#160;ключевыми ценностями системы. Он
              поможет познакомиться с&#160;основной функциональностью и оценить, насколько&#160;Стафскрин подходит вашей
              HR-команде.
            </DialogDescription>
            <Button className="block ml-auto mr-0 mt-auto" onClick={changeTab} variant={'primary'}>
              Продолжить
            </Button>
          </div>
          {getCloseBtn()}
        </TabsContent>

        <TabsContent value={OnboardinTabValueType.second} className={TABS_CONTENT_STYLE}>
          <video src={Onboarding2} autoPlay muted loop playsInline className={s.onboarding__video} />
          <div className={s.onboarding__text}>
            <DialogTitle className={TABS_TITLE_STYLE}>
              <h3>Экономим время на сборе и расчёте HR-метрик</h3>
            </DialogTitle>
            <DialogDescription>
              Мы автоматически собираем большой объём данных и трансформируем его в&#160;понятные HR-менеджерам
              показатели. Больше не&#160;нужно каждый раз обращаться за&#160;помощью к&#160;маркетологам, аналитикам
              и&#160;кадровым специалистам, чтобы получить данные из&#160;1C, Хантфлоу, Яндекс Метрики и&#160;Яндекс
              Директ. Все необходимые показатели всегда готовы к&#160;анализу и&#160;принятию управленческих решений.
            </DialogDescription>
            <Button className="block ml-auto mr-0 mt-auto" onClick={changeTab} variant={'primary'}>
              Продолжить
            </Button>
          </div>
          {getCloseBtn()}
        </TabsContent>

        <TabsContent value={OnboardinTabValueType.third} className={TABS_CONTENT_STYLE}>
          <video src={Onboarding3} autoPlay muted loop playsInline className={s.onboarding__video} />
          <div className={s.onboarding__text}>
            <DialogTitle className={TABS_TITLE_STYLE}>
              <h3>Вдохновляем работать с данными в&#160;команде</h3>
            </DialogTitle>
            <DialogDescription>
              Работа с&#160;данными приносит пользу, когда она командная и&#160;регулярная. Поэтому мы добавили
              возможность приглашать в&#160;систему других пользователей. Также настроили аккуратные и&#160;ненавязчивые
              уведомления, которые будут напоминать вам о&#160;необходимости проанализировать данные.
            </DialogDescription>
            <Button className="block ml-auto mr-0 mt-auto" onClick={changeTab} variant={'primary'}>
              Продолжить
            </Button>
          </div>
          {getCloseBtn()}
        </TabsContent>

        <TabsContent value={OnboardinTabValueType.fourth} className={TABS_CONTENT_STYLE}>
          <video src={Onboarding4} autoPlay muted loop playsInline className={s.onboarding__video} />
          <div className={s.onboarding__text}>
            <DialogTitle className={TABS_TITLE_STYLE}>
              <h3>Разложили все метрики по&#160;полочкам</h3>
            </DialogTitle>
            <DialogDescription className="mb-3">
              Мы собрали более двадцати HR-показателей и сгруппировали их в&#160;одну понятную систему. На дашбордах
              «Общие метрики», «Подбор персонала» и&#160;«HR-бренд» вы можете увидеть результат работы вашей HR-команды
              за&#160;три&#160;года.{' '}
            </DialogDescription>
            <DialogDescription>Понимаем, что процесс эффективен, когда он правильно организован.</DialogDescription>
            <Button onClick={changeTab} variant={'primary'} className="block ml-auto mr-0 mt-auto">
              Начать
            </Button>
          </div>
        </TabsContent>
      </Tabs>
    </>
  );
};
