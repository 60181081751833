import {FC} from 'react';
import {Area, AreaChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

import {CommonMetricsDTO} from 'api/dto';

import {ClipLoader} from '../../../ClipLoader';
import {CustomTooltipContent} from '../../CustomTooltip';
import {useDashboardContext} from '../../DashboardContextProvider';
import {
  DEFAULT_YAXIS_LABEL_WIDTH,
  DOMAIN_OF_EMPTY_CHART,
  getCartesianGridDefaultProps,
  getLegendDefaultProps,
  getLabelListDefaultStyles,
  getXAxisDefaultProps,
  getYAxisDefaultProps,
  getDefaultTooltipCursorProps,
  getXAxisInterval,
  getYAxisWidthByValue,
  getXAxisPropsForEmptyChart,
  getCustomizedYAxisTick,
  getQuickIntervalTypeByDateRange,
} from '../../utils';

type Props = {
  data: CommonMetricsDTO['charts']['flowRateDynamics'];
  isLoading?: boolean;
  className?: string;
};

export const FlowRateDynamicChart: FC<Props> = ({data, isLoading, className = ''}: Props) => {
  const {dateRange} = useDashboardContext();
  const maxValue = data ? Math.ceil(Math.max(...data.map((item) => item.value))) : 1;
  const isEmptyChart = data?.length === 0;

  const getXAxisProps = () => {
    if (isEmptyChart) return getXAxisPropsForEmptyChart(dateRange);
    return getXAxisDefaultProps();
  };

  if (isLoading) {
    return <ClipLoader containerHeight={250} />;
  }

  const getLabelFormatter = (value: any): string => (value === 0 ? '' : value);

  return (
    <>
      <ResponsiveContainer width="100%" height={250} className={className}>
        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 30,
            right: 25,
            left: 0,
            bottom: 10,
          }}
        >
          <CartesianGrid {...getCartesianGridDefaultProps()} />
          <Tooltip content={<CustomTooltipContent />} cursor={getDefaultTooltipCursorProps()} />
          <XAxis
            dataKey="date"
            {...getXAxisProps()}
            interval={getXAxisInterval(getQuickIntervalTypeByDateRange(dateRange))}
          />
          <YAxis
            {...getYAxisDefaultProps()}
            domain={isEmptyChart ? DOMAIN_OF_EMPTY_CHART : [0, maxValue]}
            tickCount={!isEmptyChart ? Math.min(maxValue, 6) : 5}
            tick={getCustomizedYAxisTick}
            width={!isEmptyChart ? getYAxisWidthByValue(data) : DEFAULT_YAXIS_LABEL_WIDTH}
          />
          <Area
            type="monotone"
            fillOpacity="1"
            dataKey="value"
            name="Текучесть (%)"
            stroke="#FFC93F"
            fill="#FFF8D8"
            isAnimationActive={false}
            dot={{fill: '#FFC93F', stroke: '#FFC93F', strokeWidth: 1, r: 6}}
          >
            <LabelList
              dataKey="value"
              position="top"
              dy={-5}
              style={getLabelListDefaultStyles()}
              formatter={getLabelFormatter}
            />
          </Area>
          <Legend {...getLegendDefaultProps()} />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};
