import {ClipLoader} from 'shared/components/ClipLoader';
import {UsersCard} from 'shared/components/UsersCard';
import {useLoadCompany} from 'shared/hooks/useLoadCompany';
import {useScrollToTopOnMount} from 'shared/hooks/useScrollToTopOnMount';
import {useProvideBreadcrumbsData} from 'shared/layouts/BaseLayout/BaseLayoutContext';

import s from './CompanyDetails.module.scss';
import {CompanyAdminCard} from './components/CompanyAdminCard';
import {CompanyInfoCard} from './components/CompanyInfoCard';

export const CompanyDetails = () => {
  const {company, loaded} = useLoadCompany();

  useProvideBreadcrumbsData(company);
  useScrollToTopOnMount();

  if (loaded) return <ClipLoader />;

  return (
    <div className={s.companyDetails}>
      <CompanyInfoCard />
      <CompanyAdminCard />
      <UsersCard />
    </div>
  );
};
