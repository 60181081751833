import {zodResolver} from '@hookform/resolvers/zod';
import cn from 'classnames';
import {addDays, intervalToDuration, isAfter, isBefore, startOfDay} from 'date-fns';
import {ChangeEvent, FC, useEffect, useMemo} from 'react';
import {useSelectRange} from 'react-day-picker';
import {useForm, useWatch} from 'react-hook-form';
import * as z from 'zod';

import {DateInputFormatter} from 'shared/components/DateInputFormatter';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Form, FormControl, FormField, FormItem, FormMessage} from 'shared/components/shadcn-ui/Form';
import {toast} from 'shared/components/shadcn-ui/Toast/useToast';
import {useCurrentCompany} from 'shared/hooks';
import {bem} from 'shared/utils';
import {DEFAULT_DATE_FORMAT, isAfterOrSame, isBeforeOrSame, safeFormatDate, safeParseDate} from 'shared/utils/date';
import {pluralize} from 'shared/utils/helpers';

import s from './DateRangeInputForm.module.scss';

import {useDashboardContext} from '../../DashboardContextProvider';
import {DEMO_END_DATE, getEndDate, getStartDate} from '../utils';

const sn = bem('dateRangeBox', s);

type Props = {
  onAfterSubmit?: () => void;
};

export const DateRangeInputForm: FC<Props> = ({onAfterSubmit}) => {
  const {setDateRange} = useDashboardContext();
  const dateRange = useSelectRange().selected;
  const {hasDemoStatus} = useCurrentCompany();

  const FormSchema = useMemo(() => {
    const dateValidationRules = z.string().transform((value, ctx) => {
      if (!value) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Выберите дату',
          fatal: true,
        });
        return z.NEVER;
      }
      const parsed = safeParseDate(value, DEFAULT_DATE_FORMAT);

      if (!parsed) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_date,
          message: 'Некорректная дата',
          fatal: true,
        });

        return z.NEVER;
      }

      const limitStartDate = startOfDay(getStartDate(hasDemoStatus));
      if (parsed && isBefore(parsed, limitStartDate)) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_date,
          message: 'Выберите дату позже',
          fatal: true,
        });
        toast({
          description: hasDemoStatus
            ? 'В демо доступны данные от 01.01.2023.'
            : 'Система хранит исторические данные за 3 года.',
          variant: 'destructive',
        });

        return z.NEVER;
      }

      const limitEndDate = startOfDay(getEndDate(hasDemoStatus));
      if (parsed && isAfter(parsed, limitEndDate)) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_date,
          message: 'Некорректная дата',
          fatal: true,
        });

        toast({
          description:
            !hasDemoStatus || isAfter(parsed, new Date())
              ? 'Эта дата ещё не наступила.'
              : `В демо доступе доступны данные до ${safeFormatDate(DEMO_END_DATE, 'd MMMM y года.')}`,
          variant: 'destructive',
        });

        return z.NEVER;
      }

      return value;
    });

    return z.object({
      dateTo: dateValidationRules,
      dateFrom: dateValidationRules,
    });
  }, [hasDemoStatus]);

  const inputDateRangeForm = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      dateFrom: safeFormatDate(dateRange?.from),
      dateTo: safeFormatDate(dateRange?.to),
    },
  });
  const watchedFormValues = useWatch({control: inputDateRangeForm.control});

  useEffect(() => {
    inputDateRangeForm.reset({
      dateFrom: safeFormatDate(dateRange?.from),
      dateTo: safeFormatDate(dateRange?.to),
    });
    inputDateRangeForm.clearErrors();
  }, [dateRange]);

  const applyDateRange = (values: z.infer<typeof FormSchema>) => {
    const dateFrom = safeParseDate(values.dateFrom, DEFAULT_DATE_FORMAT);
    const dateTo = safeParseDate(values.dateTo, DEFAULT_DATE_FORMAT);
    if (dateFrom && dateTo) {
      const isValidStartDate = isBeforeOrSame(dateFrom, dateTo);
      const isValidEndDate = isAfterOrSame(dateTo, dateFrom);
      if (isValidStartDate && isValidEndDate) {
        setDateRange({from: dateFrom, to: dateTo});
        if (onAfterSubmit) onAfterSubmit();
        return;
      }
    }
  };

  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name as keyof z.infer<typeof FormSchema>;
    inputDateRangeForm.setValue(name, e.target.value);
    if (name === 'dateTo') {
      const parsedDate = safeParseDate(e.target.value, DEFAULT_DATE_FORMAT);
      const parsedDateFrom = safeParseDate(watchedFormValues.dateFrom, DEFAULT_DATE_FORMAT);
      if (parsedDate && parsedDateFrom && isBefore(parsedDate, parsedDateFrom)) {
        inputDateRangeForm.setValue('dateFrom', e.target.value);
        inputDateRangeForm.setValue('dateTo', watchedFormValues.dateFrom ?? '');
      }
    }
  };

  const humanReadableDuration = useMemo(() => {
    const {dateFrom, dateTo} = watchedFormValues;
    const parsedDateFrom = safeParseDate(dateFrom, DEFAULT_DATE_FORMAT);
    let parsedDateTo = safeParseDate(dateTo, DEFAULT_DATE_FORMAT);
    let res = '';

    if (parsedDateTo && parsedDateFrom) {
      // исскуственно увеличиваем дату на 1 день, чтобы учитывать дату окончания при расчете длительности
      parsedDateTo = addDays(parsedDateTo, 1);

      const {months, days, years} = intervalToDuration({start: parsedDateFrom, end: parsedDateTo});
      if (years) {
        res += `${pluralize(years, ['год', 'года', 'лет'])}`;
      }
      if (months) {
        res += ` ${pluralize(months, ['месяц', 'месяца', 'месяцев'])}`;
      }
      if (days) {
        res += ` ${pluralize(days, ['день', 'дня', 'дней'])}`;
      }
      return res;
    }
    return '';
  }, [watchedFormValues]);

  return (
    <Form {...inputDateRangeForm}>
      <form className={s.dateRangeBox} onSubmit={inputDateRangeForm.handleSubmit(applyDateRange)}>
        <div className={sn('inputBox')}>
          <FormField
            control={inputDateRangeForm.control}
            name="dateFrom"
            render={({field}) => {
              return (
                <FormItem className={sn('dateFromBox')}>
                  <FormControl>
                    <DateInputFormatter
                      {...field}
                      onChange={onChangeField}
                      value={`${field.value}`}
                      defaultValue={safeFormatDate(inputDateRangeForm.formState.defaultValues?.dateFrom)}
                      className={cn(sn('input'))}
                    />
                  </FormControl>
                  <FormMessage className={cn('normal-case', sn('errorMessage'))} />
                </FormItem>
              );
            }}
          />
          <FormField
            control={inputDateRangeForm.control}
            name="dateTo"
            render={({field}) => (
              <FormItem>
                <FormControl>
                  <DateInputFormatter
                    {...field}
                    disabled={!watchedFormValues.dateFrom}
                    onChange={onChangeField}
                    value={`${field.value}`}
                    defaultValue={safeFormatDate(inputDateRangeForm.formState.defaultValues?.dateTo)}
                    className={sn('input')}
                  />
                </FormControl>
                <FormMessage className={cn('normal-case', sn('errorMessage'))} />
              </FormItem>
            )}
          />
        </div>
        {humanReadableDuration && <span className={sn('intervalDuration')}>Выбрано: {humanReadableDuration}</span>}
        <Button type="submit" variant="primary" className={sn('button')}>
          Применить
        </Button>
      </form>
    </Form>
  );
};
