import {Eye, EyeOff} from 'lucide-react';
import {forwardRef, useState} from 'react';

import s from './PasswordInput.module.scss';

import {Button} from '../shadcn-ui/Button';
import {Input} from '../shadcn-ui/Input';
import {Props} from '../shadcn-ui/Input/Input';

export const PasswordInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown((prev) => !prev);
  };
  return (
    <div className={s.passwordInput}>
      <Input className="pr-11" type={passwordShown ? 'text' : 'password'} {...props} ref={ref} />
      <Button
        size="icon"
        type="button"
        variant="icon"
        className="absolute top-1 right-3  text-neutral-400"
        onClick={togglePasswordVisiblity}
      >
        {passwordShown ? <EyeOff /> : <Eye />}
      </Button>
    </div>
  );
});
