import {createSelector} from 'reselect';

import {RootState} from './index';

export const getUserCompanyRole = createSelector(
  (state: RootState) => state.auth.companyProfile?.companyRoleId,
  (state: RootState) => state.dictionaries.companyRoles,
  (userCompanyRoleId, companyRolesList) =>
    companyRolesList?.find(({value}) => value === userCompanyRoleId)?.description,
);
