import {Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

import {CommonMetricsDTO} from 'api/dto';

import {ClipLoader} from '../../../ClipLoader';
import {CustomTooltipContent} from '../../CustomTooltip';
import {useDashboardContext} from '../../DashboardContextProvider';
import {NoDataPlaceholder} from '../../NoDataPlaceholder';
import {
  DOMAIN_OF_EMPTY_CHART,
  calcDomainDataRange,
  getCartesianGridDefaultProps,
  getCustomizedYAxisTick,
  getDefaultTooltipCursorProps,
  getLabelFormatter,
  getLabelListDefaultStyles,
  getLegendDefaultProps,
  getXAxisDefaultProps,
  getXAxisInterval,
  getXAxisPropsForEmptyChart,
  getYAxisDefaultProps,
  getQuickIntervalTypeByDateRange,
} from '../../utils';

type Props = {
  color?: string;
  data: CommonMetricsDTO['charts']['absencesDynamics'] | undefined;
  isLoading?: boolean;
  className?: string;
  infoMessage?: string;
};

export const AbsencesDynamicsChart = ({
  data,
  infoMessage = '',
  isLoading = false,
  className = '',
  color = '#56BC6B',
}: Props) => {
  const isEmptyChart = !data?.length || data.every((item) => item.value === 0);
  const {dateRange} = useDashboardContext();

  const getXAxisProps = () => {
    if (isEmptyChart) return getXAxisPropsForEmptyChart(dateRange);
    return getXAxisDefaultProps();
  };

  if (isLoading) {
    return <ClipLoader containerHeight={250} />;
  }

  if (infoMessage || !data) {
    return <NoDataPlaceholder height={250} text={infoMessage} />;
  }

  return (
    <>
      <ResponsiveContainer width="100%" height={250} className={className}>
        <BarChart
          barSize={22}
          data={data ?? []}
          margin={{
            top: 30,
            right: 25,
            left: 0,
            bottom: 10,
          }}
        >
          {!isEmptyChart && <Tooltip content={<CustomTooltipContent />} cursor={getDefaultTooltipCursorProps()} />}
          <CartesianGrid {...getCartesianGridDefaultProps()} />
          <XAxis
            dataKey="date"
            {...getXAxisProps()}
            interval={getXAxisInterval(getQuickIntervalTypeByDateRange(dateRange))}
          />
          <YAxis
            {...getYAxisDefaultProps()}
            domain={isEmptyChart ? DOMAIN_OF_EMPTY_CHART : calcDomainDataRange}
            tick={getCustomizedYAxisTick}
            width={25}
          />
          <Bar dataKey="value" fill={color} radius={[4, 4, 4, 4]} name="Отсутствия (%)">
            <LabelList
              dataKey="value"
              position="top"
              style={getLabelListDefaultStyles()}
              formatter={getLabelFormatter}
            />
          </Bar>
          <Legend {...getLegendDefaultProps()} />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};
