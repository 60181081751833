import {addDays, subDays, subMonths} from 'date-fns';
import {toJpeg} from 'html-to-image';
import JsPDF from 'jspdf';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {DateRange} from 'react-day-picker';
import {Outlet} from 'react-router';

import {CommonMetricsDTO} from 'api/dto';
import {useBrowserStorageValue, useCurrentCompany} from 'shared/hooks';

import {DashboardData, DashboardContextType, FileReportOptions} from './types';

import {QuickPeriodType} from '../DashboardTopBar/utils';
import {getQuickIntervalTypeByDateRangeFromToday} from '../utils';

const DashboardContext = createContext<DashboardContextType>({
  data: {} as CommonMetricsDTO,
  setData: null as unknown as Dispatch<SetStateAction<DashboardData>>,
  dateRange: {
    from: undefined,
    to: undefined,
  },
  setDateRange: () => null,
  downloadReport: async () => null,
  fetchDataCb: async () => undefined,
  resetState: () => null,
  quickPeriod: null,
  setQuickPeriod: () => null,
});

export const useDashboardContext = () => useContext(DashboardContext);
const STORAGE_KEY = 'dashboardSelectedRange';

export const DashboardContextProvider = () => {
  const [data, setData] = useState<DashboardData>();
  const {company, hasDemoStatus} = useCurrentCompany();
  const initialized = useRef(false);
  const [getLastSelectedRange, setLastSelectedRange] = useBrowserStorageValue<DateRange | null>(sessionStorage, {
    key: STORAGE_KEY,
    path: `${company?.id}`,
    defaultValue: null,
    enabled: !!company?.id,
  });
  const lastSelectedRangeFromStorage = getLastSelectedRange();

  const parsedDateFromStorage = useMemo(() => {
    if (lastSelectedRangeFromStorage) {
      const {from, to} = lastSelectedRangeFromStorage;
      if (from && to) {
        return {
          from: new Date(from),
          to: new Date(to),
        } as DateRange;
      }
    }
    return null;
  }, [lastSelectedRangeFromStorage]);

  const defaultDateRange = useMemo(() => {
    let from = hasDemoStatus ? subMonths(new Date(), 3) : subDays(new Date(), 6);
    if (hasDemoStatus) {
      // увеличиваем дату на 1 день, т.к. при расчете необходимо учитывать сегодняший день
      // поэтому сокращаем левую границу на 1 день
      from = addDays(from, 1);
    }
    return {
      from,
      to: new Date(),
    };
  }, [hasDemoStatus]);

  useEffect(() => {
    // Для демо компаний по умолчанию должен быть установлен интервал КВАРТАЛ
    // но при первом открытии нет гарантий, что информация о компании подгрузилась
    if (company?.id && !initialized.current) {
      initialized.current = true;
      setDateRange(parsedDateFromStorage || defaultDateRange);
    }
  }, [company, defaultDateRange, parsedDateFromStorage]);

  const resetState = () => {
    setData(undefined);
  };
  const [selectedRange, setSelectedRange] = useState<DateRange>(parsedDateFromStorage ?? defaultDateRange);

  const [quickPeriod, setQuickPeriod] = useState<QuickPeriodType | null>(
    getQuickIntervalTypeByDateRangeFromToday(selectedRange),
  );

  const setDateRange = (newState: DateRange) => {
    setSelectedRange(newState);
    setLastSelectedRange(newState ?? null);
    setQuickPeriod(getQuickIntervalTypeByDateRangeFromToday(newState));
  };

  const downloadReport = useCallback(async (element: HTMLElement | null, {name, extension}: FileReportOptions) => {
    if (!element) throw new Error('Не найден корневой элемент.');
    const doc = new JsPDF('portrait', 'mm', 'a4', true);
    const dataUrl = await toJpeg(element, {quality: 1, backgroundColor: '#f7f8fa'});
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    doc.addImage(dataUrl, extension, 0, 0, pageWidth, pageHeight);
    doc.save(`${name}.pdf`);
  }, []);

  useEffect(() => {
    const period = getQuickIntervalTypeByDateRangeFromToday(selectedRange);
    if (period) setQuickPeriod(period);
  }, [selectedRange]);

  const dashboardContextValue = useMemo(
    () => ({
      data,
      setData,
      dateRange: selectedRange,
      setDateRange,
      downloadReport,
      resetState,
      quickPeriod,
      setQuickPeriod,
    }),
    [data, setData, selectedRange, setDateRange, downloadReport, resetState, quickPeriod, setQuickPeriod],
  );

  return (
    <DashboardContext.Provider value={dashboardContextValue}>
      <div id="dashboardContext">
        <Outlet />
      </div>
    </DashboardContext.Provider>
  );
};
