import {CardHeader} from 'shared/components/shadcn-ui/Card';

import {ChangePassword} from './components/ChangePassword';
import {ExitButton} from './components/ExitButton';
import s from './Options.module.scss';

export const Options = () => {
  return (
    <div className={s.options}>
      <div className={s.options__column}>
        <CardHeader className="font-bold">Дополнительно</CardHeader>
      </div>
      <div className={s.options__actions}>
        <ChangePassword />
        <ExitButton />
      </div>
    </div>
  );
};
