import {NpsAndEnpsDTO, NpsAndEnpsViewDTO} from 'api/dto/NpsAndEnpsDTO';
import {NpsOrEnpsType} from 'modules/User/HrBrand/NpsOrEnpsMetricCard/NpsOrEnpsMetricCard';

import ApiClient from '../client';

class LoyaltyApiService {
  getBasePath = function (companyId: string | number): string {
    return `company-operations/${companyId}/operations/loyalty-indexes`;
  };

  deleteValueList(companyId: string | number, ids: number[]) {
    return ApiClient.delete(this.getBasePath(companyId), {data: ids}).then((res) => res.data);
  }

  updateValueList(companyId: string | number, payload: NpsAndEnpsDTO[], type: NpsOrEnpsType) {
    return ApiClient.post(this.getBasePath(companyId), {data: payload, type}).then((res) => res.data);
  }

  getValueList(companyId: string | number, type: NpsOrEnpsType) {
    return ApiClient.get<NpsAndEnpsViewDTO[]>(this.getBasePath(companyId), {params: {type}}).then((res) => res.data);
  }
}

export const LoyaltyApi = new LoyaltyApiService();
