import {zodResolver} from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/browser';
import {useForm} from 'react-hook-form';
import {Link, Navigate, generatePath, useNavigate} from 'react-router-dom';
import * as z from 'zod';

import {CompaniesApi} from 'api';
import {CompanyStatus} from 'api/dto';
import {NewCompanyUserFields} from 'shared/components/NewCompanyUserFields';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Card, CardHeader} from 'shared/components/shadcn-ui/Card';
import {CardActions} from 'shared/components/shadcn-ui/Card/Card';
import {Form} from 'shared/components/shadcn-ui/Form';
import {Separator} from 'shared/components/shadcn-ui/Separator';
import {toast} from 'shared/components/shadcn-ui/Toast/useToast';
import {routes} from 'shared/constants/routes';
import {useCurrentCompany, useUserRole} from 'shared/hooks';
import {setValidationFormErrors} from 'shared/utils';
import {extractApiErrorMessage} from 'shared/utils/axios';
import {getRawPhoneNumber, handleApiErrors} from 'shared/utils/helpers';
import {CompanyUserSchema} from 'shared/utils/validation';
import {useRootDispatch} from 'store';
import {companiesAsyncActions} from 'store/companies/actions';

import {NewCompanyUserType} from './types';

export const NewCompanyUser = () => {
  const navigate = useNavigate();
  const {isSuperAdmin} = useUserRole();
  const {company} = useCurrentCompany();
  const companyStatusName = company?.statusName;
  const dispatch = useRootDispatch();

  const canAddNewUser =
    companyStatusName === CompanyStatus.Active || companyStatusName === CompanyStatus.UnActive || CompanyStatus.Demo;

  const form = useForm<NewCompanyUserType>({
    resolver: zodResolver(CompanyUserSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      jobTitle: '',
      phoneNumber: '',
    },
  });

  const onSubmit = async (data: z.infer<typeof CompanyUserSchema>) => {
    const {phoneNumber, ...rest} = data;
    if (company) {
      try {
        await CompaniesApi.addCompanyUser(
          company?.id,
          phoneNumber ? {...rest, phoneNumber: getRawPhoneNumber(phoneNumber)} : rest,
        );
        dispatch(companiesAsyncActions.fetchCurrentCompany());
        toast({
          description: `Пользователь ${rest.firstName} ${rest.lastName} создан.`,
        });
        isSuperAdmin
          ? navigate(generatePath(routes.company, {compId: company?.id}))
          : navigate(routes.companyUsersSettings);
      } catch (error) {
        const extractedError = extractApiErrorMessage(error);
        Sentry.captureException(error);
        if (typeof extractedError === 'string') {
          handleApiErrors(error);
        } else {
          setValidationFormErrors<typeof data>(extractedError, (fieldName, fieldErrors) => {
            form.setError(fieldName, {
              message: fieldErrors[0],
            });
          });
        }
      }
    }
  };
  const cancel = () => {
    isSuperAdmin
      ? navigate(generatePath(routes.company, {compId: company?.id}))
      : navigate(routes.companyUsersSettings);
  };

  if (!canAddNewUser) {
    return <Navigate replace to={routes.settings} />;
  }
  return (
    <Card className="flex items-start gap-x-10">
      <CardHeader className="font-bold basis-[30%]">Данные пользователя</CardHeader>
      <Form {...form}>
        <form
          id="newCompanyUserForm"
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-wrap basis-[616px] gap-x-5"
        >
          <NewCompanyUserFields />
          <Separator className="mt-4" />
          <CardActions className="max-w-[250px] mt-4 mr-0 ml-auto">
            <Button type="button" variant="ghost" onClick={cancel}>
              Отмена
            </Button>
            <Button type="submit" variant="primary" disabled={form.formState.isSubmitting}>
              {form.formState.isSubmitting ? 'Загрузка...' : 'Добавить'}
            </Button>
          </CardActions>
        </form>
      </Form>
      <Link to={routes.settings} state={{tab: 'users'}} />
    </Card>
  );
};
