import {FC, ReactNode} from 'react';

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'shared/components/shadcn-ui/AlertDialog';
import {useConfirmation} from 'shared/hooks';

import {Button} from '../../../shadcn-ui/Button';

type Props = {
  title: string;
  description?: string | ReactNode;
  isOpen: boolean;
  acceptButton?: string | ReactNode;
  cancelButton?: string | ReactNode;
};

export const ConfirmationDialog: FC<Props> = ({title, description, isOpen, acceptButton, cancelButton}) => {
  const {accept, cancel} = useConfirmation();

  return (
    <AlertDialog open={isOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          {description ? <AlertDialogDescription>{description}</AlertDialogDescription> : null}
        </AlertDialogHeader>
        <AlertDialogFooter>
          {typeof cancelButton === 'string' ? (
            <AlertDialogCancel onClick={cancel}>{cancelButton}</AlertDialogCancel>
          ) : (
            cancelButton
          )}
          {typeof acceptButton === 'string' && acceptButton.length > 0 ? (
            <Button variant="primary" onClick={accept}>
              {acceptButton}
            </Button>
          ) : (
            acceptButton
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
