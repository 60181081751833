import {FC} from 'react';

import {useUserRole} from 'shared/hooks';

import s from './MenuList.module.scss';

import {MenuItem} from '../MenuItem';
import {getMenuItems} from '../utils/constants';

type Props = {
  isToggled: boolean;
};

export const MenuList: FC<Props> = ({isToggled}) => {
  const {systemRole} = useUserRole();
  return (
    <ul className={s.menuList}>
      {getMenuItems(systemRole).map((item, index) => (
        <MenuItem key={`${index}_${item.route}`} isToggled={isToggled} {...item} />
      ))}
    </ul>
  );
};
