import {XIcon} from 'lucide-react';
import {FC} from 'react';
import {useNavigate} from 'react-router';

import SuccessImg from 'assets/icons/tick.svg';
import {routes} from 'shared/constants/routes';
import {bem} from 'shared/utils';

import s from './SuccessfullySendingRequest.module.scss';

import {Button} from '../shadcn-ui/Button';

const sn = bem('successfullySendingRequest', s);

type Props = {withoutCloseBtn?: boolean; title: string; subtitle: string};

export const SuccessfullySendingRequest: FC<Props> = ({withoutCloseBtn, title, subtitle}) => {
  const navigate = useNavigate();
  const close = () => {
    navigate(routes.login);
  };
  return (
    <div className={s.successfullySendingRequest}>
      {!withoutCloseBtn && (
        <Button className="absolute right-2.5 top-2.5 p-0" type="button" variant="ghost" onClick={close}>
          <XIcon />
        </Button>
      )}
      <h2 className={sn('title')}>{title}</h2>
      <p className={sn('subtitle')}>{subtitle}</p>

      <img className={sn('success')} src={SuccessImg} alt="Success image" />
    </div>
  );
};
