import {Fragment, ReactNode, useContext, useMemo} from 'react';
import {UIMatch, useMatches} from 'react-router';

import Icon from 'assets/icons/chevron-right.svg';
import {useSettingsContext} from 'modules/Settings/SettingsContext/SettingsContext';
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from 'shared/components/shadcn-ui/Tooltip';
import {bem} from 'shared/utils';

import s from './Breadcrumbs.module.scss';

import {BaseLayoutContext} from '../../BaseLayoutContext';

const sn = bem('breadcrumbs', s);

interface MatchType {
  crumb: (data: any) => ReactNode;
  prevCrumb?: (data: any) => ReactNode;
  isShownTitleTooltip?: (data: any) => boolean;
}
type MatchData = () => any;

const MIN_LVL_TO_SHOW = 2;

export const Breadcrumbs = () => {
  const {breadcrumbsData} = useContext(BaseLayoutContext);

  const {noTitle} = useSettingsContext();
  const matches: UIMatch<MatchData, MatchType>[] = useMatches() as UIMatch<MatchData, MatchType>[];

  const crumbs = useMemo(() => {
    return matches.filter((match) => Boolean(match.handle?.crumb)).map((match) => match.handle?.crumb(breadcrumbsData));
  }, [matches, breadcrumbsData]);

  const prevCrumbs = useMemo(() => {
    const prevCrumb = matches.find((match) => Boolean(match.handle?.prevCrumb))?.handle?.prevCrumb;
    return prevCrumb ? prevCrumb(breadcrumbsData) : null;
  }, [matches, breadcrumbsData]);

  const isShownTitleTooltip = matches.find((match) => Boolean(match.handle?.isShownTitleTooltip))?.handle
    ?.isShownTitleTooltip;

  const lastCrumb = crumbs[crumbs.length - 1];

  return (
    <div className={s.breadcrumbs}>
      {!isShownTitleTooltip && <h1 className={sn('title')}>{lastCrumb}</h1>}
      {isShownTitleTooltip && !noTitle && (
        <TooltipProvider>
          <Tooltip>
            <h1 className={sn('title')}>
              <TooltipTrigger className="text-left" asChild>
                {lastCrumb}
              </TooltipTrigger>
            </h1>
            <TooltipContent align="end">
              <p className="tooltip">{lastCrumb}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
      {crumbs.length >= MIN_LVL_TO_SHOW && !noTitle && (
        <ol className={sn('list')}>
          {crumbs.map((crumb, index) => {
            const key = `${crumb?.toString().charAt(-1)}_${index}`;
            if (index === crumbs.length - 1 && prevCrumbs) {
              return (
                <Fragment key={key}>
                  <li key={`${key}_1`} className={sn('item')}>
                    <img className={sn('chevron')} alt="chevron" src={Icon} />
                    {prevCrumbs}
                  </li>
                  <li key={`${key}_2`} className={sn('item')}>
                    <img className={sn('chevron')} alt="chevron" src={Icon} />
                    {crumb}
                  </li>
                </Fragment>
              );
            } else if (index !== 1) {
              return (
                <li key={key} className={sn('item')}>
                  <img className={sn('chevron')} alt="chevron" src={Icon} />
                  {crumb}
                </li>
              );
            } else {
              return (
                <TooltipProvider key={key}>
                  <Tooltip>
                    <li className={sn('item')}>
                      <img className={sn('chevron')} alt="chevron" src={Icon} />
                      <TooltipTrigger>{crumb}</TooltipTrigger>
                    </li>
                    <TooltipContent>{crumb}</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              );
            }
          })}
        </ol>
      )}
    </div>
  );
};
