import * as amplitude from '@amplitude/analytics-browser';
import {createAsyncThunk} from '@reduxjs/toolkit';

import {serializeError} from 'store/serializeErrors';

import {CompaniesApi} from '../../api';
import {RootState} from '../index';

export const fetchCurrentCompany = createAsyncThunk(
  'companies/fetchCurrentCompany',
  async (payload, {getState}) => {
    const state = getState() as RootState;

    const res = await CompaniesApi.getCompanyById(state.companies.currentCompanyId);

    // TODO заменить на вызов метода сервиса аналитики
    // TODO уточнить у Даши что оставлять группу или свойство пользователя
    amplitude.setGroup('companyStatus', res.statusName);
    const companyStatusProperty = new amplitude.Identify();
    companyStatusProperty.set('companyStatusProperty', res.statusName);
    amplitude.identify(companyStatusProperty);

    return res;
  },
  {serializeError},
);

export const fetchIntegrationsStatuses = createAsyncThunk(
  'companies/fetchIntegrationsStatuses',
  async (payload, {getState}) => {
    const state = getState() as RootState;
    return await CompaniesApi.getAllIntegrationsStatuses(state.companies.currentCompanyId);
  },
  {serializeError},
);

export const companiesAsyncActions = {
  fetchCurrentCompany,
  fetchIntegrationsStatuses,
};
