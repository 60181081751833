import {Fragment} from 'react';

import {IntegrationTypeId} from 'api/dto';
import {IntegrationInfoBlock} from 'shared/components/IntegrationInfoBlock';
import {useCurrentCompany, useIntegrationStatuses} from 'shared/hooks';

export const InfoBlock = () => {
  const {hasDemoStatus} = useCurrentCompany();
  const hasMetricaSettings = useIntegrationStatuses(IntegrationTypeId.YandexMetrica);

  if (hasDemoStatus) return <IntegrationInfoBlock />;

  return (
    <IntegrationInfoBlock
      title={hasMetricaSettings ? 'Этапы воронки' : 'Шаг 3. Выбор сегмента'}
      previewChars={132}
      description={[
        <Fragment key="description-1">
          Если на первом шаге вы указали, что у вас нет карьерного сайта, тогда слева необходимо выбрать один или
          несколько сегментов. Укажите ссылки на страницы сайта, посвящённые кандидатам. Это позволит вам получать
          данные с вашего сайта только о соискателях.
        </Fragment>,
        <Fragment key="description-2">
          Например, вы можете указать ссылку на раздел со списком всех вакансий: https://staffscreen.ru/vacancies/.
          Тогда система «Стафскрин» получит информацию о посетителях-кандидатах, которые посетили страницу со списком
          вакансий и все последующие (вложенные) ссылки, например, https://staffscreen.ru/vacancies/php.
        </Fragment>,
      ]}
    />
  );
};
