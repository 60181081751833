import {ChevronDownIcon} from 'lucide-react';
import {FC, useState} from 'react';
import {useFormContext} from 'react-hook-form';

import {IntegrationTypeId} from 'api/dto';
import {Button} from 'shared/components/shadcn-ui/Button';
import {CardActions} from 'shared/components/shadcn-ui/Card/Card';
import {Command, CommandGroup, CommandItem, CommandList} from 'shared/components/shadcn-ui/Command';
import {FormField, FormItem, FormLabel} from 'shared/components/shadcn-ui/Form';
import {Popover, PopoverContent, PopoverTrigger} from 'shared/components/shadcn-ui/Popover';
import {Separator} from 'shared/components/shadcn-ui/Separator';
import {useCurrentCompany, useFormValues, useIntegrationStatuses} from 'shared/hooks';
import {HuntflowOrganizationModel} from 'shared/models/huntflowModel';
import {bem} from 'shared/utils';
import {cn} from 'shared/utils/helpers';

import {InfoBlock} from './components/InfoBlock';
import s from './OrganizationStep.module.scss';

import {FormValuesType} from '../../utils/types';

const sn = bem('organizationStep', s);

type Props = {
  isFirstSetup: boolean;
  organizationList: HuntflowOrganizationModel[];
  prevOrgId: number;
  onChangeTab: (newTabName: string) => void;
};

export const OrganizationStep: FC<Props> = ({organizationList, prevOrgId, isFirstSetup, onChangeTab}) => {
  const [isToggled, setIsToggled] = useState(false);
  const {formState, setValue, control} = useFormContext();

  const {organizationId, organizationName} = useFormValues<FormValuesType>();

  const errors = formState.errors;
  const isError = (errors.accessToken || errors.organizationId) && !organizationId;
  const {hasDemoStatus} = useCurrentCompany();
  const isIntegrationEnabled = useIntegrationStatuses(IntegrationTypeId.Huntflow)?.isEnabled ?? false;

  const toggle = () => setIsToggled((prev) => !prev);

  const onSelect = (organization: HuntflowOrganizationModel) => {
    setValue('organizationId', organization.id);
    setValue('organizationName', organization.name);
    toggle();
  };

  const goForward = () => onChangeTab('mappingStep');
  const goBack = () => onChangeTab('tokensStep');

  const getActionButtons = () => {
    if (!isFirstSetup) return null;
    return (
      <>
        <Separator className="mt-12 mb-12" />

        <CardActions className="flex items-end justify-end">
          <Button variant="outline" onClick={goBack} type="button">
            Назад
          </Button>
          <Button
            type="button"
            onClick={goForward}
            variant="primary"
            disabled={formState.isSubmitting || !organizationName}
          >
            {formState.isSubmitting ? 'Загрузка...' : 'Далее'}
          </Button>
        </CardActions>
      </>
    );
  };

  return (
    <div className={s.organizationStep}>
      <div className={sn('form')}>
        <FormField
          control={control}
          name="organization"
          render={() => (
            <FormItem className="h-[92px]">
              <FormLabel>Организация</FormLabel>
              <Popover open={isToggled} onOpenChange={toggle}>
                <PopoverTrigger asChild>
                  <Button
                    disabled={
                      (organizationId && !organizationList.length) ||
                      prevOrgId !== 0 ||
                      hasDemoStatus ||
                      isIntegrationEnabled
                    }
                    variant="outline"
                    className={cn('justify-between disabled:opacity-100 disabled:text-neutral-400 border-neutral-300', {
                      'bg-neutral-100 border-neutral-400': organizationName,
                      'border-error': isError,
                      'hover:cursor-not-allowed': hasDemoStatus,
                    })}
                  >
                    <span className={cn(sn('text'), {[sn('placeholder')]: !organizationName})}>
                      {organizationName || 'Выберите организацию'}
                    </span>
                    <ChevronDownIcon className="h-4 w-4" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="p-0">
                  <Command>
                    <CommandList>
                      <CommandGroup>
                        {organizationList.length
                          ? organizationList.map((organization) => (
                            <CommandItem key={organization.id} onSelect={() => onSelect(organization)}>
                              {organization.name}
                            </CommandItem>
                          ))
                          : 'Нет доступных организаций.'}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>

              {isError && typeof errors.organizationId?.message === 'string' && (
                <span className={sn('error')}>{errors.organizationId.message}</span>
              )}
            </FormItem>
          )}
        />
        {getActionButtons()}
      </div>
      <Separator orientation="vertical" className={sn('separator')} />
      <InfoBlock />
    </div>
  );
};
