import {Slot} from '@radix-ui/react-slot';
import {cva, type VariantProps} from 'class-variance-authority';
import {forwardRef, ButtonHTMLAttributes} from 'react';

import {cn} from 'shared/utils/helpers';

const buttonVariants = cva(
  'inline-flex items-center justify-center text-base font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-40 focus:ring focus:ring-warning whitespace-nowrap ',
  {
    variants: {
      variant: {
        default: 'bg-neutral-0 rounded-md text-primary-foreground shadow hover:bg-neutral-100 active:bg-neutral-300',
        primary:
          'bg-primary rounded-md text-white shadow hover:bg-primary-600 focus:bg-primary-600 active:bg-primary-700',
        error: 'bg-error text-white shadow-sm hover:bg-error-600 active:bg-error-700 rounded-md',
        secondary:
          'bg-secondary text-foreground shadow-sm hover:bg-secondary-600 active:bg-secondary-700 focus:bg-secondary-600 rounded-md',
        outline:
          'border border-input bg-transparent shadow-sm active:bg-neutral-300 hover:bg-neutral-100 hover:text-neutral-900 rounded-md',
        ghost: 'hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:text-primary-600 active:text-primary-700 focus-visible:ring-0 focus:ring-0',
        icon: 'h-8 w-8 rounded-md',
        tableHeader:
          'bg-neutral-100 pl-4 font-medium m:text-base text-sm text-primary-foreground shadow-none rounded-none  hover:bg-neutral-200  active:bg-neutral-200 border-none outline-none focus-visible:ring-0 focus:ring-0',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-8 rounded-md px-3 text-xs',
        lg: 'h-12 rounded-md px-8',
        icon: 'h-8 w-8',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({className, variant, size, asChild = false, ...props}, ref) => {
    const Comp = asChild ? Slot : 'button';
    return <Comp className={cn(buttonVariants({variant, size, className}))} type="button" ref={ref} {...props} />;
  },
);
Button.displayName = 'Button';

export {Button, buttonVariants};
