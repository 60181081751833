import {CheckedState} from '@radix-ui/react-checkbox';
import {Dispatch, FC, ReactNode, SetStateAction} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';

import {YandexIntegrationType, YandexMetricaBaseModelWithCounter, YandexMetricaSettingsViewDTO} from 'api/dto';
import {Button} from 'shared/components/shadcn-ui/Button';
import {CardActions} from 'shared/components/shadcn-ui/Card/Card';
import {Checkbox} from 'shared/components/shadcn-ui/Checkbox';
import {FormMessage} from 'shared/components/shadcn-ui/Form';
import {Separator} from 'shared/components/shadcn-ui/Separator';
import {RefreshDescription} from 'shared/components/YandexOauth/RefreshDescription';
import {useYandexIntegrationParametersContext} from 'shared/components/YandexOauth/YandexIntegrationProvider';
import {useCurrentCompany, useShowMore} from 'shared/hooks';
import {bem} from 'shared/utils';

import {GoalsError} from './GoalsError';
import s from './GoalsStep.module.scss';
import {InfoBlock} from './InfoBlock';

import {FormValuesType} from '../../../../utils/types';
import {StepType} from '../../utils/types';

type Props = {
  submitButton: ReactNode | null;
  setTabValue: Dispatch<SetStateAction<StepType>>;
};

const sn = bem('goalsStep', s);

export const GoalsStep: FC<Props> = ({submitButton, setTabValue}) => {
  const {yandexParameters} = useYandexIntegrationParametersContext();
  const yandexMetricaParameters = yandexParameters as YandexMetricaSettingsViewDTO;
  const form = useFormContext<FormValuesType>();
  const selectedCounterId = Number(useWatch({control: form.control, name: 'counterStatus.counterId'}));
  const goals = yandexMetricaParameters?.goals?.filter((item) => item.counterId === selectedCounterId) ?? [];
  const selectedGoals = useWatch({control: form.control, name: 'goals'});
  const {hasDemoStatus} = useCurrentCompany();
  const [visibleCount, showMore] = useShowMore(8, 8);

  const submit = () => {
    setTabValue(StepType.segmentStep);
  };

  const goBack = () => {
    setTabValue(StepType.counterStep);
  };

  const getActions = () => {
    if (yandexMetricaParameters?.isEnabled) return null;
    return (
      <>
        <Separator className="mt-12" />
        <CardActions className="ml-auto justify-end">
          <Button variant="outline" onClick={goBack} type="button" className="mt-12 block">
            Назад
          </Button>
          {submitButton ?? (
            <Button type="button" onClick={submit} className="mt-12 block" variant="primary">
              Далее
            </Button>
          )}
        </CardActions>
      </>
    );
  };

  const renderGoal = (goal: YandexMetricaBaseModelWithCounter, index: number) => {
    if (index >= visibleCount) return null;
    return (
      <Checkbox
        key={`key_${goal.yandexId}`}
        className="mb-1"
        name={`goals.${index}`}
        label={goals[index]['name']}
        disabled={goal.isRemovedFromYandex || hasDemoStatus}
        defaultChecked={goal.isActive}
        checked={!!selectedGoals?.includes(goals[index].id)}
        onCheckedChange={(checked: CheckedState) => {
          const newGoals: Set<number> = new Set();
          form.getValues().goals.forEach((item) => newGoals.add(item));
          checked && !goal.isRemovedFromYandex ? newGoals.add(goal.id) : newGoals.delete(goal.id);
          form.setValue('goals', [...newGoals]);
          form.clearErrors('goals');
        }}
      />
    );
  };

  const getContent = () => {
    if (!goals.length) {
      return (
        <>
          <GoalsError />
          <FormMessage className="mt-6">
            <span>{form.formState?.errors?.goals?.message}</span>
          </FormMessage>
          {getActions()}
        </>
      );
    }
    return (
      <>
        <RefreshDescription description="Выберите цели" disabled={hasDemoStatus} />
        {goals.map(renderGoal)}
        {goals.length > visibleCount && (
          <Button variant="link" onClick={showMore}>
            Показать далее
          </Button>
        )}
        <FormMessage className="mt-6">
          <span>{form.formState?.errors?.goals?.message}</span>
        </FormMessage>
        {getActions()}
      </>
    );
  };

  return (
    <div className={s.goalsStep}>
      <div className={sn('content')}>{getContent()}</div>
      <Separator orientation="vertical" className={s.goalsStep__separator} />
      <InfoBlock />
    </div>
  );
};
