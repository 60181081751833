import {ReactElement} from 'react';
import {Navigate, Outlet} from 'react-router';

import {routes} from 'shared/constants/routes';
import {useUserRole} from 'shared/hooks';

type Props = {
  allowedRoles?: string[];
  path?: string;
  children?: ReactElement;
};
export const ProtectedRouteByCompanyRole = ({allowedRoles = [], children}: Props) => {
  const {companyRole} = useUserRole();
  const isAllowed = companyRole ? allowedRoles.includes(companyRole) : false;

  if (!isAllowed) {
    return <Navigate replace to={routes.settings} />;
  }
  return children ? children : <Outlet />;
};
