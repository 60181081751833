import {RefreshCcw} from 'lucide-react';

import {YandexIntegrationType} from 'api/dto';
import {RefreshDescription} from 'shared/components/YandexOauth/RefreshDescription';
import {bem} from 'shared/utils';

import s from '../../../SettingsForm.module.scss';

const sn = bem('settingsForm', s);

export const GoalsError = () => {
  return (
    <>
      <RefreshDescription description="Выберите цели" />
      <h4 className={sn('subtitle')}>Для выбранного счётчика не найдено ни одной активной цели</h4>
      <p>
        Создайте одну или несколько целей для выбранного счётчика. Затем обновите список целей, нажав на кнопку{' '}
        <span className={sn('refresh')}>
          <RefreshCcw />
        </span>
      </p>
    </>
  );
};
