import {zodResolver} from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/browser';
import {createColumnHelper} from '@tanstack/react-table';
import {formatISO} from 'date-fns/esm';
import {SmileIcon, ArrowDown, ArrowUp} from 'lucide-react';
import {FC, useState, useMemo, useRef, useEffect} from 'react';
import {useFieldArray, useForm} from 'react-hook-form';
import * as z from 'zod';

import {NpsAndEnpsViewDTO} from 'api/dto/NpsAndEnpsDTO';
import {LoyaltyApi} from 'api/services/loyaltyApi';
import {MetricCard} from 'shared/components/Dashboards';
import {EditMetricDescription} from 'shared/components/Dashboards/EditMetricDescription';
import {formatValuePercentage, MINI_CARD_TIPS, DIRTY_FORM_CONFIRM_OPTIONS} from 'shared/components/Dashboards/utils';
import {DataTable} from 'shared/components/DataTable';
import {DateCell} from 'shared/components/DateCell';
import {EditableCell} from 'shared/components/EditableCell/EditableCell';
import {RowActions} from 'shared/components/RowActions/RowActions';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Form} from 'shared/components/shadcn-ui/Form';
import {Input} from 'shared/components/shadcn-ui/Input';
import {ScrollArea} from 'shared/components/shadcn-ui/ScrollArea';
import {Separator} from 'shared/components/shadcn-ui/Separator';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from 'shared/components/shadcn-ui/Sheet';
import {toast} from 'shared/components/shadcn-ui/Toast/useToast';
import {HEIGHT_MEDIA_BREAKPOINT_L} from 'shared/constants';
import {useCurrentCompany, useConfirmation, useDimensions} from 'shared/hooks';
import {extractApiErrorMessage} from 'shared/utils/axios';
import {safeParseDate} from 'shared/utils/date';

import {NPS_ENPS_CONFIG} from './utils/constants';
import {NpsOrEnpsRecordType} from './utils/types';
import {FormSchema} from './utils/validationSchema';

export const enum NpsOrEnpsType {
  enps = 1,
  nps = 2,
}

type Props = {
  type: NpsOrEnpsType;
  className?: string;
  value: number | null;
  compareDif: number | null;
  compareResult: boolean | null;
  onAfterEdit: () => void;
};

export const NpsOrEnpsMetricCard: FC<Props> = ({type, className, value, compareDif, compareResult, onAfterEdit}) => {
  const {hasDemoStatus, company} = useCurrentCompany();
  const {confirm} = useConfirmation();
  const [isEditing, setIsEditing] = useState(false);
  const screenHeight = useDimensions().height ?? HEIGHT_MEDIA_BREAKPOINT_L;

  const [removedRecordsIds, setRemovedRecordsIds] = useState<number[]>([]);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const onEditDialogVisibleChange = async (isOpened: boolean) => {
    if (!isOpened) {
      if (form.formState.isDirty && !(await confirm(DIRTY_FORM_CONFIRM_OPTIONS))) return;
      form.reset(form.formState.defaultValues);
    }
    setIsEditing(isOpened);
  };

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {rows: []},
  });
  const tableRows = form.watch('rows');

  const {prepend, remove} = useFieldArray({control: form.control, name: 'rows'});

  const onSubmit = async (values: z.infer<typeof FormSchema>) => {
    if (!company?.id) return;
    const isNewRecords = values.rows.filter((item) => item.id === null).length;
    if (removedRecordsIds.length) {
      await LoyaltyApi.deleteValueList(company.id, Array.from(new Set(removedRecordsIds)));
      setRemovedRecordsIds([]);
      toast({
        title: 'Данные успешно сохранены.',
      });
      !isNewRecords ? cancelEdit() : fetchTableData();
    }
    if (isNewRecords) {
      try {
        await LoyaltyApi.updateValueList(
          company.id,
          values.rows
            .filter((item) => !item.id && item.date)
            .map((item) => {
              const {date, ...itemWithoutDate} = item;
              return {...itemWithoutDate, date: date ? formatISO(date, {representation: 'date'}) : null};
            }),
          type,
        );
        toast({
          description: 'Данные успешно сохранены.',
        });
        cancelEdit();
      } catch (error) {
        Sentry.captureException(error);
        handleApiErrors(error);
      }
    }
  };

  const getPreparedTableItems = (data: NpsAndEnpsViewDTO[]) => {
    return data.map((item) => {
      const {operationDate, ...itemWithoutOperationDate} = item;
      return {
        ...itemWithoutOperationDate,
        date: safeParseDate(operationDate) ?? operationDate,
        [type]: 0,
      };
    });
  };

  const handleApiErrors = (error: unknown) => {
    const extractedError = extractApiErrorMessage(error);
    if (typeof extractedError === 'string') {
      form.reset();
      toast({
        variant: 'destructive',
        description: extractedError || 'Произошла непредвиденная ошибка, повторите попытку позже.',
      });
    }
  };

  const fetchTableData = async () => {
    if (company?.id) {
      try {
        const newTableData = await LoyaltyApi.getValueList(company.id, type);
        const preparedTableItems = getPreparedTableItems(newTableData);
        form.reset({rows: preparedTableItems});
      } catch (error) {
        handleApiErrors(error);
      }
    }
  };

  const addNewRow = () => {
    prepend({
      id: null,
      date: null,
      positive: 0,
      negative: 0,
      neutral: 0,
    });
  };

  const calculateEnpsRecord = (rowIndex: number) => {
    if (!tableRows?.length || !tableRows[rowIndex]) return;
    const {negative, positive, neutral} = tableRows[rowIndex];
    if (!negative || !positive) return '';
    const total = Number(negative) + Number(positive) + Number(neutral);
    const diff = Number(positive) - Number(negative);
    return `${Math.round((diff / total) * 100)}%`;
  };

  const onChange = (
    value: string,
    name: `rows.${number}.neutral` | `rows.${number}.positive` | `rows.${number}.negative`,
  ) => {
    form.setValue(name, value);
    form.trigger();
  };

  const getColumns = useMemo(() => {
    const columnHelper = createColumnHelper<NpsOrEnpsRecordType>();
    return [
      columnHelper.accessor('date', {
        header: 'Дата',
        size: 150,
        cell: DateCell,
      }),
      columnHelper.accessor('positive', {
        size: 120,
        header: 'Сторонники',
        cell: ({row, column}) => {
          return (
            <EditableCell columName={column.id} rowIndex={row.index}>
              <Input
                type="number"
                min={0}
                onChange={(e) => onChange(e.target.value, `rows.${row.index}.positive`)}
                defaultValue={row.original.positive}
                disabled={!!row.original.id}
              />
            </EditableCell>
          );
        },
      }),
      columnHelper.accessor('neutral', {
        header: 'Нейтралы',
        size: 120,
        cell: ({row, column}) => (
          <EditableCell columName={column.id} rowIndex={row.index}>
            <Input
              type="number"
              onChange={(e) => onChange(e.target.value, `rows.${row.index}.neutral`)}
              min={0}
              defaultValue={row.original.neutral}
              disabled={!!row.original.id}
            />
          </EditableCell>
        ),
      }),
      columnHelper.accessor('negative', {
        size: 120,
        header: 'Критики',
        cell: ({row, column}) => (
          <EditableCell columName={column.id} rowIndex={row.index}>
            <Input
              type="number"
              onChange={(e) => onChange(e.target.value, `rows.${row.index}.negative`)}
              min={0}
              defaultValue={row.original.negative}
              disabled={!!row.original.id}
            />
          </EditableCell>
        ),
      }),
      columnHelper.accessor(NPS_ENPS_CONFIG[type].name, {
        size: 75,
        header: NPS_ENPS_CONFIG[type].name,
        cell: ({row}) => {
          return calculateEnpsRecord(row.index);
        },
      }),
      columnHelper.display({
        id: 'actions',
        size: 60,
        cell: RowActions,
      }),
    ];
  }, [tableRows]);

  const removeRow = (rowIndex: number) => {
    const record = form.getValues().rows[rowIndex];
    remove(rowIndex);
    if (record?.id) {
      const {id} = record;
      setRemovedRecordsIds((prev) => prev.concat([id]));
    }
  };

  const startEdit = () => {
    fetchTableData();
    setIsEditing(true);
  };

  const cancelEdit = () => {
    onAfterEdit();
    form.reset();
    setIsEditing(false);
  };

  const isDisabledSubmitBtn =
    hasDemoStatus || (!tableRows.filter((item) => item.id === null).length && !removedRecordsIds.length);

  const scrollToTop = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    scrollToTop();
  }, [tableRows.length]);

  return (
    <>
      <MetricCard
        className={className}
        title={NPS_ENPS_CONFIG[type].title}
        value={value}
        description={MINI_CARD_TIPS.loyalty[type]}
        icon={<SmileIcon />}
        targetIcon={compareResult ? <ArrowUp /> : <ArrowDown />}
        targetValue={compareDif !== null ? Math.abs(compareDif) : compareDif}
        targetBadgeClassname="bg-target"
        onEditIconClick={startEdit}
        formatter={formatValuePercentage}
      />
      <Sheet open={isEditing} onOpenChange={onEditDialogVisibleChange}>
        <SheetContent className="lg:max-w-5xl">
          <ScrollArea className="h-full pr-3">
            <SheetHeader>
              <SheetTitle>Редактирование {NPS_ENPS_CONFIG[type].title}</SheetTitle>
              <SheetDescription dangerouslySetInnerHTML={{__html: NPS_ENPS_CONFIG[type].description}} />
            </SheetHeader>
            <Form {...form}>
              <section>
                <EditMetricDescription
                  title={`Ручные данные ${NPS_ENPS_CONFIG[type].title}`}
                  onButtonClick={addNewRow}
                  disabledAddBtn={hasDemoStatus}
                />
                <form id="editEnpsForm" onSubmit={form.handleSubmit(onSubmit)}>
                  <DataTable<NpsOrEnpsRecordType>
                    state={{pagination: {pageSize: 100, pageIndex: 0}}}
                    columns={getColumns}
                    data={tableRows ?? []}
                    meta={{removeRow}}
                    emptySearchResult="Данные отсутствуют."
                    scrollHeight={screenHeight > HEIGHT_MEDIA_BREAKPOINT_L ? 500 : 350}
                    viewportRef={tableContainerRef}
                  />
                </form>
              </section>
            </Form>
            <Separator className="mt-4 mb-4" />
            <SheetFooter className="justify-start">
              <Button variant="primary" type="submit" form="editEnpsForm" disabled={isDisabledSubmitBtn}>
                Сохранить изменения
              </Button>
            </SheetFooter>
          </ScrollArea>
        </SheetContent>
      </Sheet>
    </>
  );
};
