import * as z from 'zod';

import {REQUIRED_FIELD_MSG} from 'shared/utils/validation';

const MIN_LENGTH_MSG = 'Минимальная длина 3 символа';
const MAX_LENGTH_MSG = 'Максимальная длина 180 символов';

export const EditSystemUserProfileSchema = z.object({
  firstName: z
    .string()
    .nonempty({message: REQUIRED_FIELD_MSG})
    .min(3, {message: MIN_LENGTH_MSG})
    .max(180, {message: MAX_LENGTH_MSG}),
  lastName: z
    .string()
    .nonempty({message: REQUIRED_FIELD_MSG})
    .min(3, {message: MIN_LENGTH_MSG})
    .max(180, {message: MAX_LENGTH_MSG}),
  email: z.string().email({
    message: 'Некорректный email',
  }),
});
