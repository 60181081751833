import {RefreshCcw} from 'lucide-react';

import {IntegrationTypeId} from 'api/dto';
import {IntegrationInfoBlock} from 'shared/components/IntegrationInfoBlock';
import {useCurrentCompany, useIntegrationStatuses} from 'shared/hooks';
import {bem} from 'shared/utils';

import s from '../../CounterStep.module.scss';

const sn = bem('counterStep', s);

export const InfoBlock = () => {
  const {hasDemoStatus} = useCurrentCompany();
  const hasMetricaSettings = useIntegrationStatuses(IntegrationTypeId.YandexMetrica);

  if (hasDemoStatus)
    return (
      <IntegrationInfoBlock
        title="Подключение и выбор счётчика"
        description={[
          <>
            В полной версии вы сможете настроить интеграцию между Яндекс Метрикой и Стафскрин для получения данных об
            аудитории вашего карьерного сайта. Настройка состоит из трёх простых шагов.
          </>,
          <>Вам будет доступна подробная инструкция, для самостоятельной настройки интеграции. </>,
        ]}
      />
    );

  return (
    <IntegrationInfoBlock
      title={hasMetricaSettings ? 'Счётчик' : 'Шаг 1. Подключение и выбор счётчика'}
      previewChars={115}
      description={[
        'Подключение источника данных состоит из трёх небольших шагов. После того, как вы успешно подтвердили аккаунт Яндекс, система «Стафскрин» получила токен, необходимый для работы с API Яндекс Метрики.',
        <>
          На этой странице вам необходимо выбрать один счётчик Яндекс Метрики, который получает статистику вашего сайта.
          Если система «Стафскрин» не нашла ни одного активного счётчика, попробуйте авторизоваться под другой учётной
          записью Яндекса или добавьте новый счётчик к текущему аккаунту, используя эту{' '}
          <a
            className="underline text-link"
            href="https://yandex.ru/support/metrica/general/creating-counter.html"
            target="_blank"
            rel="noreferrer"
          >
            инструкцию
          </a>
          .
        </>,

        <>
          В случае создания нового счётчика обновите страницу, нажав на кнопку{' '}
          <span className={sn('refresh')}>
            <RefreshCcw />
          </span>
        </>,
        <>
          После того, как выбрали счётчик, отметьте, подключён ли он к вашему{' '}
          <a
            className="underline text-link"
            target="_blank"
            href="https://inostudio.com/blog/articles-hr-tech/chto-takoe-karernyy-sayt-kompanii-i-pochemu-on-vam-nuzhen/?utm_medium=staffscreen&utm_campaign=integration&utm_content=yandexmetrika"
            rel="noreferrer"
          >
            карьерному сайту
          </a>
          .
        </>,

        'Если некоторые счётчики в списке неактивны, значит, что они были удалены из Яндекс Метрики и система «Стафскрин» больше не может получать с них данные.',
      ]}
    />
  );
};
