import * as z from 'zod';

export const FormSchema = z
  .object({
    isEnabled: z.boolean(),
    addAllCampaigns: z.boolean().optional(),
    accessToken: z.string(),
    availableAdsCampaigns: z.array(
      z.object({
        id: z.coerce.number(),
        name: z.string(),
        isChecked: z.boolean(),
      }),
    ),
    adsCampaignsIds: z.array(z.number()),
  })
  .refine(
    (val) =>
      (val.isEnabled && !!val.adsCampaignsIds?.length) || !val.isEnabled || (val.addAllCampaigns && val.isEnabled),
    {
      message: 'Выберите одну или несколько рекламных кампаний.',
      path: ['adsCampaignsIds'],
    },
  );
