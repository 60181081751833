import {FC} from 'react';

import s from './CustomTooltip.module.scss';

type Props = {
  label?: string;
  payload?: any[];
  active?: boolean;
  title?: string | null;
};

export const CustomTooltipContent: FC<Props> = ({active, label, payload, title}: Props) => {
  if (active && label && payload?.length) {
    return (
      <div className={s.customTooltip}>
        <p className={s.customTooltip__date}>
          {title === null ? '' : 'Дата:'} {label}
        </p>
        <ul className={s.tooltipItemsList}>
          {payload.map(({value, name, stroke, fill}) => {
            if (name === 'none') return null;
            return (
              <li
                key={`${value}__${name}`}
                className={s.tooltipItemsList__item}
                style={stroke || fill ? {color: stroke || fill} : undefined}
              >
                {name} : {value}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  return null;
};
