type ConfigType = {
  1: {
    name: 'eNPS';
    title: string;
    description: string;
  };
  2: {
    name: 'NPS';
    title: string;
    description: string;
  };
};
export const NPS_ENPS_CONFIG: ConfigType = {
  1: {
    name: 'eNPS',
    title: 'eNPS',
    description: `<p>
                  В таблице ниже отражаются результаты опросов по индексу удовлетворённости сотрудников. Чтобы
                  показатель на дашборде работал корректно, необходимо наполнить поля данными. Для этого выберите дату
                  завершения опроса и укажите количество сторонников, нейтралов и критиков, которые приняли участие в
                  измерении индекса.
                </p>
                <p>
                  Если у вас пока нет исторических данных, мы рекомендуем следующее: определить, с какой периодичностью
                  вы будете проводить опросы, опросить сотрудников и занести данные в поля ниже. Система автоматически
                  рассчитает показатель eNPS и выведет результат на дашборд.
                </p>`,
  },
  2: {
    name: 'NPS',
    title: 'NPS кандидатов',
    description: `<p>В таблице ниже отражаются результаты опросов по индексу удовлетворённости кандидатов. Чтобы показатель
     на дашборде работал корректно, необходимо наполнить поля данными.  Для этого выберите дату завершения опроса и укажите 
     количество сторонников, нейтралов  и критиков, которые приняли участие в измерении индекса.</p>
    <p>Если у вас пока нет исторических данных, мы рекомендуем следующее: определить, с какой периодичностью вы будете 
    проводить опросы по NPS, опросить кандидатов, с которыми вы недавно взаимодействовали, и занести данные в поля ниже. 
    Система автоматически рассчитает показатель NPS и выведет результат на дашборд.</p>`,
  },
};
