import {useContext, useLayoutEffect} from 'react';

import {CompanyStatus} from 'api/dto';
import ShortLogo from 'assets/icons/logo_short_light.svg';
import {Logo} from 'shared/components/Logo';
import {useUserRole} from 'shared/hooks';
import {bem} from 'shared/utils';
import {cn} from 'shared/utils/helpers';
import {useRootSelector} from 'store';

import {AccessRequestCard} from './AccessRequestCard';
import s from './AsideMenu.module.scss';
import {AsideToggleBtn} from './AsideToggleBtn';
import {MenuList} from './MenuList';

import {BaseLayoutContext} from '../BaseLayoutContext';

const sn = bem('asideMenu', s);

export const AsideMenu = () => {
  const currentCompany = useRootSelector((state) => state.companies.currentCompany);
  const {isSuperAdmin} = useUserRole();
  const {toggleAsideMenu, isCollapsedAsideMenu} = useContext(BaseLayoutContext);

  const isShownDemoStatus = currentCompany?.statusName === CompanyStatus.Demo && !isSuperAdmin;

  useLayoutEffect(() => {
    // для маленьких мониторов по умолчанию закрываем сайдбар
    if (window.innerWidth <= 1560) {
      toggleAsideMenu(true);
    }
  }, []);

  return (
    <aside className={cn(s.asideMenu, {[s.asideMenu_collapsed]: isCollapsedAsideMenu})}>
      <div className={sn('wrapper')}>
        {isCollapsedAsideMenu ? (
          <img className={cn(sn('logo'), sn('logo_short'))} src={ShortLogo} alt="Логотип" />
        ) : (
          <Logo className={sn('logo')} theme="light" />
        )}
        {!isSuperAdmin && !isCollapsedAsideMenu && <h1 className={sn('companyName')}>{currentCompany?.companyName}</h1>}
      </div>
      <AsideToggleBtn
        isToggled={isCollapsedAsideMenu}
        toggle={() => {
          toggleAsideMenu(!isCollapsedAsideMenu);
        }}
      />

      <MenuList isToggled={isCollapsedAsideMenu} />
      {isShownDemoStatus && <AccessRequestCard isToggled={isCollapsedAsideMenu} />}
    </aside>
  );
};
