import {useState} from 'react';

import {Button} from 'shared/components/shadcn-ui/Button';
import {MEDIA_BREAKPOINT_L} from 'shared/constants';
import {bem} from 'shared/utils';
import {cn} from 'shared/utils/helpers';

import s from './InfoBlock.module.scss';

const sn = bem('infoBlock', s);

export const InfoBlock = () => {
  const [isFullInfo, setIsFullInfo] = useState(window.innerWidth >= MEDIA_BREAKPOINT_L);

  const direction = window.innerWidth >= MEDIA_BREAKPOINT_L ? 'Слева' : 'Ниже';

  return (
    <div className={s.infoBlock}>
      <h3 className={sn('title')}>Интеграция с Яндекс Директом настроена</h3>
      <p className={cn(sn('paragraf'), {[sn('paragraf_without_margin')]: !isFullInfo})}>
        Поздравляем! Вы успешно справились с подключением источника данных. Через некоторое время данные отобразятся на
        дашборде «HR-бренд».{' '}
        {!isFullInfo && (
          <Button variant="link" onClick={() => setIsFullInfo(true)} className="h-[5px] pl-0">
            Подробнее
          </Button>
        )}
      </p>
      {isFullInfo && (
        <>
          <p className={sn('paragraf')}>
            {direction} показаны выбранные вами рекламные кампании. Вы можете изменить свой выбор в любой момент, нажав
            на кнопку «Редактировать».
          </p>
          <p className={sn('paragraf')}>
            Если остались вопросы, пожалуйста, свяжитесь с менеджером системы:{' '}
            <a className={sn('link')} href="mailto:hello@staffscreen.ru">
              hello@staffscreen.ru
            </a>
          </p>
        </>
      )}
    </div>
  );
};
