import {Card} from 'shared/components/shadcn-ui/Card';
import {CardContent} from 'shared/components/shadcn-ui/Card/Card';
import {Separator} from 'shared/components/shadcn-ui/Separator';
import {useEffectOnce, useUserRole} from 'shared/hooks';
import {useScrollToTopOnMount} from 'shared/hooks/useScrollToTopOnMount';

import {CompanyUserFields} from './components/CompanyUserFields';
import {EditSystemUserProfile} from './components/EditSystemUserProfile';
import {NotificationsSetting} from './components/NotificationsSetting';
import {Options} from './components/Options';

import {useSettingsContext} from '../SettingsContext';

export const Profile = () => {
  useScrollToTopOnMount();
  const {hasCompanyRole} = useUserRole();
  const {showTitle} = useSettingsContext();

  useEffectOnce(() => {
    if (showTitle) showTitle();
  });

  return (
    <Card>
      <CardContent className="mt-0">
        {hasCompanyRole ? <CompanyUserFields /> : <EditSystemUserProfile />}
        <Separator className="w-full mt-8 mb-10" />
        <NotificationsSetting />
        <Separator className="w-full my-10" />
        <Options />
      </CardContent>
    </Card>
  );
};
