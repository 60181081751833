import {
  YandexDirectSettingsViewDTO,
  UpdatedYandexMetricaParametersDTO,
  YandexOAuthSettingsDTO,
  YandexMetricaSettingsViewDTO,
  UpdatedYandexDirectParametersDTO,
} from 'api/dto';

import ApiClient from '../client';

class YandexApiService {
  getBasePath(compId: string | number, type: 'direct' | 'metrica' | 'integrations'): string {
    return `companies/${compId}/integrations/yandex-${type}`;
  }
  getYandexDirectIntegrationSettingsView(compId: string | number) {
    return ApiClient.get<YandexDirectSettingsViewDTO>(this.getBasePath(compId, 'direct')).then((res) => res.data);
  }
  getYandexMetricaIntegrationSettingsView(compId: string | number) {
    return ApiClient.get<YandexMetricaSettingsViewDTO>(this.getBasePath(compId, 'metrica')).then((res) => res.data);
  }
  setYandexMetricaCounters(compId: string | number, payload: UpdatedYandexMetricaParametersDTO) {
    return ApiClient.put(`${this.getBasePath(compId, 'metrica')}/counters`, payload).then((res) => res.data);
  }
  updateDataOfYandexMetricaOnServer(compId: string | number) {
    return ApiClient.get(`${this.getBasePath(compId, 'metrica')}/update-counters`).then((res) => res.data);
  }
  getYandexMetricaRedirectLink(compId: string | number) {
    return ApiClient.get<string>(`${this.getBasePath(compId, 'integrations')}/oauth-url`).then((res) => res.data);
  }
  getYandexDirectRedirectLink(compId: string | number) {
    return ApiClient.get<string>(`${this.getBasePath(compId, 'direct')}/oauth-url`).then((res) => res.data);
  }
  setYandexMetricaIntegrationSettings(compId: string | number, payload: YandexOAuthSettingsDTO) {
    return ApiClient.post(`${this.getBasePath(compId, 'integrations')}/save-integration`, payload).then(
      (res) => res.data,
    );
  }
  setYandexDirectIntegrationSettings(compId: string | number, payload: UpdatedYandexDirectParametersDTO) {
    return ApiClient.post(this.getBasePath(compId, 'direct'), {...payload}).then((res) => res.data);
  }
}

export const YandexApi = new YandexApiService();
