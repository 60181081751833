import {useMemo} from 'react';

import {IntegrationStatusId, IntegrationTypeId} from 'api/dto';
import {ChartInfoBadge} from 'shared/components/Dashboards/ChartInfoBadge';
import {ContentWhenBlurred} from 'shared/components/Dashboards/ContentWhenBlurred';
import {MISSING_DATA_MSG} from 'shared/components/Dashboards/utils';
import {routes} from 'shared/constants/routes';

import {useCurrentCompany} from './useCurrentCompany';
import {useIntegrationStatuses} from './useIntegrationStatuses';

const getPropsForBlur = (integrationType: IntegrationTypeId): {userMessage: string; route: keyof typeof routes} => {
  switch (integrationType) {
    case IntegrationTypeId.Huntflow:
      return {userMessage: 'Завершите интеграцию с Huntflow.', route: 'huntflowIntegration'};
    case IntegrationTypeId.OneC:
      return {userMessage: 'Завершите экспорт данных из 1С', route: 'oneCIntegration'};
    case IntegrationTypeId.YandexDirect:
      return {userMessage: 'Завершите интеграцию с Яндекс Директом.', route: 'yandexDirectIntegration'};
    case IntegrationTypeId.YandexMetrica:
      return {userMessage: 'Завершите интеграцию с Яндекс Метрикой.', route: 'yandexMetricIntegration'};
    default:
      return {userMessage: 'Проверьте настройки интеграций.', route: 'sourcesSettings'};
  }
};

export const useDependencyOnIntegration = (integrationType: IntegrationTypeId) => {
  // TODO: возможно надо объединить логику этого хука с useIntegrationStatuses
  const integrationStatus = useIntegrationStatuses(integrationType);
  const {hasActiveCompanyStatus} = useCurrentCompany();
  const isBlur = hasActiveCompanyStatus && !integrationStatus?.isEnabled;
  const infoMessage = integrationStatus?.info || '';
  const {userMessage, route} = getPropsForBlur(integrationType);

  const infoBadge = useMemo(() => {
    if (isBlur) {
      return <ContentWhenBlurred routeToEnableIntegration={route} messageForUser={userMessage} />;
    }
    if (integrationStatus?.statusId !== IntegrationStatusId.Ready && hasActiveCompanyStatus) {
      return <ChartInfoBadge type={integrationStatus?.statusId} message={infoMessage} />;
    }
    return <ChartInfoBadge type={IntegrationStatusId.Info} message={MISSING_DATA_MSG} />;
  }, [isBlur, integrationStatus, hasActiveCompanyStatus]);

  return {infoBadge, isBlur, integrationStatus, infoMessage};
};
