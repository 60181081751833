import {useState} from 'react';

export const useShowMore = (initialCount: number, increment: number): [number, () => void] => {
  const [visibleCount, setVisibleCount] = useState<number>(initialCount);

  const showMore = () => {
    setVisibleCount((prevCount) => prevCount + increment);
  };

  return [visibleCount, showMore];
};
