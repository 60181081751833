import * as Sentry from '@sentry/browser';
import cn from 'classnames';
import {addMonths} from 'date-fns';
import {Check, ChevronDown} from 'lucide-react';
import {FC, useState} from 'react';

import {CompaniesApi} from 'api';
import {CompanyDetailsDTO, SetStatusDTO} from 'api/dto';
import {DictionaryTypeDTO} from 'api/dto/DictionaryDTO';
import {Button} from 'shared/components/shadcn-ui/Button';
import {PopoverCalendar} from 'shared/components/shadcn-ui/Calendar';
import {Command, CommandGroup, CommandItem} from 'shared/components/shadcn-ui/Command';
import {Popover, PopoverTrigger, PopoverContent} from 'shared/components/shadcn-ui/Popover';
import {toast} from 'shared/components/shadcn-ui/Toast/useToast';
import {useCurrentCompany} from 'shared/hooks';
import {bem} from 'shared/utils';
import {handleApiErrors} from 'shared/utils/helpers';
import {useRootDispatch} from 'store';
import {companiesActions} from 'store/companies';

import s from './SubscriptionBlock.module.scss';

const sn = bem('subscriptionBlock', s);

type Props = {
  company: CompanyDetailsDTO;
};
const defaultErrorMsg = 'Произошла ошибка при смене статуса компаний.';

export const SubscriptionBlock: FC<Props> = ({company}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useRootDispatch();
  const {companyStatusList, activeStatusId, hasActiveCompanyStatus} = useCurrentCompany();
  const [subscriptionStatus, setStatus] = useState<DictionaryTypeDTO>({
    value: company?.statusId,
    description: company?.statusName,
  });
  const [isToggledStatus, setIsToggledStatus] = useState(false);
  const [endDate, setEndDate] = useState<Date | null>(
    company?.statusExpiresAt ? new Date(company?.statusExpiresAt) : null,
  );

  const toggleStatusMenu = () => setIsToggledStatus((prev) => !prev);

  const changeStatus = async (data: SetStatusDTO, description?: string) => {
    try {
      setIsLoading(true);
      await CompaniesApi.setStatus(data, company?.id);
      toast({
        title: 'Статус подписки успешно обновлен.',
      });
      if (description) setStatus({value: data.statusId, description});
      if (data.statusId === activeStatusId) setEndDate(data.expiresAt);
      dispatch(companiesActions.setCurrentCompany({...company, statusId: data.statusId}));
    } catch (error) {
      Sentry.captureException(error);
      handleApiErrors(error, defaultErrorMsg);
    } finally {
      setIsLoading(false);
    }
  };

  const setStatusValue = async ({value, description}: DictionaryTypeDTO) => {
    setIsToggledStatus(false);
    const data = {
      statusId: value,
      expiresAt: value === activeStatusId ? endDate ?? addMonths(new Date(), 1) : null,
    };
    await changeStatus(data, description);
  };

  const changeDate = async (date: Date | undefined) => {
    const data = {
      statusId: subscriptionStatus.value,
      expiresAt: date ?? null,
    };
    await changeStatus(data);
    setEndDate(date ?? null);
  };

  return (
    <div className={s.subscriptionBlock}>
      <div className={sn('field')}>
        <label className={sn('label')}>Статус подписки </label>
        <Popover open={isToggledStatus} onOpenChange={toggleStatusMenu}>
          <PopoverTrigger asChild>
            <Button variant="outline" className="justify-between" disabled={isLoading}>
              <span className={sn('status')}>{isLoading ? 'Обновляем...' : subscriptionStatus.description}</span>
              <ChevronDown className="h-4 w-4" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[200px] p-0">
            <Command>
              <CommandGroup>
                {companyStatusList &&
                  Object.values(companyStatusList).map((status) => (
                    <CommandItem key={status.value} onSelect={() => setStatusValue(status)}>
                      <Check
                        className={cn('mr-2 h-4 w-4', sn('item'), {
                          [sn('item_active')]: status.value === subscriptionStatus.value,
                        })}
                      />
                      {status.description}
                    </CommandItem>
                  ))}
              </CommandGroup>
            </Command>
          </PopoverContent>
        </Popover>
      </div>
      {hasActiveCompanyStatus && (
        <div className={sn('field')}>
          <label className={sn('label')}>Дата окончания</label>
          <PopoverCalendar
            initialFocus
            autoClosing
            mode="single"
            defaultMonth={endDate ?? new Date()}
            selected={endDate ?? undefined}
            disabled={(date) => date < new Date() || isLoading}
            onSelect={changeDate}
          />
        </div>
      )}
    </div>
  );
};
