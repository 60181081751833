import {useEffect, useRef} from 'react';

export function useUnmount<F extends () => void>(fn: F) {
  const callback = useRef<F>(fn);

  useEffect(() => {
    return () => {
      typeof callback.current === 'function' && callback.current();
    };
  }, []);
}
