import {forwardRef} from 'react';
import {PatternFormat} from 'react-number-format';
import {InternalNumberFormatBase} from 'react-number-format/types/types';

import {Input} from '../shadcn-ui/Input';

export type Props = InternalNumberFormatBase & {readOnly?: boolean};

// TODO поискать возможность возвращать только цифры без скобок пробелов и т д
export const PhoneInput = forwardRef<HTMLInputElement, Props>(({readOnly = false, ...props}, ref) => {
  return (
    <PatternFormat
      getInputRef={ref}
      customInput={Input}
      format="+# (###) #### ###"
      placeholder="+7 (  ) ___−__−__"
      readOnly={readOnly}
      mask="_"
      {...props}
    />
  );
});
