import * as amplitude from '@amplitude/analytics-browser';
import {zodResolver} from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/browser';
import {FC, HTMLAttributes, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Link, useNavigate} from 'react-router-dom';
import * as z from 'zod';

import {AccountRoles} from 'api/dto';
import {PasswordInput} from 'shared/components/PasswordInput';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Checkbox} from 'shared/components/shadcn-ui/Checkbox';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'shared/components/shadcn-ui/Dialog';
import {Form, FormControl, FormField, FormItem, FormMessage} from 'shared/components/shadcn-ui/Form';
import {Input} from 'shared/components/shadcn-ui/Input';
import {toast} from 'shared/components/shadcn-ui/Toast/useToast';
import {routes} from 'shared/constants/routes';
import {bem, setValidationFormErrors} from 'shared/utils';
import {UNEXPECTED_ERROR, extractApiErrorMessage, isAxiosError} from 'shared/utils/axios';
import {cn, handleApiErrors} from 'shared/utils/helpers';
import {useRootDispatch} from 'store';
import {authAsyncActions} from 'store/auth/actions';
import {companiesActions} from 'store/companies';
import {companiesAsyncActions} from 'store/companies/actions';
import {dictionariesAsyncActions} from 'store/dictionaries/actions';

import s from './Login.module.scss';
import {FormSchema} from './validationSchema';

const sn = bem('login', s);

type Props = {className?: string} & HTMLAttributes<HTMLDivElement>;

export const Login: FC<Props> = ({className = ''}) => {
  const dispatch = useRootDispatch();
  const navigate = useNavigate();
  const [isShownForbiddenAccessDialog, setShowForbiddenAccessDialog] = useState(false);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: '',
      password: '',
      isRememberMe: true,
    },
  });

  const {isSubmitting} = form.formState;

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    const {email, password, isRememberMe} = data;

    const res = await dispatch(authAsyncActions.signIn({email: email.trim(), password, isRememberMe}));

    if (authAsyncActions.signIn.fulfilled.match(res)) {
      const {
        payload: {systemRoleName, id, companyId = null},
      } = res;
      const redirectPath = systemRoleName === AccountRoles.superAdmin ? routes.companies : routes.generalDashboard;
      if (systemRoleName !== AccountRoles.superAdmin && companyId) {
        dispatch(companiesActions.setCurrentCompanyId(companyId));
        dispatch(companiesAsyncActions.fetchCurrentCompany());
        dispatch(companiesAsyncActions.fetchIntegrationsStatuses());
        dispatch(authAsyncActions.getCompanyUserProfile({companyId, userId: id}));
      }

      // загружаем словари
      dispatch(dictionariesAsyncActions.getUserStatuses());
      dispatch(dictionariesAsyncActions.getCompanyStatuses());
      dispatch(dictionariesAsyncActions.getCompanyRoles());

      amplitude.setUserId(`amp_${id}`);
      navigate(redirectPath);
    } else {
      const {payload} = res;
      if (!payload) {
        Sentry.captureException(res);
        toast({
          variant: 'destructive',
          title: res.error.message ?? UNEXPECTED_ERROR,
        });
      }
      const extractedError = extractApiErrorMessage(payload);
      Sentry.captureException(payload);
      if (isAxiosError(payload) && payload.response?.status === 403) {
        setShowForbiddenAccessDialog(true);
      } else if (typeof extractedError === 'string') {
        handleApiErrors(payload);
      } else {
        setValidationFormErrors<typeof data>(extractedError, (fieldName, fieldErrors) => {
          form.setError(fieldName, {
            message: fieldErrors[0],
          });
        });
      }
    }
  };
  return (
    <div className={cn(className, s.login)}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className={sn('component')}>
          <h2 className={sn('title')}>Авторизация</h2>
          <p className={sn('subtitle')}>Введите свой электронный адрес и пароль.</p>
          <FormField
            control={form.control}
            name="email"
            render={({field}) => (
              <FormItem className="mb-4">
                <FormControl>
                  <Input placeholder="Электронный адрес" autoComplete="off" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({field}) => (
              <FormItem className="mb-4">
                <FormControl>
                  <PasswordInput placeholder="Пароль" autoComplete="off" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="isRememberMe"
            render={({field}) => (
              <Checkbox
                label="Запомнить меня"
                name={field.name}
                className={sn('checkbox')}
                checked={field.value}
                onCheckedChange={field.onChange}
              />
            )}
          />
          <Button type="submit" variant="primary" disabled={isSubmitting}>
            {isSubmitting ? 'Загрузка...' : 'Войти'}
          </Button>
          <Link to={routes.forgotPassword} className={sn('link')}>
            Забыли пароль?
          </Link>
        </form>
      </Form>
      <Dialog open={isShownForbiddenAccessDialog} onOpenChange={setShowForbiddenAccessDialog}>
        <DialogContent className="sm:max-w-[425px] bg-neutral-0 ">
          <DialogHeader>
            <DialogTitle>Ваша учетная запись находится в архиве.</DialogTitle>
            <DialogDescription>
              Для восстановления учетной записи обратитесь, пожалуйста, к администратору компании.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button onClick={() => setShowForbiddenAccessDialog(false)}>Вернуться к авторизации</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
