import {useEffect, useState} from 'react';

import {fullAccessRequestsApi} from 'api/services/fullAccessRequestsApi';
import {SendFullAccessRequestPopup} from 'shared/components/SendFullAccessRequestPopup';
import {useConfirmation, useCurrentCompany, useUserRole} from 'shared/hooks';

export const useFullAccessRequest = () => {
  const {confirm} = useConfirmation();
  const {isCompanyAdmin} = useUserRole();
  const {company, hasDemoStatus} = useCurrentCompany();

  const [isFullAccessSent, setIsFullAccessSent] = useState(true);

  const checkFullAccessSent = async () => {
    let isSent = false;
    if (company?.id) {
      isSent = await fullAccessRequestsApi.checkFullAccessSent(company.id);
    }
    setIsFullAccessSent(isSent);
  };

  useEffect(() => {
    if (isCompanyAdmin && hasDemoStatus) checkFullAccessSent();
  }, [company?.id, isCompanyAdmin, hasDemoStatus]);

  const sendRequest = async () => {
    await confirm({
      title: isFullAccessSent
        ? 'Заявка на полный доступ уже отправлена.'
        : 'Отправка запроса на полный доступ Стафскрин.',
      description: (
        <>
          <span>
            {isFullAccessSent
              ? 'Наш менеджер свяжется с вами по электронной почте в течение 24 часов.'
              : 'После отправки запроса, наш менеджер свяжется с вами по электронной почте в течение 24 часов, чтобы подтвердить ваш запрос.'}
          </span>
          {!isFullAccessSent && (
            <span>Вы действительно хотите отправить запрос на полный доступ к системе Стафскрин?</span>
          )}
        </>
      ),
      acceptButton: isFullAccessSent ? (
        ''
      ) : (
        <SendFullAccessRequestPopup saveToStorage={() => setIsFullAccessSent(true)} />
      ),
      cancelButton: isFullAccessSent ? 'Ок' : 'Отмена',
    });
  };

  return {sendRequest, isFullAccessSent};
};
