import * as Sentry from '@sentry/browser';
import cn from 'classnames';
import {AlertCircleIcon, CheckCircleIcon} from 'lucide-react';
import {FC} from 'react';

import {UsersApi} from 'api';
import {CompanyUserDTO} from 'api/dto';
import {Button} from 'shared/components/shadcn-ui/Button';
import {useToast} from 'shared/components/shadcn-ui/Toast/useToast';
import {bem} from 'shared/utils';
import {handleApiErrors} from 'shared/utils/helpers';

import s from './AdminEmailConfirmation.module.scss';

type Props = {
  className?: string;
  admin: CompanyUserDTO;
};

const sn = bem('adminEmailConfirmation', s);

export const AdminEmailConfirmation: FC<Props> = ({admin, className}: Props) => {
  const {toast} = useToast();
  const {emailConfirmed} = admin;
  const icon = emailConfirmed ? <CheckCircleIcon /> : <AlertCircleIcon />;
  const sendConfirmationLink = async () => {
    try {
      await UsersApi.sendConfirmationEmailLink(admin.userId);
      toast({
        title: 'Письмо отправлено.',
      });
    } catch (e) {
      Sentry.captureException(e);
      handleApiErrors(e, 'При отправке запроса произошла ошибка. Повторите попытку.');
    }
  };
  return (
    <div className={s.adminEmailConfirmation}>
      <div
        className={cn(
          s.adminEmailConfirmation__badge,
          {
            [sn('badge_isConfirmed')]: emailConfirmed,
          },
          className,
        )}
      >
        {icon}
        <span className={sn('badgeText')}>
          {emailConfirmed ? 'Электронный адрес подтверждён' : 'Электронный адрес не подтверждён.'}
        </span>
      </div>
      {!emailConfirmed && (
        <Button className={sn('button')} variant="outline" onClick={sendConfirmationLink}>
          Отправить ссылку на подтвеждение
        </Button>
      )}
    </div>
  );
};
