import {FC, useEffect, useState} from 'react';

import {CompanyListDTO} from 'api/dto';
import {HeaderProps} from 'shared/components/DataTable/tableTypes';
import {Button} from 'shared/components/shadcn-ui/Button';
import {Popover, PopoverContent, PopoverTrigger} from 'shared/components/shadcn-ui/Popover';
import {bem} from 'shared/utils';
import {cn} from 'shared/utils/helpers';

import s from './SortableByAscOrDesTableTitle.module.scss';

import {AscOrDesSortBlock} from '../AscOrDesSortBlock';
import {CurrentIconForSortedHead} from '../CurrentIconForSortedHead';

const sn = bem('sortableByAscOrDesTableTitle', s);

export const SortableByAscOrDesTableTitle: FC<HeaderProps<CompanyListDTO, unknown>> = ({column, title}) => {
  const [sortingDirection, setSortingDirection] = useState<'asc' | 'desc' | 'none'>('none');
  const [isToggled, setIsToggled] = useState(false);

  useEffect(() => {
    handleSort(sortingDirection);
  }, [sortingDirection]);

  const toggleMenu = () => setIsToggled((prev) => !prev);

  const handleSort = (mode: 'asc' | 'desc' | 'none') => {
    if (mode === 'asc') {
      column.toggleSorting(false);
    } else if (mode === 'desc') {
      column.toggleSorting(true);
    } else if (mode === 'none') {
      column.clearSorting();
    }
  };

  const handleSortingClick = (direction: string) => {
    if (direction === 'asc' || direction === 'desc' || direction === 'none') setSortingDirection(direction);
  };

  return (
    <div className={s.sortableByAscOrDesTableTitle}>
      <Popover open={isToggled} onOpenChange={toggleMenu}>
        <PopoverTrigger asChild>
          <Button
            variant="tableHeader"
            className={cn(sn('button'), {[sn('button_active')]: sortingDirection !== 'none'})}
          >
            <span className={sn('span')}>{title}</span>
            <CurrentIconForSortedHead isToggled={isToggled} sortingDirection={sortingDirection} />
          </Button>
        </PopoverTrigger>
        <PopoverContent className={sn('content')} align="end">
          <AscOrDesSortBlock
            handleSortingClick={handleSortingClick}
            sortingDirection={sortingDirection}
            toggleMenu={toggleMenu}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};
