import * as z from 'zod';

import {REQUIRED_FIELD_MSG} from 'shared/utils/validation';

export const FormSchema = z.object({
  rows: z
    .array(
      z.object({
        id: z.coerce.number().nullable(),
        reasonId: z.coerce.number().nullable(),
        reason: z.string().nonempty({message: REQUIRED_FIELD_MSG}),
        qty: z.coerce.number().min(1, {message: REQUIRED_FIELD_MSG}),
        date: z
          .date({
            invalid_type_error: REQUIRED_FIELD_MSG,
          })
          .nullable(),
      }),
    )
    .superRefine((rows, ctx) => {
      const keyCount = new Map();

      rows.forEach((row) => {
        if (row.date && row.reasonId !== null) {
          const key = `${row.date.toISOString()}-${row.reasonId}`;
          keyCount.set(key, (keyCount.get(key) || 0) + 1);
        }
      });

      rows.forEach((row, index) => {
        if (row.date && row.reasonId !== null) {
          const key = `${row.date.toISOString()}-${row.reasonId}`;
          if (keyCount.get(key) > 1) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Укажите разные причины увольнения.',
              path: [index, 'reason'],
            });
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: ' ',
              path: [index, 'date'],
            });
          }
        }
      });
    }),
});
