import {CompanyStatus} from 'api/dto';
import {DictionaryTypeDTO} from 'api/dto/DictionaryDTO';
import {useRootSelector} from 'store';
import {dictionariesSelector} from 'store/dictionaries/selectors';

const getActiveSubscriptionStatusId = (list: DictionaryTypeDTO[] | null): number | null => {
  return (
    list?.find(
      ({description}) => description.toLowerCase().includes('актив') && !description.toLowerCase().includes('не'),
    )?.value ?? null
  );
};

export function useCurrentCompany() {
  const companyStatusList = useRootSelector(dictionariesSelector.getDictionaries).companyStatuses;
  const activeStatusId = getActiveSubscriptionStatusId(companyStatusList);
  const company = useRootSelector((state) => state.companies.currentCompany);
  const companyStatusId = company?.statusId;
  const hasActiveCompanyStatus = companyStatusId === activeStatusId;
  const hasDemoStatus = company?.statusName === CompanyStatus.Demo;
  return {
    company,
    companyStatusList,
    activeStatusId,
    hasActiveCompanyStatus,
    hasDemoStatus,
  };
}
