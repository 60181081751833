import {FilterFnOption, SortingFnOption, createColumnHelper} from '@tanstack/react-table';
import {generatePath, Link} from 'react-router-dom';

import {CompanyDTO, CompanyListDTO} from 'api/dto';
import {FilteredByStatusTableTitle} from 'modules/SuperAdmin/Companies/components/FilteredByStatusTableTitle';
import {SortableByAscOrDesTableTitle} from 'modules/SuperAdmin/Companies/components/SortableByAscOrDesTableTitle';
import {DatePickerTableTitle, DirType} from 'shared/components/DatePickerTableTitle';
import {Badge} from 'shared/components/shadcn-ui/Badge';
import {routes} from 'shared/constants/routes';
import {bem} from 'shared/utils';
import {DEFAULT_DATE_FORMAT, safeFormatDate} from 'shared/utils/date';

import {getBadgeVariant} from './helpers';

import s from '../Companies.module.scss';

const sn = bem('companies', s);

const columnHelper = createColumnHelper<CompanyListDTO>();

export const getColumns = () => {
  // startDateSortDir и endDateSortDir не могут быть отличны от 'none' одновременно,
  // потому что одновременная сортировка по дате создания и по дате окончания нелогична
  let startDateSortDir: DirType = 'none';
  let endDateSortDir: DirType = 'none';

  const setStartDateSortDir = (dir: DirType) => {
    endDateSortDir = 'none';
    startDateSortDir = dir;
  };
  const setEndDateSortDir = (dir: DirType) => {
    startDateSortDir = 'none';
    endDateSortDir = dir;
  };

  return [
    columnHelper.accessor('companyName', {
      header: 'Название компании',
      cell: ({row, ...rest}) => (
        <Link to={generatePath(routes.company, {compId: String(row.original.id)})} className={sn('name')}>
          {String(rest.getValue())}
        </Link>
      ),
      meta: {className: 'grow'},
      id: 'name',
    }),

    columnHelper.accessor('usersCount', {
      header: ({column}) => <SortableByAscOrDesTableTitle title="Кол-во пользователей" column={column} />,
      cell: (info) => <div className={sn('usersNumber')}>{String(info.getValue())}</div>,
      id: 'usersNumber',
    }),

    columnHelper.accessor('createdAt', {
      sortingFn: 'datetime',
      filterFn: 'dateFilter' as FilterFnOption<CompanyListDTO>,
      header: (info) => {
        return (
          <DatePickerTableTitle
            title="Дата создания"
            column={info.column}
            dateSortDir={endDateSortDir}
            setDateSortDir={setEndDateSortDir}
          />
        );
      },
      cell: (info) => (
        <div className={sn('toDate')}>{safeFormatDate(info.getValue(), DEFAULT_DATE_FORMAT) || 'Без даты'}</div>
      ),
      id: 'createdAt',
    }),
    columnHelper.accessor('statusExpiresAt', {
      sortingFn: 'dateWithNullSort' as SortingFnOption<CompanyDTO>, // TODO убрать as
      filterFn: 'dateFilter' as FilterFnOption<CompanyListDTO>, // TODO убрать as
      header: (info) => (
        <DatePickerTableTitle
          title="Дата окончания"
          column={info.column}
          dateSortDir={startDateSortDir}
          setDateSortDir={setStartDateSortDir}
        />
      ),
      cell: (info) => (
        <div className={sn('toDate')}>{safeFormatDate(info.getValue(), DEFAULT_DATE_FORMAT) || 'Без даты'}</div>
      ),
      id: 'statusExpiresAt',
    }),
    columnHelper.accessor('statusName', {
      filterFn: 'arrIncludesSome',
      header: ({column}) => <FilteredByStatusTableTitle title="Статус подписки" column={column} />,
      cell: ({row, getValue}) => {
        return <Badge status={getBadgeVariant(row.original.statusId)}>{String(getValue())}</Badge>;
      },
      id: 'status',
    }),
    columnHelper.accessor('companyAdminEmail', {
      header: 'Администратор',
      id: 'admin',
      meta: {
        className: 'break-words',
      },
    }),
  ];
};
