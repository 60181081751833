import {InputHTMLAttributes, forwardRef} from 'react';

import {cn} from 'shared/utils/helpers';

import {useFormField} from '../Form';

export type Props = InputHTMLAttributes<HTMLInputElement>;

const Input = forwardRef<HTMLInputElement, Props>(({className, type, value, ...props}, ref) => {
  const {error} = useFormField();
  return (
    <input
      type={type}
      value={type === 'number' ? Number(value).toString() : value}
      className={cn(
        `flex h-10 w-full rounded-md border border-neutral-300
        bg-neutral-100 px-3 py-2 text-base text-neutral-900 placeholder:text-neutral-400
        focus:outline-none enabled: active:bg-neutral-100 focus:border-primary-400 read-only:focus:border-primary-100
        read-only:active:border-primary-100 
        autofill:bg-primary-400  active:border-primary-400 placeholder-shown:bg-neutral-0
        invalid:[&:not(:placeholder-shown)]:border-error invalid:[&:not(:placeholder-shown)]: 
        focus:invalid:border-error focus:invalid:text-error
        disabled:cursor-not-allowed disabled:opacity-50 transition duration-360 ease-out hover:ease-in`,
        error
          ? 'border-error invalid-text text-error active:border-error active:bg-neutral-0 focus:border-error focus:text-error bg-neutral-0'
          : '',
        className,
      )}
      ref={ref}
      {...props}
    />
  );
});
Input.displayName = 'Input';

export {Input};
