import {Outlet} from 'react-router';

import {Logo} from 'shared/components/Logo';
import {bem} from 'shared/utils';

import s from './AuthLayout.module.scss';

const sn = bem('authLayout', s);

export function AuthLayout() {
  return (
    <div className={s.authLayout}>
      <Logo className={sn('logo')} />
      <div className={sn('form')}>
        <Outlet />
      </div>
      <p className={sn('caption')}>{`© ${new Date().getFullYear()} Стафскрин`}</p>
    </div>
  );
}
