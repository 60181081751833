import axios from 'axios';

const utcOffset = new Date().getTimezoneOffset();

const ApiClient = axios.create({
  baseURL: '/api/v1',
  withCredentials: true,
  headers: {Timezoneoffset: -1 * utcOffset}, // отправляем разницу между текущим временем и временем в utc, а не наоборот
});

export default ApiClient;
