import * as z from 'zod';

import {CompanyUserSchema, REQUIRED_FIELD_MSG} from 'shared/utils/validation';

export const TAXPAYER_IDENTIFICATION_NUMBER_MSG = 'Проверьте правильность ИНН';
const COMPANY_NAME_MSG = 'Проверьте наименование организации';
const LEGAL_ADDRESS_MSG = 'Проверьте юридический адрес';

export const CompanyStepSchema = z.object({
  inn: z
    .string()
    .nonempty({message: REQUIRED_FIELD_MSG})
    .min(3, {message: TAXPAYER_IDENTIFICATION_NUMBER_MSG})
    .max(200, {message: TAXPAYER_IDENTIFICATION_NUMBER_MSG}),
  companyName: z
    .string()
    .nonempty({message: REQUIRED_FIELD_MSG})
    .min(3, {message: COMPANY_NAME_MSG})
    .max(300, {message: COMPANY_NAME_MSG}),

  legalAddress: z
    .string()
    .nonempty({message: REQUIRED_FIELD_MSG})
    .min(3, {message: LEGAL_ADDRESS_MSG})
    .max(180, {message: LEGAL_ADDRESS_MSG}),
});

export const mergedSchema = CompanyStepSchema.merge(CompanyUserSchema);
